import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddOutcomesModal from './AddOutcomesModal';
import { ClockIcon, DeleteIcon, EditIcon } from '../../Constant/Icons';
import { Popconfirm, Select, Tag, Tooltip, message } from 'antd';
import { deleteCourse, getCourse, updateTierAndVisiblityCourse } from '../../module/features/courses';
import { EyeClosed, EyeOpen } from '../../Constant/Icons';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Button, Input, Space, Table } from 'antd'
import Column from 'antd/es/table/Column';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { useRef } from 'react';
import { deleteOutcomes, getOutcomes, getScheduledOutcome } from '../../module/features/outcomesSlice';
import './AddOutcomes.scss'
import ScheduleOutcomesModal from './AddSchedule';

function AddOutcomes() {
    const dispatch = useDispatch()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenSchedule, setIsModalOpenSchedule] = useState(false);
    const [editState, setEditState] = useState(false)
    const outcomes = useSelector(state => state.outcomesSlice.outcomes)
    const scheduledOutcome = useSelector(state => state.outcomesSlice.scheduledOutcome)

    const showModal = (data) => {
        setIsModalOpen(true);
        setEditState(data)
    };
    const handleOk = () => {
        setIsModalOpen(false);
        setEditState(false)
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setEditState(false)
    };
    const showModalSchedule = (data) => {
        setIsModalOpenSchedule(true);
        // setEditState(data)
    };
    const handleOkSchedule = () => {
        setIsModalOpenSchedule(false);
        setEditState(false)
    };
    const handleCancelSchedule = () => {
        setIsModalOpenSchedule(false);
        setEditState(false)
    };
    const callback = () => {
        dispatch(getOutcomes())
    }
    const confirm = (data) => {
        dispatch(deleteOutcomes({ id: data.id, callback: callback }))
    };
    const cancel = (e) => {
        console.log(e);
    };

    const confirmSchedule = (data) => {
        showModalSchedule()
    };
    const cancelSchedule = (e) => {
        console.log(e);
    };
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    useEffect(() => {
        console.log(scheduledOutcome, 'scheduledOutcome')
    }, [scheduledOutcome])
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch([], confirm, dataIndex)
                        }}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const updateTierAndVisiblityCourseFn = (data, id) => {
        dispatch(updateTierAndVisiblityCourse({ data: data, id: id, callback }))
    }

    return (
        <div className='AddOutcomes'>
            <div className='innerAddOutcomes'>
                <div className='PageHeading'>
                    <h1>Outcomes</h1>
                    <div className='buttonDiv'>
                        {/* {scheduledOutcome ?
                            <Popconfirm
                                title="Outcomes are already Scheduled"
                                description={() => {
                                    return (
                                        <>
                                            Outcomes repeat after every: <b>{scheduledOutcome.repeatAfter}</b> at <b>{scheduledOutcome.time}</b>
                                            <br />
                                            <span>Do you want to reschedule?</span>
                                        </>
                                    )
                                }}
                                onConfirm={() => confirmSchedule()}
                                onCancel={cancelSchedule}
                                okText="Yes"
                                cancelText="No"
                            >
                                <button className='hoverEffect' >
                                    <ClockIcon />
                                    Schedule
                                </button>
                            </Popconfirm>
                            :
                            <button className='hoverEffect' onClick={() => showModalSchedule(false)}>
                                <ClockIcon />
                                Schedule
                            </button>
                        } */}
                        <button className='hoverEffect' onClick={() => showModal(false)}>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8Z"></path><path d="M192 474h672q8 0 8 8v60q0 8-8 8H160q-8 0-8-8v-60q0-8 8-8Z"></path></svg>
                            Add New
                        </button>
                    </div>
                </div>
                <div className='coursesTable'>
                    <div className='coursesTableDiv'>
                        <Table dataSource={outcomes}
                        >
                            <Column width="90%" filterDropdown={getColumnSearchProps('name').filterDropdown} filterIcon={getColumnSearchProps('name').filterIcon} onFilter={getColumnSearchProps('name').onFilter}
                                onFilterDropdownOpenChange={getColumnSearchProps('name').onFilterDropdownOpenChange} title="Outcome Name" className='tdDiv title cursorNormal' dataIndex="name" key="name"
                                filterSearch

                                render={getColumnSearchProps('name').render}
                            />
                            <Column title="Actions"
                                align='center'
                                sorter={(a, b) => a?.hidden?.length - b?.hidden?.length}
                                render={(text, record, index) => (
                                    <div className='actionButtons'>

                                        {/* <Tooltip title={"Set Visibility"}>
                                            <div>
                                                {record.hidden ?
                                                    <Tag color="red" onClick={() => {
                                                        updateTierAndVisiblityCourseFn({
                                                            "hidden": 0,
                                                        }, record.id)
                                                    }}>Hidden</Tag>
                                                    :

                                                    <Tag color="blue" onClick={() => {
                                                        updateTierAndVisiblityCourseFn({
                                                            "hidden": 1,
                                                        }, record.id)
                                                    }}>Visible</Tag>
                                                }
                                            </div>
                                        </Tooltip> */}
                                        <button className='editButton' onClick={(e) => {
                                            e.stopPropagation()
                                            showModal(record)
                                        }}>
                                            <EditIcon />
                                        </button>
                                        <Popconfirm
                                            title="Delete the outcome"
                                            description="Are you sure to delete this outcome?"
                                            onConfirm={() => confirm(record)}
                                            onCancel={cancel}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <button className='deleteButton' onClick={(e) => {
                                                e.stopPropagation()
                                            }}>
                                                <DeleteIcon />
                                            </button>
                                        </Popconfirm>
                                    </div>
                                )}
                            />
                        </Table>
                        {/* <table className='coursesTable'>
                            <thead>
                                <tr>
                                    <th className='title'>Course Title</th>
                                    <th className='status'>Level</th>
                                    <th className='status'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {courses?.map((a, i) => (
                                    <tr>
                                        <td>
                                            <div>
                                                {a.course}
                                            </div>
                                        </td>
                                        <td>
                                            {a.degree}
                                        </td>
                                        <td>
                                            <div className='actionButtons'>
                                                <button className='editButton' onClick={() => {
                                                    showModal(a)
                                                }}>
                                                    <EditIcon />
                                                </button>
                                          
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> */}
                    </div>
                </div>
                {/* <div className='coursesList hidePc'>
                    <div className='innerCoursesList'>
                        {courses?.map((a, i) => (
                            <div className='courseCard'>
                                <div className='title'>
                                    <h2>Course Name</h2>
                                    <p>{a.course}</p>
                                </div>
                                <div className='level'>
                                    <h2>Level</h2>
                                    <p>{a.degree}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}
            </div>
            <AddOutcomesModal
                showModal={showModal}
                handleOk={handleOk}
                handleCancel={handleCancel}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                editState={editState}
            />

        </div>
    )
}

export default AddOutcomes