import { Modal, Radio, Space, Spin } from 'antd';
import CheckableTag from 'antd/es/tag/CheckableTag';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../Constant/Spinner/Spinnner';
import { getCompetencies, updateTierAndVisiblityCompetency } from '../../module/features/competency';
import { getCourse, updateTierAndVisiblityCourse } from '../../module/features/courses';
import { ClockIcon } from '../../Constant/Icons';

function AddOutcomeModal({
    isModalOpen,
    handleOk,
    handleCancel,
    editState,
    showModalSchedule

}) {
    const outcomes = useSelector(state => state.outcomesSlice.outcomes)
    const outcomesLoader = useSelector(state => state.outcomesSlice.outcomesLoader)
    const competenciesOutcome = useSelector(state => state.outcomesSlice.competenciesOutcome)

    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [selectedTags, setSelectedTags] = useState([]);
    const [repeatNumber, setRepeatNumber] = useState(1)
    const [shiftHeld, setShiftHeld] = useState(false);
    const [repeatDay, setRepeatDay] = useState('days')
    const handleChange = (tag, checked) => {
        console.log(tag, checked, 'sadsadasd')
        const nextSelectedTags = checked
            ? [...selectedTags, tag.id]
            : selectedTags.filter((t) => t !== tag.id);
        console.log('You are interested in: ', nextSelectedTags);
        setSelectedTags(nextSelectedTags);
    };
    // useEffect(() => {
    //     if (competenciesOutcome && competenciesOutcome.length) {
    //         let arr = competenciesOutcome.map((a, i) => {
    //             return a.id
    //         })
    //         setSelectedTags(arr)
    //     }
    // }, [competenciesOutcome])
    const clearState = () => {
        setSelectedTags([])
    }
    const callback = () => {
        setLoader(false)
        dispatch(getCourse());
        // showModalSchedule()
        handleOk()
    }
    const updateOutcomes = () => {
        setLoader(true)
        let data = {
            "outcomes": selectedTags,
            "repeatAfter": repeatNumber + " " + repeatDay
        }
        console.log(data, 'datadata')
        dispatch(
            updateTierAndVisiblityCourse({ data: data, id: editState.id, callback })
        );
    }

    useEffect(() => {
        if (editState) {
            setRepeatNumber(Number(editState?.repeatAfter?.split(' ')[0]))
            if (editState.outcomes.length) {
                let arr = []
                editState?.outcomes?.map(({ outcome }, i) => {
                    arr.push(outcome.id)
                })
                let updData = { ...editState, outcomes: arr }
                console.log(updData, 'updData')
                setSelectedTags(arr)
            }
        }
    }, [editState])
    return (
        <Modal className='AddOutcomeModal' title="Add Outcome" open={isModalOpen} footer={false} onCancel={() => { handleCancel(); clearState() }}>
            <div className='coursesTableDiv'>
                <div className='form'>

                    <label className='addCourseInput addCourseInputShedule ' style={{ width: '50%' }}>
                        <p onClick={() => {
                            console.log(selectedTags, 'selectedTags')
                        }}>Repeat After</p>
                        {/* <div className='addCourse'> */}
                        <div className='scheduleTimeInput'>

                            <input style={{ width: '50%' }} placeholder='Outcome Name'
                                value={repeatNumber}
                                type='number'
                                // value={data.fullname}
                                onChange={(ev) => {
                                    setRepeatNumber(ev.target.value)
                                }}
                            />
                            <Radio.Group onChange={(ev) => {
                                console.log(ev.target.value, 'ev.target.value')
                                setRepeatDay(ev.target.value)
                            }} value={repeatDay}>
                                <Space direction="horizontal">
                                    <Radio value={"days"}>Days</Radio>
                                    <Radio value={"months"}>Months</Radio>
                                </Space>
                            </Radio.Group>
                            {/* <button className='hoverEffect'>Add</button>
                 */}
                        </div>
                    </label>
                </div>
            </div>
            {/* <div className='addCourseInput'> */}
            <p className='selectOutcomeText' onClick={() => {
                console.log(outcomes, 'outcomes')
            }}>Select Outcome *</p>
            {/* </div> */}

            <div className='innerAddOutcomeModal'>
                {outcomesLoader ?
                    <Spin />
                    :
                    <>

                        {outcomes?.map((a, i) => (
                            <CheckableTag
                                className='outcomeTag'
                                key={i}
                                checked={selectedTags.includes(a.id)}
                                onChange={(checked) => handleChange(a, checked)}
                            >
                                {a.name}
                            </CheckableTag>
                        ))}
                    </>
                }
            </div>
            <div className='footer'>
                <button className='addCompetencyButton cancel' onClick={handleCancel}>Cancel</button>
                {/* <button className='hoverEffect addCompetencyButton add' onClick={() => showModalSchedule(false)}>
                    <ClockIcon />
                    Schedule
                </button> */}
                <button className='addCompetencyButton add' onClick={updateOutcomes}>{loader ? <Spinner /> : `Update`}</button>

            </div>
        </Modal>
    )
}

export default AddOutcomeModal
