import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddProfessorModal from './AddProfessorModal';
import { DeleteIcon, EditIcon } from '../../Constant/Icons';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Button, Input, Space, Table } from 'antd'
import Column from 'antd/es/table/Column';
import { useRef } from 'react';
import { getProfessors } from '../../module/features/professor';


function Professor() {
    const dispatch = useDispatch()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editState, setEditState] = useState(false)
    const professorList = useSelector(state => state.professorSlice.professorList)
    const showModal = (data) => {
        setIsModalOpen(true);
        setEditState(data)
    };
    const handleOk = () => {
        setIsModalOpen(false);
        setEditState(false)
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setEditState(false)
    };
    const callback = () => {
        dispatch(getProfessors())
    }
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch([], confirm, dataIndex)
                        }}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    return (
        <div className='AddCourse'>
            <div className='innerAddCourse'>
                <div className='PageHeading'>
                    <h1>Add Professor</h1>
                    <button className='hoverEffect' onClick={() => showModal(false)}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8Z"></path><path d="M192 474h672q8 0 8 8v60q0 8-8 8H160q-8 0-8-8v-60q0-8 8-8Z"></path></svg>
                        Add Professor
                    </button>
                </div>
                <div className='coursesTable'>
                    <div className='coursesTableDiv'>
                        <Table dataSource={professorList}
                        >
                            <Column width="40%" filterDropdown={getColumnSearchProps('professor_name').filterDropdown} filterIcon={getColumnSearchProps('professor_name').filterIcon} onFilter={getColumnSearchProps('professor_name').onFilter}
                                onFilterDropdownOpenChange={getColumnSearchProps('professor_name').onFilterDropdownOpenChange} title="Professor Name" className='tdDiv title' dataIndex="professor_name" key="professor_name"
                                filterSearch

                                render={getColumnSearchProps('professor_name').render}
                            />
                            <Column title="Department Name" dataIndex="department_name" key="department_name"
                                sorter={(a, b) => a.department_name.length - b.department_name.length}
                                render={(text) => (
                                    <span>
                                        {text}
                                    </span>
                                )}
                            />
                            <Column title="Actions"
                                align='center'
                                sorter={(a, b) => a.hidden.length - b.hidden.length}
                                render={(text, record, index) => (
                                    <div className='actionButtons'>

                                        <button className='editButton' onClick={(e) => {
                                            e.stopPropagation()
                                            showModal(record)
                                        }}>
                                            <EditIcon />
                                        </button>

                                    </div>
                                )}
                            />
                        </Table>
                    </div>
                </div>
            </div>
            <AddProfessorModal
                showModal={showModal}
                handleOk={handleOk}
                handleCancel={handleCancel}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                editState={editState}
            />
        </div>
    )
}

export default Professor