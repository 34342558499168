import React from 'react'
import loginGIF from './../../Assest/loginGIF.json'
import { Link, useNavigate } from 'react-router-dom'
import './Authentication.scss'
import { useLottie } from "lottie-react";
import logo from './../../Assest/logo.png'
import { useState } from 'react';
import { login } from '../../module/features/authentication';
import { useDispatch } from 'react-redux';
import Spinner from '../../Constant/Spinner/Spinnner';
import SocialLogin from './SocialLogin';
import { EyeClosed, EyeOpen } from '../../Constant/Icons';


function Login() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [passwordInputType, setPasswordInputType] = useState('password')
    const [data, setData] = useState({
        email: '',
        password: ''
    })
    const options = {
        animationData: loginGIF,
        loop: true
    };
    const clearState = (user) => {
        setData({
            email: '',
            password: ''
        })
        const url = new URL(window.location)
        console.log(user, 'asdasdas')
        if (user.type === "admin") {
            window.location.href = "/dashboard/Admin/Users"
        } else {
            const redirect = url.searchParams.get('redirect')
            window.location.href = redirect ? redirect : "/dashboard"
        }
    }
    const { View } = useLottie(options);
    const loginFunction = () => {
        dispatch(login({ data: data, clearState: clearState, setLoader }))
    }
    const register = () => {
        navigate(`/register`)
    }
    const forgetPassword = () => {
        navigate(`/confirm-email`)
    }
    return (
        <div className='authenticationPage'>
            <div className='innerAuthenticationPage'>
                <div className='gifBoxDiv'>
                    <div className='gifBox'>
                        {View}
                    </div>
                </div>
                <div className='form center'>
                    <div className='formDIv'>

                        <div>
                            <h3>Login with email</h3>
                        </div>
                        <div className='innerForm'>
                            <label>
                                <p>Email</p>
                                <input placeholder='Email'
                                    type='email'
                                    value={data.email}
                                    onChange={(ev) => {
                                        let obj = { ...data }
                                        obj.email = ev.target.value
                                        setData(obj)
                                    }}
                                />
                            </label>
                            <label>
                                <p>Password</p>
                                <div className='passwordInput'>
                                    <input placeholder='Password'
                                        type={passwordInputType}
                                        value={data.password}
                                        onChange={(ev) => {
                                            let obj = { ...data }
                                            obj.password = ev.target.value
                                            setData(obj)
                                        }}
                                        onKeyDown={(ev) => {
                                            if (ev.keyCode === 13) {
                                                loginFunction()
                                            }
                                        }}
                                    />
                                    <button className='showPassword' onClick={() => {
                                        if (passwordInputType === 'password') {
                                            setPasswordInputType('text')
                                        } else {
                                            setPasswordInputType('password')
                                        }
                                    }}>
                                        {passwordInputType === 'password' ?
                                            <EyeOpen />
                                            :
                                            <EyeClosed />
                                        }
                                    </button>
                                </div>
                            </label>
                            <div className='loginButtons'>
                                <div>
                                    <button className='hoverEffect' onClick={() => loginFunction()}>{loader ? <Spinner /> : 'Login'}</button>
                                    <p>Don't have an account? <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={register}>Register Now</span></p>
                                </div>
                                <p><span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={forgetPassword}>Forget password?</span></p>
                            </div>
                        </div>
                        {/* <SocialLogin type="student" /> */}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Login