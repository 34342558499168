import React, { useEffect, useState } from "react";
// import "./LandingPage.scss";
// import "./NewLandingPage.scss";
import "./aboutus.scss";
import "../LandingPage/NewLandingPage.scss"
import { NavLink, useNavigate } from "react-router-dom";
import LandingPageNavbar from "../Navbar/LandingPageNavbar";
import NewLandNavBar from "../Navbar/NewLandNavBar";
import slider1 from "./../../Assest/slider1.png"
import slider2 from "./../../Assest/slider2.png"
import slider3 from "./../../Assest/slider3.png"
import card1Vector from "./../../Assest/card1Vector.png"
import card2Vector from "./../../Assest/card2Vector.png"
import card3Vector from "./../../Assest/card3Vector.png"
import testimonial from "./../../Assest/testimonial.png"
import gradhatIcon from "./../../Assest/gradhatIcon.png"
import searchIcon from "./../../Assest/searchIcon.png"
import topUniversity1 from "./../../Assest/topUniversity1.png"
import topUniversity2 from "./../../Assest/topUniversity2.png"
import topUniversity3 from "./../../Assest/topUniversity3.png"
import nextArrow from "./../../Assest/nextArrow.png"
import professor1 from "./../../Assest/TopProfessors/professor1.png"
import professor2 from "./../../Assest/TopProfessors/professor2.png"
import professor3 from "./../../Assest/TopProfessors/professor3.png"
import professor4 from "./../../Assest/TopProfessors/professor4.png"
import course1 from "./../../Assest/course1.png";
import course2 from "./../../Assest/course2.png";
import course3 from "./../../Assest/course3.png";


// import Swiper from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles

import { Pagination } from 'swiper/modules';

import { Card, Col, Row } from 'antd';

// import image from "../../Assest/big_rmp_logo_black.svg";
import image from './../../Assest/logo.png'
import EditRatingImg from "../../Assest/rmp-Family/rating.png";
import AnonymousRatingImg from "../../Assest/rmp-Family/anonymous.png";
import LikeRatingImg from "../../Assest/rmp-Family/like.png";
import LandingPageFooter from "../footer/LandingPageFooter";
import { Carousel, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
// import OutcomesModal from "./OutcomesModal";
import { getUserOutcome } from "../../module/features/outcomesSlice";
import Search from "antd/es/input/Search";
import SearchSchool from "../SearchSchool/SearchSchool";
import Nosubscribefooter from "../footer/Nosubscribefooter";
const { Option } = Select;
function AboutUs() {
    const [professorSchool, setProfessorSchool] = useState(false);
    const dispatch = useDispatch()
    const [professorArr, setProfessorArr] = useState([])
    const professorList = useSelector(e => e.professorSlice.professorList)
    const universityList = useSelector(state => state.universitySlice.universityList)
    const navigate = useNavigate();
    useEffect(() => {
        if (professorSchool) {

            if (professorList) {
                let arr = professorList.map((a, i) => {
                    return {
                        value: a.id,
                        label: a.professor_name
                    }
                })
                setProfessorArr(arr)
                console.log(professorList, 'professorList')
            }
        } else if (universityList) {
            let arr = universityList.map((a, i) => {
                return {
                    value: `${a.OBJECTID}`,
                    label: `${a.NAME}`,
                    ...a
                }
            })
            setProfessorArr(arr)
            console.log(professorList, 'professorList')
            // setProfessorArr([])

        }
    }, [professorList, professorSchool, universityList])
    const onChange = (value) => {
        if (professorSchool) {
            navigate(`/professors?id=${value}`)
        } else {
            navigate(`/school?id=${value}`)

        }
        console.log(`selected ${value} `);
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    const renderOptionUniversity = (option) => {
        // Customize the rendering of each option
        return (
            <div className="universityListOpt" onClick={() => console.log(option)}>
                <div>
                    <span style={{ marginRight: '8px' }}>{option.label}</span>
                    <div>
                        <span className="location">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 32C167.67 32 96 96.51 96 176c0 128 160 304 160 304s160-176 160-304c0-79.49-71.67-144-160-144zm0 224a64 64 0 1 1 64-64 64.07 64.07 0 0 1-64 64z"></path></svg>
                            &nbsp;
                            {option.CITY ? option.CITY + ", " : ''} {option.COUNTRY ? option.COUNTRY : ''}</span>
                    </div>
                </div>
            </div>
        );
    };


    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const showModal = () => {
    //     setIsModalOpen(true);
    // };
    // const handleOk = () => {
    //     setIsModalOpen(false);
    // };
    // const handleCancel = () => {
    //     setIsModalOpen(false);
    // };
    // useEffect(() => {
    //     const url = new URL(window.location)
    //     const course = url.searchParams.get('course')
    //     const userid = url.searchParams.get('userid')
    //     if (course) {
    //         showModal()
    //         dispatch(getUserOutcome({
    //             "ids": course,
    //             "userId": userid
    //         }))
    //     }
    // }, [])
    const imageArr = [topUniversity1,
        topUniversity2,
        topUniversity3]
    return (
        <React.Fragment>
            <div className="landing-page">
                {/* <LandingPageNavbar /> */}
                <NewLandNavBar bg={'#D9E8FF5E'} />


                <div className="aboutus-Section">


                    <div className="Aboutus-header">
                        <h1>About us</h1>
                    </div>

                    <div className="aboutus-sub-section">

                        <div className="para-header"><h2>Welcome to Ungrade.us</h2></div>
                        <div className="para-sub-text"><p>
                            Welcome to Ungrade.us! We're here to help you understand your college education better and get ready for the real world. We know it can be tough to figure out if you're getting the education you need. That's why we're here to help.</p>
                        </div>

                        <div className="para-header"><h2>What Ungrade.us aims for?</h2></div>
                        <div className="para-sub-text"><p>

                            Our main goal at Ungrade.us is to help students like you make smart choices about your education and future jobs. Our website lets you look up, rate, and talk about teachers, colleges, and universities all over the world. This helps you pick the best courses and teachers for you.</p>
                        </div>

                        <div className="para-header"><h2>Empowering Student Decisions!</h2></div>
                        <div className="para-sub-text"><p>

                            Our website is made so students can share their thoughts on their education. It's not just about picking the right class; it's about understanding how your skills match up with what jobs are out there</p>
                        </div>

                        <div className="para-header"><h2>Learning with Data</h2></div>
                        <div className="para-sub-text"><p>
                         What makes Ungrade.us special is how accurate and relevant our information is. You can check your skills before and after your classes, see how you're doing in detail, and get tips on how to improve. This approach helps you learn better and gets you ready for work with real skills you can show to employers.</p>
                        </div>

                        <div className="para-header"><h2>A Tool for Everyone!</h2></div>
                        <div className="para-sub-text"><p>
                           Ungrade.us isn't just for students; schools can use it too. It helps with getting recognized, marketing, and making education better. We make sure our tests and advice match up with what the Department of Labor suggests, so everything is useful and up-to-date.</p>
                        </div>

                        <div className="para-header"><h2>Making Education A Better Place For Everyone.</h2></div>
                        <div className="para-sub-text"><p>
                          Come and be part of Ungrade.us. We're more than just giving grades—we're about making education better for everyone.</p>
                        </div>


                    </div>



                </div>

                



                <Nosubscribefooter />
            </div>

        </React.Fragment>
    );
}

export default AboutUs;
