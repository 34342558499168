import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddAssesmentModal from "./AddAssesmentModal";
import "./addAssessment.scss";
import { useEffect } from "react";
import { getAssessments } from "../../module/features/assesments";
import { EditIcon } from "../../Constant/Icons";
import { Dropdown, Space } from "antd";
import AddDescriptionModal from "./AddDescriptionModal";
import {
  getCompetencybyId,
  getSelectedCompetenciesById,
} from "../../module/features/competency";
import { Editor, EditorState, convertFromRaw } from "draft-js";
function AddAssesment() {
  const dispatch = useDispatch();
  const assessments = useSelector((state) => state.assessmentSlice.assessments);
  const competency = useSelector(
    (state) => state.competencySlice.singleCompentency
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDesModalOpen, setIsDesModalOpen] = useState(false);
  const [editState, setEditState] = useState(false);
  const [status, setStatus] = useState("pre");
  const url = new URL(window.location);

  const items = [
    {
      label: "Pre",
      key: "Pre",
      onClick: () => {
        setStatus("pre");
      },
    },
    {
      label: "Post",
      key: "Post",
      onClick: () => {
        setStatus("post");
      },
    },
  ];

  const showModal = (data) => {
    setIsModalOpen(true);
    setEditState(data);
  };
  const showDesModal = (data) => {
    setIsDesModalOpen(true);
    setEditState(competency);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setIsDesModalOpen(false);
    setEditState(false);
  };
  const handleCancel = () => {
    setIsDesModalOpen(false);
    setIsModalOpen(false);
    setEditState(false);
  };

  useEffect(() => {
    const assessmentId = url.searchParams.get("id");
    dispatch(getAssessments({ id: assessmentId, status: status }));
    let data = {
      id: assessmentId,
    };
    dispatch(getCompetencybyId(data));
  }, [status]);

  const backButton = () => {
    window.history.back();
  };

  useEffect(() => {
    console.log(competency, 'competencycompetency')
  }, [competency])
  return (
    <div className="AddAssesment">
      <div className="innerAddAssesment">
        <div className="PageHeading">
          <h1>
            <span onClick={backButton}>
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 16 16"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                ></path>
              </svg>
            </span>
            &nbsp;{url.searchParams.get("name")} Assessments
          </h1>
          <div className="PageHEaderSelect">
            <Dropdown
              className="Dropdown"
              menu={{
                items,
              }}
              trigger={["click"]}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  {status}
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 448 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
                  </svg>
                </Space>
              </a>
            </Dropdown>
            <button
              className="hoverEffect"
              style={{
                marginRight: "10px",
              }}
              onClick={() => showDesModal(false)}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 1024 1024"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8Z"></path>
                <path d="M192 474h672q8 0 8 8v60q0 8-8 8H160q-8 0-8-8v-60q0-8 8-8Z"></path>
              </svg>{" "}
              Add Description
            </button>
            <button className="hoverEffect" onClick={() => showModal(false)}>
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 1024 1024"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8Z"></path>
                <path d="M192 474h672q8 0 8 8v60q0 8-8 8H160q-8 0-8-8v-60q0-8 8-8Z"></path>
              </svg>{" "}
              Add Question
            </button>
          </div>
        </div>
        <p className="descriptionText">
          {competency?.description ? (
            <Editor
              readOnly={true}
              editorState={competency?.description ? EditorState.createWithContent(
                convertFromRaw(JSON.parse(competency?.description || ''))
              ) : null}
            />
          ) : (
            ""
          )}
        </p>
        <div className="coursesTable hideMob">
          <div className="coursesTableDiv">
            <table className="OccupationalTable">
              <thead>
                <tr>
                  <th className="Question">Question</th>
                  <th className="CompetencyName">Question Name</th>
                  <th className="key">key</th>
                  <th className="">Action</th>
                </tr>
              </thead>
              <tbody>
                {assessments?.map((a, i) => (
                  <tr>
                    <td>
                      <div className="tdDiv">{a.question}</div>
                    </td>
                    <td>
                      <div className="tdDiv CompetencyName_value">{a.name}</div>
                    </td>
                    <td>
                      <div className="tdDiv key_value">{a.key}</div>
                    </td>
                    <td>
                      <div className="actionButtons">
                        <button
                          className="editButton"
                          onClick={() => {
                            showModal(a);
                          }}
                        >
                          <EditIcon />
                        </button>
                        {/* <Popconfirm
                            title="Delete Course"
                            description="Are Your Sure You Want To Delete Course"
                            onConfirm={() => confirm(a)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                          >

                            <button className='deleteButton'>
                              <DeleteIcon />
                            </button>
                          </Popconfirm> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="assesmentList hidePc">
          <div className="innerAssesmentList">
            {assessments?.map((a, i) => (
              <div className="assesment">
                <div className="title">
                  <h2>Question</h2>
                  <p>{a.question}</p>
                </div>
                <div className="CompetencyName">
                  <h2>Competency name</h2>
                  <p>{a.name}</p>
                </div>
                <div className="Key">
                  <h5>Key</h5>
                  <p>{a.key}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <AddAssesmentModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        editState={editState}
        status={status}
      />
      <AddDescriptionModal
        isModalOpen={isDesModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        editState={editState}
        status={status}
      />
    </div>
  );
}

export default AddAssesment;
