import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit'
import { message } from 'antd';
import axios from 'axios'
import { toast } from 'react-toastify';
import { getCookie } from '../../Constant/Utils';

const initialState = {
    userProfile: null
}

export const getProfile = createAsyncThunk(
    "completeProfileSlice/getProfile",
    async (data) => {
        const token = getCookie('ungradeToken');
        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/client/profile/user`,
                headers: {
                    'authorization': token
                }
            };


            const response = await axios.request(config);
            return response.data.data; // Return the data here

        } catch (error) {
            console.error(error);
        }
    });


export const completeProfileSlice = createSlice({
    name: 'completeProfileSlice',
    initialState,
    reducers: {
        completeProfile: (state, action) => {
            let data = JSON.stringify(action.payload.data);
            action.payload.setLoader(true)
            const token = getCookie('ungradeToken');

            let config = {
                method: 'post',
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/client/profile/user`,
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': token
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    action.payload.callback()
                    message.success("Profile Updated")
                    action.payload.setLoader(false)
                })
                .catch((error) => {
                    console.log(error)
                    let errorMsg = error?.response?.data?.error  || error?.response?.data?.message || 'Please Try Again10'
                    message.error(errorMsg)

                    action.payload.setLoader(false)
                });


        },
        updateProfile: (state, action) => {
            try {
                console.log(action.payload)
                let data = JSON.stringify(action.payload.data);
                action.payload.setLoader(true)
                const token = getCookie('ungradeToken');
                let config = {
                    method: 'patch',
                    url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/client/profile/user`,
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': token

                    },
                    data: data
                };

                axios.request(config)
                    .then((response) => {
                        if (action.payload.callback) {
                            action.payload.callback()
                            message.success("Profile Updated")
                            action.payload.setLoader(false)
                        }
                    })
                    .catch((error) => {
                        let errorMsg = error?.response?.data?.error  || error?.response?.data?.message || 'Please Try Again11'
                        console.log(error)
                        message.error(errorMsg)

                        action.payload.setLoader(false)
                    });
            } catch (err) {
                console.log(err, 'dasdasdasd')

            }
        },
        updateProfileTier: (state, action) => {
            let data = JSON.stringify(action.payload.data);
            const token = getCookie('ungradeToken');
            // action.payload.setLoader(true)
            let config = {
                method: 'patch',
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/client/profile/user`,
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': token

                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    action.payload.callback()
                    // action.payload.setLoader(false)
                })
                .catch((error) => {
                    let errorMsg = error?.response?.data?.error  || error?.response?.data?.message || 'Please Try Again tier'
                    message.error(errorMsg)

                    // action.payload.setLoader(false)
                });
        },
        uploadImage: (state, action) => {

            let config = {
                method: 'post',
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}upload`,
                headers: {
                    'Content-Type': 'multipart/form-data',


                },
                data: action.payload.data
            };

            axios.request(config)
                .then((response) => {
                    action.payload.callback(response.data)
                    // action.payload.setLoader(false)
                })
                .catch((error) => {
                    let errorMsg = error?.response?.data?.error  || error?.response?.data?.message || 'Please Try Again tier'
                    message.error(errorMsg)

                    // action.payload.setLoader(false)
                });
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getProfile.pending, (state, action) => {
                // state.isLoading = true;
                // state.hasError = false;
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                state.userProfile = action.payload;
                //   state.isLoading = false;
                //   state.hasError = false
            })
            .addCase(getProfile.rejected, (state, action) => {
                //   state.hasError = true
                //   state.isLoading = false;
            })
    }
})

export const { completeProfile, updateProfile, updateProfileTier, uploadImage } = completeProfileSlice.actions

export default completeProfileSlice.reducer