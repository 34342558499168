import { Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'

function UserCompetencies({ record, expanded, student_id }) {
  const [competencies, setCompetencies] = useState(false)
  // useEffect(() => {
  //   if (!competencies && record) {
  //     console.log(record, 'record')

  //     let config = {
  //       method: 'get',
  //       maxBodyLength: Infinity,
  //       url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}competency/getByCourse/${record.id}`,
  //       headers: {}
  //     };

  //     axios.request(config)
  //       .then((responsecompetencies) => {
  //         console.log(responsecompetencies.data.competencies, 'response.data.competencies')

  //         let config2 = {
  //           method: 'post',
  //           maxBodyLength: Infinity,
  //           url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}assesments/AllStudentAverageResult/`,
  //           headers: {
  //             'Content-Type': 'application/json'
  //           },
  //           data: {
  //             userId: student_id,
  //             competencyIds: responsecompetencies.data.competencies
  //           }
  //         };
  //         axios.request(config2)
  //           .then((response) => {
  //             console.log(response.data, 'response.data')
  //             let arr = []
  //             responsecompetencies.data.competencies.map((a, i) => {
  //               return response.data.data.map((b, j) => {
  //                 if (b.competencyId === a.id) {
  //                   arr.push({
  //                     ...a,
  //                     ...b,
  //                     scoreGroup: Number(b.scoreGroup).toFixed(2),
  //                     scoreIndividual: Number(b.scoreIndividual).toFixed(2)

  //                   })
  //                 }
  //               })
  //             })
  //             setCompetencies(arr)
  //           })
  //           .catch((error) => {
  //             console.log(error);
  //           });


  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });

  //   }
  // }, [competencies, record])
  const columns = [
    {
      title: 'Competency Name',
      dataIndex: 'compentencyName',
      key: 'compentencyName',
    },
    {
      title: 'Class Average Score',
      dataIndex: 'scoreGroup',
      key: 'scoreGroup'
    },
    {
      title: 'Individual Score',
      dataIndex: 'scoreIndividual',
      key: 'scoreIndividual',
    },
  ];
  return (
    <p
      style={{
        margin: 0,
      }}
    >
      <Table dataSource={competencies} columns={columns} pagination={false} />;
    </p>
  )
}

export default UserCompetencies