import React, { useEffect, useRef, useState } from "react";
import "./ProfessorRating.scss";
import ComputerIcon from "../../Assest/footerLanding/computer-icon.svg";
import CheckMark from "../../Assest/footerLanding/icons-checkmark-white.svg";
import alert from "../../Assest/RateMyProfessor/alert.9c5c6685.svg";
import caratBlack from "../../Assest/RateMyProfessor/caret-up-black.cba128c4.svg";
import { Button, Divider, Input, Select, Space, Tag } from "antd";
import LandingPageFooter from "../footer/LandingPageFooter";
import ProfessorRatingNavbar from "../Navbar/professorRatingNavbar";
import RatingSlider from "../UniversityRating/RatingSlider";
import { useDispatch, useSelector } from "react-redux";
import { addProfessorRating } from "../../module/features/professor";
import { useNavigate } from "react-router-dom";
import Spinner from "../../Constant/Spinner/Spinnner";
import NewLandNavBar from "../Navbar/NewLandNavBar";
const { CheckableTag } = Tag;

let ratingObj = ["Awful", "OK", "Good", "Great", "Awesome"];
let DifficultyObj = [
  "Very Easy",
  "Easy",
  "Average",
  "Difficult",
  "Very Difficult",
];
const tagsData = [
  "Tough Grader",
  "Get Ready To Read",
  "Participation Matters",
  "Extra Credit",
  "Group Projects",
  "Amazing Lectures",
  "Clear Grading Criteria",
  "Gives Good Feedback",
  "Inspirational",
  "Lots Of Homework",
  "Hilarious",
  "Beware Of Pop Quizzes",
  "So Many Papers",
  "Caring",
  "Respected",
  "Lecture Heavy",
  "Test Heavy",
  "Graded By Few Things",
  "Accessible Outside Class",
  "Online Savvy",
];
const grades = [
  {
    value: "A+",
    label: "A+"
  },
  {
    value: "A",
    label: "A"
  },
  {
    value: "A-",
    label: "A-"
  },
  {
    value: "B+",
    label: "B+"
  },
  {
    value: "B",
    label: "B"
  },
  {
    value: "B-",
    label: "B-"
  },
  {
    value: "C+",
    label: "C+"
  },
  {
    value: "C",
    label: "C"
  },
  {
    value: "C-",
    label: "C-"
  },
  {
    value: "D+",
    label: "D+"
  },
  {
    value: "D",
    label: "D"
  },
  {
    value: "D-",
    label: "D-"
  },
  {
    value: "F",
    label: "F"
  },
  {
    value: "Audit/No Grade",
    label: "Audit/No Grade"
  },
  {
    value: "Drop/Withdrawal",
    label: "Drop/Withdrawal"
  },
  {
    value: "Incomplete",
    label: "Incomplete"
  },
  {
    value: "Not sure yet",
    label: "Not sure yet"
  },
  {
    value: "Rather not say",
    label: "Rather not say"
  }
]

const ProfessorRating = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const courses = useSelector(state => state.coursesSlice.courses)
  const [selectedTags, setSelectedTags] = useState([]);
  const [courseToggle, setCourseToggle] = useState(false);
  const [classValue, setclassValue] = useState(false)
  const [online, setonline] = useState(false)
  const [rating, setrating] = useState(false)
  const [difficulty, setdifficulty] = useState(false)
  const [wouldTakeAgain, setwouldTakeAgain] = useState(false)
  const [forCredit, setforCredit] = useState(false)
  const [usesTextbooks, setusesTextbooks] = useState(false)
  const [attendanceMandatory, setattendanceMandatory] = useState(false)
  const [grade, setgrade] = useState(false)
  const [tags, settags] = useState(false)
  const [comment, setcomment] = useState(false)
  const [teacherID, setteacherID] = useState(false)
  const [items, setItems] = useState([]);
  const [name, setName] = useState('');
  const [loader, setLoader] = useState(false)
  const inputRef = useRef(null);
  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, name]);
    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };
  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const handleChange = (value) => {
    setgrade(value.value)
    console.log(value); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
  };

  const handleTagsChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    console.log("You are interested in: ", nextSelectedTags);
    setSelectedTags(nextSelectedTags);
  };
  useEffect(() => {
    if (courses) {
      let arr = courses.map((a, i) => a.course)
      setItems(arr)
    }
  }, [courses])
  const callback = () => {
    setLoader(false)
    navigate(`/professors${window.location.search}`);

  }
  const submitRating = () => {
    const url = new URL(window.location);
    const id = url.searchParams.get("id");
    let data = {
      "forCredit": forCredit,
      "attendanceMandatory": attendanceMandatory,
      "tags": selectedTags.join(),
      "class": classValue,
      "wouldTakeAgain": wouldTakeAgain,
      "usesTextbooks": usesTextbooks,
      "teacherID": id,
      "rating": rating,
      "difficulty": difficulty,
      "online": online,
      "grade": grade,
      "comment": comment
    }
    setLoader(true)
    dispatch(addProfessorRating({ data: data, callback }))
  }

  return (
    <div className="professor-rating">
      <NewLandNavBar bg={'#f1f7ff'} />
      <header className="professor-rating-second-header">
        <div className="child-professor-rating">
          <div className="professor-rating-second-header-clild">
            <div className="professor-rating-second-header-heading not-bold">
              Rate:{" "}
            </div>
            <div className="professor-rating-second-header-heading">
              Steve , Quane
            </div>
          </div>
          <div className="professor-rating-second-header-clild">
            <div className="TeacherTitles-heading">Geology</div>
            <span className="TeacherTitles__StyledDot">·</span>
            <div className="TeacherTitles-text">Quest University</div>
          </div>
        </div>
      </header>
      <div className="rating-section-form">
        <div className="rating-form">
          <div className="rating-form-child">
            <div className="rating-form-child-Label">
              Select Course Code<span>*</span>
            </div>
            <div
              className="rating-form-child-body"
              style={{ flexDirection: "column" }}
            >
              <Select
                style={{
                  width: 300,
                }}
                placeholder="custom dropdown render"
                onChange={(ev) => {
                  setclassValue(ev)
                }}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: '8px 0',
                      }}
                    />
                    <Space
                      style={{
                        padding: '0 8px 4px',
                      }}
                    >
                      <Input
                        placeholder="Please enter item"
                        ref={inputRef}
                        value={name}
                        onChange={onNameChange}
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                      <Button type="text" onClick={addItem}>
                        Add item
                      </Button>
                    </Space>
                  </>
                )}
                options={items.map((item) => ({
                  label: item,
                  value: item,
                }))}
              />

              {/* <Select
                showSearch
                placeholder="Select a person"
                style={{ width: "360px" }}
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={filterOption}
                options={[
                  {
                    value: "jack",
                    label: "Jack",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "tom",
                    label: "Tom",
                  },
                ]}
              /> */}
              <div className="rating-second-child">
                <div
                  className={
                    courseToggle
                      ? "course-checkbox course-checkbox-active"
                      : "course-checkbox"
                  }
                  onClick={() => {
                    setCourseToggle(!courseToggle)
                    // if(!courseToggle){
                    setonline(!courseToggle)
                    // }
                  }}
                >
                  {courseToggle && <img src={CheckMark} />}
                </div>
                <div className="online-course">
                  <img src={ComputerIcon} />
                  <span>This is an online course</span>
                </div>
              </div>
            </div>
          </div>
          <div className="rating-form-child">
            <div className="rating-form-child-Label">
              Rate your professor<span>*</span>
            </div>
            <RatingSlider setState={setrating} />
          </div>
          <div className="rating-form-child">
            <div className="rating-form-child-Label">
              How difficult was this professor?<span>*</span>
            </div>
            <RatingSlider setState={setdifficulty} />
          </div>
          <div className="rating-form-child">
            <div className="rating-form-child-Label">
              Would you take this professor again?<span>*</span>
            </div>
            <div className="rating-form-child-body">
              <div className="rating-form-child-body-child">
                <input
                  type="radio"
                  name="wouldTakeAgain"
                  id="wouldTakeAgain-Yes"
                  value="Yes"
                  label="Would you take this professor again?"
                  className="RadioButtons__StyledRadioButton-sc-1ho7g4w-3 icTrWI"
                  onChange={(ev) => {
                    setwouldTakeAgain(true)
                  }}
                />
                <label className="RadioButtons__RadioButtonLabel-sc-1ho7g4w-2 bjnSgl">
                  Yes
                </label>
              </div>
              <div className="rating-form-child-body-child">
                <input
                  type="radio"
                  name="wouldTakeAgain"
                  id="wouldTakeAgain-No"
                  value="No"
                  label="Would you take this professor again?"
                  className="RadioButtons__StyledRadioButton-sc-1ho7g4w-3 gYNYsj"
                  onChange={(ev) => {
                    setwouldTakeAgain(false)
                  }}
                />
                <label className="RadioButtons__RadioButtonLabel-sc-1ho7g4w-2 bjnSgl">
                  No
                </label>
              </div>
            </div>
          </div>
          <div className="rating-form-child">
            <div className="rating-form-child-Label">
              Was this class taken for credit?
            </div>
            <div className="rating-form-child-body">
              <div className="rating-form-child-body-child">
                <input
                  type="radio"
                  name="forCredit"
                  id="forCredit-Yes"
                  value="Yes"
                  label="Was this class taken for credit?"
                  className="RadioButtons__StyledRadioButton-sc-1ho7g4w-3 icTrWI"
                  onChange={(ev) => {
                    setforCredit(true)
                  }}
                />
                <label className="RadioButtons__RadioButtonLabel-sc-1ho7g4w-2 bjnSgl">
                  Yes
                </label>
              </div>
              <div className="rating-form-child-body-child">
                <input
                  type="radio"
                  name="forCredit"
                  id="forCredit-No"
                  value="No"
                  label="Was this class taken for credit?"
                  className="RadioButtons__StyledRadioButton-sc-1ho7g4w-3 gYNYsj"
                  onChange={(ev) => {
                    setwouldTakeAgain(false)
                  }}
                />
                <label className="RadioButtons__RadioButtonLabel-sc-1ho7g4w-2 bjnSgl">
                  No
                </label>
              </div>
            </div>
          </div>
          <div className="rating-form-child">
            <div className="rating-form-child-Label">
              Did this professor use textbooks?
            </div>
            <div className="rating-form-child-body">
              <div className="rating-form-child-body-child">
                <input
                  type="radio"
                  name="usesTextbooks"
                  id="usesTextbooks-Yes"
                  value="Yes"
                  label="Did this professor use textbooks?"
                  className="RadioButtons__StyledRadioButton-sc-1ho7g4w-3 icTrWI"
                  onChange={(ev) => {
                    setusesTextbooks(true)
                  }}
                />
                <label className="RadioButtons__RadioButtonLabel-sc-1ho7g4w-2 bjnSgl">
                  Yes
                </label>
              </div>
              <div className="rating-form-child-body-child">
                <input
                  type="radio"
                  name="usesTextbooks"
                  id="usesTextbooks-No"
                  value="No"
                  label="Did this professor use textbooks?"
                  className="RadioButtons__StyledRadioButton-sc-1ho7g4w-3 gYNYsj"
                  onChange={(ev) => {
                    setusesTextbooks(false)
                  }}
                />
                <label className="RadioButtons__RadioButtonLabel-sc-1ho7g4w-2 bjnSgl">
                  No
                </label>
              </div>
            </div>
          </div>
          <div className="rating-form-child">
            <div className="rating-form-child-Label">
              Was attendance mandatory?
            </div>
            <div className="rating-form-child-body">
              <div className="rating-form-child-body-child">
                <input
                  type="radio"
                  name="attendanceMandatory"
                  id="attendanceMandatory-Yes"
                  value="Yes"
                  label="Was attendance mandatory?"
                  className="RadioButtons__StyledRadioButton-sc-1ho7g4w-3 icTrWI"
                  onChange={(ev) => {
                    setattendanceMandatory(true)
                  }}
                />
                <label className="RadioButtons__RadioButtonLabel-sc-1ho7g4w-2 bjnSgl">
                  Yes
                </label>
              </div>
              <div className="rating-form-child-body-child">
                <input
                  type="radio"
                  name="attendanceMandatory"
                  id="attendanceMandatory-No"
                  value="No"
                  label="Was attendance mandatory?"
                  className="RadioButtons__StyledRadioButton-sc-1ho7g4w-3 gYNYsj"
                  onChange={(ev) => {
                    setattendanceMandatory(false)
                  }}
                />
                <label className="RadioButtons__RadioButtonLabel-sc-1ho7g4w-2 bjnSgl">
                  No
                </label>
              </div>
            </div>
          </div>
          <div className="rating-form-child">
            <div className="rating-form-child-Label">Select grade received</div>
            <div className="rating-form-child-body">
              <Select
                labelInValue
                value={grade}
                style={{
                  width: "360px",
                }}
                onChange={handleChange}
                options={grades}
              />
            </div>
          </div>
          <div className="formCard_formCardContainer">
            <div className="formCard">
              <div className="bNqnap">
                Select up to 3 tags
                <span></span>
              </div>
              <div className="rating-form-child-body tags-parent">
                {tagsData.map((tag) => (
                  <CheckableTag
                    className="check-tags"
                    key={tag}
                    checked={selectedTags.includes(tag)}
                    onChange={(checked) => handleTagsChange(tag, checked)}
                  >
                    {tag}
                  </CheckableTag>
                ))}
              </div>
            </div>
          </div>
          <div className="formCard_formCardContainers">
            <div className="bUsHOg">
              <div className="bNqnap">
                Write a Review
                <span>*</span>
              </div>
              <div className="kocErm">
                Discuss the professor's professional abilities including
                teaching style and ability to convey the material clearly
              </div>
              <div className="jFzfvl">
                <div>
                  <div className="ktRyxZ">
                    <div className="gSZISy">
                      <div className="ckuecy">
                        <img src={alert} alt="alert" className="alert" />
                        <h2 className="iflaUE">Guidelines</h2>
                      </div>
                      <img
                        src={caratBlack}
                        alt="carat-black"
                        className="carat-black"
                      />
                    </div>
                    <div className="exWlGN">
                      <ol className="bXOTWo">
                        <li>
                          Your rating could be removed if you use profanity or
                          derogatory terms.
                        </li>
                        <li>
                          Don't claim that the professor shows bias or
                          favoritism for or against students.
                        </li>
                        <li>Don’t forget to proof read!</li>
                      </ol>
                      <a target="_blank" className="KbYAj" href="#">
                        View all guidelines
                      </a>
                    </div>
                  </div>
                  <div className="ebCnIf">
                    <div className="fNwBoK">
                      <textarea
                        className="cDiOMj"
                        name="comment"
                        placeholder="What do you want other students to know about this professor?"
                        rows="9"
                        onChange={(ev) => setcomment(ev.target.value)}></textarea>
                      <span className="fXFjll">0 / 350</span>
                    </div>
                    <div className="lbqXUp"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="formCard_formCardContainerrs">
            <div className="bUsHOg">
              <div className="jFzfvl">
                <div className="hPqoKd">
                  <div className="cnrTNe">
                    By clicking the "Submit" button, I acknowledge that I have
                    read and agreed to the Rate My Professors
                    <a href="#" target="_blank" className="kOeZHi">
                      Site Guidelines
                    </a>
                    ,
                    <a href="#" target="_blank" className="kOeZHi">
                      Terms of Use
                    </a>
                    and
                    <a href="#" target="_blank" className="kOeZHi">
                      Privacy Policy
                    </a>
                    . Submitted data becomes the property of
                    RateMyProfessors.com. IP addresses are logged.
                  </div>
                  <div className="dryOBQ">
                    <div className="ebCnIf">
                      <input hidden readonly name="recaptcha" value="" />
                      <button className="TplOt" type="submit" onClick={submitRating}>
                        {loader ? <Spinner /> : `Submit Rating`}
                      </button>
                      <div className="lbqXUp"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-section">
        <LandingPageFooter />
      </div>
    </div>
  );
};
export default ProfessorRating;
