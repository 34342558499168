import React from "react";
import "./schoolcomponents.scss";
import ProfessorRatingNavbar from "../Navbar/professorRatingNavbar";
import SchoolComponent from "./schoolComponent";
import NewLandNavBar from "../Navbar/NewLandNavBar";

export default () => {
  return (
    <div className="schoolRatingPage">
        <NewLandNavBar bg={'#f1f7ff'} />
    <div className="SchoolComponents">
      <div className="navbar-section">
      </div>
      <SchoolComponent />
      {/* <div className="footer-section">
        <LandingPageFooter />
      </div> */}
    </div>
    </div>
  );
};
