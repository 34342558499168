import React from 'react'
import LandingPageNavbar from '../../Navbar/NewLandNavBar'
import SearchSchool from '../../SearchSchool/SearchSchool'
import success from '../../../Assest/success/success.png'
import successImg from '../../../Assest/success/successImg.png'
import './successRating.scss'
import { Button, Dropdown, message, Space, Tooltip } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import LandingPageFooter from '../../footer/LandingPageFooter'

function ProfessorSuccessRating() {
    const handleButtonClick = (e) => {
        console.log('click left button', e);
    };
    const handleMenuClick = (e) => {
        console.log('click', e);
    };
    const items = [
        {
            label: '1st menu item',
            key: '1',
        },
        {
            label: '2nd menu item',
            key: '2',
        },
        {
            label: '3rd menu item',
            key: '3',
        },
        {
            label: '4rd menu item',
            key: '4',
        },
    ];
    const menuProps = {
        items,
        onClick: handleMenuClick,
    };
    return (
        <>
            <div className='container-components' style={{ overflow: "auto" }}>
                <LandingPageNavbar />
                <div className='searchComponent'>
                    <SearchSchool />
                </div>
                <div className='main-con'>
                    <div className='succes-compo'>
                        <img src={success} className='success' alt='success' />
                    </div>
                    <div className='sucess-text'>
                        <h4 className='bold-thanks'>Thanks for rating Abcott Institute
                        </h4>
                        <p className='light-text'>We've posted your rating but it is still under review to ensure that it meets our  <span className='underline-text'>Site Guidelines.</span>
                        </p>
                    </div>
                </div>
                <div className='coloab'>
                    <div className='double-text'>
                        <p className='single'>Rate a professor at 
                        </p>
                        <h4 className='double'>Abcott Institute</h4>
                    </div>
                    <div className='department'>
                    <h5 className='department-heading'>Department</h5>
                    <div className='dropsown'>
                        <Space wrap>
                            <Dropdown menu={menuProps} className='dropbtn'>
                                <Button>
                                    <Space>
                                        Department...
                                        <DownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                        </Space>
                    </div>
                </div>
                </div>
                <div style={{display: "flex", justifyContent: "center", paddingTop: "10px"}}>
                <hr style={{width: "88%"}}/>
                </div>
                <div className='again-coloab'>
                    <div className='imgas'>
                    <img src={successImg} className='successImg' alt='successimg' />
                    </div>
                    <div className='portions'>
                        <div className='school-rating'>
                    <ul className='ratingList-ul'>

                        <li className='styled-listed'>
                            <div className='ratingStyle-rating' style={{width: "230%"}}>
                                <div className='ratingBody'>

                                    <div className='ratingValue'>
                                        <div className='ratingValue-RatingContainers'>
                                            <div className='cardNumRating'>
                                                <div className='cardNumRating-header'>Overall</div>
                                                <div className='cardNumRating-number'>50</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ratingInfo'>
                                        <div className='ratingheader'>
                                            <div className='RatingHeader__ClassInfoWrapper'>
                                                <div className='RatingHeader__StyledClass'></div>
                                            </div>
                                            <div className='TimeStamp__StyledTimeStamp'>Apr14,2020</div>
                                        </div>
                                        <div className='comments'>
                                            Oddly functional chaos … mean nurse professor and the nursing students are rude as ever .. great
                                            central processing and surgical tech students
                                            <hr />
                                            <div className='namess'>
                                                <h4 className='bold'>Sir Amrud</h4>
                                                <h4 className='normal'>English</h4>
                                                <h4 className='normal'>Govt, premier collage...</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='ratingStyle-rating' style={{width: "230%"}}>
                                <div className='ratingBody'>

                                    <div className='ratingValue'>
                                        <div className='ratingValue-RatingContainers'>
                                            <div className='cardNumRating'>
                                                <div className='cardNumRating-header'>Overall</div>
                                                <div className='cardNumRating-number'>50</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ratingInfo'>
                                        <div className='ratingheader'>
                                            <div className='RatingHeader__ClassInfoWrapper'>
                                                <div className='RatingHeader__StyledClass'></div>
                                            </div>
                                            <div className='TimeStamp__StyledTimeStamp'>Apr14,2020</div>
                                        </div>
                                        <div className='comments'>
                                            Oddly functional chaos … mean nurse professor and the nursing students are rude as ever .. great
                                            central processing and surgical tech students
                                            <hr />
                                            <div className='namess'>
                                                <h4 className='bold'>Sir Amrud</h4>
                                                <h4 className='normal'>English</h4>
                                                <h4 className='normal'>Govt, premier collage...</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='CompareThisProfessor'>
                                <button className='compareProfessor' style={{marginTop: "0px"}}>Compare This Professor</button>
                            </div>
                        </li>
                    </ul>
                </div>
                    </div>
                </div>
                 <div className='footer'>
                    <LandingPageFooter />
                </div>
            </div>
        </>
    )
}

export default ProfessorSuccessRating