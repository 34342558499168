import React, { useEffect, useRef, useState } from 'react'
import { Dropdown, Pagination, Progress, Rate, Select, Space } from 'antd';
import './Occupational.scss'
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Draggable from '../../Constant/Draggable';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Table } from 'antd'
import Column from 'antd/es/table/Column';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Link, useNavigate, useRoutes } from 'react-router-dom';
import { getStudentOccupation } from '../../module/features/assesments';
import { getLinkedInJobs, getUSAJobs } from '../../module/features/occupational';
import linkedIn from 'linkedin-jobs-api'
import moment from 'moment';
import OccupationTable from './OccupationTable';


function Occupational() {
    const navigate = useNavigate()
    const occupation = useSelector(state => state.assessmentSlice.occupation)
    const totalPages = useSelector(state => state.assessmentSlice.totalOccupationPage)
    const occupationLoader = useSelector(state => state.assessmentSlice.occupationLoader)
    const courseCompetencies = useSelector(state => state.competencySlice.courseCompetencies)
    const userProfile = useSelector(state => state.completeProfileSlice.userProfile)
    const competenciesWithCourse = useSelector(state => state.competencySlice.userCompetencies) || []
    const usaJobs = useSelector(state => state.occupationalTodo.usaJobs)
    const getRelatedJobs = useSelector(state => state.occupationalTodo.getRelatedJobs)
    const usaJobsCountAll = useSelector(state => state.occupationalTodo.usaJobsCountAll)
    const usaJobsCount = useSelector(state => state.occupationalTodo.usaJobsCount)
    const linkedInJobs = useSelector(state => state.occupationalTodo.linkedInJobs)
    const [selectCompetency, setSelectedCompetency] = useState(false)
    const [selectedOccupation, setSelectedOccupation] = useState('')
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [items, setItems] = useState([])
    const [occupationState, setOccupationState] = useState([])
    const [pageNumber, setPageNumber] = useState(1)
    const [rerender, setRerender] = useState(false)
    const ourRef = useRef(null);
    const dispatch = useDispatch()

    useEffect(() => {
        console.log(competenciesWithCourse, 'competenciesWithCoursecompetenciesWithCourse')
        if (competenciesWithCourse) {
            console.log(competenciesWithCourse, 'competenciesWithCoursecompetenciesWithCourse')
            let arr = []
            competenciesWithCourse.map((a, i) => {
                if (a.onet_element_id) {

                    arr.push({
                        label: a?.name,
                        key: i,
                        ...a,
                        onClick: () => setSelectedCompetency(a)
                    })
                }

            })
            setItems(arr)
        }
    }, [competenciesWithCourse])

    // useEffect(() => {
    //     if (occupation.length) {
    //         setSelectedOccupation(occupation[0].title)
    //     }
    // }, [occupation])

    useEffect(() => {
        if (selectedOccupation) {
            console.log(selectedOccupation, 'selectedOccupation')
        //     dispatch(getUSAJobs(selectedOccupation.trim()))
        //     dispatch(getLinkedInJobs({
        //         'keyword': 'Chief Executive Officer',
        //         "location": "",
        //         "dateSincePosted": "",
        //         "jobType": "",
        //         "remoteFilter": "",
        //         "salary": "",
        //         "experienceLevel": "",
        //         "limit": "10"
        //     }))
        }
    }, [selectedOccupation])

    useEffect(() => {
        if (selectCompetency) {
            console.log(selectCompetency, 'selectCompetency')
            let obj = {
                "id": selectCompetency.id,
                "name": selectCompetency.name,
                "onet_element_id": selectCompetency.onet_element_id
            }
            setOccupationState([])
            dispatch(getStudentOccupation({
                data: {
                    "competencies": [obj],
                },
                page: pageNumber
            }))
        } else {
            setOccupationState([])
            dispatch(getStudentOccupation({ page: pageNumber }))
        }
    }, [selectCompetency, pageNumber])
    useEffect(() => {
        console.log(usaJobs,
            usaJobsCountAll,
            usaJobsCount, 'sdadasdassd')
    }, [usaJobs,
        usaJobsCountAll,
        usaJobsCount])
    const mouseCoords = useRef({
        startX: 0,
        startY: 0,
        scrollLeft: 0,
        scrollTop: 0
    });
    const [isScrolling, setIsScrolling] = useState(false);
    const handleDragStart = (e) => {
        if (!ourRef.current) return
        const slider = ourRef.current.children[0];
        const startX = e.pageX - slider.offsetLeft;
        const startY = e.pageY - slider.offsetTop;
        const scrollLeft = slider.scrollLeft;
        const scrollTop = slider.scrollTop;
        mouseCoords.current = { startX, startY, scrollLeft, scrollTop }
        setIsMouseDown(true)
        document.body.style.cursor = "grabbing"
    }
    const handleDragEnd = () => {
        setIsMouseDown(false)
        if (!ourRef.current) return
        document.body.style.cursor = "default"
    }
    const handleDrag = (e) => {
        if (!isMouseDown || !ourRef.current) return;
        e.preventDefault();
        const slider = ourRef.current.children[0];
        const x = e.pageX - slider.offsetLeft;
        const y = e.pageY - slider.offsetTop;
        const walkX = (x - mouseCoords.current.startX) * 1.5;
        const walkY = (y - mouseCoords.current.startY) * 1.5;
        slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
        slider.scrollTop = mouseCoords.current.scrollTop - walkY;
    }

    useEffect(() => {
        const queryOptions = {
            keyword: 'software engineer',
            location: 'India',
            dateSincePosted: 'past Week',
            jobType: 'full time',
            remoteFilter: 'remote',
            salary: '100000',
            experienceLevel: 'entry level',
            limit: '10'
        };

        linkedIn.query(queryOptions).then(response => {
            console.log(response, 'responseresponse'); // An array of Job objects
        }).catch((err) => {
            console.log(err, 'responseresponse')
        });
    }, [])



    useEffect(() => {
        if (occupation) {
            setOccupationState(occupation)

        }
    }, [occupation])

    useEffect(() => {
        console.log(occupationState, 'occupationState')
    }, [occupationState])

    return (
        <div className='Occupational'>
            <div className='innerOccupational'>
                <div className='PageHeading'>
                    <h1>Recommended Occupations based on Competencies</h1>

                </div>


                <OccupationTable data={occupationState} setSelectedOccupation={setSelectedOccupation} selectCompetency={selectCompetency} occupationLoader={occupationLoader} setPageNumber={setPageNumber} totalPages={totalPages} occupation={occupation} />
                <div className='PageHeading'>
                    <h1>Recent Job Postings</h1>

                    {/* <Select
                        showSearch
                        placeholder="Select a person"
                        optionFilterProp="children"
                        value={selectedOccupation ? selectedOccupation : 'Select desire position'}
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={filterOption}
                        defaultValue={'Select a position'}
                        options={occupation.map((a, i) => ({ ...a, value: a.title, label: a.title }))}
                    /> */}
                </div>
                <div className='jobsListContainer'>
                    <div className='jobRecents'>
                        <p className='today'>Relatable Jobs</p>

                    </div>
                    <Draggable className={'jobsList'}>
                        {/* <div className='jobsList'> */}
                        {/* {['#e9e5fe', '#fce0e5', '#d4eaff', '#e9e5fe', '#fce0e5', '#d4eaff'].map((a) => { */}
                        {getRelatedJobs && getRelatedJobs.map((a) => {
                            let data = a.MatchedObjectDescriptor

                            return (

                                <div className='jobBox' style={{ backgroundColor: "#e9e5fe" }}>

                                    <div className='jobDetail'>
                                        <div className='jobDetailIamge'>

                                            <div className='jobImage'>
                                                <Avatar size="large" icon={<UserOutlined />} />
                                            </div>
                                            <div className='jobTitle'>
                                                <h5>{data.PositionTitle}</h5>
                                                <p>{moment(data.PositionStartDate, "YYYYMMDD").fromNow()}</p>
                                            </div>
                                        </div>
                                        <div className='jobDescription'>
                                            <p className='origanizationName'><b>Organization: </b>{data.OrganizationName}</p>
                                            <p className='detail'><b>Detail: </b>{data.QualificationSummary}</p>
                                            {/* <p>Add file on Project</p>
                                            <p><span><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"></path><path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"></path></svg></span> Plan 2022.pdf</p>
                                            <p>Change project priority</p> */}
                                            <div className='priority'>
                                                <p className='locationJob'>

                                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 32C167.67 32 96 96.51 96 176c0 128 160 304 160 304s160-176 160-304c0-79.49-71.67-144-160-144zm0 224a64 64 0 1 1 64-64 64.07 64.07 0 0 1-64 64z"></path></svg>
                                                    {data.PositionLocationDisplay}
                                                </p>
                                                {data?.ApplyURI?.length ?
                                                    <div className='applyButton' onClick={() => {
                                                        window.open(data.ApplyURI, '_blank')
                                                    }}>Apply Now</div>
                                                    : null}
                                                {/* <div className='dir'><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"></path></svg></div>
                                                <div className='to'>High</div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )

                        })}

                        {/* </div> */}
                    </Draggable>
                </div>
                {(usaJobs && usaJobs.length) || (linkedInJobs && linkedInJobs.length) ?
                    <>

                        {usaJobs && usaJobs.length ?
                            <div className='jobsListContainer'>
                                <div className='jobRecents'>
                                    <p className='today'>USA Jobs</p>

                                </div>
                                <Draggable className={'jobsList'}>
                                    {/* <div className='jobsList'> */}
                                    {/* {['#e9e5fe', '#fce0e5', '#d4eaff', '#e9e5fe', '#fce0e5', '#d4eaff'].map((a) => { */}
                                    {usaJobs && usaJobs.map((a) => {
                                        let data = a.MatchedObjectDescriptor

                                        return (

                                            <div className='jobBox' style={{ backgroundColor: "#e9e5fe" }}>

                                                <div className='jobDetail'>
                                                    <div className='jobDetailIamge'>

                                                        <div className='jobImage'>
                                                            <Avatar size="large" icon={<UserOutlined />} />
                                                        </div>
                                                        <div className='jobTitle'>
                                                            <h5>{data.PositionTitle}</h5>
                                                            <p>{moment(data.PositionStartDate, "YYYYMMDD").fromNow()}</p>
                                                        </div>
                                                    </div>
                                                    <div className='jobDescription'>
                                                        <p className='origanizationName'><b>Organization: </b>{data.OrganizationName}</p>
                                                        <p className='detail'><b>Detail: </b>{data.QualificationSummary}</p>
                                                        {/* <p>Add file on Project</p>
                                            <p><span><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"></path><path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"></path></svg></span> Plan 2022.pdf</p>
                                            <p>Change project priority</p> */}
                                                        <div className='priority'>
                                                            <p className='locationJob'>

                                                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 32C167.67 32 96 96.51 96 176c0 128 160 304 160 304s160-176 160-304c0-79.49-71.67-144-160-144zm0 224a64 64 0 1 1 64-64 64.07 64.07 0 0 1-64 64z"></path></svg>
                                                                {data.PositionLocationDisplay}
                                                            </p>
                                                            {data?.ApplyURI?.length ?
                                                                <div className='applyButton' onClick={() => {
                                                                    window.open(data.ApplyURI, '_blank')
                                                                }}>Apply Now</div>
                                                                : null}
                                                            {/* <div className='dir'><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"></path></svg></div>
                                                <div className='to'>High</div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    })}

                                    {/* </div> */}
                                </Draggable>
                            </div>
                            : null}
                        {linkedInJobs && linkedInJobs.length ?
                            <div className='jobsListContainer'>
                                <div className='jobRecents'>
                                    <p className='today'>LinkedIn Jobs</p>

                                </div>
                                <Draggable className={'jobsList'}>
                                    {/* <div className='jobsList'> */}
                                    {/* {['#e9e5fe', '#fce0e5', '#d4eaff', '#e9e5fe', '#fce0e5', '#d4eaff'].map((a) => { */}
                                    {linkedInJobs && linkedInJobs.map((a) => {
                                        let data = a

                                        return (

                                            <div className='jobBox' style={{ backgroundColor: "#e9e5fe" }}>

                                                <div className='jobDetail'>
                                                    <div className='jobDetailIamge'>

                                                        <div className='jobImage'>
                                                            <Avatar size="large" icon={<UserOutlined />} />
                                                        </div>
                                                        <div className='jobTitle'>
                                                            <h5>{data.position}</h5>
                                                            <p>{data.agoTime}</p>
                                                        </div>
                                                    </div>
                                                    <div className='jobDescription'>
                                                        <p className='origanizationName'><b>Organization: </b>{data.company}</p>
                                                        <p className='detail'><b>Salary: </b>{data.salary || 'Not Specified'}</p>
                                                        {/* <p>Add file on Project</p>
                                            <p><span><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"></path><path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"></path></svg></span> Plan 2022.pdf</p>
                                            <p>Change project priority</p> */}
                                                        <div className='priority'>
                                                            <p className='locationJob'>
                                                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 32C167.67 32 96 96.51 96 176c0 128 160 304 160 304s160-176 160-304c0-79.49-71.67-144-160-144zm0 224a64 64 0 1 1 64-64 64.07 64.07 0 0 1-64 64z"></path></svg>
                                                                {data.location}
                                                            </p>
                                                            {data?.jobUrl?.length ?
                                                                <div className='applyButton' onClick={() => {
                                                                    window.open(data.jobUrl, '_blank')
                                                                }}>Apply Now</div>
                                                                : null}
                                                            {/* <div className='dir'><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"></path></svg></div>
                                                <div className='to'>High</div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    })}

                                    {/* </div> */}
                                </Draggable>
                            </div>
                            : null}
                    </>
                    : null}
            </div>
        </div>
    )
}

export default Occupational