import React from 'react'
import { calculateAverage, calculatePercentage, ratingDateFormat } from '../../Constant/Utils'
import { Progress } from 'antd'

function IndivisualRating({ a, obj }) {
    return (
        <div className='ratingStyle-rating'>
            <div className='ratingBody'>

                <div className='ratingValue'>
                    <div className='ratingValue-RatingContainers'>
                        <div className='cardNumRating'>
                            <div className='cardNumRating-header'>Overall</div>
                            <div className='cardNumRating-number'>{calculateAverage(a)}</div>
                        </div>
                    </div>
                </div>
                <div className='ratingInfo'>
                    <div className='ratingheader'>
                        <div className='RatingHeader__ClassInfoWrapper'>
                            <div className='RatingHeader__StyledClass'></div>
                        </div>
                        <div className='TimeStamp__StyledTimeStamp'>{ratingDateFormat(a.created_at)}</div>
                    </div>
                    <div className='comments'>
                        {a.comment}
                    </div>
                    <div>
                        <div className='schoolratingsummrary-container'>
                            {Object.keys(obj).map((b, j) => (
                                <div className='display-slider'>
                                    <div className='inner-display-slider'>
                                        <div className='display-slider-title'>{b}</div>
                                        <Progress strokeLinecap="butt" type="circle" percent={calculatePercentage(a[b], 5)} size={86} strokeWidth={10} strokeColor={"#7FF6C3"} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default IndivisualRating