import { Input, Modal, Select, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../Constant/Spinner/Spinnner'
import { getProfile, updateProfile } from '../../module/features/completeProfile'
import { getUser } from '../../module/features/authentication'
import { getYourCourses, professorCourseAdd, professorCourseUpdate } from '../../module/features/ProfessorCourse'
import './AddYourCourse.scss'
import AddProfModal from './AddProfModal'
import { universityProfessorsforCourse } from '../../module/features/professor'
import { addUserCourse, addUserMajorCourse, getMajorCourses, getMinorCourses, updateUserCourse } from '../../module/features/courses'
import { semesterTypeArr } from '../../Constant/Utils'
import Notification from '../../Constant/Notification'
import { getSelectedCompetenciesWithCourse } from '../../module/features/competency'
function AddNewCourseModal({ isModalOpen,
    setIsModalOpen,
    showModal,
    handleOk,
    handleCancel,
    editState,
    majorMinor, setEditState }) {
    const dispatch = useDispatch()
    const [professorArr, setProfessorArr] = useState([])
    const professorList = useSelector(state => state.professorSlice.professorList)
    const courses = useSelector(state => state.coursesSlice.courses)
    const userProfile = useSelector(state => state.completeProfileSlice.userProfile)
    const majorCourses = useSelector(state => state.coursesSlice.majorCourses)
    const minorCourses = useSelector(state => state.coursesSlice.minorCourses)
    const user = useSelector(state => state.Authentication.user)
    const [coursesArr, setCoursesArr] = useState([])
    const [loader, setLoader] = useState(false)
    const [isModalOpenForm, setIsModalOpenForm] = useState(false)
    const showModalForm = () => {
        setIsModalOpenForm(true)
    }
    const handleOkForm = () => {
        setIsModalOpenForm(false)
    }
    const handleCancelForm = () => {
        setIsModalOpenForm(false)
    }


    const [semesterType, setSemesterType] = useState({
        'semesterType': '',
        'duration': 0,
        'weeks': ''
    })
    const [selectedCourse, setSelectedCourse] = useState({
        'courseName': '',
        'courses': 0
    })
    const [selectedProfessor, setSelectedProfessor] = useState({
        'professorName': '',
        'professor_id': 0
    })
    const [minorMajor, setMinerMajor] = useState()
    useEffect(() => {
        console.log(courses, 'coursesArr')
        if (courses?.length) {
            let arr = []
            courses.map((a, i) => {
                if (a.type === 'course') {
                    arr.push({
                        ...a,
                        value: a.id,
                        label: a.course,
                    })
                }
            })
            console.log(arr, 'coursesArr')
            setCoursesArr(arr)
        }
    }, [courses])
    useEffect(() => {

        if (professorList) {
            let arr = professorList.map((a, i) => {
                return {
                    value: a.id,
                    label: a.professor_name
                }
            })
            setProfessorArr(arr)
            console.log(professorList, 'professorList')
        }
    }, [professorList])
    const callback = (res) => {
        console.log(res, 'adasdasdsa')
        if (res) {
            setNotificationFire(true)
        }
        dispatch(getSelectedCompetenciesWithCourse())
        dispatch(getMajorCourses())
        dispatch(getMinorCourses())
        setSelectedCourse({
            'courseName': '',
            'courses': 0
        })
        setSelectedProfessor({
            'professorName': '',
            'professor_id': 0
        })
        setSemesterType({
            'duration': '',
            'semesterType': '',
            'weeks': ''
        })
        handleOk()
        if (editState) {
            setEditState(false)
        }

    }
    const [notificationFire, setNotificationFire] = useState(false)
    const addNewCourse = () => {
        console.log('dasdasdasdas')
        console.log(userProfile)
        if (selectedCourse.courses) {
            let arr = []
            if (majorCourses?.length && minorCourses?.length) {
                arr = [...majorCourses, ...minorCourses]
            } else if (majorCourses.length) {
                arr = [...majorCourses]
            } else if (minorCourses.length) {
                arr = [...minorCourses]
            }
            console.log(arr, selectedCourse.courses[0], majorCourses, minorCourses)
            let alreadyFound = arr.filter(obj => obj.course.id === selectedCourse.courses[0])
            if (alreadyFound.length) {
                message.error('You are already enrolled in this course')
            } else {
                if (selectedCourse.courses && selectedProfessor.professor_id && semesterType.semesterType && semesterType.duration) {

                    let obj = {
                        course_id: selectedCourse.courses[0],
                        professor_id: selectedProfessor.professor_id,
                        semesterType: semesterType.semesterType,
                        duration: semesterType.duration
                    }
                    console.log(obj, 'asdadsadas')
                    dispatch(addUserCourse({ data: obj, setLoader, callback, type: majorMinor }))
                } else {
                    console.log(selectedCourse.length, selectedProfessor.professor_id, semesterType.semesterType, semesterType.duration, 'asdsadasd')
                    message.error("Required fields can't be empty")

                }
            }
        }
    }


    const updateCourse = () => {
        console.log(userProfile)
        if (selectedCourse.courses) {
            let obj = {
                'course_id': selectedCourse.courses[0],
                'professor_id': selectedProfessor.professor_id,
                'old_professor_id': editState.profId,
                'duration': semesterType.duration,
                'semesterType': semesterType.semesterType,
            }
            dispatch(updateUserCourse({ data: obj, setLoader, callback, type: majorMinor, id: editState.id }))
        }
    }
    // const updateCourse = () => {
    //     if (editState) {
    //         console.log(editState)
    //         let obj = {
    //             majorCourse: userProfile?.profile.majorCourse,
    //             minorCourse: userProfile?.profile.minorCourse
    //         }
    //         let majorCourseArr = (obj.majorCourse).split(',')
    //         let minorCourseArr = (obj.minorCourse).split(',')
    //         let updatedMixArr = []
    //         let updatedMinorArr = []
    //         let updatedMajorArr = []
    //         majorCourseArr.map((a, i) => {
    //             if (a == editState.id) {
    //                 updatedMixArr.push(selectedCourse?.courses?.toString())
    //                 updatedMajorArr.push(selectedCourse?.courses?.toString())
    //             } else {
    //                 updatedMixArr.push(a.toString())
    //                 updatedMajorArr.push(a.toString())
    //             }
    //         })
    //         minorCourseArr.map((a, i) => {
    //             if (a == editState.id) {
    //                 updatedMixArr.push(selectedCourse?.courses?.toString())
    //                 updatedMinorArr.push(selectedCourse?.courses?.toString())
    //             } else {
    //                 updatedMixArr.push(a.toString())
    //                 updatedMinorArr.push(a.toString())
    //             }
    //         })
    //         let obj2 = {
    //             "courses": selectedCourse?.courses,
    //             "professor_Id": selectedProfessor?.professor_id,
    //             "student_id": userProfile.id,
    //             // "university_id": userProfile?.profile?.university_id
    //         }
    //         if (minorMajor === 'minor') {
    //             obj.minorCourse = (obj.minorCourse ? obj.minorCourse + ',' : '') + selectedCourse?.courses?.toString()
    //         } else if (minorMajor === 'major') {
    //             obj.majorCourse = (obj.majorCourse ? obj.majorCourse + ',' : '') + selectedCourse?.courses?.toString()
    //         }
    //         console.log(updatedMinorArr, updatedMajorArr)
    //         dispatch(updateProfile({
    //             data: {
    //                 majorCourse: updatedMinorArr.join(),
    //                 minorCourse: updatedMajorArr.join()
    //             }, setLoader, id: userProfile?.profile?.id
    //         }))
    //         dispatch(professorCourseUpdate({ data: { ...obj2 }, id: editState.id, callback }))
    //     }

    // }
    useEffect(() => {
        if (userProfile) {
            if (userProfile?.profile?.university_id) {

                dispatch(universityProfessorsforCourse({ id: userProfile?.profile?.university_id }))
            }
        }
    }, [userProfile])


    useEffect(() => {
        if (editState) {
            console.log(editState, 'editState')
            setSelectedCourse({
                'courseName': editState.course,
                'courses': editState.courseId
            })
            setSelectedProfessor({
                'professorName': editState.professor_name,
                'professor_id': editState.profId
            })

            setSemesterType({
                'duration': editState.duration,
                'semesterType': editState.semesterType,
                'weeks': ''
            })
            setMinerMajor()
        }
    }, [editState])
    return (
        <>
            <Modal className='AddCourseModal' title={`Add ${majorMinor} course`} open={isModalOpen} footer={false} onCancel={() => { handleCancel(); callback() }}>
                <div className='form'>
                    <label style={{ width: "49%" }}>
                        <p>Course Name {editState ? null : <sup className="requiredField">*</sup>}</p>
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            options={coursesArr}
                            value={selectedCourse?.courses || ''}
                            onSelect={(ev, option) => {
                                console.log(option)
                                let data = { ...selectedCourse }
                                data.courseName = option.course;
                                data.courses = [ev];
                                setSelectedCourse(data)

                            }}
                        />
                    </label>
                    <label style={{ width: "49%" }}>
                        <p>Select Semester Type {editState ? null : <sup className="requiredField">*</sup>}</p>
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            options={semesterTypeArr}
                            value={semesterType.semesterType || ''}
                            onSelect={(ev, option) => {
                                console.log(option)
                                let data = { ...semesterType }
                                data.semesterType = ev;
                                data.duration = option.milisecond;
                                data.weeks = option.duration
                                setSemesterType(data)

                            }}
                        />
                    </label>
                    {/* <label style={{ width: "49%" }}>
                    <p>Duration</p>
                    <Input disabled value={semesterType.weeks} />
                </label> */}
                    {/* <label style={{ width: "49%" }}>
                    <p>Minor/Major</p>
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        value={majorMinor}
                        disabled
                        options={[
                            {
                                value: 'minor',
                                label: 'minor'
                            },
                            {
                                value: 'major',
                                label: 'major'
                            }
                        ]}
                        onSelect={(ev, option) => {
                            setMinerMajor(ev)
                        }}
                    />
                </label> */}
                    <label style={{ width: "49%" }}>
                        <p>Professor Name {editState ? null : <sup className="requiredField">*</sup>}</p>
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            options={professorArr}
                            value={selectedProfessor.professor_id || ''}
                            onSelect={(ev, option) => {
                                console.log(option)
                                let data = { ...selectedProfessor }
                                data.professorName = option.professorName;
                                data.professor_id = ev;
                                setSelectedProfessor(data)

                            }}
                        />
                    </label>
                    <label style={{ width: "49%" }}>
                        <p style={{ visibility: 'hidden' }}>Add New Professor</p>
                        <button className='addProf hoverEffect' disabled={!selectedCourse.courses} onClick={showModalForm}>
                            Add New Professor
                        </button>
                    </label>
                    {/* <hr style={{ width: '100%', margin: '20px 0px' }} /> */}

                </div >
                <div className='footer'>
                    <button className=' cancel' onClick={handleCancel}>Cancel</button>
                    <button className='addCourseButton hoverEffect add' onClick={() => {
                        if (editState) {
                            updateCourse()
                        } else {
                            addNewCourse()
                        }
                    }}>{loader ? <Spinner /> :
                        editState ?
                            `Update ${majorMinor} course`
                            :
                            `Add ${majorMinor} course`
                        }</button>
                </div>
                <AddProfModal
                    isModalOpen={isModalOpenForm}
                    setIsModalOpen={setIsModalOpenForm}
                    showModal={showModalForm}
                    handleOk={handleOkForm}
                    handleCancel={handleCancelForm}
                    selectedCourse={selectedCourse}
                />
            </Modal >
            <Notification notificationFire={notificationFire} setNotificationFire={setNotificationFire} />


        </>
    )
}

export default AddNewCourseModal