import { Modal, Select } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../Constant/Spinner/Spinnner'
import './addCompetency.scss'
import { useNavigate } from 'react-router-dom'
import { addCompetency, editCompetency, getCompetencies } from '../../module/features/competency'

function AddCompentencyModal({
    isModalOpen,
    handleOk,
    handleCancel,
    editState
}) {
    const courses = useSelector(state => state.coursesSlice.courses)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [data, setData] = useState({
        // "course_id": "",
        "name": "asdasd",
        "level": 0,
        "average": 0.0,
        "hidden": 0,
        "tier": 1
    })
    const [loader, setLoader] = useState(false)
    const [coursesArr, setCoursesArr] = useState([])
    useEffect(() => {
        if (courses?.length) {
            let arr = courses.map((a, i) => {
                return {
                    ...a,
                    value: a.id,
                    label: a.course,
                }
            })
            setCoursesArr(arr)
        }
    }, [courses])
    const clearState = () => {
        setData({
            // "course_id": "",
            "name": "",
            "level": 0,
            "average": 0.0,
            "hidden": 0,
        "tier": 1
        })
    }
    const callback = () => {
        clearState()
        handleOk()
        setLoader(false)
        dispatch(getCompetencies())
    }
    const addCompetencyFun = () => {
        setLoader(true)
        dispatch(addCompetency({ data: data, callback }))
    }
    useEffect(() => {
        if (editState) {
            setData({
                // "course_id": editState.course_id,
                "name": editState.name,
                "level": editState.level,
                "average": editState.average,
                "hidden": 0,
                "tier": 1
            })
        } else {
            clearState()
        }
    }, [editState])
    const editCompetencyFun = () => {
        setLoader(true)
        dispatch(editCompetency({ data: data, id: editState.id, callback }))
    }

    return (
        <Modal className='addCompetencyModal' title="Add Competency" open={isModalOpen} footer={false} onCancel={() => { handleCancel(); clearState() }}>
            <div className='form'>
                {/* <label className='addCourseInput'>
                    <p>Course</p>
                    <Select
                        showSearch
                        style={{
                            width: 200,
                        }}
                        placeholder="Select Course"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        value={data.course_id}

                        options={coursesArr}
                        onChange={(ev) => {
                            let obj = { ...data }
                            obj.course_id = ev
                            setData(obj)
                        }}
                    />
                </label> */}
                <label className='addCourseInput'>
                    <p>Competency Name</p>
                    <input placeholder='Competency Name'
                        value={data.name}
                        onChange={(ev) => {
                            let obj = { ...data }
                            obj.name = ev.target.value
                            setData(obj)

                        }}
                    />
                </label>
                <label className='addCourseInput'>
                    <p>Competency Level</p>
                    <Select
                        showSearch
                        style={{
                            width: 200,
                        }}
                        placeholder="Select Level"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        value={data.level}
                        options={[
                            {
                                value: 0,
                                label: 'level',
                            },
                            {
                                value: 1,
                                label: 'Advanced',
                            }
                        ]}
                        onChange={(ev) => {
                            let obj = { ...data }
                            obj.level = ev
                            setData(obj)
                        }}
                    />
                </label>
                <label className='addCourseInput'>
                    <p>Competency Average</p>
                    <input placeholder='Competency Average'
                        type='number'
                        min={0.0}
                        max={4.0}
                        step={0.01}
                        value={data.average}
                        onChange={(ev) => {
                            let obj = { ...data }
                            obj.average = Number(ev.target.value)
                            setData(obj)

                        }}
                    />
                </label>
            </div>
            <div className='footer'>
                <button className='addCompetencyButton cancel' onClick={handleCancel}>Cancel</button>
                {editState ?
                    <button className='addCompetencyButton add' onClick={editCompetencyFun}>{loader ? <Spinner /> : `Edit Competency`}</button>
                    :
                    <button className='addCompetencyButton add' onClick={addCompetencyFun}>{loader ? <Spinner /> : `Add Competency`}</button>
                }
            </div>
        </Modal>
    )
}

export default AddCompentencyModal