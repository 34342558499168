import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit'
import { message } from 'antd';
import axios from 'axios'
import { toast } from 'react-toastify';
import { errorHandle, getCookie } from '../../Constant/Utils';

const initialState = {
    loader: false,
    courses: [],
    chooseCourses: [],
    yourCourses: false,
    yourCoursesLoader: false,
    studentCourses: false,
    studentCoursesLoader: false,
    tier1: [],
    tier2: [],
    tier3: [],
    majorCourses: [],
    minorCourses: [],
    courseTracking: false,
    courseProgessInfo: [],
    recommendedCourses: []
}

export const getCourse = createAsyncThunk(
    "courses/getCourse",
    async (data) => {
        const token = getCookie('ungradeToken');
        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/admin/course`,
                headers: {
                    'authorization': token
                }
            };

            const response = await axios.request(config);
            return response.data.data; // Return the data here


        } catch (error) {
            console.error(error);
        }
    });

export const getCourseTracking = createAsyncThunk(
    "courses/getCourseTracking",
    async (data) => {
        const token = getCookie('ungradeToken');
        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/client/course/user-tracking`,
                headers: {
                    'authorization': token
                }
            };

            const response = await axios.request(config);
            console.log(response.data)
            return response.data.data; // Return the data here


        } catch (error) {
            console.error(error);
        }
    });


export const getCourseById = createAsyncThunk(
    "courses/getCourseById",
    async (data) => {
        try {
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}course/getByMultipleIds`,
                headers: {},
                data: data
            };

            const response = await axios.request(config);
            return response.data.courses; // Return the data here


        } catch (error) {
            console.error(error);
        }
    });

export const getCourseStudentById = createAsyncThunk(
    "courses/getCourseStudentById",
    async (data) => {
        try {
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}course/getUserCourses`,
                headers: {},
                data: data
            };

            const response = await axios.request(config);
            return response.data; // Return the data here


        } catch (error) {
            console.error(error);
        }
    });

export const getStudentCourseById = createAsyncThunk(
    "courses/getStudentCourseById",
    async (data) => {
        const token = getCookie('ungradeToken');
        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/admin/profile/user/${data.userId}`,
                headers: {
                    'authorization': token

                }
            };

            const response = await axios.request(config);
            console.log(response.data, 'asdasdsadasdsadsa')
            return [...response.data?.data?.majorCourses, ...response.data?.data?.minorCourses]; // Return the data here


        } catch (error) {
            console.error(error);
        }
    });


export const getTierCourses = createAsyncThunk(
    "courses/getTierCourses",
    async (data) => {
        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/client/course/tier/${data}`,
                headers: {},
            };

            const response = await axios.request(config);
            return {
                data: response.data.data,
                tier: data
            }; // Return the data here


        } catch (error) {
            console.error(error);
        }
    });

export const getMajorCourses = createAsyncThunk(
    "courses/getMajorCourses",
    async (data) => {
        const token = getCookie('ungradeToken');

        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/client/profile/major/courses`,
                headers: {
                    'authorization': token
                },
            };

            const response = await axios.request(config);
            return response.data.data;

        } catch (error) {
            console.error(error);
        }
    });


export const getMinorCourses = createAsyncThunk(
    "courses/getMinorCourses",
    async (data) => {
        const token = getCookie('ungradeToken');

        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/client/profile/minor/courses`,
                headers: {
                    'authorization': token
                },
            };

            const response = await axios.request(config);
            return response.data.data;

        } catch (error) {
            console.error(error);
        }
    });


export const getCourseProgress = createAsyncThunk(
    "courses/getCourseProgress",
    async (data) => {
        const token = getCookie('ungradeToken');

        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/client/result/user-course?courses=${data.id}`,
                headers: {
                    'authorization': token
                },
            };

            const response = await axios.request(config);
            console.log(response.data.data, 'courseProgessInfo')
            return response.data.data;

        } catch (error) {
            console.error(error);
        }
    });

export const getRecomendedCourses = createAsyncThunk(
    "courses/getRecomendedCourses",
    async (data) => {
        const token = getCookie('ungradeToken');

        try {
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/client/course/recommended`,
                headers: {
                    'authorization': token
                },
                data: { competencies: data }
            };

            const response = await axios.request(config);
            console.log(response.data.data, 'courseProgessInfo')
            return response.data.data;

        } catch (error) {
            console.error(error);
        }
    });




export const coursesSlice = createSlice({
    name: 'courses',
    initialState,
    reducers: {
        addCourse: (state, action) => {
            try {

                let data = JSON.stringify(action.payload.data);
                const token = getCookie('ungradeToken');

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/admin/course`,
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': token

                    },
                    data: data
                };

                axios.request(config)
                    .then((response) => {
                        message.success("Course Added")
                        if (action.payload.callback) {
                            action.payload.callback()
                        }
                    })
                    .catch((error) => {
                        errorHandle(error.response.data)
                        console.log(error.response.data, 'sadasdasdas')
                        // let errorMsg = error?.response?.data?.error  || error?.response?.data?.message || 'Please Try Again12'
                        // message.error(errorMsg)
                        // state.loader = false

                    });
            } catch (err) { }

        },
        editCourse: (state, action) => {
            let data = JSON.stringify(action.payload.data);
            const token = getCookie('ungradeToken');

            let config = {
                method: 'patch',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/admin/course/${action.payload.id}`,
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': token

                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    message.success("Course Updated")
                    if (action.payload.callback) {
                        action.payload.callback()
                    }
                })
                .catch((error) => {
                    let errorMsg = error?.response?.data?.error  || error?.response?.data?.message || 'Please Try Again13'
                    message.error(errorMsg)
                    state.loader = false

                });

        },
        updateTierAndVisiblityCourse: (state, action) => {
            let data = JSON.stringify(action.payload.data);
            const token = getCookie('ungradeToken');
            let config = {
                method: 'patch',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/admin/course/${action.payload.id}`,
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': token
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    message.success("Course Updated")
                    if (action.payload.callback) {
                        action.payload.callback()
                    }
                })
                .catch((error) => {
                    let errorMsg = error?.response?.data?.error  || error?.response?.data?.message || 'Please Try Again14'
                    message.error(errorMsg)
                    state.loader = false

                });

        },
        deleteCourse: (state, action) => {
            let data = JSON.stringify(action.payload.data);

            let config = {
                method: 'delete',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}course/delete/${action.payload.id}`,
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            axios.request(config)
                .then((response) => {
                    message.success('Course Deleted')
                    if (action.payload.callback) {
                        action.payload.callback()
                    }
                })
                .catch((error) => {
                    let errorMsg = error?.response?.data?.error  || error?.response?.data?.message || 'Please Try Again15'
                    message.error(errorMsg)
                    state.loader = false
                });

        },
        addUserCourse: (state, action) => {
            let data = JSON.stringify(action.payload.data);
            const token = getCookie('ungradeToken');

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/client/profile/${action.payload.type}/courses`,
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': token
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    message.success('Course Added')
                    if (action.payload.callback) {
                        action.payload.callback(response)
                    }
                })
                .catch((error) => {
                    let errorMsg = error?.response?.data?.message || ['Please Try Again']
                    console.log(error?.response?.data?.message, 'sadasda')
                    message.error(error?.response?.data?.message)
                    // state.loader = false
                });

        },
        updateUserCourse: (state, action) => {
            let data = JSON.stringify(action.payload.data);
            const token = getCookie('ungradeToken');

            let config = {
                method: 'patch',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/client/profile/${action.payload.type}/courses/${action.payload.id}`,
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': token
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    message.success('Course Added')
                    if (action.payload.callback) {
                        action.payload.callback()
                    }
                })
                .catch((error) => {
                    let errorMsg = error?.response?.data?.error  || error?.response?.data?.message || 'Please Try Again15'
                    message.error(errorMsg)
                    state.loader = false
                });

        },
        deleteUserCourse: (state, action) => {
            let data = JSON.stringify(action.payload.data);
            const token = getCookie('ungradeToken');

            let config = {
                method: 'delete',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/client/profile/${action.payload.type}/courses/${action.payload.id}`,
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': token
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    message.success('Course Deleted')
                    if (action.payload.callback) {
                        action.payload.callback()
                    }
                })
                .catch((error) => {
                    let errorMsg = error?.response?.data?.error  || error?.response?.data?.message || 'Please Try Again15'
                    message.error(errorMsg)
                    state.loader = false
                });

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCourse.pending, (state, action) => {
                // state.isLoading = true;
                // state.hasError = false;
            })
            .addCase(getCourse.fulfilled, (state, action) => {
                state.courses = action.payload;
                //   state.isLoading = false;
                //   state.hasError = false
            })
            .addCase(getCourse.rejected, (state, action) => {
                //   state.hasError = true
                //   state.isLoading = false;
            })

            .addCase(getCourseById.pending, (state, action) => {
                // state.isLoading = true;
                // state.hasError = false;
            })
            .addCase(getCourseById.fulfilled, (state, action) => {
                state.chooseCourses = action.payload;
                //   state.isLoading = false;
                //   state.hasError = false
            })
            .addCase(getCourseById.rejected, (state, action) => {
                //   state.hasError = true
                //   state.isLoading = false;
            })

            .addCase(getCourseStudentById.pending, (state, action) => {
                state.yourCoursesLoader = true;
                // state.hasError = false;
            })
            .addCase(getCourseStudentById.fulfilled, (state, action) => {
                state.yourCourses = action.payload;
                state.yourCoursesLoader = false;
                //   state.isLoading = false;
                //   state.hasError = false
            })
            .addCase(getCourseStudentById.rejected, (state, action) => {
                state.yourCoursesLoader = false;
                //   state.hasError = true
                //   state.isLoading = false;
            })

            .addCase(getTierCourses.pending, (state, action) => {

            })
            .addCase(getTierCourses.fulfilled, (state, action) => {
                if (action?.payload?.tier === 1) {
                    state.tier1 = action.payload.data;
                } else if (action?.payload?.tier === 2) {
                    state.tier2 = action.payload.data;
                } else if (action.payload?.tier === 3) {
                    state.tier3 = action.payload.data;
                }

            })
            .addCase(getTierCourses.rejected, (state, action) => {

            })

            .addCase(getStudentCourseById.pending, (state, action) => {
                state.studentCoursesLoader = true;
                // state.hasError = false;
            })
            .addCase(getStudentCourseById.fulfilled, (state, action) => {
                state.studentCourses = action.payload;
                state.studentCoursesLoader = false;
                //   state.isLoading = false;
                //   state.hasError = false
            })
            .addCase(getStudentCourseById.rejected, (state, action) => {
                state.studentCoursesLoader = false;
                //   state.hasError = true
                //   state.isLoading = false;
            })
            .addCase(getMajorCourses.pending, (state, action) => {
                // state.hasError = false;
            })
            .addCase(getMajorCourses.fulfilled, (state, action) => {
                state.majorCourses = action.payload;
                //   state.isLoading = false;
                //   state.hasError = false
            })
            .addCase(getMajorCourses.rejected, (state, action) => {
                //   state.hasError = true
                //   state.isLoading = false;
            })

            .addCase(getMinorCourses.pending, (state, action) => {
                // state.hasError = false;
            })
            .addCase(getMinorCourses.fulfilled, (state, action) => {
                state.minorCourses = action.payload;
                //   state.isLoading = false;
                //   state.hasError = false
            })
            .addCase(getMinorCourses.rejected, (state, action) => {
                //   state.hasError = true
                //   state.isLoading = false;
            })
            .addCase(getCourseTracking.pending, (state, action) => {
                // state.hasError = false;
            })
            .addCase(getCourseTracking.fulfilled, (state, action) => {
                state.courseTracking = action.payload;
                //   state.isLoading = false;
                //   state.hasError = false
            })
            .addCase(getCourseTracking.rejected, (state, action) => {
                //   state.hasError = true
                //   state.isLoading = false;
            })

            .addCase(getCourseProgress.pending, (state, action) => {
                // state.hasError = false;
            })
            .addCase(getCourseProgress.fulfilled, (state, action) => {
                state.courseProgessInfo = action.payload;
                //   state.isLoading = false;
                //   state.hasError = false
            })
            .addCase(getCourseProgress.rejected, (state, action) => {
                //   state.hasError = true
                //   state.isLoading = false;
            })
            .addCase(getRecomendedCourses.pending, (state, action) => {
                // state.hasError = false;
            })
            .addCase(getRecomendedCourses.fulfilled, (state, action) => {
                state.recommendedCourses = action.payload;
                //   state.isLoading = false;
                //   state.hasError = false
            })
            .addCase(getRecomendedCourses.rejected, (state, action) => {
                //   state.hasError = true
                //   state.isLoading = false;
            })



    }
})

export const { addCourse, editCourse, deleteCourse, updateTierAndVisiblityCourse, addUserCourse, updateUserCourse, deleteUserCourse } = coursesSlice.actions

export default coursesSlice.reducer