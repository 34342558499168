import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

function NotAdded({ text,addCourse }) {
  return (
    <div className='notAddedCOurse'>
      <h4 className='notFoundText'>{text}</h4>
      {addCourse ?
        <Link to={`/dashboard/Profile?tab=Courses`}>Add Course</Link>
        : null}
    </div>
  )
}

export default NotAdded