import React, { useEffect } from 'react'
import './UniversityRatingSuccess.scss';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown, message, Space, Tooltip } from 'antd';
import bookmark from '../../../Assest/RateMyProfessor/bookmark-hover.497698ea.svg'
import ProfessorRatingNavbar from '../../Navbar/professorRatingNavbar';
import LandingPageFooter from '../../footer/LandingPageFooter';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleUniversities } from '../../../module/features/university';
import { useNavigate } from 'react-router-dom';


function UniversityRatingSuccess() {
    const singleUniversity = useSelector(state => state.universitySlice.singleUniversity)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
      const url = new URL(window.location)
      const id = url.searchParams.get('id')
      console.log(id, 'id')
      dispatch(getSingleUniversities({ id: id }))
    }, [])
    const handleButtonClick = (e) => {
        console.log('click left button', e);
    };
    const handleMenuClick = (e) => {
        console.log('click', e);
    };
    const items = [
        {
            label: '1st menu item',
            key: '1',
        },
        {
            label: '2nd menu item',
            key: '2',
        },
        {
            label: '3rd menu item',
            key: '3',
        },
        {
            label: '4rd menu item',
            key: '4',
        },
    ];
    const menuProps = {
        items,
        onClick: handleMenuClick,
    };
    return (
        <>
        
            <div className='bgplVn'>
                <div className='gxbBpy'>
                    <div className='fwNdYA'>
                        <div className='hBGAzn'>
                            <div className='hfOves'>
                                <div className='iuNgUI'>
                                    Thanks for rating {singleUniversity.NAME}
                                </div>
                                <div className='bPFDtA'>
                                We've posted your rating but it is still under review to ensure that it meets our 
                                    <a href='#' target="_blank" className='ftZYdd'> Site Guidelines.</a>
                                </div>
                                <a className='ilzHrk' href='#' id="view-my-rating-btn" onClick={()=>{
                                    navigate(`/school${window.location.search}`)
                                }}>View my rating</a>
                            </div>
                        </div>
                    </div>
                    {/* <div className='fcXCC'>
                        <div>
                            <h1>Rate another professor at <b>Daniel Webster College</b> </h1>
                        </div>
                    </div>
                    <div className='gfzvFL'>
                        <div className='hHRBYq'>
                            <div className='fvPBYC'>Department</div>
                            <div className='css-2b097c-container'>
                                <div className='css-1l6bn5c-control'>
                                    <Space wrap>
                                        <Dropdown menu={menuProps}>
                                            <Button>
                                                <Space>
                                                    Select...
                                                    <DownOutlined />
                                                </Space>
                                            </Button>
                                        </Dropdown>
                                    </Space>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <a className='dLJIlx'>
                            <div className='kcbPEB'>
                                <div className='joEEbw'>
                                    <div className='eWZmyX'>
                                        <div className='fVETNc'>QUALITY</div>
                                        <div className='gcFhmN'>4.5</div>
                                        <div className='jMRwbg'>20 ratings</div>
                                    </div>
                                </div>
                                <div className='fkdYMc'>
                                    <div className='cJdVEK'>
                                        Ann Commito
                                    </div>
                                    <div className='gSTNdb'>
                                        <div className='haUIRO'>Mathematics</div>
                                        <div className='iDlVGM'>Frederick Community College</div>
                                    </div>
                                    <div className='frciyA'>
                                        <div className='fyKbws'>
                                            <div className='hroXqf'>N/A</div>
                                             would take again
                                        </div>
                                        <div className='enhFnm'></div>
                                        <div className='fyKbws'>
                                            <div className='hroXqf'>2.8</div>
                                             level of difficulty
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className='hVjzhH' type="button">
                                <img src={bookmark} alt='bookmark' className='bookmark' />
                            </button>
                        </a>
                        <a className='dLJIlx'>
                            <div className='kcbPEB'>
                                <div className='joEEbw'>
                                    <div className='eWZmyX'>
                                        <div className='fVETNc'>QUALITY</div>
                                        <div className='gcFhmN'>4.5</div>
                                        <div className='jMRwbg'>20 ratings</div>
                                    </div>
                                </div>
                                <div className='fkdYMc'>
                                    <div className='cJdVEK'>
                                        Ann Commito
                                    </div>
                                    <div className='gSTNdb'>
                                        <div className='haUIRO'>Mathematics</div>
                                        <div className='iDlVGM'>Frederick Community College</div>
                                    </div>
                                    <div className='frciyA'>
                                        <div className='fyKbws'>
                                            <div className='hroXqf'>N/A</div>
                                             would take again
                                        </div>
                                        <div className='enhFnm'></div>
                                        <div className='fyKbws'>
                                            <div className='hroXqf'>2.8</div>
                                             level of difficulty
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className='hVjzhH' type="button">
                                <img src={bookmark} alt='bookmark' className='bookmark' />
                            </button>
                        </a>
                    </div>
                    <div className='dubepU'>
                        <button className='eUNaBX' type="button">Show More</button>
                        <div className='juHSdN'>
                            <div className='hTgwin'>Don't see the professor you're looking for? </div>
                        </div>
                        <a href='#' className='cdfqaH'>
                            Add a Professor
                        </a>
                    </div> */}
                </div>
            </div>
           
        </>
    )
}

export default UniversityRatingSuccess