import React from "react";
import "./SearchComponent.scss";
import ProfessorRatingNavbar from "../Navbar/professorRatingNavbar";
import LandingPageFooter from "../footer/LandingPageFooter";
import SearchComponent from "./searchComponent";

export default () => {
  return (
    <div className="search-section">
      <ProfessorRatingNavbar />
      <SearchComponent />
      {/* <div className="footer-section">
        <LandingPageFooter />
      </div> */}
    </div>
  );
};
