import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { getUser } from './module/features/authentication'
import { useState } from 'react'

function Authentication({ children }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userProfile = useSelector(state => state.completeProfileSlice.userProfile)
    const [pathName, setPathName] = useState(window.location.pathname)
    useEffect(() => {
        // if (pathName.toLowerCase() != '/dashboard/profile') {
        //     if (userProfile  && !userProfile?.profile?.fullname) {
        //         navigate('/dashboard/Profile?tab=General')
        //     }
        // }
    }, [userProfile, pathName])


    return (
        <>

            {children}
        </>
    )
}

export default Authentication