import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './Competency.scss'
import { Dropdown, Space } from 'antd';
import { PieChart, Pie, AreaChart, Cell, Area, BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Line, ComposedChart, Cross, Customized, ReferenceLine } from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import { AllStudentAverageResult, getStudentAverage } from '../../module/features/competency';
import { Tooltip as TooltipAntd } from 'antd'
import OutcomesGraph from '../ProfessorRating/OutcomesGraph';
import { getSingleUserOutcome } from '../../module/features/outcomesSlice';


function Competency() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const courseCompetencies = useSelector(state => state.competencySlice.courseCompetencies)
  const userProfile = useSelector(state => state.completeProfileSlice.userProfile)
  const AllCompetencieResult = useSelector(state => state.competencySlice.AllCompetencieResult)
  const studentAverageResults = useSelector(state => state.competencySlice.studentAverageResults)
  const [yourCourses, setYourCourses] = useState([])
  const getSingleUserOutcomeResult = useSelector(state => state.outcomesSlice.getSingleUserOutcomeResult)
  const competenciesWithCourse = useSelector(state => state.competencySlice.selectedCourseCompetenciesWithCourse)
  const AllCompetencieTier = useSelector(state => state.competencySlice.AllCompetencieTier)

  const majorCourses = useSelector(state => state.coursesSlice.majorCourses)
  const minorCourses = useSelector(state => state.coursesSlice.minorCourses)
  const [courseCompetenciesArr, setCourseCompetenciesArr] = useState([])
  const [activeCompetency, setActiveCompetency] = useState('')
  const [allCompetencieResultArr, setAllCompetencieResultArr] = useState([])
  const [competencies, setCompetencies] = useState([])
  const [dataPopulation, setDataPopulation] = useState([])
  const [activeOutcomeCourse, setActiveOutcomeCourse] = useState(false)
  const tier1 = useSelector(state => state.coursesSlice.tier1)
  const tier2 = useSelector(state => state.coursesSlice.tier2)
  const tier3 = useSelector(state => state.coursesSlice.tier3)

  const dataPie = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    { name: 'Group D', value: 200 },
  ];

  const [courses, setCourses] = useState([[
    {
      "label": "Principle Of Accounting",
      "key": 0
    }
  ]])

  const COLORS = [
    {
      color: '#0088FE',
      text: 'Communication Skills',
    },
    {
      color: '#00C49F',
      text: 'Self-Management',
    },
    {
      color: '#FFBB28',
      text: 'Decision Making',
    },
    {
      color: '#FF8042',
      text: 'Leadership Skills',
    }
  ];

  // useEffect(() => {
  //   if (yourCourses.length) {
  //     let arr = []
  //     yourCourses.map((a, i) => {
  //       arr.push({
  //         // ...a,
  //         label: a?.course?.course,
  //         key: i
  //       })
  //     })
  //     console.log(arr, 'arrrrrrr')
  //     setCourses(arr)
  //   }
  // }, [yourCourses])

  useEffect(() => {
    if ((majorCourses || []) &&
      (minorCourses || [])) {
      let arrMajor = majorCourses || [];
      let arrMinor = minorCourses || [];
      setYourCourses([...arrMajor, ...arrMinor])
    }
  }, [majorCourses,
    minorCourses])

  useEffect(() => {
    console.log(courseCompetenciesArr, 'courseCompetenciesArrcourseCompetenciesArr')
    if (courseCompetenciesArr && courseCompetenciesArr.length) {
      let arr = []
      courseCompetenciesArr.map((a, i) => {
        a.competencies.map((b, j) => {
          arr.push({
            ...a,
            id: b.id,
            label: b?.name,
            key: b?.id,
            onClick: () => {
              // console.log(b)
              setActiveCompetency({
                ...a,
                id: b.id,
                label: b?.name,
                key: b?.id,
              })
            }
          })
        })
      })
      console.log(arr, 'sdasdasdas')
      setCompetencies(arr)
      setActiveCompetency(arr[0])
    }
  }, [courseCompetenciesArr])

  useEffect(() => {
    if (activeCompetency) {
      console.log(activeCompetency, 'activeCompetency')
      dispatch(getStudentAverage({
        "userId": userProfile.id,
        "competencyId": activeCompetency.id
      }))
    }
    if (activeCompetency) {

    }
  }, [activeCompetency])

  useEffect(() => {
    if (studentAverageResults) {
      setDataPopulation([{
        preScore: 0,
        postScore: 0,
        OnetLevel: 0,
      },
      {
        preScore: studentAverageResults?.PreScore?.toFixed(2) ? studentAverageResults?.PreScore?.toFixed(2) : 0,
        postScore: studentAverageResults?.postScore?.toFixed(2) ? studentAverageResults?.postScore?.toFixed(2) : 0,
        OnetLevel: studentAverageResults?.OnetLevel?.toFixed(2) ? studentAverageResults?.OnetLevel?.toFixed(2) : 0
      }
      ])
    }
  }, [studentAverageResults])

  useEffect(() => {
    if (AllCompetencieResult?.length) {
      console.log(AllCompetencieResult, 'AllCompetencieResult')
      let arr = AllCompetencieResult.map((a, i) => {
        return {
          ...a,
          YourScore: a.pre_score?.toFixed(2),
          yourPostScore: a.post_score?.toFixed(2),
          ungradeScore: a.scoreGroup?.toFixed(2),
          amt: a.scoreGroup?.toFixed(2),
        }
      })
      setAllCompetencieResultArr(arr)
    }
  }, [AllCompetencieResult])
  const [singleCompetencieResult, setSingleCompetencieResult] = useState([])

  useEffect(() => {
    if (AllCompetencieResult && activeCompetency) {
      console.log(AllCompetencieResult, activeCompetency, 'sdasdasdsa')
      setSingleCompetencieResult(AllCompetencieResult.filter(obj => obj.competency_id === activeCompetency?.id))
      // setSingleCompetencieResult([...arr])
    }
  }, [AllCompetencieResult, activeCompetency])
  // using Customized gives you access to all relevant chart props

  const CustomizedCross = (props) => {
    const { width, height, stroke, fill, formattedGraphicalItems, prevData } = props;
    // get first series in chart
    const firstSeries = formattedGraphicalItems.length ? formattedGraphicalItems[0] : {};
    // get any point at any index in chart
    const secondPoint = firstSeries?.props?.data[0];
    let point = prevData.length ? prevData[0]?.scoreGroup : 0
    // render custom content using points from the graph
    return (
      <Cross
        y={studentAverageResults?.score}
        x={studentAverageResults?.score}
        top={point}
        left={50}
        height={height}
        width={width}
        stroke={'#ff8042'}
        fill={fill ?? 'none'}
      />
    );
  };
  const getResult = () => {
    console.log('calledasdasd')
    let arr = []
    let resultArr = []
    if (competenciesWithCourse) {
      console.log(competenciesWithCourse, 'competenciesWithCourse')
      let minors = competenciesWithCourse?.minor ? competenciesWithCourse?.minor : []
      let major = competenciesWithCourse?.major ? competenciesWithCourse?.major : []
      let updatedArr = [];
      ([...minors, ...major]).map((a, i) => {
        a.course_competencies?.map((b) => {
          let data = { competencies: [{ ...b.competencies, courseId: b.course.id }] }
          if (!updatedArr.includes(data)) {
            updatedArr.push(data)
          }
        })
      })
      resultArr = [...updatedArr]
    }
    if (resultArr.length) {
      console.log(resultArr, 'course_competencies')
      resultArr.map(({ competencies }, i) => {
        // course.competencies.map(({competencies}, j) => {
        if (!arr.includes(competencies[0].id)) arr.push(competencies[0].id)
        // })
      })
      let AllCompetencieTierID = AllCompetencieTier.map((val=>val.id)).join(",")
      console.log(AllCompetencieTier,AllCompetencieTierID ,"AllCompetencieTierID + "," + ")
      dispatch(AllStudentAverageResult({
        "userId": userProfile?.id,
        "competencyIds": AllCompetencieTierID + "," +  arr
      }))
    }

  }

  useEffect(() => {
    if (competenciesWithCourse && userProfile) {
      getResult()
    }
  }, [userProfile, competenciesWithCourse, tier1, tier2, tier3])

  useEffect(() => {
    let updatedArr = [];
    if (competenciesWithCourse) {
      let minors = competenciesWithCourse?.minor ? competenciesWithCourse?.minor : [];
      let major = competenciesWithCourse?.major ? competenciesWithCourse?.major : [];
      let joinedArr = [...minors, ...major]
      joinedArr.map((a, i) => {
        a.course_competencies?.map((b) => {
          let data = { competencies: [{ ...b.competencies, courseId: b.course.id }] }
          if (!updatedArr.includes(data)) {
            updatedArr.push(data)
          }
        })
        //   updatedArr.push(data)
      })
    }
    console.log(updatedArr, AllCompetencieResult, competenciesWithCourse, 'updatedArr')
    if (updatedArr?.length && AllCompetencieResult) {
      let arr = []
      updatedArr.map((a, i) => {
        let score = 0
        AllCompetencieResult.map((b, j) => {
          a.competencies.map((c, k) => {
            if (c.id === b.competency_id) {
              if (b.pre_score) {
                score = b.pre_score
              }
            }
          })
        })
        if (score) {
          arr.push({
            ...a,
            score: score
          })
        }

      })
      console.log(arr, 'arrasdsadas')
      setCourseCompetenciesArr(arr)
    }
  }, [competenciesWithCourse, AllCompetencieResult])

  const [outcomes, setOutcomes] = useState([
    {
      repeated: 2,
      outcome: {
        name: 'Very Good'
      }
    },
    {
      repeated: 6,
      outcome: {
        name: 'Very Bad'
      }
    },
    {
      repeated: 1,
      outcome: {
        name: 'Very Fair'
      }
    }
  ])

  useEffect(() => {
    if (yourCourses) {
      setActiveOutcomeCourse(yourCourses[0])
    }
  }, [yourCourses])
  useEffect(() => {
    console.log('asdasdasdasdasd')
    if (activeOutcomeCourse && userProfile) {
      dispatch(getSingleUserOutcome({
        "studentId": userProfile.id,
        "courseId": activeOutcomeCourse?.course?.id
      }))
    }
  }, [activeOutcomeCourse, userProfile])

  useEffect(() => {
    console.log(getSingleUserOutcomeResult, 'getSingleUserOutcomeResultgetSingleUserOutcomeResult')
  }, [getSingleUserOutcomeResult])

  return (
    <div className='Competency'>
      <div className='innerCompetency'>
        <div className='PageHeading'>
          <h1></h1>
          <button className='hoverEffect' onClick={() => {
            navigate('/dashboard/assesment')
          }}>
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8Z"></path><path d="M192 474h672q8 0 8 8v60q0 8-8 8H160q-8 0-8-8v-60q0-8 8-8Z"></path></svg>
            Take the Competency Test
          </button>


        </div>

        <div className='competencyReport'>
          <div className='leftBar'>
            <div className='PageHeading'>
              <h1>Competency Report</h1>
              {/* <Dropdown
                menu={{
                  items,
                }}
                trigger={['click']}
              >
                <button className='hoverEffect'>Overall <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path></svg></button>
              </Dropdown> */}
            </div>
            <div className='barchart'>
              <ResponsiveContainer width={'100%'} height={350}>
                <ComposedChart
                  // width={window.innerWidth - 298 - 120 - 400}
                  // height={350}
                  data={allCompetencieResultArr}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <YAxis />
                  <XAxis dataKey="competency_name" />
                  <Tooltip />
                  <Legend />
                  <Bar name='Pre score' dataKey="YourScore" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                  <Bar name='Post score' dataKey="yourPostScore" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} />
                  <Line type="monotone" name='Onet level' dataKey="OnetLevel" stroke="#58a8ff" strokeWidth={2} strokeLinecap="round" />
                  <Line type="monotone" dataKey="ungradeScore" stroke="#ff8042" strokeWidth={2} strokeLinecap="round" name="Ungrade level" />
                </ComposedChart>
              </ResponsiveContainer>
            </div>

            <div className='PageHeading'>
              <h1 onClick={() => {
                console.log(singleCompetencieResult, 'singleCompetencieResult')
              }}>Competency By Population</h1>


            </div>
            <div className='barchart'>
              <ResponsiveContainer width={'100%'} height={350}>
                <AreaChart
                  // width={window.innerWidth - 298 - 120 - 400}
                  // height={350}
                  data={dataPopulation}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <YAxis />
                  <Tooltip />
                  {/* <Bar name='Pre Score' dataKey="YourScore" fill="#ff8042" activeBar={<Rectangle fill="pink" stroke="blue" />} /> */}
                  <Area type="monotone" dataKey="scoreGroup" stroke="#8884d8" fill="#8884d8" name="Ungrade Pre-level" />
                  <Area type="monotone" dataKey="pre_score" stroke="#82ca9d" fill="#82ca9d" name="Ungrade Post-level" />
                  <Area type="monotone" dataKey="OnetLevel" stroke="#58a8ff" fill="#58a8ff" name="Onet level" />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className='rightbar'>
            <TooltipAntd title="Coming Soon">
              <div className='pieChart'>

                <div className='PageHeading'>
                  <h1>Participant Outcomes</h1>
                  <Dropdown
                    // menu={{
                    //   courses,
                    // }}
                    trigger={['click']}
                    dropdownRender={() => {
                      return (
                        <div className='dropDownCourseOutcome'>
                          {yourCourses.map((a, i) => (
                            <div className='dropDownSingle' onClick={() => setActiveOutcomeCourse(a)}>
                              {a?.course?.course}
                            </div>
                          ))}
                        </div>
                      )
                    }}
                  >

                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path></svg>
                  </Dropdown>
                </div>
                <p className='courseName'>{activeOutcomeCourse?.course?.course}</p>
                <OutcomesGraph outcomes={getSingleUserOutcomeResult} />
                {/* <div>
                  <ResponsiveContainer width={'100%'} height={200}>
                    <PieChart>
                      <Pie
                        data={dataPie}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                      >

                        {dataPie.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length].color} />
                        ))}
                      </Pie>

                    </PieChart>
                  </ResponsiveContainer>
                </div> */}
                {/* {COLORS.map((a, i) => (
                  <div className='graphPoints'>
                    <div className='graphColor' style={{ backgroundColor: a.color }}></div>
                    {a.text}
                  </div>
                ))} */}
              </div>
            </TooltipAntd>

            <div className='PageHeading SelectCompe'>
              <div className='PageHEaderSelect'>

                <Dropdown
                  className='Dropdown'
                  menu={{
                    items: competencies,
                  }}
                  trigger={['click']}
                  onOpenChange={() => {
                  }}
                >
                  <Space>
                    <p>
                      {activeCompetency.label || ''}
                    </p>
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path></svg>
                  </Space>
                </Dropdown>
              </div>

            </div>
            <div className='barchart'>
              <ResponsiveContainer width={'100%'} height={350}>
                <ComposedChart
                  // width={window.innerWidth - 298 - 120 - 400}
                  // height={350}
                  data={singleCompetencieResult}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Legend />
                  <Bar name='Pre Score' dataKey="pre_score" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                  <Bar name='Post Score' dataKey="post_score" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} />
                  {/* <Customized component={CustomizedCross} /> */}
                  <XAxis dataKey="compentencyName" />
                  <YAxis />
                  {/* <Line type="monotone" dataKey="amt" stroke="#ff8042" strokeWidth={2} strokeLinecap="round" name="Average" /> */}
                  <ReferenceLine
                    y={singleCompetencieResult[0]?.ungradeScore}
                    // label="Class Average"
                    color='#ff8042'
                    stroke="#ff8042"
                    strokeWidth={2}
                    ifOverflow="extendDomain"
                  />
                  <ReferenceLine
                    y={studentAverageResults?.OnetLevel}
                    // label="Onet level"
                    color='#58a8ff'
                    stroke="#58a8ff"
                    strokeWidth={2}
                    ifOverflow="extendDomain"
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Competency