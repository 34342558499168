import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit'
import axios from 'axios'
import { getCookie } from '../../Constant/Utils';

const initialState = {
    usaJobs: [],
    usaJobsCountAll: 0,
    usaJobsCount: 0,
    linkedInJobs: [],
    getRelatedJobs: [],
    getOccupationCompetency: []

}

export const getUSAJobs = createAsyncThunk(
    "occupationalTodo/getUSAJobs",
    async (data) => {
        console.log(data, 'datadatadata')
        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://data.usajobs.gov/api/Search?Keyword=${data}`,
                headers: {
                    'User-Agent': 'saqibyousuf001@gmail.com',
                    'Authorization-Key': 'bQPu0L7kIsM7YlZZItqJTfj0z/WVbBkghmcZy2qn6gU=',
                    'Host': 'data.usajobs.gov',
                    'Cookie': 'akavpau_DATA_USAJ=1705333561~id=1dc3858580c57afc971c35bf5e03b71a'
                }
            };
            // https://data.usajobs.gov/api/Search?Keyword=Cognitive Abilities&WhoMayApply=public&fields=all
            const response = await axios.request(config);
            console.log(response)
            return response.data.SearchResult; // Return the data here


        } catch (error) {
            console.error(error);
        }
    });


export const getRelatableJobs = createAsyncThunk(
    "occupationalTodo/getRelatableJobs",
    async (data) => {
        console.log(data, 'datadatadata')
        try {
            let arr = [];
            if (data && data.length) {
                // Map each courseObj to a promise
                const promises = data.map(async (courseObj) => {
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `https://data.usajobs.gov/api/Search?Keyword=${courseObj.course.course}&ResultsPerPage=3&WhoMayApply=public&fields=all`,
                        headers: {
                            'User-Agent': 'saqibyousuf001@gmail.com',
                            'Authorization-Key': 'bQPu0L7kIsM7YlZZItqJTfj0z/WVbBkghmcZy2qn6gU=',
                            'Host': 'data.usajobs.gov',
                            'Cookie': 'akavpau_DATA_USAJ=1705333561~id=1dc3858580c57afc971c35bf5e03b71a'
                        }
                    };
                    // Fetch data for each courseObj
                    const response = await axios.request(config);
                    return response.data?.SearchResult?.SearchResultItems;
                });

                // Wait for all promises to resolve
                arr = await Promise.all(promises);
                arr = arr.flat()
            }

            console.log(arr, 'relatedjobs'); // This will execute after all promises are resolved
            return arr; // Return the data here

        } catch (error) {
            console.error(error);
            throw error; // Rethrow the error to handle it in the caller
        }
    }
);


export const getLinkedInJobs = createAsyncThunk(
    "occupationalTodo/getLinkedInJobs",
    async (data) => {
        try {
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/client/occupation/linkedIn`,
                headers: {},
                data: data
            };

            const response = await axios.request(config);
            console.log(response)
            return response.data.data; // Return the data here


        } catch (error) {
            console.error(error);
        }
    });

export const getOccupationalMap = createAsyncThunk(
    "occupationalTodo/getOccupationalMap",
    async (data) => {
        try {
            const token = getCookie('ungradeToken');
            console.log(data, 'dsadsadas')
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/client/occupation/competency/${data.onetId}`,
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': token
                },
            };

            const response = await axios.request(config);
            console.log(response)
            if (response) {
                if (data.handleShowMap) {
                    data.handleShowMap()
                }
                return response.data.data; // Return the data here
            }


        } catch (error) {
            console.error(error);
        }
    });

export const occupationalTodo = createSlice({
    name: 'occupationalTodo',
    initialState,
    reducers: {
        addTodo: (state, action) => {
            // request
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUSAJobs.pending, (state, action) => {
            })
            .addCase(getUSAJobs.fulfilled, (state, action) => {
                state.usaJobs = action.payload?.SearchResultItems;
                state.usaJobsCountAll = action.payload?.SearchResultCountAll;
                state.usaJobsCount = action.payload?.SearchResultCount;
            })
            .addCase(getUSAJobs.rejected, (state, action) => {
            })
            .addCase(getLinkedInJobs.pending, (state, action) => {
            })
            .addCase(getLinkedInJobs.fulfilled, (state, action) => {
                console.log(action.payload, 'action.payload')
                state.linkedInJobs = action.payload;
            })
            .addCase(getLinkedInJobs.rejected, (state, action) => {
            })
            .addCase(getRelatableJobs.pending, (state, action) => {
            })
            .addCase(getRelatableJobs.fulfilled, (state, action) => {
                console.log(action.payload, 'action.payload')
                state.getRelatedJobs = action.payload;
            })
            .addCase(getRelatableJobs.rejected, (state, action) => {
            })
            .addCase(getOccupationalMap.pending, (state, action) => {
            })
            .addCase(getOccupationalMap.fulfilled, (state, action) => {
                console.log(action.payload, 'action.payload')
                state.getOccupationCompetency = action.payload;
            })
            .addCase(getOccupationalMap.rejected, (state, action) => {
            })

    }
})

export const { addTodo, removeTodo } = occupationalTodo.actions

export default occupationalTodo.reducer