import { Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { universityProfessors } from "../../module/features/professor";

import {
  getUniversityAvgRating,
  getUniversityOutcomes,
  getUniversityRating,
  getUniversityWithProfessorAvgRating,
} from "../../module/features/ratings";
import { getSingleUniversities } from "../../module/features/university";
import OutcomesGraph from "../ProfessorRating/OutcomesGraph";
import SearchSchool from "../SearchSchool/SearchSchool";
import LandingPageFooter from "../footer/LandingPageFooter";
import IndivisualRating from "./IndivisualRating";
import SchoolRatingBars from "./SchoolRatingBars";
import "./schoolcomponents.scss";
import { getQuery } from "../../Constant/Utils";

function SchoolComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const url = new URL(window.location);
  const id = url.searchParams.get("id");
  // console.log(id, "id");
  const singleUniversity = useSelector(
    (state) => state.universitySlice.singleUniversity
  );

  const universityRating = useSelector(
    (state) => state.ratingSlice.universityRating
  );
  const unversityAvgRating = useSelector(
    (state) => state.ratingSlice.unversityAvgRating
  );
  const universityWithProfessorRating = useSelector(
    (state) => state.ratingSlice.universityWithProfessorRating
  );
  const singleUniversityAllProfessor = useSelector(
    (state) => state.professorSlice.singleUniversityAllProfessor
  )
  const universityOutcomes = useSelector(
    (state) => state.ratingSlice.universityOutcomes
  );

  useEffect(() => {

    if (id) {
      dispatch(getSingleUniversities({ id: id }));
      dispatch(getUniversityRating({ id: id }));
      dispatch(getUniversityAvgRating({ id: id }));
      dispatch(getUniversityWithProfessorAvgRating({ university_id: id }));
      dispatch(universityProfessors({ id: id }))
      dispatch(getUniversityOutcomes({ university_id: id }))

    }

  }, [id]);

  useEffect(() => {
    console.log(universityWithProfessorRating, "universityWithProfessorRating");
  }, [universityWithProfessorRating]);

  return (
    <>
      <div>
        <SearchSchool defaultProfessor={false} />
      </div>
      <div className="address-heading">
        <p>
          {singleUniversity.CITY}, {singleUniversity.COUNTRY}
        </p>
      </div>

      <div className="School-Rating-First-Section">
        <div>
          <h1 className="schoolName line-clamp">{singleUniversity.NAME}</h1>
        </div>

        <div className="ratingBtns">
          <button
            className="rateSchoolBtn"
            onClick={() => {
              navigate(`/compare${window.location.search}`);
            }}
          >
            Compare School
          </button>
        </div>
      </div>


      <div className="Second-Professor-Rating-Section">
        <div className="overall-Rating-Text">
          <div className="Third-Professor-Rating-Section">
            <div className="ProfessorName">
              <h2>Universities Top Tags</h2>
            </div>
          </div>
          {universityOutcomes ?
            <>
              <OutcomesGraph outcomes={universityOutcomes} />
            </>
            :
            <div className="noRatingText">
              <h3>There is no tags yet</h3>
            </div>
          }
        </div>
        <div className="schoolratingsummrary-container schoolratingsummrary-border-remove">
          {/* fourth section  */}
          <div className="fourthSection">
            <h2>Professor's rating</h2>
            <div className="fourth-Section-child">
              Select Professor:
              <Select
                className="selectCourseDropDown"
                showSearch
                placeholder="Select professor"
                optionFilterProp="children"
                onChange={(ev) => {
                  let id = getQuery("id");
                  dispatch(getUniversityWithProfessorAvgRating({
                    "university_id": id,
                    "professor_id": ev
                  }));
                }}
                options={singleUniversityAllProfessor.map((a, i) => {
                  return {
                    ...a,
                    value: a.id,
                    label: a.professor_name
                  }
                })}
              />
            </div>
          </div>
          <SchoolRatingBars universityWithProfessorRating={universityWithProfessorRating} />
        </div>
      </div>
      {/* <div className="ratingDiv">
        <h2>RATINGS</h2>
        <div className="innerRatingDiv">
          <div className="sideRates ratesOptions leftRate">
            <div
              className="rate rate-marginRight"
              style={{ marginRight: "-83px" }}
            >
              <p>
                <img
                  src={clubs}
                  alt="category-grade-icon"
                  className="category-grade-icon"
                />
                &nbsp; Clubs
              </p>
              <div className="rateNumbers">
                {unversityAvgRating["AVG(clubs)"]?.toFixed(2)}
              </div>
            </div>
            <div className="rate">
              <p>
                <img
                  src={factulity}
                  alt="category-grade-icon"
                  className="category-grade-icon"
                />
                &nbsp; Facilities
              </p>
              <div className="rateNumbers">
                {unversityAvgRating["AVG(facilities)"]?.toFixed(2)}
              </div>
            </div>

            <div className="rate">
              <p>
                <img
                  src={food}
                  alt="category-grade-icon"
                  className="category-grade-icon"
                />
                &nbsp; Food
              </p>
              <div className="rateNumbers">
                {unversityAvgRating["AVG(food)"]?.toFixed(2)}
              </div>
            </div>
            <div
              className="rate rate-marginRight"
              style={{ marginRight: "-83px" }}
            >
              <p>
                <img
                  src={happines}
                  alt="category-grade-icon"
                  className="category-grade-icon"
                />
                &nbsp; Happiness
              </p>
              <div className="rateNumbers">
                {unversityAvgRating["AVG(happiness)"]?.toFixed(2)}
              </div>
            </div>
          </div>
          <div className="ratesOptions">
            <div className="rate colRate ">
              <p>
                <img
                  src={internet}
                  alt="category-grade-icon"
                  className="category-grade-icon"
                />
                &nbsp; Internet
              </p>
              <div className="rateNumbers">
                {unversityAvgRating["AVG(internet)"]?.toFixed(2)}
              </div>
            </div>
            <div className="overAllRating">
              <h1>{unversityAvgRating["AllRating"]}</h1>
              <p>Overall Quality</p>
            </div>
            <div className="rate colRate col-Rate1">
              <div className="rateNumbers">
                {unversityAvgRating["AVG(location)"]?.toFixed(2)}
              </div>
              <p>
                <img
                  src={location}
                  alt="category-grade-icon"
                  className="category-grade-icon"
                />
                &nbsp; Location
              </p>
            </div>
          </div>
          <div className="sideRates ratesOptions rightRate">
            <div
              className="rate rate-marginLeft"
              style={{ marginLeft: "-83px" }}
            >
              <p>
                <img
                  src={opportutines}
                  alt="category-grade-icon"
                  className="category-grade-icon"
                />
                Opportunities &nbsp;
              </p>
              <div className="rateNumbers">
                {unversityAvgRating["AVG(opportunities)"]?.toFixed(2)}
              </div>
            </div>
            <div className="rate">
              <p>
                <img
                  src={reputation}
                  alt="category-grade-icon"
                  className="category-grade-icon"
                />
                Reputation &nbsp;
              </p>
              <div className="rateNumbers">
                {unversityAvgRating["AVG(reputation)"]?.toFixed(2)}
              </div>
            </div>
            <div className="rate">
              <p>
                <img
                  src={safety}
                  alt="category-grade-icon"
                  className="category-grade-icon"
                />
                Safety &nbsp;
              </p>
              <div className="rateNumbers">
                {unversityAvgRating["AVG(safety)"]?.toFixed(2)}
              </div>
            </div>
            <div
              className="rate rate-marginLeft"
              style={{ marginLeft: "-83px" }}
            >
              <p>
                <img
                  src={socail}
                  alt="category-grade-icon"
                  className="category-grade-icon"
                />
                Social &nbsp;
              </p>
              <div className="rateNumbers">
                {unversityAvgRating["AVG(social)"]?.toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="school-rating">
        <ul className="ratingList-ul">
          {universityRating &&
            universityRating.map((a, i) => {
              let obj = { ...a };
              delete obj.id;
              delete obj.comment;
              delete obj.created_at;
              delete obj.updated_at;
              delete obj.schoolID;
              delete obj.compID;
              return (
                <li className="styled-listed">
                  <IndivisualRating a={a} obj={obj} />
                </li>
              );
            })}
        </ul>
      </div>

      <LandingPageFooter />
    </>
  );
}

export default SchoolComponent;
