import React, { useEffect, useMemo } from 'react';
import {
    RadiusBottomleftOutlined,
    RadiusBottomrightOutlined,
    RadiusUpleftOutlined,
    RadiusUprightOutlined,
} from '@ant-design/icons';
import { Button, Divider, notification, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
const Context = React.createContext({
    name: 'Click here to take assesment',
});
const Notification = ({ notificationFire, setNotificationFire }) => {
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate()
    const openNotification = (placement) => {
        api.info({
            message: `Your course has been added`,
            description: <Context.Consumer>{({ name }) => `${name}!`}</Context.Consumer>,
            placement,
            onClick: () => {
                navigate('/dashboard/assesment')
            }
        });
        setNotificationFire(false)
    };
    const contextValue = useMemo(
        () => ({
            name: 'Click here to take assesment',
        }),
        [],
    );
    useEffect(() => {
        console.log(notificationFire, 'notificationFire')
        if (notificationFire) {
            openNotification('topRight')
        }
    }, [notificationFire])
    return (
        <Context.Provider value={contextValue}>
            {contextHolder}
            {/* <Space>
        <Button
          type="primary"
          onClick={() => openNotification('topLeft')}
          icon={<RadiusUpleftOutlined />}
        >
          topLeft
        </Button>
        <Button
          type="primary"
          onClick={() => openNotification('topRight')}
          icon={<RadiusUprightOutlined />}
        >
          topRight
        </Button>
      </Space>
      <Divider />
      <Space>
        <Button
          type="primary"
          onClick={() => openNotification('bottomLeft')}
          icon={<RadiusBottomleftOutlined />}
        >
          bottomLeft
        </Button>
        <Button
          type="primary"
          onClick={() => openNotification('bottomRight')}
          icon={<RadiusBottomrightOutlined />}
        >
          bottomRight
        </Button>
      </Space> */}
        </Context.Provider>
    );
};
export default Notification;