import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './Occupational.scss';

function OccupationalMap({
    isModalOpen,
    handleOk,
    handleCancel,
    Occupation,
    setOccupation
}) {
    const getOccupationCompetency = useSelector(state => state.occupationalTodo.getOccupationCompetency)
    const AllCompetencieResult = useSelector(state => state.competencySlice.AllCompetencieResult)
    const [skills, setSkills] = useState([])
    const [knowledge, setKnowledge] = useState([])
    const [abilities, setAbilities] = useState([])
    const [skillDropdown, setSkillDropdown] = useState(false)
    const [abilitiesDropdown, setAbilitiesDropdown] = useState(false)
    const [knowledgeDropdown, setKnowledgeDropdown] = useState(false)
    useEffect(() => {
        console.log(AllCompetencieResult, 'adsa')
        if (getOccupationCompetency?.length) {

            let skillArr = []
            let knowledgeArr = []
            let abilitiesArr = []
            getOccupationCompetency.map((a) => {
                if (a.dataType === 'knowledge') {
                    let data = {
                        ...a,
                        result: AllCompetencieResult.filter((obj) => a.competency_id === obj.competency_id)
                    }
                    knowledgeArr.push(data)
                } else if (a.dataType === 'skills') {
                    let data = {
                        ...a,
                        result: AllCompetencieResult.filter((obj) => a.competency_id === obj.competency_id)
                    }
                    skillArr.push(data)
                } else if (a.dataType === 'abilities') {
                    let data = {
                        ...a,
                        result: AllCompetencieResult.filter((obj) => a.competency_id === obj.competency_id)
                    }
                    abilitiesArr.push(data)
                }

            })
            console.log({ skillArr, knowledgeArr, abilitiesArr }, 'sdadasdasd')
            setSkills(skillArr)
            setKnowledge(knowledgeArr)
            setAbilities(abilitiesArr)
        }
    }, [getOccupationCompetency, AllCompetencieResult])
    return (
        <Modal title="Occupation Map" width={900} open={isModalOpen} onOk={() => {
            handleOk()
            setOccupation({})
        }} onCancel={() => {
            handleCancel()
            setOccupation({})
        }}>
            <div className='pyramidOccupation'>
                <div className='triangle'>
                    <div className='step top occupationName'>{Occupation.title || 'asdasd'}</div>
                    <div className='step middleFirst'>
                        {skills.map((a, i) => {
                            return i < 2 ?
                                <p className='skillName'>{a.name}</p>
                                : null
                        })}
                        {/* <p>skill 3</p> */}
                        <p className='seeMore' onClick={() => {
                            setSkillDropdown(!skillDropdown)
                        }}>See More</p>
                    </div>
                    {skillDropdown ?
                        <div className='dropDownMiddle skillDropdown' style={skillDropdown ? { height: "250px" } : {}}>
                            {skills.map((a, i) => {
                                return <p onClick={()=>{
                                    console.log(a)
                                }} style={a.result.length ? { filter: 'contrast(1)' } : {filter: 'contrast(0.5)'}} title={a.name}>{a.name}</p>
                            })}
                        </div>
                        : null}
                    <div className='step middleSecond'>
                        {abilities.map((a, i) => {
                            return i < 3 ?
                                <p className='skillName'>{a.name}</p>
                                : null
                        })}
                        <p className='seeMore' onClick={() => {
                            setAbilitiesDropdown(!abilitiesDropdown)
                        }} >See More</p>
                    </div>
                    {abilitiesDropdown ?
                        <div className='dropDownMiddle abilitiesDropdown' style={abilitiesDropdown ? { height: "250px" } : {}}>
                            {abilities.map((a, i) => {
                                return <p onClick={()=>{
                                    console.log(a)
                                }} style={a.result.length ? { filter: 'contrast(1)' } : {filter: 'contrast(0.5)'}} title={a.name}>{a.name}</p>
                            })}
                        </div>
                        : null}
                    <div className='step bottom'>
                        {knowledge.map((a, i) => {
                            return i < 3 ?
                                <p className='skillName'>{a.name}</p>
                                : null
                        })}
                        <p className='seeMore' onClick={() => {
                            setKnowledgeDropdown(!knowledgeDropdown)
                        }}>See More</p>
                    </div>
                    {knowledgeDropdown ?
                        <div className='dropDownMiddle knowledgeDropdown' style={knowledgeDropdown ? { height: "250px" } : {}}>
                            {knowledge.map((a, i) => {
                                return <p onClick={()=>{
                                    console.log(a)
                                }} style={a.result.length ? { filter: 'contrast(1)' } : {filter: 'contrast(0.5)'}} title={a.name}>{a.name}</p>
                            })}
                        </div>
                        : null}
                </div>
                <div className='details'>
                    <div className='detailInfo'>
                        <div className='colorBox  Skills'></div>
                        <div className='info'>Skills</div>
                    </div>
                    <div className='detailInfo'>
                        <div className='colorBox  Abilities'></div>
                        <div className='info'>Abilities</div>
                    </div>
                    <div className='detailInfo'>
                        <div className='colorBox  knowledge'></div>
                        <div className='info'>Knowledge</div>
                    </div>
                </div>
                {/* <ApexChart/> */}
            </div>
        </Modal >
    )
}

export default OccupationalMap