import React, { useState } from 'react'
import loginGIF from './../../Assest/loginGIF.json'
import { Link, useNavigate } from 'react-router-dom'
import './Authentication.scss'
import { useLottie } from "lottie-react";
import logo from './../../Assest/logo.png'
import { useDispatch } from 'react-redux';
import { confirmEmailForget, register } from '../../module/features/authentication';
import SocialLogin from './SocialLogin';
import { Checked, MailIcon } from '../../Constant/Icons';

function ConfirmEmail() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const url = new URL(window.location)
  const [data, setData] = useState({
    "email": "",
  })
  const options = {
    animationData: loginGIF,
    loop: true
  };

  const { View } = useLottie(options);
  const [sended, setSended] = useState(false)
  const clearState = () => {
    setData({
      "email": "",
    })
    setSended(true)
  }

  const ConfirmEmailUser = () => {
    const type = url.searchParams.get('type')
    let obj = {
      ...data
    }
    // setSended(true)

    dispatch(confirmEmailForget({ data: obj, clearState: clearState, setLoader }))
  }
  return (
    <div className='authenticationPage'>
      <div className='innerAuthenticationPage'>
        <div className='gifBoxDiv'>
          <div className='gifBox'>
            {View}
          </div>
        </div>
        <div className='form confirmEmail'>
          {sended ?
            <div className='formDIv'>
              <div>
                <h3 className='wevesentmail'>We've sent you an e-mail</h3>
              </div>
              <div className='innerForm mailIconContainer'>
                <div className='mailIcon'>
                  <div className='mailIconDiv'>
                    <MailIcon />
                  </div>
                  <div className='checkIconDiv'>
                    <Checked />
                  </div>
                </div>
              </div>
              {/* <SocialLogin type="student" /> */}
            </div>
            :

            <div className='formDIv'>
              <div>
                <h3>Change Your Password</h3>
              </div>
              <div className='innerForm'>

                <label>
                  <p>Email</p>
                  <input value={data.email} placeholder='Email'
                    onChange={(ev) => {
                      let obj = { ...data }
                      obj.email = ev.target.value
                      setData(obj)
                    }}
                  />
                </label>

                <div className='loginButtons'>
                  <button className='hoverEffect' onClick={ConfirmEmailUser}>Next</button>
                </div>
                
              </div>
              {/* <SocialLogin type="student" /> */}
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default ConfirmEmail