import React, { useState } from 'react'
import Spinner from '../../Constant/Spinner/Spinnner'
import { message } from 'antd'
import { reset_password } from '../../module/features/authentication'
import { useDispatch } from 'react-redux'

function ResetPassword() {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState({
        previous_password: "",
        new_password: "",
        confirm_new_password: ""
    })
    const [notMatched, setNotMatched] = useState(false)
    const clearState = () => {
        setData({
            previous_password: "",
            new_password: "",
            confirm_new_password: ""
        })
    }
    const resetPassword = () => {
        if (data.new_password === data.confirm_new_password) {
            setLoader(true)
            let payload = {
                previous_password: data.previous_password,
                new_password: data.new_password
            }
            dispatch(reset_password({ data: payload, setLoader, clearState }))
        } else {
            setNotMatched(true)
        }
    }

    return (
        <div>
            <div className='PageHeading'>
                <h1>Reset Password</h1>
            </div>
            <div className='resetPasswordSections'>
                <div className='form'>
                    <label>
                        <p>Current Password</p>
                        <input type='password' placeholder='Current passowrd'
                            value={data.previous_password}
                            onChange={(ev) => {
                                let obj = { ...data }
                                obj.previous_password = ev.target.value
                                setData(obj)
                            }}
                        />
                    </label>
                    <label className={notMatched ? 'error' : ''}>
                        <p>New Password</p>
                        <input type='password' placeholder='New Password'
                            value={data.new_password}
                            onChange={(ev) => {
                                let obj = { ...data }
                                obj.new_password = ev.target.value
                                setData(obj)
                            }}
                        />
                    </label>
                    <label className={notMatched ? 'error' : ''}>
                        <p>Re-type new password</p>
                        <input type='password' placeholder='Re-type new password'
                            value={data.confirm_new_password}
                            onChange={(ev) => {
                                let obj = { ...data }
                                obj.confirm_new_password = ev.target.value
                                setData(obj)
                            }}
                        />
                    </label>
                    <p className='notMAtchedText' style={notMatched ? { visibility: 'visible' } : { visibility: 'hidden' }}>password not matched</p>
                </div>
                <div className='SaveInformation'>
                    <button className='hoverEffect'
                        onClick={resetPassword}
                    >{
                            loader ? <Spinner /> :
                                `Save`
                        }</button>
                </div>
            </div>

        </div>
    )
}

export default ResetPassword