import { Modal, Select } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { editCourse, getCourse } from '../../module/features/courses'
import Spinner from '../../Constant/Spinner/Spinnner';
import { useEffect } from 'react';
import { addOutcomes, getOutcomes, updateOutcomes } from '../../module/features/outcomesSlice';

function AddOutcomesModal({ showModal,
    handleOk,
    handleCancel,
    isModalOpen,
    setIsModalOpen,
    editState }) {
    const dispatch = useDispatch()
    // const loader = useSelector(state => state.outcomesSlice.loader)
    const [loader, setLoader] = useState(false)


    const [data, setData] = useState({
        "name": ""
    })
    const clearState = () => {
        setData({
            "name": ""

        })
    }
    const callback = () => {
        clearState()
        dispatch(getOutcomes())
        handleOk()
        setLoader(false)
    }
    const addOutcomeFn = () => {
        setLoader(true)
        dispatch(addOutcomes({ data: data, callback: callback }))
    }


    const editOutcomeFn = () => {
        setLoader(true)
        dispatch(updateOutcomes({ data: data, id: editState.id, callback: callback }))
    }


    useEffect(() => {
        if (editState) {
            setData({
                "name": editState.name,
            })
        } else {
            clearState()
        }
    }, [editState])


    return (
        <Modal title="Add Courses" open={isModalOpen} footer={false} onCancel={() => {
            handleCancel()
            clearState()
        }}>
            <div className='coursesTableDiv'>
                <div className='form'>
                    <label className='addCourseInput questionInput' style={{ width: '100%' }}>
                        <p>Outcome Name</p>
                        {/* <div className='addCourse'> */}
                        <input style={{ width: '100%' }} placeholder='Outcome Name'
                            value={data.name}
                            // value={data.fullname}
                            onChange={(ev) => {
                                let obj = { ...data }
                                obj.name = ev.target.value
                                setData(obj)
                            }}
                        />
                        {/* <button className='hoverEffect'>Add</button>
                </div> */}
                    </label>

                    <div className='addCourse'>
                        {editState ?
                            <button className='hoverEffect' onClick={editOutcomeFn}>{loader ? <Spinner /> : `Update`}</button>
                            :
                            <button className='hoverEffect' onClick={addOutcomeFn}>{loader ? <Spinner /> : `Save`}</button>
                        }
                    </div>
                </div>
                {/* <div className='addCourseList'>

                    <table className='coursesTable'>
                        <thead>
                            <tr>
                                <th className='title'>Course Title</th>
                                <th className='status'>Level</th>
                            </tr>
                        </thead>
                        <tbody>
                            {courses?.map((a,i) => (
                                <tr>
                                    <td>
                                        <div>
                                            {a.course}
                                        </div>
                                    </td>
                                    <td>
                                        {a.degree}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div> */}

            </div>
        </Modal>
    )
}

export default AddOutcomesModal