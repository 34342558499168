import React, { useEffect, useState } from 'react'

function RatingSlider({setState}) {
    const [UniversityRatingActive, setUniversityRatingActive] = useState(0);
    const [UniversityRating, setUniversityRating] = useState(0);
    let ratingObj = ["Awful", "OK", "Good", "Great", "Awesome"];
    useEffect(()=>{
        setState(UniversityRatingActive)
    },[UniversityRatingActive])
    return (
        <div className="RatingSlider ihdjqt">
            <div className="RatingSliderChild">
                <div
                    type="QUALITY"
                    onClick={() => setUniversityRatingActive(1)}
                    onMouseMove={() => setUniversityRating(1)}
                    onMouseOut={() => setUniversityRating(0)}
                    aria-selected="true"
                    data-testid="SliderBox"
                    style={UniversityRating >= 1 ? { opacity: 0.6 } : {}}
                    // className="ioaRKy"
                    className={
                        UniversityRating >= 1
                            ? "ioaRKy"
                            : UniversityRatingActive >= 1
                                ? "ioaRKy"
                                : "icOrwZ"
                    }
                ></div>
                <div
                    type="QUALITY"
                    onClick={() => setUniversityRatingActive(2)}
                    onMouseMove={() => setUniversityRating(2)}
                    onMouseOut={() => setUniversityRating(0)}
                    aria-selected="false"
                    data-testid="SliderBox"
                    style={UniversityRating >= 2 ? { opacity: 0.6 } : {}}
                    className={
                        UniversityRating >= 2
                            ? "gkeCHo"
                            : UniversityRatingActive >= 2
                                ? "gkeCHo"
                                : "fAgwqL"
                    }
                ></div>
                <div
                    type="QUALITY"
                    onClick={() => setUniversityRatingActive(3)}
                    onMouseMove={() => setUniversityRating(3)}
                    onMouseOut={() => setUniversityRating(0)}
                    aria-selected="false"
                    data-testid="SliderBox"
                    style={UniversityRating >= 3 ? { opacity: 0.6 } : {}}
                    className={
                        UniversityRating >= 3
                            ? "bZAQyd"
                            : UniversityRatingActive >= 3
                                ? "bZAQyd"
                                : "fAgwqL"
                    }
                ></div>
                <div
                    type="QUALITY"
                    onClick={() => setUniversityRatingActive(4)}
                    onMouseMove={() => setUniversityRating(4)}
                    onMouseOut={() => setUniversityRating(0)}
                    aria-selected="false"
                    data-testid="SliderBox"
                    style={UniversityRating >= 4 ? { opacity: 0.6 } : {}}
                    className={
                        UniversityRating >= 4
                            ? "dhKOSZ"
                            : UniversityRatingActive >= 4
                                ? "dhKOSZ"
                                : "fAgwqL"
                    }
                ></div>
                <div
                    type="QUALITY"
                    onClick={() => setUniversityRatingActive(5)}
                    onMouseMove={() => setUniversityRating(5)}
                    onMouseOut={() => setUniversityRating(0)}
                    aria-selected="false"
                    data-testid="SliderBox"
                    style={UniversityRating >= 5 ? { opacity: 0.6 } : {}}
                    // className="fAgwqL"
                    className={
                        UniversityRating >= 5
                            ? "dzvPYw"
                            : UniversityRatingActive >= 5
                                ? "dzvPYw"
                                : "izVfvf"
                    }
                // className="dzvPYw"
                ></div>
            </div>
            <div className="rating-toolip-section">
                {!UniversityRatingActive && !UniversityRating ? (
                    <div className="rating-toolip-All">
                        <div className="rating-toolip-All-child">
                            1 - {ratingObj[0]}
                        </div>
                        <div className="rating-toolip-All-child">
                            5 - {ratingObj[4]}
                        </div>
                    </div>
                ) : (
                    ratingObj?.map((ev, i) => {
                        return UniversityRating === i + 1 ? (
                            <div className="rating-toolip-All-child">
                                {i + 1} - {ev}
                            </div>
                        ) : !UniversityRating && UniversityRatingActive === i + 1 ? (
                            <div className="rating-toolip-All-child-active">
                                {i + 1} - {ev}
                            </div>
                        ) : null;
                        // if(){
                        // }
                    })
                )}
            </div>
        </div>
    )
}

export default RatingSlider