import { Modal, Select } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addCourse, editCourse, getCourse } from '../../module/features/courses'
import Spinner from '../../Constant/Spinner/Spinnner';
import { useEffect } from 'react';

function AddCourseModal({ showModal,
    handleOk,
    handleCancel,
    isModalOpen,
    setIsModalOpen,
    editState }) {
    const dispatch = useDispatch()
    const loader = useSelector(state => state.coursesSlice.loader)
    const competencies = useSelector(
        (state) => state.competencySlice.competencies
    )?.map((a, i) => {
        return {
            ...a,
            value: a.id,
            label: a.name
        }
    });

    useEffect(() => {
        console.log(competencies, 'competenciescompetencies')
    }, [competencies])

    const [data, setData] = useState({
        "course": "",
        "degree": "",
        'competency': [],
        'display':true
    })
    const clearState = () => {
        setData({
            "course": "",
            "degree": "",
            'competency': [],
            'display':true
        })
    }
    const callback = () => {
        clearState()
        dispatch(getCourse())
        handleOk()
    }
    const addCourseFn = () => {
        dispatch(addCourse({ data: data, callback: callback }))
    }


    const editCourseFn = () => {
        dispatch(editCourse({ data: data, id: editState.id, callback: callback }))
    }


    useEffect(() => {
        if (editState) {
            setData({
                "course": editState.course,
                "degree": editState.degree,
                'competency': editState?.course_competencies?.map((a, i) => {
                    return a.competencies?.id
                })
            })
        } else {
            clearState()
        }
    }, [editState])


    return (
        <Modal title="Add Courses" open={isModalOpen} footer={false} onCancel={() => {
            handleCancel()
            clearState()
        }}>
            <div className='coursesTableDiv'>
                <div className='form'>
                    <label className='addCourseInput'>
                        <p>Course Name</p>
                        {/* <div className='addCourse'> */}
                        <input placeholder='Course Name'
                            value={data.course}
                            // value={data.fullname}
                            onChange={(ev) => {
                                let obj = { ...data }
                                obj.course = ev.target.value
                                setData(obj)
                            }}
                        />
                        {/* <button className='hoverEffect'>Add</button>
                </div> */}
                    </label>
                    <label className='addCourseInput'>
                        <p>Course Level</p>
                        {/* <div className='addCourse'> */}
                        <Select
                            showSearch
                            style={{
                                width: 200,
                            }}
                            placeholder="Select Level"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            value={data.degree}
                            options={[
                                {
                                    value: 'Graduate',
                                    label: 'Graduate',
                                },
                                {
                                    value: 'Undergraduate',
                                    label: 'Under Graduate',
                                }
                            ]}
                            onChange={(ev) => {
                                let obj = { ...data }
                                obj.degree = ev
                                setData(obj)
                            }}
                        />
                        {/* <button className='hoverEffect'>Add</button>
                </div> */}
                    </label>
                    <label className='addCourseInput'>
                        <p>Select Competencies</p>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            value={data.competency}
                            placeholder="Please select"
                            onChange={(ev) => {
                                console.log(ev, 'sadasdsadas')
                                let obj = { ...data }
                                obj.competency = ev
                                setData(obj)
                            }}
                            options={competencies}
                        />
                    </label>

                    <div className='addCourse'>
                        {editState ?
                            <button className='hoverEffect' onClick={editCourseFn}>{loader ? <Spinner /> : `Edit Course`}</button>
                            :
                            <button className='hoverEffect' onClick={addCourseFn}>{loader ? <Spinner /> : `Add Course`}</button>
                        }
                    </div>
                </div>
                {/* <div className='addCourseList'>

                    <table className='coursesTable'>
                        <thead>
                            <tr>
                                <th className='title'>Course Title</th>
                                <th className='status'>Level</th>
                            </tr>
                        </thead>
                        <tbody>
                            {courses?.map((a,i) => (
                                <tr>
                                    <td>
                                        <div>
                                            {a.course}
                                        </div>
                                    </td>
                                    <td>
                                        {a.degree}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div> */}

            </div>
        </Modal>
    )
}

export default AddCourseModal