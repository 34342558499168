import React, { useRef, useState } from 'react'
import './Home.scss'
import Communication from './../../Assest/commincationSkills.png'
import Leadership from './../../Assest/leadership.png'
import Decision from './../../Assest/decisionMaking.png'
import Self from './../../Assest/selfManage.png'
//var CanvasJSReact = require('@canvasjs/react-charts');

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Draggable from '../../Constant/Draggable'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCourseById, getRecomendedCourses } from '../../module/features/courses'
import { getCompetenciesById } from '../../module/features/competency'
import NotAdded from '../../Constant/NotAdded'
import { Link, useNavigate } from 'react-router-dom'
import CompetencyBox from '../../Constant/Competency/CompetencyBox'
import { getanswers } from '../../module/features/assesments'
import { Flex, Tooltip as TooltipAntd } from 'antd'
import tierbuilding from './../../Assest/building-blocks-model.png'
import { Collapse } from 'antd';
import Tier from '../Tier/Tier';
import TierCopy from '../Tier/Tier copy';

function Home() {
  const navigate = useNavigate()
  const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  const ourRef = useRef(null);
  const courses = useSelector(state => state.coursesSlice.courses)
  const userProfile = useSelector(state => state.completeProfileSlice.userProfile)
  const chooseCourses = useSelector(state => state.coursesSlice.chooseCourses)
  const courseCompetencies = useSelector(state => state.competencySlice.courseCompetencies)
  const selectedCourseCompetencies = useSelector(state => state.competencySlice.selectedCourseCompetencies)
  const AllCompetencieResult = useSelector(state => state.competencySlice.AllCompetencieResult)
  const competenciesWithCourse = useSelector(state => state.competencySlice.selectedCourseCompetenciesWithCourse)
  const majorCourses = useSelector(state => state.coursesSlice.majorCourses)
  const minorCourses = useSelector(state => state.coursesSlice.minorCourses)
  const recommendedCourses = useSelector(state => state.coursesSlice.recommendedCourses)
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [yourCourses, setYourCourses] = useState([])
  const tier1 = useSelector(state => state.coursesSlice.tier1)
  const tier2 = useSelector(state => state.coursesSlice.tier2)
  const tier3 = useSelector(state => state.coursesSlice.tier3)
  const [courseCompetenciesArr, setCourseCompetenciesArr] = useState([])
  const user = useSelector(state => state.Authentication.user)
  const dispatch = useDispatch()
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0
  });
  const [isScrolling, setIsScrolling] = useState(false);
  const handleDragStart = (e) => {
    if (!ourRef.current) return

    const slider = ourRef.current.children[0];
    const startX = e.pageX - slider.offsetLeft;
    const startY = e.pageY - slider.offsetTop;
    const scrollLeft = slider.scrollLeft;
    const scrollTop = slider.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop }
    setIsMouseDown(true)
    document.body.style.cursor = "grabbing"
  }
  const handleDragEnd = () => {
    setIsMouseDown(false)
    if (!ourRef.current) return
    document.body.style.cursor = "default"
  }
  const handleDrag = (e) => {
    if (!isMouseDown || !ourRef.current) return;
    e.preventDefault();
    const slider = ourRef.current.children[0];
    const x = e.pageX - slider.offsetLeft;
    const y = e.pageY - slider.offsetTop;
    const walkX = (x - mouseCoords.current.startX) * 1.5;
    const walkY = (y - mouseCoords.current.startY) * 1.5;
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    slider.scrollTop = mouseCoords.current.scrollTop - walkY;
  }

  const [activeTier, setActiveTier] = useState('0')
  const [activeTierArr, setActiveTierArr] = useState([])
  useEffect(() => {
    if (userProfile?.profile?.tier) {
      setActiveTierArr([userProfile?.profile?.tier])
      setActiveTier(userProfile?.profile?.tier)
    }
  }, [userProfile])


  useEffect(() => {
    console.log(AllCompetencieResult, 'asdasdas')
    if (AllCompetencieResult?.length) {
      let arr = []
      AllCompetencieResult?.map((a, i) => {
        let splitted = a.onet_element_id.split('.')
        let elmId = splitted.splice(0, 2)
        console.log(elmId, 'asdasdasasdasdas')
        if (!arr.includes(elmId.join('.'))) {
          arr.push(elmId.join('.'))
        }
      })
      if (arr.length) {
        dispatch(getRecomendedCourses(arr))
      }
    }
  }, [AllCompetencieResult])
  const [recommendedCourseArr, setRecommendedCourseArr] = useState([])

  useEffect(() => {
    let arr = []
    if (recommendedCourses && yourCourses) {
      // console.log(recommendedCourses, yourCourses,'asdasdasasdasdas')
      // recommendedCourses.map((a, i) => {
      //   yourCourses.map((b, j) => {
      //     if (b?.course?.id != a.id) {
      //       arr.push(a)
      //     }
      //   })
      // })
      // Step 1: Create a Set of Course IDs from CoursesArr
      const courseIdsInCoursesArr = new Set(yourCourses.map(courseObj => courseObj.course.id));

      // Step 2: Filter recommendedArr to include only courses not in courseIdsInCoursesArr
      const filteredRecommendedArr = recommendedCourses.filter(recommendedCourse => !courseIdsInCoursesArr.has(recommendedCourse.id));
      setRecommendedCourseArr(filteredRecommendedArr)
    }
    console.log(arr, 'asdasdasasdasdas')
  }, [recommendedCourses, yourCourses])

  // useEffect(() => {
  //   if (courseCompetencies?.length && AllCompetencieResult) {
  //     console.log(selectedCourseCompetencies, courseCompetencies, 'selectedCourseCompetencies')
  //     let arr = [];
  //     [...courseCompetencies, ...selectedCourseCompetencies].map((a, i) => {
  //       let score = 0
  //       AllCompetencieResult.map((b, j) => {
  //         if (a.id === b.competencyId) {
  //           if (b.scoreIndividual) {
  //             score = b.scoreIndividual
  //           }
  //         }
  //       })
  //       if (score) {
  //         arr.push({
  //           ...a,
  //           score: score
  //         })
  //       } else {
  //         arr.push({
  //           ...a
  //         })
  //       }

  //     })
  //     setCourseCompetenciesArr(arr)
  //   }
  // }, [courseCompetencies, AllCompetencieResult, selectedCourseCompetencies])

  useEffect(() => {
    console.log(competenciesWithCourse, 'competenciesWithCourse')
    if (competenciesWithCourse) {
      let minors = competenciesWithCourse?.minor ? competenciesWithCourse?.minor : []
      let major = competenciesWithCourse?.major ? competenciesWithCourse?.major : []
      let updatedArr = [];
      // let arr = []
      ([...minors, ...major]).map((a, i) => {
        a.course_competencies?.map((b) => {
          let data = { competencies: [{ ...b.competencies, courseId: b.course.id }] }
          if (!updatedArr.includes(data)) {
            updatedArr.push(data)
          }
        })
        //   updatedArr.push(data)
      })
      console.log(updatedArr, 'updatedArrupdatedArr')
      setCourseCompetenciesArr(updatedArr)
    }
  }, [competenciesWithCourse])



  useEffect(() => {
    if (majorCourses &&
      minorCourses) {
      let arrMajor = majorCourses || [];
      let arrMinor = minorCourses || [];
      console.log([...arrMajor, ...arrMinor], 'asdasdasdsa')
      setYourCourses([...arrMajor, ...arrMinor])
    }
  }, [majorCourses,
    minorCourses])


  return (
    <div className='Home'>
      <div className='PageHeading'>
        <h1>Welcome back, {userProfile?.username}</h1>
        <button onClick={() => {
          navigate('/dashboard/assesment')
        }}>
          Take Assesment <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8Z"></path><path d="M192 474h672q8 0 8 8v60q0 8-8 8H160q-8 0-8-8v-60q0-8 8-8Z"></path></svg>
        </button>
        {/* <button className={Number(activeTier) === Number(1) && tier1.length ? '' : Number(activeTier) === Number(2) && tier2.length ? '' : Number(activeTier) === Number(3) && tier3.length ? '' : 'notActive'  } onClick={() => {
          // navigate('/dashboard/assesment')
          if (Number(activeTier) === Number(1)) {
            let coursesId = tier1.map((a, i) => {
              return a.id
            })
            navigate(`/dashboard/AssesmentTier?tier=${activeTier}&courses=${tier1[0].id}`)
          } else if (Number(activeTier) === Number(2)) {
            let coursesId = tier2.map((a, i) => {
              return a.id
            })
            navigate(`/dashboard/AssesmentTier?tier=${activeTier}&courses=${tier2[0].id}`)
          } else if (Number(activeTier) === Number(3)) {
            let coursesId = tier3.map((a, i) => {
              return a.id
            })
            navigate(`/dashboard/AssesmentTier?tier=${activeTier}&courses=${tier3[0].id}`)
          }
        }}>
          {Number(activeTier) === Number(1) && tier1.length ?
            <>
              Start Assessment for Tier 1<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8Z"></path><path d="M192 474h672q8 0 8 8v60q0 8-8 8H160q-8 0-8-8v-60q0-8 8-8Z"></path></svg>
            </>
            : Number(activeTier) === Number(2) && tier2.length ?
              <>
                Start Assessment for Tier 2<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8Z"></path><path d="M192 474h672q8 0 8 8v60q0 8-8 8H160q-8 0-8-8v-60q0-8 8-8Z"></path></svg>
              </>
              : Number(activeTier) === Number(3) && tier3.length ?
                <>
                  Start Assessment for Tier 3<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8Z"></path><path d="M192 474h672q8 0 8 8v60q0 8-8 8H160q-8 0-8-8v-60q0-8 8-8Z"></path></svg>
                </>
                :
                <>
                  Next tier will open soon
                </>
          }
        </button> */}
      </div>
      {user ?
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {/* <Tier /> */}
          <TierCopy />
        </div>
        : null}
      <div className='Competencies'>
        <h3 >Competencies</h3>
        {/* <div> */}
        <Draggable className={'CompetenciesDivs'}>
          {courseCompetenciesArr?.length ? courseCompetenciesArr.map((a, i) => {
            return !a?.hidden ? (
              <>
                <CompetencyBox data={a.competencies[0]}
                // onClick={() => {
                //   // navigate(`/dashboard/assesment?assesmentId=${a.id}`)
                //   // dispatch(getanswers({
                //   //   "userId": user.id,
                //   //   "competencyId": a.id
                //   // }))
                // }} 
                />
              </>
            ) : null
          }) :
            <NotAdded text={chooseCourses?.length ? `Your choosed courses doesn't have any competency` : `Add other courses to see competencies`} addCourse={chooseCourses?.length ? false : true} />
          }
        </Draggable>

        {/* </div> */}
      </div>
      <div className='coursesGradSec'>
        <div className='courses'>
          <div className='coursesHeading'>
            <h3>Your courses</h3>
            {/* <Link to={`/dashboard/Course`}> */}
            {/* <span>
                See More<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"></path></svg>
              </span> */}
            {/* </Link> */}
          </div>
          <div className='coursesList'>
            {yourCourses?.length ? yourCourses.map((a, i) => (

              <div className='coursesDiv' key={i}>
                <div className='iconDiv'>
                  <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>
                </div>
                <div className='coursesContent'>
                  <div className='heading'>
                    <h4>
                      {a.course.course}
                    </h4>

                  </div>
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Donec et ultrices velit, non aliquet quam. </p> */}
                </div>
              </div>
            )) :
              <NotAdded text={'Add your courses'} addCourse={true} />
            }
          </div>
          <div className='coursesHeading' style={{ marginTop: '20px' }}>
            <h3>Recommended courses</h3>
            {/* <Link to={`/dashboard/Course`}> */}
            {/* <span>
                See More<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"></path></svg>
              </span> */}
            {/* </Link> */}
          </div>
          <div className='coursesList'>
            {recommendedCourseArr?.length ? recommendedCourseArr.map((a, i) => (

              <div className='coursesDiv' key={i}>
                <div className='iconDiv'>
                  <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>
                </div>
                <div className='coursesContent'>
                  <div className='heading'>
                    <h4>
                      {a.course}
                    </h4>

                  </div>
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Donec et ultrices velit, non aliquet quam. </p> */}
                </div>
              </div>
            )) :
              <NotAdded text={'There is no recommended course for you right now'} />
            }
          </div>
        </div>
        <div className='Grades'>
          {/* <ResponsiveContainer width="100%" height="100%"> */}
          <div className='blurOverlay'>
            <p>Coming Soon</p>
            <div className='LineChartDiv'>
              <LineChart
                width={500}
                height={400}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <Legend />
                <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                <Line type="monotone" dataKey="amt" stroke="#000" />
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis dataKey="name" />
                <Tooltip />
              </LineChart>
            </div>

          </div>


          {/* </ResponsiveContainer> */}
        </div>
      </div>
    </div>
  )
}

export default Home