import React, { useEffect, useState } from "react";
// import "./LandingPage.scss";
// import "./NewLandingPage.scss";
import "./CompareSchool.scss"
import { NavLink, useNavigate } from "react-router-dom";
import LandingPageNavbar from "../Navbar/LandingPageNavbar";
import NewLandNavBar from "../Navbar/NewLandNavBar";
import slider1 from "./../../Assest/slider1.png"
import slider2 from "./../../Assest/slider2.png"
import slider3 from "./../../Assest/slider3.png"
import card1Vector from "./../../Assest/card1Vector.png"
import card2Vector from "./../../Assest/card2Vector.png"
import card3Vector from "./../../Assest/card3Vector.png"
import testimonial from "./../../Assest/testimonial.png"
import gradhatIcon from "./../../Assest/gradhatIcon.png"
import searchIcon from "./../../Assest/searchIcon.png"
import Teacher from "./../../Assest/teacher.png"
import reset from "./../../Assest/reset.png"
import { Progress, Rate } from 'antd';
import topUniversity1 from "./../../Assest/topUniversity1.png"
import topUniversity2 from "./../../Assest/topUniversity2.png"
import topUniversity3 from "./../../Assest/topUniversity3.png"
import nextArrow from "./../../Assest/nextArrow.png"
import professor1 from "./../../Assest/TopProfessors/professor1.png"
import professor2 from "./../../Assest/TopProfessors/professor2.png"
import professor3 from "./../../Assest/TopProfessors/professor3.png"
import professor4 from "./../../Assest/TopProfessors/professor4.png"
import course1 from "./../../Assest/course1.png";
import course2 from "./../../Assest/course2.png";
import course3 from "./../../Assest/course3.png";
import facebook from "./../../Assest/socialIcons/facebook.png";
import linkedin from "./../../Assest/socialIcons/twitter.png";
import twitter from "./../../Assest/socialIcons/facebook.png";
import instagram from "./../../Assest/socialIcons/instagram.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { Card, Col, Row } from 'antd';
import image from './../../Assest/logo.png'
import EditRatingImg from "../../Assest/rmp-Family/rating.png";
import AnonymousRatingImg from "../../Assest/rmp-Family/anonymous.png";
import LikeRatingImg from "../../Assest/rmp-Family/like.png";
import LandingPageFooter from "../footer/LandingPageFooter";
import { Carousel, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import SearchSchool from "../SearchSchool/SearchSchool";
import SchoolRatingDetails from "./SchoolRatingDetails";
import { getComapredUniversities } from "../../module/features/university";
import SchoolRatingBars from "../schoolComponents/SchoolRatingBars";
import { getProfessorOutcomesCompareTo, getProfessorOutcomesCompareWith, getUniversityOutcomesCompareTo, getUniversityOutcomesCompareWith } from "../../module/features/ratings";
import OutcomesGraph from "../ProfessorRating/OutcomesGraph";
import { professorCompare } from "../../module/features/professor";
const { Option } = Select;
function CompareProfessor() {
    const [professorSchool, setProfessorSchool] = useState(false);
    const dispatch = useDispatch()
    const [professorArr, setProfessorArr] = useState([])
    const professorList = useSelector(e => e.professorSlice.professorList)
    const universityList = useSelector(state => state.universitySlice.universityList)
    const compares = useSelector(state => state.professorSlice.compares)
    const navigate = useNavigate();
    const professorOutcomesCompareWith = useSelector((state) => state.ratingSlice.professorOutcomesCompareWith)
    const professorOutcomesCompareTo = useSelector((state) => state.ratingSlice.professorOutcomesCompareTo)
    useEffect(() => {
        if (professorSchool) {

            if (professorList) {
                let arr = professorList.map((a, i) => {
                    return {
                        value: a.id,
                        label: a.professor_name
                    }
                })
                setProfessorArr(arr)
                console.log(professorList, 'professorList')
            }
        } else if (universityList) {
            let arr = universityList.map((a, i) => {
                return {
                    value: `${a.OBJECTID}`,
                    label: `${a.NAME}`,
                    ...a
                }
            })
            setProfessorArr(arr)
            console.log(professorList, 'professorList')
            // setProfessorArr([])

        }
    }, [professorList, professorSchool, universityList])
    const onChange = (value) => {
        if (professorSchool) {
            navigate(`/professors?id=${value}`)
        } else {
            navigate(`/school?id=${value}`)

        }
        console.log(`selected ${value} `);
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    const renderOptionUniversity = (option) => {
        // Customize the rendering of each option
        return (
            <div className="universityListOpt" onClick={() => console.log(option)}>
                <div>
                    <span style={{ marginRight: '8px' }}>{option.label}</span>
                    <div>
                        <span className="location">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 32C167.67 32 96 96.51 96 176c0 128 160 304 160 304s160-176 160-304c0-79.49-71.67-144-160-144zm0 224a64 64 0 1 1 64-64 64.07 64.07 0 0 1-64 64z"></path></svg>
                            &nbsp;
                            {option.CITY ? option.CITY + ", " : ''} {option.COUNTRY ? option.COUNTRY : ''}</span>
                    </div>
                </div>
            </div>
        );
    };

    const onChangeTop = (currentSlide) => {
        console.log(currentSlide);
    };

    const [searchCompareWith, setSearchCompareWith] = useState(false)
    const [searchCompareTo, setSearchCompareTo] = useState(false)

    const SelectCompareWith = (value, type) => {
        let obj = {}
        if (type === 'with') {
            obj.professor1 = value
            setSearchCompareWith(value)
            if (searchCompareTo) {
                obj.professor2 = searchCompareTo
            }
        } else if (type === 'to') {
            obj.professor2 = value
            setSearchCompareTo(value)
            if (searchCompareWith) {
                obj.professor1 = searchCompareWith
            }
        }
        dispatch(professorCompare(obj))
        // console.log(obj,'asdadadsa')
    }
    const [Professor1Rating, setProfessor1Rating] = useState({})
    const [Professor2Rating, setProfessor2Rating] = useState({})
    useEffect(() => {
        console.log(compares,'asdadasdadas')
        setProfessor1Rating(compares?.professor1)
        setProfessor2Rating(compares?.professor2)
    }, [compares])
    useEffect(() => {
        if (searchCompareWith) {
            dispatch(getProfessorOutcomesCompareWith({ professor_id: searchCompareWith }))
        }
    }, [searchCompareWith])

    useEffect(() => {
        if (searchCompareTo) {
            dispatch(getProfessorOutcomesCompareTo({ professor_id: searchCompareTo }))
        }
    }, [searchCompareTo])

    return (
        <React.Fragment>
            <div className="landing-page">
                <NewLandNavBar bg={'#f1f7ff'} />
                <div className="second-section">
                    <div className="compareSchool-header">
                        <img src={Teacher} />
                        <h1>Compare School</h1>
                    </div>
                    {/* <div className="reset-button">
                        <button><img src={reset} />Reset</button>
                    </div> */}
                </div>
                <div className="ThirdSection">
                    <div className="section1">
                        <SearchSchool SelectCompareWith={SelectCompareWith} defaultProfessor={true} hideUniversity={true} hideSwitchProUni={true} hideProfessor={false} type={'with'} />
                        <div className="schoolratingsummrary-container schoolratingsummrary-border-remove comapreRatingdiv">
                            {professorOutcomesCompareWith ? 
                            <OutcomesGraph outcomes={professorOutcomesCompareWith} />
                            :null}
                            <SchoolRatingBars universityWithProfessorRating={Professor1Rating} />
                        </div>
                    </div>
                    <div className="section1">
                        <SearchSchool SelectCompareWith={SelectCompareWith} defaultProfessor={true} hideUniversity={true} hideSwitchProUni={true} hideProfessor={false} type={'to'} />
                        <div className="schoolratingsummrary-container schoolratingsummrary-border-remove comapreRatingdiv">
                           {professorOutcomesCompareTo ? 
                            <OutcomesGraph outcomes={professorOutcomesCompareTo} />
                           :null}
                            <SchoolRatingBars universityWithProfessorRating={Professor2Rating} />
                        </div>
                    </div>
                </div>
                <LandingPageFooter />
            </div>
        </React.Fragment>
    );
}

export default CompareProfessor;
