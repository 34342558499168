import { Modal } from 'antd'
import CheckableTag from 'antd/es/tag/CheckableTag'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../Constant/Spinner/Spinnner'
import { updateUserOutcome } from '../../module/features/outcomesSlice'

function OutcomesModal({
    isModalOpen,
    setIsModalOpen,
    showModal,
    handleOk,
    handleCancel
}) {
    const outcomes = useSelector(state => state.outcomesSlice.outcomes)
    const outcomesLoader = useSelector(state => state.outcomesSlice.outcomesLoader)
    const [selectedTags, setSelectedTags] = useState([]);
    const dispatch = useDispatch()
    const competenciesOutcome = useSelector(state => state.outcomesSlice.competenciesOutcome)
    const userScheduledOutcome = useSelector(state => state.outcomesSlice.userScheduledOutcome)
    const [loader, setLoader] = useState(false)
    const handleChange = (tag, checked) => {
        const nextSelectedTags = checked
            ? [...selectedTags, tag.id]
            : selectedTags.filter((t) => t !== tag.id);
        console.log('You are interested in: ', nextSelectedTags);
        setSelectedTags(nextSelectedTags);
    };
    useEffect(() => {
        if (competenciesOutcome.length) {
            let arr = competenciesOutcome.map((a, i) => {
                return a.id
            })
            setSelectedTags(arr)
        }
    }, [competenciesOutcome])
    const callback = () => {
        setLoader(false)
        handleCancel()
        window.location.href = '/'
    }
    const updateOutcomes = () => {
        const url = new URL(window.location)
        const course = url.searchParams.get('course')
        const userid = url.searchParams.get('userid')
        const studentId = url.searchParams.get('userid')

        const assessmentType = url.searchParams.get('assessmentType')
        const professorId = url.searchParams.get('professorId')
        // const universityId = url.searchParams.get('universityId')
        setLoader(true)
        dispatch(updateUserOutcome({
            data: {
                "outcomes": selectedTags,
                "course_id": +(course),
                "professor_id": +(professorId),
                "assessmentType": assessmentType,
                "user_id": +(studentId)
            },
            id: userid, callback


        }))
    }
    useEffect(() => {
        console.log(outcomes, 'outcomes')
    }, [outcomes])
    return (
        <Modal title="Add Your Reviews" className='AddOutcomeModal' open={isModalOpen} footer={false} onOk={handleOk} onCancel={handleCancel}>
            <div className='innerAddOutcomeModal'>
                {userScheduledOutcome?.map((a, i) => (
                    <CheckableTag
                        className='outcomeTag'
                        key={i}
                        checked={selectedTags.includes(a.id)}
                        onChange={(checked) => handleChange(a, checked)}
                    >
                        {a.name}
                    </CheckableTag>
                ))}
            </div>
            <div className='footer'>
                <button className='addCompetencyButton cancel' onClick={handleCancel}>Cancel</button>

                <button className='addCompetencyButton add' onClick={updateOutcomes}>{loader ? <Spinner /> : `Update`}</button>

            </div>
        </Modal>

    )
}

export default OutcomesModal