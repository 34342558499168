import React, { useState } from "react";
import Arrow from "../../Assest/footerLanding/controls-arrow-expand.svg";
// import Logo from "../../Assest/footerLanding/small_rmp_logo_white.svg";
import Instagram from "../../Assest/footerLanding/instagram-white.svg";
import Facebook from "../../Assest/footerLanding/facebook-white.svg";
import Twiiter from "../../Assest/footerLanding/twitter-white.svg";
import Logo from './../../Assest/logo.png'
import facebook from "./../../Assest/socialIcons/facebook.png";
import linkedin from "./../../Assest/socialIcons/twitter.png";
import twitter from "./../../Assest/socialIcons/facebook.png";
import instagram from "./../../Assest/socialIcons/instagram.png";
// import "./LandingPageFooter.scss";
import "./../../Component/LandingPage/NewLandingPage.scss"
import "../LandingPage/NewLandingPage.scss";
// import "../footer/LandingPageFooter.scss";
const LandingPageFooter = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="footer-section">
      <div className="footer-section-inner">
        {/* <button
          type="button"
          className={
            !isOpen ? "footer-toggle footer-toggle-active" : "footer-toggle"
          }
          onClick={() => setIsOpen(!isOpen)}
        >
          <img src={Arrow} alt="Hide Footer" />
        </button> */}
        <div className="footer-wrapper">
          <div className="footer-breakpoint"></div>
          {isOpen ? (
            <div className="footer-links-wrapper">
              <div className="footer-links-list">
                <div className="footer-links-list-child">Site</div>
                <ul>
                  <li>
                    <a>About</a>
                  </li>
                  <li>
                    <a>Help</a>
                  </li>
                  <li>
                    <a>Site Guidelines</a>
                  </li>
                </ul>
              </div>
              <div className="footer-links-list">
                <div className="footer-links-list-child">Legal</div>
                <ul>
                  <li>
                    <a>Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <a>Privacy Policy</a>
                  </li>
                  <li>
                    <a>Copyright Compliance Policy</a>
                  </li>
                  <li>
                    <a>CA Notice at Collection</a>
                  </li>
                  <li>
                    <a>CA Do Not Sell or Share My Personal Information</a>
                  </li>
                </ul>
              </div>
              <div className="footer-links-list">
                <div className="footer-links-list-child">Partners</div>
                <ul>
                  <li>
                    <a>Optimum</a>
                  </li>
                </ul>
              </div>
            </div>
          ) : null}
          <div
            className={
              !isOpen
                ? "Footer_Copyright_Styled_Copyright Footer_Copyright_Styled_Copyright_Active"
                : "Footer_Copyright_Styled_Copyright"
            }
          >
            {/* <a
              className="Footer_Logo_Styled_Logo"
              //   href="/login"
              //   style="transform: translate(0px, 0px);"
            >
              <img src={Logo} alt="Logo" style={{objectFit:'contain'}} />
            </a> */}
            {/* <div
              className="Social_Links__Styled_Social_Links"
              //   style="transform: translate(0px, 0px);"
            >
              <a
                // href="https://www.facebook.com/RateMyProfessor"
                className="Social_Icon_Link__Styled_Social_Icon_Link"
              >
                <img src={Facebook} alt="RateMyProfessors on Facebook" />
              </a>
              <a
                // href="https://www.instagram.com/ratemyprofessors/"
                className="Social_Icon_Link__Styled_Social_Icon_Link"
              >
                <img src={Instagram} alt="RateMyProfessors on Instagram" />
              </a>
              <a
                // href="https://twitter.com/ratemyprofessor"
                className="Social_Icon_Link__Styled_Social_Icon_Link"
              >
                <img src={Twiiter} alt="RateMyProfessors on Twitter" />
              </a>
            </div> */}

             {/* SUBSCRIBE TO OUR NEWSLETTER */}
                <div className="subscribe">
                    <div className="text-subscribe">
                        <h2>Subscribe to our newsletter</h2>
                        <p>Get expert advice for your journey to university delivered to your inbox eachmonth. It's short, and worthwhile – we promise!</p>
                    </div>

                    <div className="subscribebar">
                        <div className="searchbar-inner">
                            <input type="text" placeholder="Email Address" className="subscribeInput" />
                        </div>
                    </div>

                    <div className="subscribenow">
                        <button className="subscribenow-btn">SUBSCRIBE NOW</button>
                    </div>

                    <div className="socialsCTA">
                        <div className="socialicons">
                            <img src={facebook} width={35} />
                            <img src={linkedin} width={35} />
                            <img src={instagram} width={35} />
                            <img src={twitter} width={35} />
                        </div>

                        <div className="calltoActions">
                            <p>About</p>
                            <p>Contact Us</p>
                            <p>FAQS</p>
                            <p>Terms and conditions</p>
                            <p>Cookie policy</p>
                            <p>Privacy</p>

                        </div>
                    </div>

                </div>
            <div
              className="Footer_Copyright__Copy_right_Text"
              //   style="transform: translate(0px, 0px);"
            >
            Copyright © 2024 - UNGRADE.US
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LandingPageFooter;
