import { Select } from "antd";
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getQuery } from '../../Constant/Utils';
import { getUniversityWithProfessorAvgRating } from '../../module/features/ratings';

function SchoolRatingBars({ universityWithProfessorRating }) {
    const dispatch = useDispatch();

    return (
        universityWithProfessorRating?.length ?
            <>
                <table style={{ borderSpacing: "14px" }}>
                    {universityWithProfessorRating &&
                        universityWithProfessorRating?.map((a, i) => {
                            return (
                                <tr className="ratingProgressBarDiv">
                                    <td>
                                        <p>{a?.courses?.course}</p>
                                        <span className="professorSpan">by {a?.professor?.professor_name} at Department of {a?.professor?.department_name}</span>
                                    </td>
                                    <td>
                                        <div style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            <div className="ratingProgressBar">
                                                <div
                                                    className="postProgress"
                                                    style={{
                                                        width: `${a?.postaverage ? `${a?.postaverage * 100}%` : "0%"
                                                            }`,
                                                        zIndex: `${a?.postaverage < a?.preaverage ? '0' : '1'}`
                                                    }}
                                                ></div>
                                                <div
                                                    className="preProgress"
                                                    style={{
                                                        width: `${a?.preaverage ? `${a?.preaverage * 100}%` : "0%"
                                                            }`,
                                                        zIndex: `${-(a?.preaverage < a?.postaverage ? '0' : '1')}`
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                </table>
                <div className="colorIndicator">
                    <div className="inner-colorIndicator">
                        <div className="indiDiv"><div className="preIndi"></div>Pre-assesment</div>
                        <div className="indiDiv"><div className="postIndi"></div>Post-assesment</div>
                    </div>
                </div>
            </>
            :
            <div className="noRatingText">
                <h3>There is no rating yet</h3>
            </div>
    )
}

export default SchoolRatingBars