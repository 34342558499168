import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddCourseModal from './AddCourseModal';
import { AddOutcomeIcon, DeleteIcon, EditIcon } from '../../Constant/Icons';
import { Popconfirm, Select, Tag, Tooltip, message } from 'antd';
import { deleteCourse, getCourse, updateTierAndVisiblityCourse } from '../../module/features/courses';
import { EyeClosed, EyeOpen } from '../../Constant/Icons';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Button, Input, Space, Table } from 'antd'
import Column from 'antd/es/table/Column';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { useRef } from 'react';
import { getOutcomeById } from '../../module/features/outcomesSlice';
import AddOutcomeModal from '../Competencies/AddOutcomeModal';
import ScheduleOutcomesModal from '../AddOutcomes/AddSchedule';
import { useNavigate } from 'react-router-dom';


function AddCourse() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOutcomeOpen, setIsModalOutcomeOpen] = useState(false);
    const [editState, setEditState] = useState(false)
    const [isModalOpenSchedule, setIsModalOpenSchedule] = useState(false);
    const courses = useSelector(state => state.coursesSlice.courses)
    const showModal = (data) => {
        setIsModalOpen(true);
        setEditState(data)
    };
    const handleOk = () => {
        setIsModalOpen(false);
        setEditState(false)
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setEditState(false)
    };
    const callback = () => {
        dispatch(getCourse())
    }
    const confirm = (data) => {
        dispatch(deleteCourse({ id: data.id, callback: callback }))
    };
    const cancel = (e) => {
        console.log(e);
    };
    const showModalOutcome = (a) => {
        console.log(a)
        if (a.outcome_id) {

            dispatch(getOutcomeById({
                "ids": a.outcome_id
            }))
        }
        setIsModalOutcomeOpen(true);
        setEditState(a);
    };
    const handleOkOutcome = () => {
        setIsModalOutcomeOpen(false);
        // setEditState(false);
    };
    const handleCancelOutcome = () => {
        setIsModalOutcomeOpen(false);
        setEditState(false);
    };

    const showModalSchedule = (data) => {
        setIsModalOpenSchedule(true);
        // setEditState(data)
    };
    const handleOkSchedule = () => {
        setIsModalOpenSchedule(false);
        setEditState(false)
    };
    const handleCancelSchedule = () => {
        setIsModalOpenSchedule(false);
        setEditState(false)
    };

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch([], confirm, dataIndex)
                        }}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const updateTierAndVisiblityCourseFn = (data, id) => {
        dispatch(updateTierAndVisiblityCourse({ data: data, id: id, callback }))
    }

    useEffect(() => {
        console.log(courses, 'coursescourses')
    }, [courses])

    return (
        <div className='AddCourse'>
            <div className='innerAddCourse'>
                <div className='PageHeading'>
                    <h1>Add Course</h1>
                    <button className='hoverEffect' onClick={() => showModal(false)}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8Z"></path><path d="M192 474h672q8 0 8 8v60q0 8-8 8H160q-8 0-8-8v-60q0-8 8-8Z"></path></svg>
                        Add Course
                    </button>
                </div>
                <div className='coursesTable'>
                    <div className='coursesTableDiv'>
                        <Table dataSource={courses}
                        >
                            <Column width="40%" filterDropdown={getColumnSearchProps('course').filterDropdown} filterIcon={getColumnSearchProps('course').filterIcon} onFilter={getColumnSearchProps('course').onFilter}
                                onFilterDropdownOpenChange={getColumnSearchProps('course').onFilterDropdownOpenChange} title="Course Name" className='tdDiv title cursorNormal' dataIndex="course" key="course"
                                filterSearch

                                render={getColumnSearchProps('course').render}
                                onCell={(record, index) => {
                                    return {
                                        onClick: (event) => {
                                            navigate(
                                                `/dashboard/Admin/add-competency?id=${record.id}`
                                            );
                                        },
                                    };
                                }}
                            />
                            <Column width={100}
                                title="Competencies"
                                render={(text, record) => {
                                    return (
                                        <Tooltip title={record.course_competencies.map((a, i) => {
                                            return (
                                                a.competencies.name + ', '
                                            )
                                        })}>
                                            <p className='competenciesOfCourse'>
                                                {/* {record.course_competencies.map((a, i) => {
                                                    return (
                                                        a.competencies.name + ',  sadasdasdasdasdsa'
                                                    )
                                                })} */}
                                                Hover to see competencies
                                            </p>
                                        </Tooltip>
                                    )
                                }}
                            />
                            <Column title="Degree Name" className='cursorNormal' dataIndex="degree" key="degree"
                                sorter={(a, b) => a.degree?.length - b.degree?.length}
                                render={(text) => (
                                    <span>
                                        {text}
                                    </span>
                                )}
                            />
                            <Column title="Course / Certificate"
                                sorter={(a, b) => a.type?.length - b.type?.length}
                                render={(text, record, index) => (
                                    <div className='actionButtons'>


                                        <Select
                                            style={{ width: 120, marginRight: '20px' }}
                                            defaultValue="Select Tier"
                                            value={record.type}
                                            onChange={(value) => {
                                                if (value) {
                                                    updateTierAndVisiblityCourseFn({
                                                        "type": value,
                                                        "tier": value === 'course' ? 0 : record.tier
                                                    }, record.id)
                                                } else {
                                                    message.error('Select Tier')
                                                }
                                            }}
                                            options={[
                                                {
                                                    value: 'course',
                                                    label: 'Course',
                                                },
                                                {
                                                    value: 'certificate',
                                                    label: 'Certificate',
                                                }
                                            ]}
                                        />
                                        {record.type === 'certificate' ?
                                            <Select
                                                style={{ width: 120, marginRight: '20px' }}
                                                defaultValue="Select Tier"
                                                value={record.tier ? record.tier : "Select Tier"}
                                                onChange={(value) => {
                                                    if (value) {
                                                        updateTierAndVisiblityCourseFn({
                                                            "tier": value,
                                                        }, record.id)
                                                    } else {
                                                        message.error('Select Tier')
                                                    }
                                                }}
                                                options={[
                                                    {
                                                        value: 1,
                                                        label: '1st Tier',
                                                    },
                                                    {
                                                        value: 2,
                                                        label: '2nd Tier',
                                                    },
                                                    {
                                                        value: 3,
                                                        label: '3rd Tier',
                                                    }
                                                ]}
                                            />
                                            : null}
                                    </div>
                                )}
                            />
                            <Column title="Actions"
                                align='center'
                                sorter={(a, b) => a.hidden?.length - b.hidden?.length}
                                render={(text, record, index) => (
                                    <div className='actionButtons'>

                                        <Tooltip title={"Set Visibility"}>
                                            <div>
                                                {record.hidden ?
                                                    <Tag color="red" onClick={() => {
                                                        updateTierAndVisiblityCourseFn({
                                                            "hidden": 0,
                                                        }, record.id)
                                                    }}>Hidden</Tag>
                                                    :

                                                    <Tag color="blue" onClick={() => {
                                                        updateTierAndVisiblityCourseFn({
                                                            "hidden": 1,
                                                        }, record.id)
                                                    }}>Visible</Tag>
                                                }
                                            </div>
                                        </Tooltip>
                                        <button className='editButton' onClick={(e) => {
                                            e.stopPropagation()
                                            showModal(record)
                                        }}>
                                            <EditIcon />
                                        </button>

                                        <button
                                            className="editButton"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                showModalOutcome(record);
                                            }}
                                        >
                                            <AddOutcomeIcon />
                                        </button>
                                    </div>
                                )}
                            />
                        </Table>
                        {/* <table className='coursesTable'>
                            <thead>
                                <tr>
                                    <th className='title'>Course Title</th>
                                    <th className='status'>Level</th>
                                    <th className='status'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {courses?.map((a, i) => (
                                    <tr>
                                        <td>
                                            <div>
                                                {a.course}
                                            </div>
                                        </td>
                                        <td>
                                            {a.degree}
                                        </td>
                                        <td>
                                            <div className='actionButtons'>
                                                <button className='editButton' onClick={() => {
                                                    showModal(a)
                                                }}>
                                                    <EditIcon />
                                                </button>
                                          
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> */}
                    </div>
                </div>
                {/* <div className='coursesList hidePc'>
                    <div className='innerCoursesList'>
                        {courses?.map((a, i) => (
                            <div className='courseCard'>
                                <div className='title'>
                                    <h2>Course Name</h2>
                                    <p>{a.course}</p>
                                </div>
                                <div className='level'>
                                    <h2>Level</h2>
                                    <p>{a.degree}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}
            </div>
            <AddCourseModal
                showModal={showModal}
                handleOk={handleOk}
                handleCancel={handleCancel}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                editState={editState}
            />

            <AddOutcomeModal
                isModalOpen={isModalOutcomeOpen}
                handleOk={handleOkOutcome}
                handleCancel={handleCancelOutcome}
                editState={editState}
                showModalSchedule={showModalSchedule}
            />
            {/* <ScheduleOutcomesModal
                showModal={showModalSchedule}
                handleOk={handleOkSchedule}
                handleCancel={handleCancelSchedule}
                isModalOpen={isModalOpenSchedule}
                setIsModalOpen={setIsModalOpenSchedule}
                editState={editState}
            /> */}
        </div>
    )
}

export default AddCourse