import React, { useEffect } from 'react'
import './Profile.scss'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../module/features/authentication'
import { Select, Tooltip } from 'antd'
import General from './General'
import { getQueryParams } from '../../Constant/Utils'
import { useNavigate } from 'react-router-dom'
import AddYourCourse from '../AddYourCourse/AddYourCourse'
import ResetPassword from './ResetPassword'

function Profile() {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(getQueryParams('tab'))
  const changeTab = (tabName) => {
    setActiveTab(tabName)
    navigate(`/dashboard/Profile?tab=${tabName}`)
  }
  return (
    <div className='Profile'>
      <div className='innerProfile'>
        <div className='tabs'>
          <div className={activeTab === 'General' ? 'tabDiv active' : 'tabDiv '}
            onClick={() => {
              changeTab('General')
            }}
          >General</div>
          <div className={activeTab === 'Courses' ? 'tabDiv active' : 'tabDiv '}
            onClick={() => {
              changeTab('Courses')
            }}
          >Courses</div>
          <Tooltip title="Coming Soon">
            <div className='tabDiv'>Notifications</div>
          </Tooltip>
          <Tooltip title="Coming Soon">
            <div className='tabDiv'>Members</div>
          </Tooltip>
          <Tooltip title="Coming Soon">
            <div className='tabDiv'>Billings</div>
          </Tooltip>
          <Tooltip title="Coming Soon">
            <div className='tabDiv'>Language and Region</div>
          </Tooltip>
          <div className={activeTab === 'security' ? 'tabDiv active' : 'tabDiv '}
            onClick={() => {
              changeTab('security')
            }}>Security</div>
        </div>
        {activeTab === 'General' ?
          <General changeTab={changeTab} />
          :
          activeTab === 'Courses' ?
            <AddYourCourse />
            :
            activeTab === 'security' ?
              <ResetPassword />
              : null
        }
      </div>
    </div>
  );
}

export default Profile;
