import { Progress, Rate } from 'antd'
import React from 'react'

function SchoolRatingDetails() {
    return (
        <div className="reviewCard">

            <div className="progress_Outer_div">

                <div className="rating">
                    <h2>2.8</h2>
                    <p className="rating-header">Overall Rating</p>
                    <Rate allowHalf defaultValue={4.5} className="ratestars" />
                    <p className="rating-number">1 Rating</p>
                </div>

                <div className="progressbarDiv">

                    <div className="progressRow">
                        <h3>5</h3>
                        <Progress percent={50} circleTextColor={"red"} className="progressWidth" />
                        <p>Food</p>
                    </div>
                    <div className="progressRow">
                        <h3>4</h3>
                        <Progress percent={60} />
                        <p>Food</p>
                    </div>
                    <div className="progressRow">
                        <h3>3</h3>
                        <Progress percent={80} />
                        <p>Food</p>
                    </div>
                    <div className="progressRow">
                        <h3>2</h3>
                        <Progress percent={70} />
                        <p>Food</p>
                    </div>
                    <div className="progressRow">
                        <h3>1</h3>
                        <Progress percent={20} />
                        <p>Food</p>
                    </div>


                </div>
            </div>


            <div className="ReviewText">

                <h5>Review</h5>

                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nec massa ac velit fermentum faucibus. Sed ultricies massa vitae mauris gravida, eget vehicula tortor dignissim. Cras ac nisi vel justo vestibulum fringilla. Vivamus consequat nisi vitae ante commodo fermentum. Sed tincidunt massa sit amet lectus ultrices, nec mattis dolor ultricies. Suspendisse potenti. Suspendisse potenti. Donec id nisi eu magna feugiat malesuada. Vivamus id malesuada velit. Curabitur consectetur eros non risus gravida, id lacinia libero pellentesque. Nullam sed mi eu lectus commodo
                </p>

            </div>
        </div>

    )
}

export default SchoolRatingDetails