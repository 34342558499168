import React from "react";
import LandingPageNavbar from "../Navbar/NewLandNavBar";
import SearchSchool from "../SearchSchool/SearchSchool";
import { Progress, Rate, Space, Tag } from "antd";
import LandingPageFooter from "../footer/LandingPageFooter";
import { Button, Dropdown, message, Tooltip } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
// import './aProfessorRating.scss'

function AProfessorRating() {
  const handleButtonClick = (e) => {
    console.log("click left button", e);
  };
  const handleMenuClick = (e) => {
    console.log("click", e);
  };
  const items = [
    {
      label: "1st menu item",
      key: "1",
    },
    {
      label: "2nd menu item",
      key: "2",
    },
    {
      label: "3rd menu item",
      key: "3",
    },
    {
      label: "4rd menu item",
      key: "4",
    },
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  return (
    <>
      <div className="main-compo-container">
        <LandingPageNavbar />
        <div className="searchComponent">
          <SearchSchool />
        </div>
        <div className="firstsection_universityRating">
          <div className="rateHeaderTexts">
            <p className="rateHead">Rate</p>
            <p className="rateschool">the Premier School, </p>
            <p className="rateLocation">Asheville, NC</p>
          </div>
        </div>
        <div className="secondsection_universityRating">
          <div className="reputationDiv">
            <div className="reputationStar">
              <span className="repo-text">
                Reputation <span className="aesterix">*</span>
              </span>
            </div>

            <div className="dropsown">
              <Space wrap>
                <Dropdown menu={menuProps} className="dropbtn">
                  <Button
                    style={{ background: "none", border: "1px solid black" }} className="dropDown"
                  >
                    <Space>
                      select course...
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </div>
          </div>
          <div className="reputationDiv">
            <div className="reputationStar">
              <span className="repo-text">
                Reputation <span className="aesterix">*</span>
              </span>
            </div>

            <div className="RateStars">
              <div className="innerStars">
                <Rate allowHalf defaultValue={5} />
              </div>
              <div className="RateComments">
                <p>Awful</p>
                <p>Awesome</p>
              </div>
            </div>
          </div>
          <div className="reputationDiv">
            <div className="reputationStar">
              <span className="repo-text">
                Reputation <span className="aesterix">*</span>
              </span>
            </div>

            <div className="RateStars">
              <div className="innerStars">
                <Rate allowHalf defaultValue={5} className="rateed"/>
              </div>
              <div className="RateComments">
                <p>Awful</p>
                <p>Awesome</p>
              </div>
            </div>
          </div>
          <div className="reputationDiv">
            <div className="reputationStar">
              <span className="repo-text">
                Reputation <span className="aesterix">*</span>
              </span>
            </div>

            <div className="rating-form-child-body">
              <div className="rating-form-child-body-child">
                <input
                  type="radio"
                  name="attendanceMandatory"
                  id="attendanceMandatory-Yes"
                  value="Yes"
                  label="Was attendance mandatory?"
                  className="RadioButtons__StyledRadioButton-sc-1ho7g4w-3 icTrWI"
                />
                <label className="RadioButtons__RadioButtonLabel-sc-1ho7g4w-2 bjnSgl">
                  Yes
                </label>
              </div>
              <div className="rating-form-child-body-child">
                <input
                  type="radio"
                  name="attendanceMandatory"
                  id="attendanceMandatory-No"
                  value="No"
                  label="Was attendance mandatory?"
                  className="RadioButtons__StyledRadioButton-sc-1ho7g4w-3 gYNYsj"
                />
                <label className="RadioButtons__RadioButtonLabel-sc-1ho7g4w-2 bjnSgl">
                  No
                </label>
              </div>
            </div>
          </div>
          <div className="reputationDiv">
            <div className="reputationStar">
              <span className="repo-text">
                Reputation <span className="aesterix">*</span>
              </span>
            </div>

            <div className="rating-form-child-body">
              <div className="rating-form-child-body-child">
                <input
                  type="radio"
                  name="attendanceMandatory"
                  id="attendanceMandatory-Yes"
                  value="Yes"
                  label="Was attendance mandatory?"
                  className="RadioButtons__StyledRadioButton-sc-1ho7g4w-3 icTrWI"
                />
                <label className="RadioButtons__RadioButtonLabel-sc-1ho7g4w-2 bjnSgl">
                  Yes
                </label>
              </div>
              <div className="rating-form-child-body-child">
                <input
                  type="radio"
                  name="attendanceMandatory"
                  id="attendanceMandatory-No"
                  value="No"
                  label="Was attendance mandatory?"
                  className="RadioButtons__StyledRadioButton-sc-1ho7g4w-3 gYNYsj"
                />
                <label className="RadioButtons__RadioButtonLabel-sc-1ho7g4w-2 bjnSgl">
                  No
                </label>
              </div>
            </div>
          </div>
          <div className="reputationDiv">
            <div className="reputationStar">
              <span className="repo-text">
                Reputation <span className="aesterix">*</span>
              </span>
            </div>

            <div className="rating-form-child-body">
              <div className="rating-form-child-body-child">
                <input
                  type="radio"
                  name="attendanceMandatory"
                  id="attendanceMandatory-Yes"
                  value="Yes"
                  label="Was attendance mandatory?"
                  className="RadioButtons__StyledRadioButton-sc-1ho7g4w-3 icTrWI"
                />
                <label className="RadioButtons__RadioButtonLabel-sc-1ho7g4w-2 bjnSgl">
                  Yes
                </label>
              </div>
              <div className="rating-form-child-body-child">
                <input
                  type="radio"
                  name="attendanceMandatory"
                  id="attendanceMandatory-No"
                  value="No"
                  label="Was attendance mandatory?"
                  className="RadioButtons__StyledRadioButton-sc-1ho7g4w-3 gYNYsj"
                />
                <label className="RadioButtons__RadioButtonLabel-sc-1ho7g4w-2 bjnSgl">
                  No
                </label>
              </div>
            </div>
          </div>

          <div className="reputationDiv">
            <div className="reputationStar">
              <span className="repo-text">
                Reputation <span className="aesterix">*</span>
              </span>
            </div>

            <div className="rating-form-child-body">
              <div className="rating-form-child-body-child">
                <input
                  type="radio"
                  name="attendanceMandatory"
                  id="attendanceMandatory-Yes"
                  value="Yes"
                  label="Was attendance mandatory?"
                  className="RadioButtons__StyledRadioButton-sc-1ho7g4w-3 icTrWI"
                />
                <label className="RadioButtons__RadioButtonLabel-sc-1ho7g4w-2 bjnSgl">
                  Yes
                </label>
              </div>
              <div className="rating-form-child-body-child">
                <input
                  type="radio"
                  name="attendanceMandatory"
                  id="attendanceMandatory-No"
                  value="No"
                  label="Was attendance mandatory?"
                  className="RadioButtons__StyledRadioButton-sc-1ho7g4w-3 gYNYsj"
                />
                <label className="RadioButtons__RadioButtonLabel-sc-1ho7g4w-2 bjnSgl">
                  No
                </label>
              </div>
            </div>
          </div>

          <div className="reputationDiv">
            <div className="reputationStar">
              <span className="repo-text">
                Reputation <span className="aesterix">*</span>
              </span>
            </div>

            <div className="dropsown">
              <Space wrap>
                <Dropdown menu={menuProps} className="dropbtn">
                  <Button
                    style={{ background: "none", border: "1px solid black" }}
                  >
                    <Space>
                      select course...
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </div>
          </div>

          <div className="reputationDiv">
            <div className="reputationStar">
              <span className="repo-text">
                Reputation <span className="aesterix">*</span>
              </span>
            </div>

            <div className="TopTags">

                        <Space size={[0, 3]} wrap>

                        </Space>
                        <Tag className="tag">RESPECTED</Tag>
                        <Tag className="tag">CARING</Tag>
                        <Tag className="tag">CLEAR GRADING CRITERIA</Tag>
                        <Tag className="tag">WOULD TAKE AGAIN</Tag>
                        <Tag className="tag">AMAZING LECTURES</Tag>

                        {/* <div className="tag1 tag">RESPECTED</div>
                        <div className="tag2 tag">CARING</div>
                        <div className="tag3 tag">CLEAR GRADING CRITERIA</div>
                        <div className="tag4 tag">WOULD TAKE AGAIN</div>
                        <div className="tag5 tag">AMAZING LECTURES</div> */}

                    </div>
          </div>
        </div>

        {/* third section */}

        <div className="thirdsection_universityRating">
          <h1>Write a Review</h1>

          <div className="guidelines">
            <h3>Guidelines:</h3>
            <p>
              Discuss your personal experience on this school. What’s great
              about it? What could use improvement?
            </p>
            <ul>
              <li>
                Your rating could be removed if you use profanity or derogatory
                terms.
              </li>
              <li>
                Refer to the rating categories to help you better elaborate your
                comments.
              </li>
              <li>Don’t forget to proof read! View all guidelines</li>
            </ul>
          </div>
        </div>

        {/* fourth section */}

        <div className="fourthsection_universityRating">
          <textarea
            className="textareaInput"
            placeholder="What do you want to know bout this school?"
          ></textarea>
        </div>

        <button className="submitRating">Submit Rating</button>
        <LandingPageFooter />
      </div>
    </>
  );
}

export default AProfessorRating;
