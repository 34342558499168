import React, { useEffect, useState } from "react";
// import "./LandingPage.scss";
// import "./NewLandingPage.scss";
// import "./aboutus.scss";
// import "../AboutUs/aboutus.scss";
import "./Contact.scss";
import "../LandingPage/NewLandingPage.scss"
import { NavLink, useNavigate } from "react-router-dom";
import LandingPageNavbar from "../Navbar/LandingPageNavbar";
import NewLandNavBar from "../Navbar/NewLandNavBar";

import topUniversity1 from "./../../Assest/topUniversity1.png"
import topUniversity2 from "./../../Assest/topUniversity2.png"
import topUniversity3 from "./../../Assest/topUniversity3.png"

import email from "./../../Assest/email.png";
import phone from "./../../Assest/phone.png";
import helpdesk from "./../../Assest/helpdesk.png";


// import Swiper from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles

import { Pagination } from 'swiper/modules';

import { Card, Checkbox, Col, Row } from 'antd';

// import image from "../../Assest/big_rmp_logo_black.svg";
import image from './../../Assest/logo.png'
import EditRatingImg from "../../Assest/rmp-Family/rating.png";
import AnonymousRatingImg from "../../Assest/rmp-Family/anonymous.png";
import LikeRatingImg from "../../Assest/rmp-Family/like.png";
import LandingPageFooter from "../footer/LandingPageFooter";
import { Carousel, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
// import OutcomesModal from "./OutcomesModal";
import { getUserOutcome } from "../../module/features/outcomesSlice";
import Search from "antd/es/input/Search";
import SearchSchool from "../SearchSchool/SearchSchool";
import Nosubscribefooter from "../footer/Nosubscribefooter";
const { Option } = Select;
function AboutUs() {
    const [professorSchool, setProfessorSchool] = useState(false);
    const dispatch = useDispatch()
    const [professorArr, setProfessorArr] = useState([])
    const professorList = useSelector(e => e.professorSlice.professorList)
    const universityList = useSelector(state => state.universitySlice.universityList)
    const navigate = useNavigate();
    useEffect(() => {
        if (professorSchool) {

            if (professorList) {
                let arr = professorList.map((a, i) => {
                    return {
                        value: a.id,
                        label: a.professor_name
                    }
                })
                setProfessorArr(arr)
                console.log(professorList, 'professorList')
            }
        } else if (universityList) {
            let arr = universityList.map((a, i) => {
                return {
                    value: `${a.OBJECTID}`,
                    label: `${a.NAME}`,
                    ...a
                }
            })
            setProfessorArr(arr)
            console.log(professorList, 'professorList')
            // setProfessorArr([])

        }
    }, [professorList, professorSchool, universityList])
    const onChange = (value) => {
        if (professorSchool) {
            navigate(`/professors?id=${value}`)
        } else {
            navigate(`/school?id=${value}`)

        }
        console.log(`selected ${value} `);
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    const renderOptionUniversity = (option) => {
        // Customize the rendering of each option
        return (
            <div className="universityListOpt" onClick={() => console.log(option)}>
                <div>
                    <span style={{ marginRight: '8px' }}>{option.label}</span>
                    <div>
                        <span className="location">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 32C167.67 32 96 96.51 96 176c0 128 160 304 160 304s160-176 160-304c0-79.49-71.67-144-160-144zm0 224a64 64 0 1 1 64-64 64.07 64.07 0 0 1-64 64z"></path></svg>
                            &nbsp;
                            {option.CITY ? option.CITY + ", " : ''} {option.COUNTRY ? option.COUNTRY : ''}</span>
                    </div>
                </div>
            </div>
        );
    };


    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const showModal = () => {
    //     setIsModalOpen(true);
    // };
    // const handleOk = () => {
    //     setIsModalOpen(false);
    // };
    // const handleCancel = () => {
    //     setIsModalOpen(false);
    // };
    // useEffect(() => {
    //     const url = new URL(window.location)
    //     const course = url.searchParams.get('course')
    //     const userid = url.searchParams.get('userid')
    //     if (course) {
    //         showModal()
    //         dispatch(getUserOutcome({
    //             "ids": course,
    //             "userId": userid
    //         }))
    //     }
    // }, [])
    const imageArr = [topUniversity1,
        topUniversity2,
        topUniversity3]
    return (
        <React.Fragment>
            <div className="landing-page">
                {/* <LandingPageNavbar /> */}
                <NewLandNavBar bg={'#f1f7ff'}  />



<div className="contactusSection_OuterDiv">
   <div className="contactus-Section">

                    <div className="getintouch-header">
                        <h1 className="getin">Get In </h1>
                        <h1 className="touch">Touch</h1>
                    </div>

                    <div className="contactus-subText"><p>
                       Got a suggestion, a story, or a question? We're all ears! Fill in your details below and share your thoughts. Your feedback is the beacon that guides our mission.
                       <br/> 
                       <br/> 
                        Let's make education enlightening for all!
                    </p></div>

                    <div className="contactForm">

                        <div className=" form_input">
                            <input type="text" placeholder="Contact Name" />
                        </div>


                 

                        <div className="form_input">
                            <input type="text" placeholder="Email" />
                        </div>

                        <div className="form_input">
                            <textarea className="idea" placeholder="Let's talk about your idea"></textarea>
                        </div>

                     

                        

                        <div className="Submitbutton">
                            <button type="submit">Submit</button>
                        </div>

                        <div className="contactdetails">

                         

                            <div className="email">

                                <img src={email} />

                                <div className="header_details">
                                    <h3>E-MAIL</h3>
                                    <p>info@company.com</p>
                                </div>

                            </div>

                           


                        </div>

                    </div>

                </div>

                <div className="coloured_box">

                </div>


</div>

             






                <LandingPageFooter />
            </div>

        </React.Fragment>
    );
}

export default AboutUs;
