import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { useRef } from 'react';
import './Assesment.scss'
import { useDispatch, useSelector } from 'react-redux';
import CompetencyBox from '../../Constant/Competency/CompetencyBox';
import NotAdded from '../../Constant/NotAdded';
import { getQuery } from '../../Constant/Utils';
import { addAssessmentsAnswers, addAssessmentsAnswersTier, getAssessments, getAssessmentsTier, getanswers, getanswersTier } from '../../module/features/assesments';
import { Spin, message } from 'antd';
import Answers from '../../Constant/Answers';
import { AllStudentAverageResult } from '../../module/features/competency';
import { getProfile, updateProfileTier } from '../../module/features/completeProfile';

const QuestionsDiv = ({
    rerender,
    assessments,
    competencyId,
    activeIndex,
    backButton,
    setactiveIndex
}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const user = useSelector(state => state.Authentication.user)
    const courseCompetencies = useSelector(state => state.competencySlice.courseCompetencies)
    const userProfile = useSelector(state => state.completeProfileSlice.userProfile)
    const answers = useSelector(state => state.assessmentSlice.tieranswers)
  const tier1 = useSelector(state => state.coursesSlice.tier1)
  const assesmentCompleted = useSelector(state => state.assessmentSlice.assesmentCompleted)
    const assesmentType = useSelector(state => state.assessmentSlice.assesmentType)

    const [questions, setQuestions] = useState([])
    const swiperRef = useRef(null);

    const navigation = useNavigate()
    const handleNextButtonClick = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const [activeQuestions, setActiveQuestions] = useState(1)

    useEffect(() => {
        console.log(activeIndex)
        setActiveQuestions(activeIndex)
    }, [activeIndex])

    const handleSlideChange = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setActiveQuestions(swiperRef.current.swiper.activeIndex + 1);
            // You can perform additional actions based on the active slide index
        }
    };

    useEffect(() => {
        console.log(assessments, 'assessments')
        if(assessments.length){

            setQuestions(assessments)
        }
    }, [assessments])

    const getUpdatedProfile = () => {
        dispatch(getProfile({ id: user.id }))
    }
    const callback = (index) => {
        if (index === questions?.length - 1) {
            let tier = Number(getQuery('tier'))
            // setSubmitted(true)
            console.log(tier)
            // setActiveQuestions(1)
            setactiveIndex(0)
            if (Number(tier) < 4) {
                dispatch(updateProfileTier({
                    data: { tier: Number(tier) + 1 },
                    id: userProfile?.profile?.id,
                    callback: getUpdatedProfile
                }))
            }
            message.success('Assesment Submitted')

            navigate("/dashboard")

        } else {
            handleNextButtonClick()
        }
    }



    const addAssesmentFun = (data, question, index) => {
        // callback(index)
        const answerObj = {

            "question_id": question.id,
            "answer": String(data.value),
            "key": question.key,
            "competency_id": question.competency_id,
            "assessmentType": assesmentType,
            "professor_id": 1,
            "course_id": tier1[0].id,
            "university_id": userProfile.university_id,
            "completed": index === questions?.length - 1 ? true : false
        }
        
        console.log(answerObj)
        dispatch(addAssessmentsAnswersTier({ data: answerObj, callback, setLoader, index }))
    }
    useEffect(() => {
        if (assessments?.length && answers?.length && assessments?.length <= answers?.length) {
            setSubmitted(true)
        }
    }, [assessments, answers])

    useEffect(() => {
        console.log(activeIndex, 'activeIndex')

    }, [activeIndex])

    return (
        <div className='questionsDiv'>
            <div className='question'>
                <h2>Questions</h2>
                <span>(Step {activeQuestions} of {questions?.length})</span>
            </div>

            <div className='innerquestionsDiv'>
                {submitted ?
                    <div className='assesmentText'>
                        <h4>Your tier {getQuery('tier')} assesment has been submitted</h4>

                        {/* <Spin size="large" /> */}
                    </div>
                    :
                    <>
                        {activeIndex ?
                            <Swiper navigation={false} draggable={false} allowTouchMove={false} modules={[Navigation]} initialSlide={activeIndex - 1} className="mySwiper" ref={swiperRef} onSlideChange={handleSlideChange}
                            >
                                {questions.map((a, i) => {
                                    return (

                                        <SwiperSlide className='SwiperSlide' key={i}>
                                            <div className='upperSwiperSlide'>
                                                <div className='innnerSwiperSlide'>
                                                    <div className='questionDiv'>
                                                        <h3 onClick={() => {
                                                            console.log(activeIndex, 'activeIndexactiveIndex')
                                                        }}>{a.question}</h3>
                                                    </div>

                                                    <Answers addAssesmentFun={addAssesmentFun} keyed={a.key} loader={loader} question={a} index={i} />

                                                </div>
                                            </div>

                                        </SwiperSlide>
                                    )

                                })}


                            </Swiper>
                            : null}
                    </>

                }

                {/* <button className='next hoverEffect' onClick={handleNextButtonClick}>Submit</button> */}
            </div>
        </div>
    )
}

function AssesmentTier() {
    const courseCompetencies = useSelector(state => state.competencySlice.courseCompetencies)
    const AllCompetencieResult = useSelector(state => state.competencySlice.AllCompetencieResult)
    const [selectedAssesment, setSelectedAssesment] = useState('')
    const userProfile = useSelector(state => state.completeProfileSlice.userProfile)
    const [rerender, setRerender] = useState(false)
    const navigate = useNavigate()
    const user = useSelector(state => state.Authentication.user)
    const competencies = useSelector(state => state.competencySlice.competencies)
    const dispatch = useDispatch()
    const assessments = useSelector(state => state.assessmentSlice.tierAssesment)
    const answers = useSelector(state => state.assessmentSlice.tieranswers)
    const assessmentsLoader = useSelector(state => state.assessmentSlice.tierAssesmentLoader)

    const [competencyId, setCompetencyId] = useState('')
    const [unansweredArr, setUnansweredArr] = useState([])
    const [activeIndex, setactiveIndex] = useState(0)
    const [courseCompetenciesArr, setCourseCompetenciesArr] = useState([])
    const [tierId, setTierId] = useState(0)


    // useEffect(()=>)

    useEffect(() => {
        let tier = getQuery('tier')
        let courses = getQuery('courses')
        setCompetencyId(tier)
        if (tier) {
            dispatch(getAssessmentsTier({ id: courses }))
        }
    }, [rerender])
    const getResult = () => {
        console.log('calledasdasd')
        dispatch(AllStudentAverageResult({
            "userId": userProfile.id,
            "competencyIds": courseCompetencies
        }))
    }


    const backButton = () => {
        if (competencyId) {
            navigate(`/dashboard`)
            setRerender(!rerender)
            getResult()
        } else {
            window.history.back()
            setRerender(!rerender)
        }
    }

    // useEffect(() => {
    //     if (assessments) {
    //         setactiveIndex(0)
    //     }
    // }, [assessments])




    // useEffect(() => {
    //     setactiveIndex(0)
    // }, [])


    useEffect(() => {
        if (user) {
            let assesmentId = getQuery('assesmentId');
            let courses = getQuery('courses');
            console.log('asdasdasd')
            dispatch(getanswersTier({
                "userId": user.id,
                "courseIds": courses
            }))
        }
    }, [user])

    useEffect(() => {
        console.log(answers, assessments, 'answersanswers')
        if (answers?.length || assessments?.length) {
            if (answers?.length > 0) {

                let arr = assessments?.map((a, i) => {
                    let answer = answers.filter(obj => Number(a.id) === Number(obj.question_id))?.length ? true : false
                    console.log(answer, 'asdsadasdsad')
                    if (answer) {
                        setactiveIndex(i + 1 + 1)
                    }
                    return {
                        ...a,
                        answer
                    }
                    // return answers.map((b, j) => {
                    //     if (Number(a.id) !== Number(b.question_id)) {
                    //         return a
                    //     }
                    // })[0]
                })
                setUnansweredArr(arr)
            } else {
                console.log('answerNotFound asdsadasdsad')
                setUnansweredArr(assessments)
                setactiveIndex(1)
            }

        }
    }, [answers, assessments])

    return (
        <div className='Assesment'>
            <div className='PageHeading'>
                <h1>

                    <span onClick={backButton}><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"></path></svg></span>

                    &nbsp;Tier assesment</h1>

            </div>

            {assessmentsLoader ?
                <div className='assesmentText'>
                    <h4>Your assesment is about to start</h4>
                    <Spin size="large" />
                </div>
                :
                <QuestionsDiv setactiveIndex={setactiveIndex} rerender={rerender} assessments={assessments} competencyId={competencyId} activeIndex={activeIndex} backButton={backButton} />
            }
        </div>
    )
}

export default AssesmentTier