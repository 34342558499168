import React from "react";
import "./SearchComponent.scss";
import ProfessorRatingNavbar from "../Navbar/professorRatingNavbar";
import bookmark from "../../Assest/RateMyProfessor/bookmark-hover.497698ea.svg";

import { Select } from "antd";
function SearchComponent() {
  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
      <div className="search-section-inner">
        <h2 className="header-text">495 professors with "d"</h2>
        <div className="department-main">
          <h3>Department</h3>
          <Select
            style={{ width: "264px" }}
            showSearch
            placeholder="Select a person"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </div>
        <div className="rating-section">
          <div className="dLJIlx">
            <div className="kcbPEB">
              <div className="joEEbw">
                <div className="eWZmyX">
                  <div className="fVETNc">QUALITY</div>
                  <div className="gcFhmN">4.5</div>
                  <div className="jMRwbg">20 ratings</div>
                </div>
              </div>
              <div className="fkdYMc">
                <div className="cJdVEK">Ann Commito</div>
                <div className="gSTNdb">
                  <div className="haUIRO">Mathematics</div>
                  <div className="iDlVGM">Frederick Community College</div>
                </div>
                <div className="frciyA">
                  <div className="fyKbws">
                    <div className="hroXqf">N/A</div>
                    would take again
                  </div>
                  <div className="enhFnm"></div>
                  <div className="fyKbws">
                    <div className="hroXqf">2.8</div>
                    level of difficulty
                  </div>
                </div>
              </div>
            </div>
            <button className="hVjzhH" type="button">
              <img src={bookmark} alt="bookmark" className="bookmark" />
            </button>
          </div>
        </div>
      </div>
  );
}
export default SearchComponent;
