import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteIcon, EditIcon } from '../../Constant/Icons';
import { Popconfirm, Select, Tag, Tooltip, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Button, Input, Space, Table } from 'antd'
import Column from 'antd/es/table/Column';
import { useRef } from 'react';
import StudentDetailModal from './StudentDetailModal';
import './../Users/user.scss'
import { getAge } from '../../Constant/Utils';

function User() {
  const users = useSelector(state => state.getUsersSlice.users)?.map((a, i) => {
    return {
      ...a,
      ...a?.userProfile
    }
  })

  useEffect(() => {
    console.log(users, 'users')
  }, [users])
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [student, setStudent] = useState(false)
  const showModal = (record) => {
    setIsModalOpen(true);
    setStudent(record)
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setStudent(false)
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setStudent(false)
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              handleSearch([], confirm, dataIndex)
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          // textToHighlight={text ? text?.toString() : ''
          textToHighlight={dataIndex === 'student_age' ? getAge(text) : text ? text?.toString() : ''
          }
        />
      ) : dataIndex === 'student_age' ? (
        <p onClick={() => console.log(text, 'text')}>
          {text ? getAge(text) : ''}
        </p>
      ) : (
        text
      ),
  });


  return (
    <div className='users'>
      <div className='innerUsers'>
        <div className='PageHeading'>
          <h1>Users</h1>
          {/* <button className='hoverEffect' onClick={() => showModal(false)}>
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8Z"></path><path d="M192 474h672q8 0 8 8v60q0 8-8 8H160q-8 0-8-8v-60q0-8 8-8Z"></path></svg>
            Add Course
          </button> */}
        </div>
        <div className='coursesTable'>
          <div className='coursesTableDiv'>
            <Table dataSource={users}
              footer={() => {
                return (
                  <div className='totalUsers'>
                    <h3>Total Users:</h3>
                    <p>{users?.length}</p>
                  </div>
                )

              }

              }
            >
              <Column filterDropdown={getColumnSearchProps('fullname').filterDropdown} filterIcon={getColumnSearchProps('fullname').filterIcon} onFilter={getColumnSearchProps('fullname').onFilter}
                onFilterDropdownOpenChange={getColumnSearchProps('fullname').onFilterDropdownOpenChange} title="Student name" className='tdDiv title' dataIndex="fullname" key="fullname"
                filterSearch
                onCell={(record, index) => {
                  return {
                    onClick: (event) => {
                      showModal(record)
                    }
                  }
                }}
                render={getColumnSearchProps('fullname').render}
              />
              <Column title="Student age" className='tdDiv ' dataIndex="age" key="age"
                filterSearch
                sorter={(a, b) => Number(getAge(a.age)) - Number(getAge(b.age))}
                render={getColumnSearchProps('age').render}
              />
              <Column filterDropdown={getColumnSearchProps('email').filterDropdown} filterIcon={getColumnSearchProps('email').filterIcon} onFilter={getColumnSearchProps('email').onFilter}
                onFilterDropdownOpenChange={getColumnSearchProps('email').onFilterDropdownOpenChange} title="Student Email" className='tdDiv ' dataIndex="email" key="email"
                filterSearch

                render={getColumnSearchProps('email').render}
              />
              <Column filterDropdown={getColumnSearchProps('religion').filterDropdown} filterIcon={getColumnSearchProps('religion').filterIcon} onFilter={getColumnSearchProps('religion').onFilter}
                onFilterDropdownOpenChange={getColumnSearchProps('religion').onFilterDropdownOpenChange} title="Student Religion" className='tdDiv ' dataIndex="religion" key="religion"
                filterSearch
                sorter={(a, b) => a.religion?.length - b.religion?.length}
                render={getColumnSearchProps('religion').render}
              />
              <Column title="Actions"
                align='center'
                sorter={(a, b) => a.hidden?.length - b.hiddens?.length}
              />
            </Table>

          </div>
        </div>
      </div>
      <StudentDetailModal isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        student={student}
      />
    </div>
  )
}

export default User