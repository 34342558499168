import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Column from 'antd/es/table/Column';
import React, { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from 'react-redux';
import FullPageLoader from '../../Constant/FullPageLoader/FullPageLoader';
import { ViewDetailIcon } from '../../Constant/Icons';
import { getOccupationalMap } from '../../module/features/occupational';
import './Occupational.scss';
import OccupationalMap from './OccupationalMap';



function OccupationTable({ data, setSelectedOccupation, occupationLoader, setPageNumber, totalPages, occupation, selectCompetency }) {
    const [dataTable, setDataTable] = useState([])
    const mergedResults = useSelector(state => state.assessmentSlice.mergedResults)

    const [occupationCompetencies, setOccupationCompetencies] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [Occupation, setOccupation] = useState({})

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);

    };
    const handleCancel = () => {
        setIsModalOpen(false);

    };

    useEffect(() => {
        setDataTable(data)
    }, [data])
    const onChange = (value) => {
        // dispatch(getUSAJobs(value))
        console.log(`selected ${value}`);
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const dispatch = useDispatch()

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        if (confirm) {
            confirm();
        }
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch([], confirm, dataIndex)
                        }}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            if (selectedRows.length) {
                setSelectedOccupation(selectedRows[0].title)

            }
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name,
        }),
    };
    useEffect(() => {
        console.log(mergedResults, 'mergedResults')
    }, [mergedResults])
    const [loader, setLoader] = useState(false)
    const handleShowMap = () => {
        setLoader(false)
        showModal()
        console.log('asdasdasd')
    }
    return (
        <>
            <div className='coursesTableDiv'>
                <Table dataSource={dataTable} loading={occupationLoader}
                    rowKey={(record) => record.index}
                    rowSelection={{
                        type: 'radio',
                        ...rowSelection,
                    }}
                >
                    <Column filterDropdown={getColumnSearchProps('title').filterDropdown} filterIcon={getColumnSearchProps('title').filterIcon} onFilter={getColumnSearchProps('title').onFilter}
                        onFilterDropdownOpenChange={getColumnSearchProps('title').onFilterDropdownOpenChange} title="Occupation Name" className='tdDiv' dataIndex="title" key="title"
                        // onCell={(record, index) => {
                        //     return {
                        //         onClick: (event) => {
                        //             navigate(`/dashboard/Admin/add-assesment?id=${record.competency_id}`)
                        //         }
                        //     }
                        // }}
                        render={getColumnSearchProps('title').render}
                    />
                    <Column title="View Map" className='tdDiv'
                        // onCell={(record, index) => {
                        //     return {
                        //         onClick: (event) => {
                        //             navigate(`/dashboard/Admin/add-assesment?id=${record.competency_id}`)
                        //         }
                        //     }
                        // }}
                        render={(text, record) => (
                            <div>
                                <button className='occupationalMap' onClick={() => {
                                    setLoader(true)
                                    console.log(record, 'asdsadasdasd')
                                    setOccupation(record)
                                    dispatch(getOccupationalMap({ onetId: record.domain_source, handleShowMap: handleShowMap }))
                                }}>Occupational Map</button>
                            </div>
                        )}
                    />
                    {/* <Column filterDropdown={getColumnSearchProps('compentencyTitle').filterDropdown} filterIcon={getColumnSearchProps('compentencyTitle').filterIcon} onFilter={getColumnSearchProps('compentencyTitle').onFilter}
                        onFilterDropdownOpenChange={getColumnSearchProps('compentencyTitle').onFilterDropdownOpenChange} title="Competency Name" className='tdDiv' dataIndex="compentencyTitle" key="compentencyTitle"
                        // onCell={(record, index) => {
                        //     return {
                        //         onClick: (event) => {
                        //             navigate(`/dashboard/Admin/add-assesment?id=${record.competency_id}`)
                        //         }
                        //     }
                        // }}
                        render={getColumnSearchProps('compentencyTitle').render}
                    /> */}
                    <Column filterDropdown={getColumnSearchProps('domain_source').filterDropdown} filterIcon={getColumnSearchProps('domain_source').filterIcon} onFilter={getColumnSearchProps('domain_source').onFilter}
                        onFilterDropdownOpenChange={getColumnSearchProps('domain_source').onFilterDropdownOpenChange} title="Domain Source" className='tdDiv' dataIndex="domain_source" key="domain_source"
                        // onCell={(record, index) => {
                        //     return {
                        //         onClick: (event) => {
                        //             navigate(`/dashboard/Admin/add-assesment?id=${record.competency_id}`)
                        //         }
                        //     }
                        // }}
                        render={getColumnSearchProps('domain_source').render}
                    />
                    {/* <Column filterDropdown={getColumnSearchProps('data_value').filterDropdown} filterIcon={getColumnSearchProps('data_value').filterIcon} onFilter={getColumnSearchProps('data_value').onFilter}
                        onFilterDropdownOpenChange={getColumnSearchProps('data_value').onFilterDropdownOpenChange} title="Level" className='tdDiv' dataIndex="data_value" key="data_value"
                        // onCell={(record, index) => {
                        //     return {
                        //         onClick: (event) => {
                        //             navigate(`/dashboard/Admin/add-assesment?id=${record.competency_id}`)
                        //         }
                        //     }
                        // }}
                        render={getColumnSearchProps('data_value').render}
                    /> */}

                    <Column title="Action"
                        // onCell={(record, index) => {
                        //     return {
                        //         onClick: (event) => {
                        //             navigate(`/dashboard/Admin/add-assesment?id=${record.competency_id}`)
                        //         }
                        //     }
                        // }}
                        render={(text, record) => {
                            return (
                                <button>
                                    <ViewDetailIcon />
                                </button>
                            )
                        }}
                    />

                    {/* {selectCompetency ?
                        <Column title="Eligibility"
                            render={(text, record) => {
                                return (
                                    <div className='eligibleDiv'>

                                        <div className='progressDiv'>
                                            <Progress className='progress' percent={10 * 100} showInfo={false} strokeColor={"#7b77d0"} />
                                            <div className='percentCompetency'>
                                                {calculateJobEligibility(record.data_value, mergedResults.pre_score, mergedResults.post_score)}%
                                            </div>
                                        </div>
                                        <div className='progressBar'>
                                            <div className='innerProgressBar'>
                                            </div>
                                        </div>
                                        <div className='colorBox'>
                                            <div className='boxDiv'>
                                                <div></div>
                                                <div></div>

                                            </div>
                                            <div className='boxDiv'>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }}
                        />
                        : null} */}
                </Table>

            </div>
            <OccupationalMap
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                Occupation={Occupation}
                setOccupation={setOccupation}
            />


            {
                loader ?
                    <FullPageLoader />
                    : null
            }
        </>

    )
}

export default OccupationTable