import React from 'react'
import FormDiv from '../AddProfessorAndUniversity/FormDiv'
import { Modal } from 'antd'

function AddProfModal({
    isModalOpen,
    setIsModalOpen,
    showModal,
    handleOk,
    handleCancel, 
    selectedCourse
}) {
    return (
        <Modal className='AddCourseModal'  open={isModalOpen} footer={false} onCancel={() => { handleCancel() }}>
            <FormDiv selectedCourse={selectedCourse} />
        </Modal>
    )
}

export default AddProfModal