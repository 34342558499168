import React, { useEffect } from "react";
import "./LandingPageNavbar.scss";
import Facebooks from '../../Assest/RateMyProfessor/facebook-black.942803b4.svg';
import Instagrams from '../../Assest/RateMyProfessor/instagram-black.b1c1f7b5.svg';
import Twitters from '../../Assest/RateMyProfessor/twitter-black.9e8418fe.svg';
import { NavLink, useNavigate } from "react-router-dom";
import { Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import profile from './../../Assest/profile.jpg'
import { logout } from "../../module/features/authentication";
function LandingPageNavbar() {
  const user = useSelector(state => state.Authentication.user)
  const dispatch = useDispatch()
  const logoutFunction = () => {
    const callback = () => {
      window.location.reload()
    }
    dispatch(logout({ callback }))
  }
  const items = [
    {
      label: 'Setting',
      key: '0',
      onClick: () => {
        navigate('/dashboard/Setting')
      }
    },
    {
      label: `Edit Profile`,
      key: '1',
      onClick: () => {
        navigate('/dashboard/Profile?tab=General')

      }
    },
    {
      type: 'divider',
    },
    {
      label: 'Logout',
      key: '3',
      onClick: () => {
        logoutFunction()
      }
    },
  ];
  const navigate = useNavigate();
  useEffect(() => {
    console.log(user,'asdsdasdsa')
  }, [user])
  return (
    <div className="landing-page-navbar">
      <div className="landing-page-social-icon">

        <div className="social-login">
          <img src={Facebooks} alt="facebook" className="facebook-icon" />
          <img src={Instagrams} alt="Instagram" className="instagram-icon" />
          <img src={Twitters} alt="twitter" className="twitter-icon" />
        </div>
      </div>
      {user ?
        <div className="landingNavBarTool">
          <NavLink to={'/dashboard'} className="gotodash">Dashboard</NavLink>
          <Dropdown
            menu={{
              items,
            }}
            trigger={['click']}
          >
            <div className='navButtons'>
              <img src={profile} />
            </div>
          </Dropdown>
        </div>
        :
        <div className="landing-page-navbar-child">
          <button className="navbar-child-btn" onClick={() => {
            navigate(`/login?redirect=${window.location.pathname}`)
          }}>Login</button>
          <button className="navbar-child-active" onClick={() => {
            navigate(`/register?redirect=${window.location.pathname}`)
          }}>Signup</button>
        </div>
      }
    </div>
  );
}

export default LandingPageNavbar;
