import React, { useEffect, useState } from "react";
// import "./LandingPage.scss";
import "../LandingPage/NewLandingPage.scss"
import { NavLink, useNavigate } from "react-router-dom";

import gradhatIcon from "./../../Assest/gradhatIcon.png"
import searchIcon from "./../../Assest/searchIcon.png"

import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import { searchProfessors } from "../../module/features/professor";
import { searchUniversities } from "../../module/features/university";
const { Option } = Select;
function SearchSchool({ hideProfessor, defaultProfessor, SelectCompareWith, type, hideUniversity, hideSwitchProUni }) {
    const [professorSchool, setProfessorSchool] = useState(false);
    const user = useSelector(state => state.Authentication.user)
    const [professorArr, setProfessorArr] = useState([])
    const [search, setSearch] = useState('')
    const professorList = useSelector(e => e.professorSlice.searchProfessorsData)
    const universityList = useSelector(state => state.universitySlice.searchUniversitiesData)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(searchProfessors({ search: search }))
        dispatch(searchUniversities({ search: search }))
    }, [])
    useEffect(() => {
        const debounceId = setTimeout(() => {
            if (professorSchool) {

                dispatch(searchProfessors({ search: search }))
            } else {
                dispatch(searchUniversities({ search: search }))

            }
        }, 1000);

        return () => {
            clearTimeout(debounceId);
        };
    }, [search]);
    useEffect(() => {
        console.log(defaultProfessor, 'defaultProfessor')
        setProfessorSchool(defaultProfessor)
    }, [defaultProfessor])
    useEffect(() => {
        if (professorSchool) {

            if (professorList) {
                let arr = professorList.map((a, i) => {
                    return {
                        ...a,
                        value: a.id,
                        label: a.professor_name
                    }
                })
                setProfessorArr(arr)
                console.log(professorList, 'professorList')
            }
        } else if (universityList) {
            let arr = universityList.map((a, i) => {
                return {
                    value: `${a.id}`,
                    label: `${a.NAME}`,
                    ...a
                }
            })
            setProfessorArr(arr)
            console.log(professorList, 'professorList')
            // setProfessorArr([])

        }
    }, [professorList, professorSchool, universityList])
    const onChange = (value) => {
        if (professorSchool) {
            navigate(`/professors?id=${value}`)
        } else {
            navigate(`/school?id=${value}`)
        }
        // console.log(`selected ${value} `);
    };
    const onSearch = (value) => {
        setSearch(value);
    };
    const filterOption = (input, option) =>
        (option?.label ?? option?.key ?? '').toLowerCase().includes(input.toLowerCase());
    const renderOptionUniversity = (option) => {
        // Customize the rendering of each option
        return (
            <div className="universityListOpt" onClick={() => console.log(option)}>
                <div>
                    <span style={{ marginRight: '8px' }}>{option.label}</span>
                    <div>
                        <span className="location">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 32C167.67 32 96 96.51 96 176c0 128 160 304 160 304s160-176 160-304c0-79.49-71.67-144-160-144zm0 224a64 64 0 1 1 64-64 64.07 64.07 0 0 1-64 64z"></path></svg>
                            &nbsp;
                            {option.CITY ? option.CITY + ", " : ''} {option.COUNTRY ? option.COUNTRY : ''}</span>
                    </div>
                </div>
            </div>
        );
    };

    const renderOptionProfessor = (option) => {
        // Customize the rendering of each option
        return (
            <div className="universityListOpt" onClick={() => console.log(option)}>
                <div>
                    <span style={{ marginRight: '8px' }}>{option.professor_name}</span>
                    <div>
                        <span className="location">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 32C167.67 32 96 96.51 96 176c0 128 160 304 160 304s160-176 160-304c0-79.49-71.67-144-160-144zm0 224a64 64 0 1 1 64-64 64.07 64.07 0 0 1-64 64z"></path></svg>
                            &nbsp;
                            {option.department_name ? option.department_name + ", " : ''} {option.NAME ? option.NAME : ''}</span>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <div className="landing-page">

                {/* search school  */}

                {!professorSchool ?
                    <div className="search-School  search-school-border">
                        <p className="search-heading">Search <span className="school-text">School</span> to get started</p>
                        <div className="searchbar">
                            <div className="searchbar-inner">


                                <Select
                                    className="searchInput"
                                    showSearch
                                    placeholder="Your School"
                                    optionFilterProp="children"
                                    // onChange={onChange}
                                    onChange={(value) => {
                                        if (SelectCompareWith) {
                                            SelectCompareWith(value, type)
                                        } else {
                                            onChange(value)
                                        }
                                    }}
                                    onSearch={onSearch}
                                    filterOption={filterOption}
                                // options={universityArr}
                                >
                                    {professorArr.map((option) => (
                                        <Option key={option.label} value={option.value}>
                                            {renderOptionUniversity(option)}
                                        </Option>
                                    ))}
                                </Select>

                                <div className="grad-hatIcon">
                                    <img src={gradhatIcon} alt="gradHatIcon" />
                                </div>

                                <div className="searchIcon">
                                    <img src={searchIcon} alt="searchIcon" />
                                </div>

                            </div>

                        </div>
                        <div className="professorSchool-btn">
                            {hideSwitchProUni ? null :
                                <button className="professor-text" onClick={() => { setProfessorSchool(true) }}><p style={{ textDecoration: 'underline' }} >Are you looking for Professor ?</p></button>
                            }

                            {hideProfessor ?
                                null
                                :

                                <div className="professor-text"><p>Can't find your school? <NavLink to={user ? '/add-university' : `/login?redirect=${'/add-university'}`}>Add your school</NavLink ></p></div>
                            }
                        </div>
                    </div>
                    :
                    <div className="search-School search-school-border">
                        <p className="search-heading">Search <span className="school-text">Professor</span> to get started</p>
                        <div className="searchbar">
                            <div className="searchbar-inner">

                                <Select
                                    className="searchInput"
                                    showSearch
                                    placeholder={professorSchool ? "Professor name" : "Your School"}
                                    optionFilterProp="children"
                                    onChange={(value) => {
                                        if (SelectCompareWith) {
                                            SelectCompareWith(value, type)
                                        } else {
                                            onChange(value)
                                        }
                                    }}
                                    onSearch={onSearch}
                                    filterOption={filterOption}
                                // options={professorArr}
                                >
                                    {professorArr.map((option) => (
                                        <Option key={option.label} value={option.value}>
                                            {renderOptionProfessor(option)}
                                        </Option>
                                    ))}
                                </Select>


                                <div className="grad-hatIcon">
                                    <img src={gradhatIcon} alt="gradHatIcon" />
                                </div>

                                <div className="searchIcon">
                                    <img src={searchIcon} alt="searchIcon" />
                                </div>

                            </div>

                        </div>
                        <div className="professorSchool-btn">
                            {hideSwitchProUni ? null :
                                <button className="professor-text" onClick={() => { setProfessorSchool(false) }}><p style={{ textDecoration: 'underline' }} >Are you looking for Universities?</p></button>
                            }
                            {!hideUniversity ?
                                <div className="professor-text"><p>Can't find your professor? <NavLink to={user ? '/add-professor' : `/login?redirect=${'/add-professor'}`}>Add your professor</NavLink></p></div>
                                : null}

                        </div>

                    </div>
                }



            </div>
        </React.Fragment>
    );
}

export default SearchSchool;
