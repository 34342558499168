import React from 'react'
import './Tier.scss'
import { useNavigate } from 'react-router-dom'
import { Collapse } from 'antd'
import tier1building from './../../Assest/tier1block.png'
import tier2building from './../../Assest/tier2block.png'
import tier3building from './../../Assest/tier3block.png'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { useEffect } from 'react'
import { getCompetenciesByIdTier } from '../../module/features/competency'
import { Spin, message } from 'antd';
import TierMap from './Tier'

function Tier() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const tier1 = useSelector(state => state.coursesSlice.tier1)
    const tier2 = useSelector(state => state.coursesSlice.tier2)
    const tier3 = useSelector(state => state.coursesSlice.tier3)
    const AllCompetencieTier = useSelector(state => state.competencySlice.AllCompetencieTier)
    const AllCompetencieTier2 = useSelector(state => state.competencySlice.AllCompetencieTier2)
    const AllCompetencieTier3 = useSelector(state => state.competencySlice.AllCompetencieTier3)

    const userProfile = useSelector(state => state.completeProfileSlice.userProfile)
    const [activeTier, setActiveTier] = useState('0')
    const [activeTierArr, setActiveTierArr] = useState([])
    useEffect(() => {
        if (userProfile?.tier) {
            setActiveTierArr(['1'])
            setActiveTier(userProfile?.tier)
        }
    }, [userProfile])


    useEffect(() => {
        console.log(tier1, 'tier1tier1')
        if (tier1?.length) {

            let data = {
                "courseIds": [tier1[0]?.id]
            }
            dispatch(getCompetenciesByIdTier({ data, tier: '1' }))
        }
    }, [tier1?.length])
    useEffect(() => {
        console.log(tier2, 'tier2tier2')
        if (tier2?.length) {

            let data = {
                "courseIds": [tier2[0].id]
            }
            dispatch(getCompetenciesByIdTier({ data, tier: '2' }))
        }
    }, [tier2?.length])
    useEffect(() => {
        console.log(tier3, 'tier3tier3')
        if (tier3?.length) {

            let data = {
                "courseIds": [tier3[0].id]
            }
            dispatch(getCompetenciesByIdTier({ data, tier: '3' }))
        }
    }, [tier3?.length])

    useEffect(() => {
        console.log(AllCompetencieTier, 'AllCompetencieTier')
    }, [AllCompetencieTier])


    const tierDiv = (tierArr, tier, tierObj) => {
        return (
            <div className='courseList'>
                <div className='innerCourseList'>
                    <h3>
                    Competencies List
                    </h3>
                    <div className='courseList'>
                        {tierArr.map((a, i) => (
                            <p><span>•</span>{a.name}</p>
                        ))}
                    </div>
                    <div className='startButton'>
                        <p>Start your tier {tier} assesment</p>
                        <button className={Number(activeTier) === Number(tier) ? 'hoverEffect' : 'notActive'} onClick={() => {
                            if (Number(activeTier) === Number(tier)) {
                                let coursesId = tierArr.map((a, i) => {
                                    return a.id
                                })
                                console.log(tier1,tier,tierObj)
                                navigate(`/dashboard/AssesmentTier?tier=${tier}&courses=${tierObj[0]?.id}`)
                            }
                        }}>
                            {Number(activeTier) > Number(tier) ?
                                'Submitted'
                                : Number(activeTier) === Number(tier) ?
                                    'Start'
                                    : Number(activeTier) < Number(tier) ?
                                        'Pending'
                                        : ''
                            }

                        </button>
                    </div>
                </div>
            </div>
        )
    }
    const items = [
        {
            key: '1',
            label: `Tier 1 - Personal Effectiveness Competencies ${tier1?.length ? '' : '(will open soon)'}`,
            children: tierDiv(AllCompetencieTier, '1', tier1),
            collapsible: tier1?.length ? '' : 'disabled',
        },
        {
            key: '2',
            label: `Tier 2 - Academic Competencies ${tier2?.length ? '' : '(will open soon)'}`,
            children: tierDiv(AllCompetencieTier2, '2', tier2),
            collapsible: tier2?.length ? '' : 'disabled',
        },
        {
            key: '3',
            label: `Tier 3 - Workplace Competencies ${tier3?.length ? '' : '(will open soon)'}`,
            children: tierDiv(AllCompetencieTier3, '3', tier3),
            collapsible: tier3?.length ? '' : 'disabled'
        },
    ];

    const onChange = (key) => {
        setActiveTierArr(key);
    };
    const [activeTierImage, setActiveTierImage] = useState(1)
    return (
        <div className='Tier'>
            <div className='innerTier'>
                <h3>Tier</h3>
                {/* <button onClick={() => {
                    setActiveTierImage(activeTierImage + 1)
                }}>update</button> */}
                {AllCompetencieTier?.length ?
                    <Collapse className='tiersCollapse' items={items} activeKey={activeTierArr} onChange={onChange} />
                    :
                    <Spin size="large" className='loadingData' />
                }
                {/* <p className='loadingData'>loading Data...</p> */}
            </div>
            <div className='innerTierImage'>
                {/* <TierMap /> */}
                <div className='imageDivTier'>
                    <img className={activeTier >= 3 ? 'tier3building active' : 'tier3building'} src={tier3building} />
                    <img className={activeTier >= 2 ? 'tier2building active' : 'tier2building'} src={tier2building} />
                    <img className={activeTier >= 1 ? 'tier1building active' : 'tier1building'} src={tier1building} />
                </div>
            </div>
        </div>
    )
}

export default Tier