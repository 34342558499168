import { Modal, Select } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../Constant/Spinner/Spinnner";
import { useEffect } from "react";
import { addProfessor, editProfessor, getProfessors } from "../../module/features/professor";

function AddProfessorModal({
  showModal,
  handleOk,
  handleCancel,
  isModalOpen,
  setIsModalOpen,
  editState,
}) {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.professorSlice.loader);

  const [data, setData] = useState({
    NAME: "",
    ADDRESS: "",
    CITY: "",
    COUNTRY: "",
    WEBSITE: "",
    TELEPHONE: "",
  });
  const clearState = () => {
    setData({
      NAME: "",
      ADDRESS: "",
      CITY: "",
      COUNTRY: "",
      WEBSITE: "",
      TELEPHONE: "",
    });
  };
  const callback = () => {
    clearState();
    dispatch(getProfessors());
    handleOk();
  };
  const addProfessorFn = () => {
    dispatch(addProfessor({ data: data, callback: callback }));
  };

  const editProfessorFn = () => {
    dispatch(editProfessor({ data: data, id: editState.OBJECTID, callback: callback }));
  };

  useEffect(() => {
    if (editState) {
      setData({
        NAME: editState.NAME,
        ADDRESS: editState.ADDRESS,
        CITY: editState.CITY,
        COUNTRY: editState.COUNTRY,
        WEBSITE: editState.WEBSITE,
        TELEPHONE: editState.TELEPHONE,
      });
    } else {
      clearState();
    }
  }, [editState]);

  return (
    <Modal
      title={!editState ? "Add Professor" : "Edit Professor"}
      open={isModalOpen}
      footer={false}
      onCancel={() => {
        handleCancel();
        clearState();
      }}
    >
      <div className="coursesTableDiv">
        <div className="form">
          <label className="addCourseInput">
            <p>Professor Name</p>
            <input
              placeholder="Name"
              value={data.NAME}
              onChange={(ev) => {
                let obj = { ...data };
                obj.NAME = ev.target.value;
                setData(obj);
              }}
            />
          </label>
          <label className="addCourseInput">
            <p>Professor Telephone</p>
            <input
              placeholder="Telephone"
              value={data.TELEPHONE}
              onChange={(ev) => {
                let obj = { ...data };
                obj.TELEPHONE = ev.target.value;
                setData(obj);
              }}
            />
          </label>
          <label className="addCourseInput">
            <p>Professor Website</p>
            <input
              placeholder="Website"
              value={data.WEBSITE}
              onChange={(ev) => {
                let obj = { ...data };
                obj.WEBSITE = ev.target.value;
                setData(obj);
              }}
            />
          </label>
          <label className="addCourseInput">
            <p>Professor Address</p>
            <input
              placeholder="Address"
              value={data.ADDRESS}
              onChange={(ev) => {
                let obj = { ...data };
                obj.ADDRESS = ev.target.value;
                setData(obj);
              }}
            />
          </label>
          <label className="addCourseInput">
            <p>City</p>
            <input
              placeholder="City"
              value={data.CITY}
              onChange={(ev) => {
                let obj = { ...data };
                obj.CITY = ev.target.value;
                setData(obj);
              }}
            />
          </label>
          <label className="addCourseInput">
            <p>Country</p>
            <input
              placeholder="Country"
              value={data.COUNTRY}
              onChange={(ev) => {
                let obj = { ...data };
                obj.COUNTRY = ev.target.value;
                setData(obj);
              }}
            />
          </label>
          <div className="addCourse">
            {editState ? (
              <button className="hoverEffect" onClick={editProfessorFn}>
                {loader ? <Spinner /> : `Edit Professor`}
              </button>
            ) : (
              <button className="hoverEffect" onClick={addProfessorFn}>
                {loader ? <Spinner /> : `Add Professor`}
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddProfessorModal;
