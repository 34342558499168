import { Button, Input, Modal, Progress, Space, Table } from 'antd'

import Column from 'antd/es/table/Column';
import React, { useEffect, useRef, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { getAge, joinIds } from '../../Constant/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { getStudentCourseById } from '../../module/features/courses';
import UserCompetencies from './UserCompetencies';

function StudentDetailModal({
    isModalOpen,
    handleOk,
    handleCancel,
    student
}) {
    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [coursesList, setCoursesList] = useState([])
    const searchInput = useRef(null);
    const studentCourses = useSelector(state => state.coursesSlice.studentCourses)

    const tier1 = useSelector(state => state.coursesSlice.tier1)
    const tier2 = useSelector(state => state.coursesSlice.tier2)
    const tier3 = useSelector(state => state.coursesSlice.tier3)


    const data = [
        {
            key: 1,
            name: 'John Brown',
            age: 32,
            address: 'New York No. 1 Lake Park',
            description: 'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
        },
        {
            key: 2,
            name: 'Jim Green',
            age: 42,
            address: 'London No. 1 Lake Park',
            description: 'My name is Jim Green, I am 42 years old, living in London No. 1 Lake Park.',
        },
        {
            key: 3,
            name: 'Not Expandable',
            age: 29,
            address: 'Jiangsu No. 1 Lake Park',
            description: 'This not expandable',
        },
        {
            key: 4,
            name: 'Joe Black',
            age: 32,
            address: 'Sydney No. 1 Lake Park',
            description: 'My name is Joe Black, I am 32 years old, living in Sydney No. 1 Lake Park.',
        },
        {
            key: 5,
            name: 'Joe Black',
            age: 32,
            address: 'Sydney No. 1 Lake Park',
            description: 'My name is Joe Black, I am 32 years old, living in Sydney No. 1 Lake Park.',
        },
        {
            key: 6,
            name: 'Joe Black',
            age: 32,
            address: 'Sydney No. 1 Lake Park',
            description: 'My name is Joe Black, I am 32 years old, living in Sydney No. 1 Lake Park.',
        },
        {
            key: 7,
            name: 'Joe Black',
            age: 32,
            address: 'Sydney No. 1 Lake Park',
            description: 'My name is Joe Black, I am 32 years old, living in Sydney No. 1 Lake Park.',
        },
        {
            key: 8,
            name: 'Joe Black',
            age: 32,
            address: 'Sydney No. 1 Lake Park',
            description: 'My name is Joe Black, I am 32 years old, living in Sydney No. 1 Lake Park.',
        },
        {
            key: 9,
            name: 'Joe Black',
            age: 32,
            address: 'Sydney No. 1 Lake Park',
            description: 'My name is Joe Black, I am 32 years old, living in Sydney No. 1 Lake Park.',
        },
    ];
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch([], confirm, dataIndex)
                        }}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const viewCourses = () => {
        if (student) {

            let dataUser = {
                "userId": student?.id
            }
            dispatch(getStudentCourseById(dataUser))
        }
    }

    useEffect(() => {
        if (studentCourses && studentCourses && studentCourses?.length) {
            let arr = studentCourses.map(({ course }, i) => {
                return {
                    ...course
                }
            })
            setCoursesList(arr)
        }
    }, [studentCourses])

    useEffect(() => {
        console.log(studentCourses, 'studentCourses')
    }, [studentCourses])
    return (
        <Modal title={student.fullname} open={isModalOpen} onOk={() => { handleOk(); setCoursesList([]) }} onCancel={() => { handleCancel(); setCoursesList([]) }} className='studentDetailModal'>
            <div className='informationBox'>
                <div className='detailHeading'>
                    <h4>Student Detail</h4>
                    <button onClick={() => {
                        viewCourses()
                    }}>View Courses</button>
                </div>
                <div className='details'>
                    <div className='detailCell'>
                        <p><b>Name: </b>{student.fullname}</p>
                    </div>
                    <div className='detailCell'>
                        <p><b>Age: </b>{getAge(student.age)}</p>
                    </div>
                    <div className='detailCell'>
                        <p><b>Religion: </b>{student.religion}</p>
                    </div>
                    <div className='detailCell'>
                        <p><b>Ethnicity: </b>{student.ethnicity}</p>
                    </div>
                    <div className='detailCell'>
                        <p><b>College: </b>{student.nameOfCollege}</p>
                    </div>
                </div>
                <div className='detailCellBio'>
                    <p><b>Bio: </b><br />{student.bio}</p>
                </div>
            </div>
            {coursesList?.length ?
                <>

                    <div class="ant-modal-title coursesHeading">Courses</div>
                    <div className='coursesTable'>
                        <div className='coursesTableDiv'>
                            <Table dataSource={coursesList}
                                expandable={{
                                    expandedRowRender: (record, index, indent, expanded) => (
                                        <UserCompetencies record={record} expanded={expanded} student_id={student?.student_id} />
                                    )
                                }}
                                rowKey='courseId'

                            >
                                <Column filterDropdown={getColumnSearchProps('course').filterDropdown} filterIcon={getColumnSearchProps('course').filterIcon} onFilter={getColumnSearchProps('course').onFilter}
                                    onFilterDropdownOpenChange={getColumnSearchProps('course').onFilterDropdownOpenChange} title="Course name" className='tdDiv' dataIndex="course" key="course"
                                    filterSearch
                                    ex
                                    render={getColumnSearchProps('course').render}
                                />
                                <Column filterDropdown={getColumnSearchProps('type').filterDropdown} filterIcon={getColumnSearchProps('type').filterIcon} onFilter={getColumnSearchProps('type').onFilter}
                                    onFilterDropdownOpenChange={getColumnSearchProps('type').onFilterDropdownOpenChange} title="Course Type" className='tdDiv ' dataIndex="type" key="type"
                                    filterSearch
                                    sorter={(a, b) => a?.type?.length - b?.type?.length}
                                    render={getColumnSearchProps('type').render}
                                />
                                <Column filterDropdown={getColumnSearchProps('degree').filterDropdown} filterIcon={getColumnSearchProps('degree').filterIcon} onFilter={getColumnSearchProps('degree').onFilter}
                                    onFilterDropdownOpenChange={getColumnSearchProps('degree').onFilterDropdownOpenChange} title="Course level" className='tdDiv ' dataIndex="degree" key="degree"
                                    filterSearch

                                    render={getColumnSearchProps('degree').render}
                                />
                                <Column title="Completeness" className='tdDiv ' dataIndex="completeness" key="completeness"
                                    filterSearch

                                    render={(text) => {
                                        return (
                                            Number(Number(text) * 100).toFixed(2) + "%"
                                        )
                                    }}
                                />
                                <Column width="250px" title="Competency level" className='tdDiv ' dataIndex="level" key="level"
                                    filterSearch

                                    render={(text) => {
                                        return (
                                            <div className='progressDiv'>
                                                <Progress className='progress' percent={text * 100} showInfo={false} strokeColor={"#7b77d0"} />
                                                <div className='percentCompetency'>
                                                    {Number(Number(text) * 100).toFixed(2) + "%"}
                                                </div>
                                            </div>

                                        )
                                    }}
                                />
                            </Table>

                        </div>
                    </div>
                </>
                : null}
        </Modal>
    )
}

export default StudentDetailModal