import React from 'react'
import profile from './../../Assest/profile.jpg'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getUser } from '../../module/features/authentication';
import completeProfile, { updateProfile, uploadImage } from '../../module/features/completeProfile';
import { themeColors } from '../../Constant/Utils';
import axios from 'axios';
import { ImageEdit } from '../../Constant/Icons';
import { Image } from 'antd';

function EditSetting() {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [lastColor, setLastColor] = useState('')
    const [file, setFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [data, setData] = useState(false)
    let LColor;
    const toggleTheme = (className, color) => {
        document.getElementsByTagName('body')[0].classList.add(className)
        setLastColor(className)
        if (lastColor) {
            document.getElementsByTagName('body')[0].classList.remove(lastColor)
        }
        let obj = { ...data }
        obj.theme = color
        setData(obj)
    }

    const userProfile = useSelector(state => state.completeProfileSlice.userProfile)
    useEffect(() => {
        if (userProfile) {
            let obj = {
                ...data,
                // ...userProfile,
                ...userProfile,
                studentId: userProfile.id,
                username: userProfile.username,
                majorCourse: userProfile?.majorCourse?.split(','),
                minorCourse: userProfile?.minorCourse?.split(',')
            }
            setData(obj)
        }
    }, [userProfile])



    const callback = () => {
        dispatch(getUser(false))
    }
    const completeProfileFunction = () => {
        let obj = {
            ...data,
            majorCourse: data.majorCourse.join(','),
            minorCourse: data.minorCourse.join(','),
        }
        delete obj.email
        delete obj.updated_at
        delete obj.created_at
        delete obj.id
        delete obj.username
        if (userProfile?.profile?.id) {
            dispatch(updateProfile({ data: obj, callback, setLoader, id: userProfile?.profile?.id }))
        } else {
            dispatch(completeProfile({ data: obj, callback, setLoader }))
        }
    }

    const handleFileChange = (ev) => {
        const file = ev.target.files[0];
        setFile(file);

        // Read the file as a data URL
        const reader = new FileReader();
        reader.onloadend = () => {
            setImageUrl(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const updateImage = (url) => {
        // let formur
        dispatch(updateProfile({ data: { profileUrl: url }, callback, setLoader, id: userProfile?.id }))
    }

    const uploadImageFn = () => {
        console.log(file, imageUrl)
        let data = new FormData()
        data.append("file", file)
        data.forEach((value, key) => {
            console.log(key, value);
        });
        dispatch(uploadImage({
            data, callback: (res) => {
                updateImage(res.data)
                console.log(res)
            }
        }))
    }



    return (
        <div className='EditSetting'>
            <div className='innerEditSetting'>
                <div className='PageHeading'>
                    <h1>Settings</h1>
                </div>
                <div className='userDetail'>
                    {/* <input type='file' onChange={(ev) => {
                        setFile(ev.target.files[0])
                    }} /> */}
                    <div className='imageDivMain'>
                        <div className='imageDiv'>
                            <Image style={{borderRadius:"100%"}} className='image' src={imageUrl || data.profileUrl || profile} />
                            <label className='uploadImage'>
                                <input type='file' onChange={handleFileChange} />
                                <ImageEdit />
                                {/* <button className=''></button> */}
                            </label>
                        </div>
                        {file ?
                            <button className='hoverEffect submitButton savePP' onClick={uploadImageFn}>Save Profile Picture</button>
                            : null}
                    </div>
                    <div className='textDetails'>
                        <span className='nameOccupation'>
                            <h1>{userProfile?.profile?.fullname}</h1>
                            <p className='occupation'>{userProfile?.type}</p>
                        </span>

                        <div className='form'>
                            <label className='bio'>
                                <p>Bio</p>
                                <textarea placeholder='Input Text' rows={6} value={data.bio}
                                    onChange={(ev) => {
                                        let obj = { ...data }
                                        obj.bio = ev.target.value
                                        setData(obj)
                                    }}></textarea>
                            </label>
                            <label>
                                <p>Profile</p>
                                <div className='profileLinkDiv'>
                                    <input placeholder='You Profile Link' value={data.profileUrl} onChange={(ev) => {
                                        let obj = { ...data }
                                        obj.profileUrl = ev.target.value
                                        setData(obj)
                                    }} />
                                    <svg style={{ cursor: 'pointer' }} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"></path></svg>
                                </div>
                            </label>

                            {/* <label>
                                <p>Profile</p>
                                <div className='profileLinkDiv'>
                                    <input placeholder='-Select your location-'
                                        value={data.location}
                                        onChange={(ev) => {
                                            let obj = { ...data }
                                            obj.location = ev.target.value
                                            setData(obj)
                                        }}
                                    />
                                    <svg style={{ cursor: 'pointer' }} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"></path></svg>
                                </div>
                            </label> */}
                        </div>
                    </div>

                </div>
                <div className='PageHeading'>
                    <h1>Theme</h1>

                </div>
                <div className='themeColors'>
                    {themeColors.map((a) => {
                        return (
                            <div className='colorBox' style={data.theme === a.color ? { border: `1px solid ${a.color}` } : {}} onClick={() => {
                                toggleTheme(a.class, a.color)
                            }}>

                                <div style={{ backgroundColor: a.color }}>

                                </div>

                            </div>
                        )
                    })}
                </div>
                <div className='submitButtonDiv'>
                    <button className='hoverEffect submitButton' onClick={completeProfileFunction}>Submit Changes</button>
                </div>
            </div>
        </div>
    )
}

export default EditSetting