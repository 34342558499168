import React, { useEffect, useState } from "react";
// import "./LandingPage.scss";
import "./NewLandingPage.scss";
import { NavLink, useNavigate } from "react-router-dom";
import LandingPageNavbar from "../Navbar/LandingPageNavbar";
import NewLandNavBar from "../Navbar/NewLandNavBar";
import slider1 from "./../../Assest/slider1.png"
import slider2 from "./../../Assest/slider2.png"
import slider3 from "./../../Assest/slider3.png"
import card1Vector from "./../../Assest/card1Vector.png"
import card2Vector from "./../../Assest/card2Vector.png"
import card3Vector from "./../../Assest/card3Vector.png"
import testimonial from "./../../Assest/testimonial.png"
import gradhatIcon from "./../../Assest/gradhatIcon.png"
import searchIcon from "./../../Assest/searchIcon.png"
import topUniversity1 from "./../../Assest/topUniversity1.png"
import topUniversity2 from "./../../Assest/topUniversity2.png"
import topUniversity3 from "./../../Assest/topUniversity3.png"
import nextArrow from "./../../Assest/nextArrow.png"
import professor1 from "./../../Assest/TopProfessors/professor1.png"
import professor2 from "./../../Assest/TopProfessors/professor2.png"
import professor3 from "./../../Assest/TopProfessors/professor3.png"
import professor4 from "./../../Assest/TopProfessors/professor4.png"
import course1 from "./../../Assest/course1.png";
import course2 from "./../../Assest/course2.png";
import course3 from "./../../Assest/course3.png";


// import Swiper from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles

import { Pagination } from 'swiper/modules';

import { Card, Col, Row } from 'antd';

// import image from "../../Assest/big_rmp_logo_black.svg";
import image from './../../Assest/logo.png'
import EditRatingImg from "../../Assest/rmp-Family/rating.png";
import AnonymousRatingImg from "../../Assest/rmp-Family/anonymous.png";
import LikeRatingImg from "../../Assest/rmp-Family/like.png";
import LandingPageFooter from "../footer/LandingPageFooter";
import { Carousel, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import OutcomesModal from "./OutcomesModal";
import { getUserOutcome } from "../../module/features/outcomesSlice";
import Search from "antd/es/input/Search";
import SearchSchool from "../SearchSchool/SearchSchool";
const { Option } = Select;
function LandingPage() {
    const [professorSchool, setProfessorSchool] = useState(false);
    const dispatch = useDispatch()
    const [professorArr, setProfessorArr] = useState([])
    const professorList = useSelector(e => e.professorSlice.professorList)
    const universityList = useSelector(state => state.universitySlice.universityList)
    const userScheduledOutcome = useSelector(state => state.outcomesSlice.userScheduledOutcome)

    const navigate = useNavigate();
    useEffect(() => {
        if (professorSchool) {

            if (professorList) {
                let arr = professorList.map((a, i) => {
                    return {
                        value: a.id,
                        label: a.professor_name
                    }
                })
                setProfessorArr(arr)
                console.log(professorList, 'professorList')
            }
        } else if (universityList) {
            let arr = universityList.map((a, i) => {
                return {
                    value: `${a.OBJECTID}`,
                    label: `${a.NAME}`,
                    ...a
                }
            })
            setProfessorArr(arr)
            console.log(professorList, 'professorList')
            // setProfessorArr([])

        }
    }, [professorList, professorSchool, universityList])
    const onChange = (value) => {
        if (professorSchool) {
            navigate(`/professors?id=${value}`)
        } else {
            navigate(`/school?id=${value}`)

        }
        console.log(`selected ${value} `);
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    const renderOptionUniversity = (option) => {
        // Customize the rendering of each option
        return (
            <div className="universityListOpt" onClick={() => console.log(option)}>
                <div>
                    <span style={{ marginRight: '8px' }}>{option.label}</span>
                    <div>
                        <span className="location">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 32C167.67 32 96 96.51 96 176c0 128 160 304 160 304s160-176 160-304c0-79.49-71.67-144-160-144zm0 224a64 64 0 1 1 64-64 64.07 64.07 0 0 1-64 64z"></path></svg>
                            &nbsp;
                            {option.CITY ? option.CITY + ", " : ''} {option.COUNTRY ? option.COUNTRY : ''}</span>
                    </div>
                </div>
            </div>
        );
    };


    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        const url = new URL(window.location)
        const course = url.searchParams.get('course')
        const userid = url.searchParams.get('userid')
        if (course) {
            dispatch(getUserOutcome({
                "ids": course,
                "userId": userid
            }))
        }
    }, [])
    useEffect(() => {
        const url = new URL(window.location)
        const course = url.searchParams.get('course')
        if (course && userScheduledOutcome && userScheduledOutcome?.length) {
            showModal()
        }
    }, [userScheduledOutcome])
    const imageArr = [topUniversity1,
        topUniversity2,
        topUniversity3]
    return (
        <React.Fragment>
            <div className="landing-page">
                {/* <LandingPageNavbar /> */}
                <NewLandNavBar bg={'#F1F7FF'} />


                <div className="landing-page-carousel">
                    <div className="carousel-text">
                        <h4 className="welcome-tag">Welcome To <span className="ungrade-tag">UNGRADE.US</span></h4>
                        <p className="sub-text">Unlocking the Potential of Education !</p>
                    </div>
                    <Carousel  >
                        <div className="carousel-style" >
                            <img className="contentStyle" src={slider1} />


                        </div>

                        <div className="carousel-style" >
                            <img className="contentStyle" src={slider2} />


                        </div>

                        <div className="carousel-style" >
                            <img className="contentStyle" src={slider3} />


                        </div>

                    </Carousel>
                </div>

                {/* why ungrade us section */}
                <div className="whyungradeus_start">
                    <div className="question-para">
                        <h1 className="question-text">What is Ungrade.us?</h1>
                        <p className="answer-text">College education is a transformative journey, but its challenges are real. Students face a daunting task –choosing the right courses and professors to equip them for a competitive job market. Ungrade.us steps inwhere traditional evaluations fall short, offering a revolutionary approach to empower students in theireducational choices.</p>
                    </div>
                    {/* <div className="getstarted-btn">
                        <button className="hi">GET STARTED</button>

                    </div> */}

                </div>

                {/* 3 card section */}
                <div className="testimonial-section">

                    {/* <img className="testimonial" src={testimonial} /> */}


                    <div className="testimonialCards">

                        <div className="testimonial-card">
                            <div className="cardVector-div">
                                <img className="card1vector" src={card1Vector} />
                            </div>
                            <div className="card-text">
                                <h1>Refine Your Feedback, Anytime, Anywhere.</h1>
                                <p>Ungrade.us gives you the power to manage and edit your ratings, ensuring your feedback is always a true reflection on your educational experiences</p>
                            </div>
                        </div>

                        <div className="testimonial-card">
                            <div className="cardVector-div">
                                <img className="card1vector" src={card3Vector} />
                            </div>
                            <div className="card-text">
                                <h1>Anonymous Ratings  for Honest Feedback </h1>
                                <p>Ungrade.us ensures that  your ratings are always anonymous, providing a secure space for your honest feedback without compromising your privacy.</p>
                            </div>
                        </div>

                        <div className="testimonial-card">
                            <div className="cardVector-div">
                                <img className="card1vector" src={card2Vector} />
                            </div>
                            <div className="card-text">
                                <h1>Express Yourself Effortlessly.</h1>

                                <p>With Ungrade.us, you can like or dislike ratings, making it simple to express your sentiments about educational experiences.Your feedback, your choice.</p>
                            </div>
                        </div>
                    </div>


                </div>

                {/* why ungrade us section */}
                <div className="whyungradeus">
                    <div className="question-para">
                        <h1 className="question-text">Why Ungrade.us?</h1>
                        <p className="answer-text"><p className="inner-text">Bridging the Evaluation Gap</p>
                            Ungrade.us recognizes the absence of data-driven tools for assessing professors' competency-based education. We understand that students need more than just anecdotes; they need real insights to make informed decisions.</p>
                    </div>
                    {/* <div className="getstarted-btn">
                        <button className="hi">GET STARTED</button>

                    </div> */}

                </div>

                {/* search school  */}
                <SearchSchool />

                {/* top ranking universities */}
                <div className="Top-RankingUniversities">

                    <div className="text-header">
                        <h4>Top Ranking Universities</h4>
                        <div className="scrollbar" ></div>
                    </div>

                    <div className="carouselTopRanking">
                        {/* <Carousel afterChange={onChangeTop}> */}
                        <div className="carousel1">

                            <Swiper
                                slidesPerView={1}
                                spaceBetween={10}
                                pagination={{
                                    clickable: true,
                                }}
                                breakpoints={{

                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 40,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 50,
                                    },

                                }}

                                modules={[Pagination]}
                                className="mySwiper"
                            >
                                {universityList && universityList.map((a, i) => {
                                    return i < 8 ? (
                                        <SwiperSlide>
                                            <Card className="card" bordered={false}>
                                                <div className="cardImage">
                                                    <img src={imageArr[i % 3]} />
                                                </div>
                                                <div className="cardText">
                                                    <p className="seeUniversityHeader">{a.NAME}</p>
                                                    {a.WEBSITE ? 
                                                    <div className="seeUniversity">
                                                        <p onClick={() => {
                                                            window.open(a?.WEBSITE?.includes('https://') ? a?.WEBSITE : 'https://'+a?.WEBSITE  , '_blank')
                                                        }}>SEE UNIVERSITY </p>
                                                        <img src={nextArrow} className="nextarrow" />
                                                    </div>
:null}
                                                </div>
                                            </Card>
                                        </SwiperSlide>
                                    ) : null
                                })}

                                {/* <SwiperSlide> <Card className="card" bordered={false}>
                                    <div className="cardImage">
                                        <img src={topUniversity2} />
                                    </div>
                                    <div className="cardText">
                                        <p className="seeUniversityHeader">Stallion University</p>
                                        <div className="seeUniversity">
                                            <p>SEE UNIVERSITY </p>
                                            <img src={nextArrow} className="nextarrow" />
                                        </div>

                                    </div>
                                </Card> </SwiperSlide>
                                <SwiperSlide> <Card className="card" bordered={false}>
                                    <div className="cardImage">
                                        <img src={topUniversity3} />
                                    </div>
                                    <div className="cardText">
                                        <p className="seeUniversityHeader">Covenant University</p>
                                        <div className="seeUniversity">
                                            <p>SEE UNIVERSITY </p>
                                            <img src={nextArrow} className="nextarrow" />
                                        </div>

                                    </div>
                                </Card></SwiperSlide>
                                <SwiperSlide>   <Card className="card" bordered={false}>
                                    <div className="cardImage">
                                        <img src={topUniversity1} />
                                    </div>
                                    <div className="cardText">
                                        <p className="seeUniversityHeader">Stanford University</p>
                                        <div className="seeUniversity">
                                            <p>SEE UNIVERSITY </p>
                                            <img src={nextArrow} className="nextarrow" />
                                        </div>

                                    </div>
                                </Card> </SwiperSlide>
                                <SwiperSlide> <Card className="card" bordered={false}>
                                    <div className="cardImage">
                                        <img src={topUniversity2} />
                                    </div>
                                    <div className="cardText">
                                        <p className="seeUniversityHeader">Stallion University</p>
                                        <div className="seeUniversity">
                                            <p>SEE UNIVERSITY </p>
                                            <img src={nextArrow} className="nextarrow" />
                                        </div>

                                    </div>
                                </Card> </SwiperSlide>
                                <SwiperSlide> <Card className="card" bordered={false}>
                                    <div className="cardImage">
                                        <img src={topUniversity3} />
                                    </div>
                                    <div className="cardText">
                                        <p className="seeUniversityHeader">Covenant University</p>
                                        <div className="seeUniversity">
                                            <p>SEE UNIVERSITY </p>
                                            <img src={nextArrow} className="nextarrow" />
                                        </div>

                                    </div>
                                </Card></SwiperSlide> */}

                            </Swiper>



                        </div>



                    </div>

                </div>


                {/* Top ranking professors */}
                <div className="topProfessors">

                    <div className="text-header">
                        <h4>Top Ranking Professors</h4>

                        <div className="scrollbar" ></div>

                    </div>

                    <div className="carouselTopRanking">

                        <div className="carousel1">

                            <Swiper
                                slidesPerView={1}
                                spaceBetween={10}
                                pagination={{
                                    clickable: true,
                                }}
                                breakpoints={{

                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 1,
                                    },
                                    768: {
                                        slidesPerView: 3,
                                        spaceBetween: 40,

                                    },
                                    1024: {
                                        slidesPerView: 5,
                                        spaceBetween: 50,
                                    },

                                }}

                                modules={[Pagination]}
                                className="mySwiperProfessor"
                            >
                                <SwiperSlide>
                                    <Card className="professorcard" bordered={false}>
                                        <div className="cardImage">
                                            <img src={professor1} />
                                        </div>
                                        <div className="cardText">
                                            <p className="seeProfessorHeader line-clamp">Daniel Martin</p>
                                            {/* <div className="seeUniversity">
                                                <p>SEE PROFESSOR </p>
                                                <img src={nextArrow} className="nextarrow" />
                                            </div> */}

                                        </div>
                                    </Card>
                                </SwiperSlide>
                                <SwiperSlide> <Card className="professorcard" bordered={false}>
                                    <div className="cardImage">
                                        <img src={professor2} />
                                    </div>
                                    <div className="cardText">
                                        <p className="seeProfessorHeader line-clamp">Christine Abraham</p>
                                        {/* <div className="seeUniversity">
                                            <p>SEE PROFESSOR </p>
                                            <img src={nextArrow} className="nextarrow" />
                                        </div> */}

                                    </div>
                                </Card> </SwiperSlide>
                                <SwiperSlide>
                                    <Card className="professorcard" bordered={false}>
                                        <div className="cardImage">
                                            <img src={professor3} />
                                        </div>
                                        <div className="cardText">
                                            <p className="seeProfessorHeader line-clamp">Anita Chang</p>
                                            {/* <div className="seeUniversity">
                                                <p>SEE PROFESSOR </p>
                                                <img src={nextArrow} className="nextarrow" />
                                            </div> */}

                                        </div>
                                    </Card> </SwiperSlide>
                                <SwiperSlide> <Card className="professorcard" bordered={false}>
                                    <div className="cardImage">
                                        <img src={professor4} />
                                    </div>
                                    <div className="cardText">
                                        <p className="seeProfessorHeader line-clamp">Kevin Callahan</p>
                                        {/* <div className="seeUniversity">
                                            <p>SEE PROFESSOR </p>
                                            <img src={nextArrow} className="nextarrow" />
                                        </div> */}

                                    </div>
                                </Card></SwiperSlide>
                                <SwiperSlide>     <Card className="professorcard" bordered={false}>
                                    <div className="cardImage">
                                        <img src={professor1} />
                                    </div>
                                    <div className="cardText">
                                        <p className="seeProfessorHeader  line-clamp">Kevin Callahan</p>
                                        {/* <div className="seeUniversity">
                                            <p>SEE PROFESSOR </p>
                                            <img src={nextArrow} className="nextarrow" />
                                        </div> */}

                                    </div>
                                </Card></SwiperSlide>
                                {/* <SwiperSlide> </SwiperSlide> */}

                            </Swiper>





                        </div>





                    </div>


                </div>

                {/* Most popular courses */}
                <div className="popularcourses">

                    <div className="text-header">
                        <h4>Top Popular Courses</h4>
                        <div className="scrollbar" ></div>
                    </div>

                    <div className="carouselTopRanking">

                        <div className="carousel1">
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={10}
                                pagination={{
                                    clickable: true,
                                }}
                                breakpoints={{

                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 40,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 50,
                                    },

                                }}

                                modules={[Pagination]}
                                className="mySwiper"
                            >
                                <SwiperSlide>    <Card className="card" bordered={false}>
                                    <div className="cardImage">
                                        <img src={course1} width={433} />
                                    </div>
                                    <div className="cardText">
                                        <p className="seeUniversityHeader">User Experience Design</p>
                                        {/* <div className="seeUniversity">
                                            <p>SEE COURSE GUIDE </p>
                                            <img src={nextArrow} className="nextarrow" />
                                        </div> */}

                                    </div>
                                </Card> </SwiperSlide>
                                <SwiperSlide>    <Card className="card" bordered={false}>
                                    <div className="cardImage">
                                        <img src={course2} width={433} />
                                    </div>
                                    <div className="cardText">
                                        <p className="seeUniversityHeader">Computer Science</p>
                                        {/* <div className="seeUniversity">
                                            <p>SEE COURSE GUIDE</p>
                                            <img src={nextArrow} className="nextarrow" />
                                        </div> */}

                                    </div>
                                </Card> </SwiperSlide>
                                <SwiperSlide>
                                    <Card className="card" bordered={false}>
                                        <div className="cardImage">
                                            <img src={course3} width={433} />
                                        </div>
                                        <div className="cardText">
                                            <p className="seeUniversityHeader">Covenant University</p>
                                            {/* <div className="seeUniversity">
                                                <p>SEE COURSE GUIDE</p>
                                                <img src={nextArrow} className="nextarrow" />
                                            </div> */}

                                        </div>
                                    </Card> </SwiperSlide>
                                <SwiperSlide>    <Card className="card" bordered={false}>
                                    <div className="cardImage">
                                        <img src={course1} width={433} />
                                    </div>
                                    <div className="cardText">
                                        <p className="seeUniversityHeader">User Experience Design</p>
                                        {/* <div className="seeUniversity">
                                            <p>SEE COURSE GUIDE </p>
                                            <img src={nextArrow} className="nextarrow" />
                                        </div> */}

                                    </div>
                                </Card> </SwiperSlide>
                                <SwiperSlide>    <Card className="card" bordered={false}>
                                    <div className="cardImage">
                                        <img src={course2} width={433} />
                                    </div>
                                    <div className="cardText">
                                        <p className="seeUniversityHeader">Computer Science</p>
                                        {/* <div className="seeUniversity">
                                            <p>SEE COURSE GUIDE</p>
                                            <img src={nextArrow} className="nextarrow" />
                                        </div> */}

                                    </div>
                                </Card> </SwiperSlide>
                                <SwiperSlide>
                                    <Card className="card" bordered={false}>
                                        <div className="cardImage">
                                            <img src={course3} width={433} />
                                        </div>
                                        <div className="cardText">
                                            <p className="seeUniversityHeader">Covenant University</p>
                                            {/* <div className="seeUniversity">
                                                <p>SEE COURSE GUIDE</p>
                                                <img src={nextArrow} className="nextarrow" />
                                            </div> */}

                                        </div>
                                    </Card> </SwiperSlide>

                            </Swiper>
                        </div>





                    </div>

                </div>








                <LandingPageFooter />
            </div>
            <OutcomesModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                showModal={showModal}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
        </React.Fragment>
    );
}

export default LandingPage;
