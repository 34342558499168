import React from "react";
import "./UniversityRatingSuccess.scss";
import UniversityRatingSuccess from "./UniversityRatingSuccess";
import ProfessorRatingNavbar from "../../Navbar/professorRatingNavbar";
import LandingPageFooter from "../../footer/LandingPageFooter";

export default () => {
  return (
    <div className="web-page">
      <div className="navbar-section">
        <ProfessorRatingNavbar />
      </div>
      <UniversityRatingSuccess />
      <div className="footer-section">
        <LandingPageFooter />
      </div>
    </div>
  );
};
