
import React, { useEffect, useState } from "react";
import ProfessorRatingNavbar from "../Navbar/professorRatingNavbar";
import "./addForm.scss";
import { Input, Select, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addProfessor, getProfessors } from "../../module/features/professor";
import Spinner from "../../Constant/Spinner/Spinnner";
import bg from './../../Assest/addFormBackground.png'
import SearchSchool from "../SearchSchool/SearchSchool";
const { Option } = Select;

function FormDiv({ selectedCourse }) {
    const universityList = useSelector(
        (state) => state.universitySlice.universityList
    );
    const courses = useSelector(state => state.coursesSlice.courses)
    const [coursesArr, setCoursesArr] = useState([])
    const user = useSelector(state => state.Authentication.user)
    const userProfile = useSelector(state => state.completeProfileSlice.userProfile)
    const [selectedCourseArr, setSelectedCourseArr] = useState([])
    const [universityArr, setUniversityArr] = useState([]);
    const [selectedUniversity, setSelectedUniversity] = useState('')
    const [agreeTerms, setAgreeTerms] = useState(false);
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState({
        university_id: 0,
        professor_name: "",
        department_id: 0,
        department_name: "",
        courses: ""
    });
    const onChange = (value) => {
        let obj = { ...data };
        obj.university_id = value;
        setData(obj);
        console.log(`selected ${value} `);
    };
    const onSearch = (value) => {
        console.log("search:", value);
    };
    const filterOption = (input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    useEffect(() => {
        if (universityList) {
            let arr = universityList.map((a, i) => {
                return {
                    value: `${a.id}`,
                    label: `${a.NAME}`,
                    ...a,
                };
            });
            setUniversityArr(arr);
        }
    }, [universityList]);
    const callback = () => {
        dispatch(getProfessors())
        let oldData = { ...data }
        oldData.professor_name = ''
        oldData.department_id = 0
        oldData.department_name = ''
        // oldData.courses = ''
        setData(oldData)
        // setData({
        //     university_id: 0,
        //     professor_name: "",
        //     department_id: 0,
        //     department_name: "",
        //     courses: ""
        // });
        setLoader(false);
    };
    useEffect(() => {
        if (userProfile) {
            console.log(userProfile, 'userProfile asda')
            if (userProfile?.university_id) {
                setSelectedUniversity(userProfile?.university_id)
            }
        }
    }, [userProfile])
    useEffect(() => {
        if (selectedCourse && selectedCourse?.courses) {
            console.log(selectedCourse, 'selectedCourse')
            let obj = { ...data }
            let arr = selectedCourse.courses
            obj.courses = selectedCourse.courses
            setSelectedCourseArr(arr)
            setData(obj)
        }
        if (selectedUniversity) {
            let obj = { ...data }
            obj.university_id = selectedUniversity
            setData(obj)
            console.log(selectedUniversity, 'selectedUniversity')
        }
    }, [selectedCourse, selectedUniversity])
    const addProfessorFn = () => {
        console.log(data)
        if (user) {
            if (data.university_id && data.professor_name && data.courses) {

                if (agreeTerms) {

                    setLoader(true);
                    dispatch(addProfessor({ data: data, callback: callback }));
                } else {
                    message.error("Agree to terms and conditon")
                }
            } else {
                message.error('All Fields are required')
            }
        } else {
            message.error("Login to add your professor")
        }
    };
    const renderOptionUniversity = (option) => {
        // Customize the rendering of each option
        return (
            <div className="universityListOpt" onClick={() => console.log(option)}>
                <div>
                    <span style={{ marginRight: "8px" }}>{option.label}</span>
                    <div>
                        <span className="location">
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 512 512"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M256 32C167.67 32 96 96.51 96 176c0 128 160 304 160 304s160-176 160-304c0-79.49-71.67-144-160-144zm0 224a64 64 0 1 1 64-64 64.07 64.07 0 0 1-64 64z"></path>
                            </svg>
                            &nbsp;
                            {option.CITY ? option.CITY + ", " : ""}{" "}
                            {option.COUNTRY ? option.COUNTRY : ""}
                        </span>
                    </div>
                </div>
            </div>
        );
    };
    useEffect(() => {
        if (courses?.length) {
            let arr = courses.map((a, i) => {
                return {
                    ...a,
                    value: a.id,
                    label: a.course,
                }
            })
            setCoursesArr(arr)
        }
    }, [courses])
    return (
        <div className="innerAddForm">
            <h1 onClick={() => {
                console.log(userProfile, 'datadata')
            }}>Add a Professor</h1>
            <p className="addProfDes">Please use the search bar above to make sure that the professor does not already exist at this school.</p>
            <div className="addFormInner">
                <label onClick={()=>{
                    console.log(universityArr,'universityArruniversityArr')
                }}>
                    <p>Name of School</p>
                    <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={filterOption}
                        disabled
                        value={data.university_id.toString()}
                    // options={universityArr}
                    >
                        {universityArr.map((option) => (
                            <Option key={option.value} value={option.value}>
                                {renderOptionUniversity(option)}
                            </Option>
                        ))}
                    </Select>
                </label>
                <label>
                    <p>Professor's Full Name</p>
                    <Input
                        value={data.professor_name}
                        onChange={(ev) => {
                            let obj = { ...data };
                            obj.professor_name = ev.target.value;
                            setData(obj);
                        }}
                    />
                </label>

                <label>
                    <p>Department</p>
                    <Input
                        value={data.department_name}
                        onChange={(ev) => {
                            let obj = { ...data };
                            obj.department_name = ev.target.value;
                            setData(obj);
                        }}
                    />
                </label>
                <label>
                    <p>Select course</p>
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        options={coursesArr}
                        value={selectedCourseArr}
                        disabled={selectedCourse}
                        onChange={(ev) => {
                            setSelectedCourseArr(ev)
                            let obj = { ...data }
                            obj.courses = ev
                            setData(obj)

                        }}
                    />
                </label>
                <label className="checkBox">
                    <input
                        type="checkbox"
                        checked={agreeTerms}
                        onChange={(ev) => {
                            setAgreeTerms(ev.target.checked);
                        }}
                    />
                    <p>I agree to the Terms of Use and Privacy Policy</p>
                </label>
            </div>
            <button
                disabled={loader}
                onClick={() => {
                    addProfessorFn();
                }}
            >
                {loader ? <Spinner /> : `Add Professor`}
            </button>
        </div>
    )
}

export default FormDiv