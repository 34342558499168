import React, { useEffect, useState } from 'react'
import CanvasJSReact from '@canvasjs/react-charts';
// import { PieChart } from 'recharts';
import { PieChart } from '@mui/x-charts/PieChart';
import { useSelector } from 'react-redux';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
function OutcomesGraph({
    outcomes
}) {
    // const options = {
    //     animationEnabled: true,
    //     // title: {
    //     //     text: "Outcomes"
    //     // },
    //     subtitles: [{
    //         text: "71% Overall rating",
    //         verticalAlign: "center",
    //         fontSize: 24,
    //         dockInsidePlotArea: true
    //     }],
    //     data: [{
    //         type: "doughnut",
    //         // showInLegend: true,
    //         indexLabel: "{name}: {y}",
    //         yValueFormatString: "#,###'%'",
    //         dataPoints: [
    //             { name: "Unsatisfied", y: 5 },
    //             { name: "Very Unsatisfied", y: 31 },
    //             { name: "Very Satisfied", y: 40 },
    //             { name: "Satisfied", y: 17 },
    //             { name: "Neutral", y: 7 }
    //         ]
    //     }
    // ],
    // indexLabelFontSize: 26,
    // }
    // return (
    //     <CanvasJSChart options={options}
    //     /* onRef={ref => this.chart = ref} */
    //     />
    // )
    // const [data, setData] = [
    //     { id: 0, value: 10, label: 'series A' },
    //     { id: 1, value: 15, label: 'series B' },
    //     { id: 2, value: 20, label: 'series C' },
    //     { id: 3, value: 10, label: 'series D' },
    //     { id: 4, value: 15, label: 'series E' },
    //     { id: 5, value: 20, label: 'series F' },
    // ];
    // useEffect(() => {
    //     let arr = []
    //     if (outcomes.length) {
    //         outcomes.map((a, i) => {
    //             arr.push({
    //                 id: i,
    //                 value: a.repeated,
    //                 label: a.label
    //             })
    //         })
    //     }
    //     setData(arr)
    // }, [outcomes])
    const [data, setData] = useState([])
    useEffect(() => {
        let arr = []
        console.log(outcomes,'getSingleUserOutcomeResultgetSingleUserOutcomeResult')
        if (outcomes?.length) {
            outcomes.map((a, i) => {
                arr.push({
                    id: i,
                    value: a?.repeated || 1,
                    label: a?.outcome?.name
                })
            })
        }
        console.log(arr, 'arrrrarrr')
        setData(arr)
    }, [outcomes])

    return (
        // <div className='pieChartDiv'>

        <PieChart
            series={[
                {
                    data,
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                    innerRadius: 30,
                    outerRadius: 100,
                    paddingAngle: 5,
                    cornerRadius: 5,
                    startAngle: -90,
                    endAngle: 300,

                },
            ]}
            height={300}
        />
        // </div>

    )
}

export default OutcomesGraph