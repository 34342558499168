import React, { useEffect, useState } from "react";
import ProfessorRatingNavbar from "../Navbar/professorRatingNavbar";
import "./addForm.scss";
import { Input, Select } from "antd";
import { countriesArr } from "../../Constant/Utils";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../Constant/Spinner/Spinnner";
import { addUniversity } from "../../module/features/university";
import bg from './../../Assest/addFormBackground.png'

function AddUniversityLanding() {
const { Option } = Select;
const universityList = useSelector(
    (state) => state.universitySlice.universityList
  );
  const [universityArr, setUniversityArr] = useState([]);
  const [statesArr, setStatesArr] = useState([]);
  const [loader, setLoader] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    geo_point: "",
    geo_shape: "",
    IPEDSID: 0,
    NAME: "",
    ADDRESS: "",
    CITY: "",
    STATE: "",
    ZIP: "",
    ZIP4: "",
    TELEPHONE: "",
    TYPE: "",
    STATUS: "",
    POPULATION: 0,
    COUNTY: "",
    COUNTYFIPS: "",
    COUNTRY: "",
    LATITUDE: 0,
    LONGITUDE: 0,
    NAICS_CODE: "",
    NAICS_DESC: "",
    SOURCE: "",
    SOURCEDATE: "",
    VAL_METHOD: "",
    VAL_DATE: "",
    WEBSITE: "",
    STFIPS: "",
    COFIPS: "",
    SECTOR: "",
    LEVEL_: "",
    HI_OFFER: "",
    DEG_GRANT: "",
    LOCALE: "",
    CLOSE_DATE: "",
    MERGE_ID: 0,
    ALIAS: "",
    SIZE_SET: "",
    INST_SIZE: "",
    PT_ENROLL: 0,
    FT_ENROLL: 0,
    TOT_ENROLL: 0,
    HOUSING: "",
    DORM_CAP: 0,
    TOT_EMP: 0,
    SHELTER_ID: 0,
  });
  const callback = () => {
    setData({
      geo_point: "",
      geo_shape: "",
      IPEDSID: 0,
      NAME: "",
      ADDRESS: "",
      CITY: "",
      STATE: "",
      ZIP: "",
      ZIP4: "",
      TELEPHONE: "",
      TYPE: "",
      STATUS: "",
      POPULATION: 0,
      COUNTY: "",
      COUNTYFIPS: "",
      COUNTRY: "",
      LATITUDE: 0,
      LONGITUDE: 0,
      NAICS_CODE: "",
      NAICS_DESC: "",
      SOURCE: "",
      SOURCEDATE: "",
      VAL_METHOD: "",
      VAL_DATE: "",
      WEBSITE: "",
      STFIPS: "",
      COFIPS: "",
      SECTOR: "",
      LEVEL_: "",
      HI_OFFER: "",
      DEG_GRANT: "",
      LOCALE: "",
      CLOSE_DATE: "",
      MERGE_ID: 0,
      ALIAS: "",
      SIZE_SET: "",
      INST_SIZE: "",
      PT_ENROLL: 0,
      FT_ENROLL: 0,
      TOT_ENROLL: 0,
      HOUSING: "",
      DORM_CAP: 0,
      TOT_EMP: 0,
      SHELTER_ID: 0,
    });
    setLoader(false);
  };
  useEffect(() => {
    let arr = universityList.map((a, i) => {
      return {
        value: `${a.OBJECTID}`,
        label: `${a.NAME}`,
        ...a,
      };
    });
    setUniversityArr(arr);
  }, [universityList]);
  const addUniversityFn = () => {
    setLoader(true);
    console.log(data);
    dispatch(addUniversity({ data: data, callback: callback }));
  };
  const onChange = (value) => {
    let obj = { ...data };
    obj.university_OBJECTID = value;
    setData(obj);
    console.log(`selected ${value} `);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
    const renderOptionUniversity = (option) => {
      // Customize the rendering of each option
      return (
        <div className="universityListOpt" onClick={() => console.log(option)}>
          <div>
            <span style={{ marginRight: "8px" }}>{option.label}</span>
            <div>
              <span className="location">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M256 32C167.67 32 96 96.51 96 176c0 128 160 304 160 304s160-176 160-304c0-79.49-71.67-144-160-144zm0 224a64 64 0 1 1 64-64 64.07 64.07 0 0 1-64 64z"></path>
                </svg>
                &nbsp;
                {option.CITY ? option.CITY + ", " : ""}{" "}
                {option.COUNTRY ? option.COUNTRY : ""}
              </span>
            </div>
          </div>
        </div>
      );
    };
  return (
    <div className="addFormPage">
      <div className="addForm">
        {/* <p className='subAddHeading'>Please use the search bar above to make sure that the professor does not already exist at this school.</p> */}
        <div className="addFormDiv">
          <div className="innerAddForm">
            <h1>Add a University</h1>
            <p>Please use the search bar above to make sure that the school does not already exist.</p>
            {/* <p className='subAddHeading'>Please use the search bar above to make sure that the professor does not already exist at this school.</p> */}
            <div className="addFormInner">
              <label>
                <p>Name of School</p>
                <Select
                  showSearch
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={filterOption}
                // options={universityArr}
                >
                  {universityArr.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {renderOptionUniversity(option)}
                    </Option>
                  ))}
                </Select>
              </label>
              <label>
                <p>Country</p>
                <Select
                  value={data.COUNTRY}
                  onChange={(value, option) => {
                    setStatesArr(option.states);
                    let obj = { ...data };
                    obj.COUNTRY = value;
                    obj.STATE = "";
                    setData(obj);
                  }}
                  options={countriesArr}
                />
              </label>
              <label>
                <p>State/Province</p>
                <Select
                  value={data.STATE}
                  onChange={(value) => {
                    let obj = { ...data };
                    obj.STATE = value;
                    setData(obj);
                  }}
                  options={statesArr}
                />
              </label>
              <label>
                <p>City</p>
                <Input
                  value={data.CITY}
                  onChange={(ev) => {
                    let obj = { ...data };
                    obj.CITY = ev.target.value;
                    setData(obj);
                  }}
                />
              </label>
              <label>
                <p>Website</p>
                <Input
                  value={data.WEBSITE}
                  onChange={(ev) => {
                    let obj = { ...data };
                    obj.WEBSITE = ev.target.value;
                    setData(obj);
                  }}
                />
              </label>
              <label className="checkBox">
                <input
                  type="checkbox"
                  checked={agreeTerms}
                  onChange={(ev) => {
                    setAgreeTerms(ev.target.checked);
                  }}
                />
                <p>I agree to the Terms of Use and Privacy Policy</p>
              </label>
            </div>
            <button
              disabled={loader}
              onClick={() => {
                addUniversityFn();
              }}
            >
              {loader ? <Spinner /> : `Add University`}
            </button>
          </div>
          <img className="backgroundForm" src={bg} />

        </div>
      </div>
    </div>
  );
}

export default AddUniversityLanding;
