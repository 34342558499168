import React from 'react'
import './CompetencyBox.scss'
import Communication from './../../Assest/commincationSkills.png'
import { daysLeft, isDurationCompleted } from '../Utils'

function CompetencyBox({ data, onClick, score }) {

  return (
    <div className='CompetenciesBox' style={data?.PostscoreIndividual || (isDurationCompleted(data?.duration, data?.createdAt) && data?.score && !data?.PostscoreIndividual) ? { cursor: 'not-allowed' } : onClick ? {
      cursor: 'pointer'
    } : {}} onClick={data?.PostscoreIndividual || (isDurationCompleted(data?.duration, data?.createdAt) && data?.score && !data?.PostscoreIndividual) ? null : onClick} >
      <img src={Communication} />
      <p>{data?.name}</p>
      {
        data?.score ?
          <>
            <p className='score'>Your Pre-Score: {data?.score.toFixed(2)}</p>
          </>
          : null
      }
      {
        data?.PostscoreIndividual ?
          <>
            <p className='score'>Your Post-Score: {data?.PostscoreIndividual.toFixed(2)}</p>
          </>
          : isDurationCompleted(data?.duration, data?.createdAt) && data?.score && !data?.PostscoreIndividual ?
            <>
              <p className='score'>{daysLeft(data?.duration, data?.createdAt)}</p>
            </>
            : null
      }
    </div >
  )
}

export default CompetencyBox