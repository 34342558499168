import { Modal, Select } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../Constant/Spinner/Spinnner";

import {
  editCompetency,
  getCompetencies,
  getCompetencybyId,
} from "../../module/features/competency";
import DraftEditor from "./Editor/DraftEditor";

function AddDescriptionModal({
  isModalOpen,
  handleOk,
  handleCancel,
  editState,
  status,
}) {
  const dispatch = useDispatch();
  const url = new URL(window.location);
  const assessmentId = url.searchParams.get("id");
  const [data, setData] = useState({
    // id: Number(assessmentId),
    description: "",
  });
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setData({
      // id: Number(assessmentId),
      description: "",
    });
  }, [status]);
  const clearState = () => {
    setData({
      // id: Number(assessmentId),
      description: "",
    });
  };
  const callback = () => {
    clearState();
    handleOk();
    let data = {
      id: assessmentId,
    };
    dispatch(getCompetencybyId(data));
  };
  const addAssesmentFun = () => {
    dispatch(editCompetency({ data: data, id: assessmentId, callback }));
  };

  const editAssesmentFun = () => {
    dispatch(editCompetency({ data: data, id: assessmentId, callback }));

    // dispatch(
    //   editAssessments({ data: data, id: editState.id, callback, setLoader })
    // );
  };
  useEffect(() => {
    if (editState) {
      setData({
        // id: Number(assessmentId),
        description: editState.description,
      });
    } else {
      clearState();
    }
  }, [editState]);

  return (
    <Modal
      className="addCompetencyModal"
      title=""
      width={800}
      open={isModalOpen}
      footer={false}
      onCancel={() => {
        handleCancel();
        clearState();
      }}
    >
      <div className="form">
        <label className="addCourseInput questionInput">
          <p>Description </p>
          {/* <textarea
            rows={4}
            placeholder=" write your descrition here"
            value={data.description}
            onChange={(ev) => {
              let obj = { ...data };
              obj.description = ev.target.value;
              setData(obj);
            }}
          ></textarea> */}
          <DraftEditor editState={editState} value={data.description} onChange={(value) => {
              let obj = { ...data };
              obj.description =value;
              setData(obj);
            }}/>
        </label>
      </div>
      <div className="footer">
        <button className="addCompetencyButton cancel" onClick={handleCancel}>
          Cancel
        </button>
        {editState ? (
          <button
            className="addCompetencyButton add"
            onClick={editAssesmentFun}
          >
            {loader ? <Spinner /> : `Edit Description`}
          </button>
        ) : (
          <button className="addCompetencyButton add" onClick={addAssesmentFun}>
            {loader ? <Spinner /> : `Add Description`}
          </button>
        )}
      </div>
    </Modal>
  );
}

export default AddDescriptionModal;
