import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { useRef } from 'react';
import './Assesment.scss'
import { useDispatch, useSelector } from 'react-redux';
import CompetencyBox from '../../Constant/Competency/CompetencyBox';
import NotAdded from '../../Constant/NotAdded';
import { getQuery } from '../../Constant/Utils';
import { addAssessmentsAnswers, getAssessments, getanswers } from '../../module/features/assesments';
import { Spin } from 'antd';
import Answers from '../../Constant/Answers';
import { AllStudentAverageResult } from '../../module/features/competency';
import CourseCompetency from './CourseCompetency';

const QuestionsDiv = ({
    rerender,
    assessments,
    competencyId,
    activeIndex,
    backButton
}) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const user = useSelector(state => state.Authentication.user)
    const courseCompetencies = useSelector(state => state.competencySlice.selectedCourseCompetencies)
    const assesmentType = useSelector(state => state.assessmentSlice.assesmentType)
    const assesmentCompleted = useSelector(state => state.assessmentSlice.assesmentCompleted)


    const [questions, setQuestions] = useState([])
    const swiperRef = useRef(null);

    const handleNextButtonClick = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const [activeQuestions, setActiveQuestions] = useState(1)

    useEffect(() => {
        setActiveQuestions(activeIndex + 1)
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(activeIndex);
        }
    }, [activeIndex])

    const handleSlideChange = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setActiveQuestions(swiperRef.current.swiper.activeIndex + 1);
            // You can perform additional actions based on the active slide index
        }
    };

    useEffect(() => {
        setQuestions(assessments)
    }, [assessments])

    const callback = (index) => {
        if (index === questions?.length - 1) {
            setSubmitted(true)
        } else {
            handleNextButtonClick()
        }
    }




    const addAssesmentFun = (data, question, index) => {
        let professorId = getQuery('professorId')
        let university_id = getQuery('university_id')
        let course_id = getQuery('course_id')
        // callback(index)
        const answerObj = {
            "question_id": question.id,
            "answer": String(data.value),
            "key": question.key,
            "competency_id": question.competency_id,
            "assessmentType": assesmentType,
            "professor_id": Number(professorId),
            "course_id": course_id,
            "university_id": university_id,
            "completed": index === questions?.length - 1 ? true : false
        }
        dispatch(addAssessmentsAnswers({ data: answerObj, callback, setLoader, index }))
    }



    return (
        <div className='questionsDiv'>
            {submitted === true || assesmentCompleted ?
                null
                :
                <div className='question'>
                    <h2>Questions</h2>
                    <span>(Step {activeQuestions} of {questions?.length})</span>
                </div>
            }

            <div className='innerquestionsDiv'>
                {assessments.length ?

                    <>
                        {assesmentCompleted ?
                            <>
                                <div className='assesmentText'>
                                    <h4>You already have submitted this competency test</h4>
                                    <button className='hoverEffect' onClick={backButton}>
                                        <span><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"></path></svg></span>
                                        Get Back To Other Assesments</button>
                                    {/* <Spin size="large" /> */}
                                </div>
                            </>
                            :
                            <>
                                {submitted === true ?
                                    <div className='assesmentText'>
                                        <h4>Your Assesment Has Been Submitted</h4>
                                        <button className='hoverEffect' onClick={backButton}>
                                            <span><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"></path></svg></span>
                                            Get Back To Other Assesments</button>
                                        {/* <Spin size="large" /> */}
                                    </div>
                                    :
                                    <Swiper navigation={false} draggable={false} allowTouchMove={false} modules={[Navigation]} initialSlide={activeIndex} className="mySwiper" ref={swiperRef} onSlideChange={handleSlideChange}
                                    >
                                        {questions.map((a, i) => {
                                            return (

                                                <SwiperSlide className='SwiperSlide'>
                                                    <div className='upperSwiperSlide'>
                                                        <div className='innnerSwiperSlide'>
                                                            <div className='questionDiv'>
                                                                <h3>{a.question}</h3>
                                                            </div>

                                                            <Answers addAssesmentFun={addAssesmentFun} keyed={a.key} loader={loader} question={a} index={i} />

                                                        </div>
                                                    </div>

                                                </SwiperSlide>
                                            )

                                        })}


                                    </Swiper>
                                }
                            </>
                        }
                    </>

                    :
                    <>
                        <div className='assesmentText'>
                            <h4>Assesment not uploaded yet</h4>
                            <button className='hoverEffect' onClick={backButton}>
                                <span><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"></path></svg></span>
                                Get Back To Other Assesments</button>
                            {/* <Spin size="large" /> */}
                        </div>
                    </>
                }
                {/* <button className='next hoverEffect' onClick={handleNextButtonClick}>Submit</button> */}
            </div>
        </div>
    )
}

function Assesment() {
    const courseCompetencies = useSelector(state => state.competencySlice.selectedCourseCompetencies)
    const competenciesWithCourse = useSelector(state => state.competencySlice.selectedCourseCompetenciesWithCourse)
    const AllCompetencieResult = useSelector(state => state.competencySlice.AllCompetencieResult)
    const [selectedAssesment, setSelectedAssesment] = useState('')
    const userProfile = useSelector(state => state.completeProfileSlice.userProfile)
    const [rerender, setRerender] = useState(false)
    const navigate = useNavigate()
    const user = useSelector(state => state.Authentication.user)
    const competencies = useSelector(state => state.competencySlice.competencies)
    const dispatch = useDispatch()
    const assessments = useSelector(state => state.assessmentSlice.assessments)
    const answers = useSelector(state => state.assessmentSlice.answers)
    const assessmentsLoader = useSelector(state => state.assessmentSlice.assessmentsLoader)
    const [competencyId, setCompetencyId] = useState('')
    const [unansweredArr, setUnansweredArr] = useState([])
    const [activeIndex, setactiveIndex] = useState(0)
    const [courseCompetenciesArr, setCourseCompetenciesArr] = useState([])
    const [tierId, setTierId] = useState(0)

    useEffect(() => {
        let assesmentId = getQuery('assesmentId')
        setCompetencyId(assesmentId)
        if (assesmentId) {
            dispatch(getAssessments({ id: assesmentId }))
        }
    }, [rerender])
    const getResult = () => {
        let arr = []
        courseCompetenciesArr.map((course, i) => {
            course.competencies.map((competency, j) => {
                if (!arr.includes(competency.id)) arr.push(competency.id)
            })
        })
        console.log(arr,'arrr')
        dispatch(AllStudentAverageResult({
            "userId": userProfile.id,
            "competencyIds": arr
        }))
    }


    const backButton = () => {
        if (competencyId) {
            navigate(`/dashboard/assesment`)
            setRerender(!rerender)
            getResult()
        } else {
            window.history.back()
            setRerender(!rerender)
        }
    }



    useEffect(() => {
        if (courseCompetenciesArr.length && userProfile) {

            getResult()
        }
    }, [courseCompetenciesArr, userProfile])



    const handleBackButton = () => {
        // Your custom function to be called when the user clicks the back button
        setRerender(!rerender)
    };

    useEffect(() => {
        const handlePopstate = () => {
            // The popstate event is triggered when the user navigates back or forward
            setRerender(!rerender)
            handleBackButton();
        };

        // Attach the event listener when the component mounts
        window.addEventListener('popstate', handlePopstate);

        // Detach the event listener when the component unmounts
        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, [rerender]);

    useEffect(() => {
        if (user) {
            let assesmentId = getQuery('assesmentId');
            if (assesmentId) {

                dispatch(getanswers({
                    "userId": user.id,
                    "competencyId": assesmentId
                }))
            }
        }
    }, [user])

    useEffect(() => {
        console.log(answers, 'answers')
        if (answers || assessments) {
            if (answers?.length > 0) {

                let arr = assessments.map((a, i) => {
                    let answer = answers.filter(obj => Number(a.id) === Number(obj.question_id))?.length ? true : false
                    if (answer) {
                        setactiveIndex(i + 1)
                    }
                    return {
                        ...a,
                        answer
                    }
                })
                setUnansweredArr(arr)
            } else {
                setUnansweredArr(assessments)
            }
        }
    }, [answers, assessments])



    // useEffect(() => {
    //     console.log(AllCompetencieResult, 'AllCompetencieResult')
    //     if (competenciesWithCourse) {
    //         let minors = competenciesWithCourse?.minor ? competenciesWithCourse?.minor : []
    //         let major = competenciesWithCourse?.major ? competenciesWithCourse?.major : []
    //         let updatedArr = [];
    //         console.log([...minors, ...major], 'AllCompetencieResult');
    //         let arr = ([...minors, ...major]).map((course, i) => {
    //             if (!updatedArr.includes(course)) {
    //                 return {
    //                     course: course.course,
    //                     competencies: course.competencies.map((competecy, k) => {
    //                         return AllCompetencieResult.map((comptencyResult, j) => {
    //                             if (competecy.id === comptencyResult.competency_id) {
    //                                 return {
    //                                     ...competecy,
    //                                     'PostscoreIndividual': competecy.post_score,
    //                                     'score': competecy.pre_score,
    //                                 }
    //                                 // let obj = {
    //                                 //     ...a,
    //                                 //     competencies: {
    //                                 //         ...a.competencies[0],
    //                                 //         'PostscoreIndividual': a.competencies[0].post_score,
    //                                 //         'score': a.competencies[0].pre_score,
    //                                 //     }
    //                                 // }
    //                             }
    //                             else {
    //                                 return {
    //                                     ...competecy
    //                                 }
    //                             }
    //                         })
    //                     })
    //                 }


    //             }
    //         })
    //         console.log(arr, 'arrarrarrarr')
    //         setCourseCompetenciesArr(updatedArr)
    //     }
    // }, [competenciesWithCourse, AllCompetencieResult])

    useEffect(() => {
        console.log(competenciesWithCourse, 'competenciesWithCourse')
        if (competenciesWithCourse) {
          let minors = competenciesWithCourse?.minor ? competenciesWithCourse?.minor : []
          let major = competenciesWithCourse?.major ? competenciesWithCourse?.major : []
          let updatedArr = [];
          // let arr = []
            ([...minors, ...major]).map((a, i) => {
              a.course_competencies?.map((b) => {
                let data = { competencies: [{ ...b.competencies, courseId: b.course.id }] } 
                console.log(data,updatedArr,'asdsadasdsadsadsadasd')
                if (!updatedArr.filter(obj=>obj.competencies[0].id === data.competencies[0].id).length) {
                updatedArr.push(data) 
                }
              })
              //   updatedArr.push(data)
            })
          console.log(updatedArr, 'updatedArrupdatedArr')
          setCourseCompetenciesArr(updatedArr)
        }
      }, [competenciesWithCourse])
    


    return (
        <div className='Assesment'>
            <div className='PageHeading'>
                <h1>
                    {competencyId ?
                        <span onClick={backButton}><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"></path></svg></span>
                        : null}
                    &nbsp;Assesment</h1>
                <button onClick={() => {
                    // setIsModalOpen(true)
                    navigate(`/dashboard/Profile?tab=Courses`)
                }} className='hoverEffect'>New Course <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8Z"></path><path d="M192 474h672q8 0 8 8v60q0 8-8 8H160q-8 0-8-8v-60q0-8 8-8Z"></path></svg></button>
                {/* <button className='hoverEffect' onClick={() => {
                    Navigate('/dashboard/assesment')
                }}>Take Assessment <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8Z"></path><path d="M192 474h672q8 0 8 8v60q0 8-8 8H160q-8 0-8-8v-60q0-8 8-8Z"></path></svg></button> */}
            </div>
            {competencyId ?
                <>

                    {assessmentsLoader ?
                        <div className='assesmentText'>
                            <h4>Your assesment is about to start</h4>
                            <Spin size="large" />
                        </div>
                        :
                        <QuestionsDiv rerender={rerender} assessments={assessments} competencyId={competencyId} activeIndex={activeIndex} backButton={backButton} />
                    }
                </>
                :
                // courseCompetenciesArr && courseCompetenciesArr.length ? courseCompetenciesArr?.map((a, i) => {
                    // return a.competencies.length ? (
                        <CourseCompetency courseCompetencies={courseCompetenciesArr} rerender={rerender} setRerender={setRerender} course={{}} />
                    // ) : null
                // })
                    // : null
            }


        </div>
    )
}

export default Assesment