import React from 'react'
import profile from './../../Assest/profile.jpg'
import './Setting.scss'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useState } from 'react'

function Setting() {
    const navigate = useNavigate()
    const userProfile = useSelector(state => state.completeProfileSlice.userProfile)
    const user = useSelector(state => state.Authentication.user)
    const [bio, setBio] = useState(false)
    useEffect(() => {
        if (userProfile) {
            setBio(userProfile.bio)
        }

    }, [userProfile])

    return (
        <div className='Setting'>
            <div className='innerSetting'>
                <div className='PageHeading dontWrap'>
                    <h1>Settings</h1>
                    <svg onClick={() => { navigate('/dashboard/EditSetting') }} className='editSetting' stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                </div>
                <div className='userDetail'>
                    <h1>{userProfile?.profile?.fullname}</h1>
                    <p className='occupation'>{userProfile?.type}</p>
                    <img className='image' src={profile} />

                    <div className='form'>
                        <label className='bio'>
                            <p>Bio</p>
                            <textarea placeholder='Input Text' rows={6} defaultValue={`Bio`} value={bio} disabled></textarea>
                        </label>
                        <label>
                                <p>Profile</p>
                                <div className='profileLinkDiv'>
                                    <input placeholder='You Profile Link' disabled value={userProfile?.profile?.profileUrl} />
                                    <svg style={{ cursor: 'pointer' }} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"></path></svg>
                                </div>
                            </label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Setting