import React from 'react'
import {
    LoginSocialGoogle,
    LoginSocialAmazon,
    LoginSocialFacebook,
    LoginSocialGithub,
    LoginSocialInstagram,
    LoginSocialLinkedin,
    LoginSocialMicrosoft,
    LoginSocialPinterest,
    LoginSocialTwitter,
    LoginSocialApple,
    IResolveParams,
} from 'reactjs-social-login';

import {
    FacebookLoginButton,
    GoogleLoginButton,
    GithubLoginButton,
    AmazonLoginButton,
    InstagramLoginButton,
    LinkedInLoginButton,
    MicrosoftLoginButton,
    TwitterLoginButton,
    AppleLoginButton,
} from 'react-social-login-buttons';
import { useCallback } from 'react';
import { useState } from 'react';
import { getGoogleUser, login, loginSocial } from '../../module/features/authentication';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { Tooltip } from 'antd'

// import CustomGoogleButton from './costumGoogleButton';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";


// import { GoogleLogin, useGoogleOneTapLogin, useGoogleLogin, googleLogout } from '@react-oauth/google';



// const GoogleAuthProvider = () => (

// );
function SocialLogin({ type }) {

    const navigate = useNavigate()

    const [provider, setProvider] = useState('');
    const [profile, setProfile] = useState();
    const [socialLogin, setSocialLogin] = useState({})
    const dispatch = useDispatch()

    const { linkedInLogin } = useLinkedIn({
        clientId: process.env.REACT_APP_LINKEDIN_APP_ID,
        redirectUri: process.env.REACT_APP_LOCAL_REDIRECT_URL, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
        scope: "openid profile email",
        onSuccess: (code) => {
            console.log(code)
        },
        onError: (error) => {
            console.log(error)
        },
    });

    const onLoginStart = useCallback((e) => {
        console.log(e)
        // setSocialLogin(profile)

    }, []);

    const callback = () => {
        window.location.href = "/dashboard"

    }

    const onLoggedIn = (data, platform) => {
        if (platform === 'google') {
            let obj = {
                "username": data?.name,
                "email": data.email,
                "social_id": data.sub,
                "platform": platform,
                // "type": type


            }
            console.log(obj)
            dispatch(loginSocial({ data: obj, callback: callback }))
        }
    }
    // {
    //     "access_token": "ya29.a0AfB_byA9xpvnlp0j6JqXoe9LCKbulyxBhiObG21SgNoIViGqNMjeI3tOycdHAP2f5UVTyG4eTvuJ-Q9IFYRa1Llx3gec2X5BVb0y30DnW_ztvIh_EwfMuc7iRRfkRCVNyC7p4rlXqeaHtKC4JP-LDwfA4hxKGw8RnwaCgYKASESARESFQGOcNnC6WfCiYNq8oU2JhTJ9gJgeg0169",
    //     "token_type": "Bearer",
    //     "expires_in": 3599,
    //     "scope": "email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid",
    //     "authuser": "0",
    //     "prompt": "none",
    //     "sub": "102845249730172394703",
    //     "name": "Saqib Yousuf",
    //     "given_name": "Saqib",
    //     "family_name": "Yousuf",
    //     "picture": "https://lh3.googleusercontent.com/a/ACg8ocL4MO7IskiCHfUjlC97aDj9fAW-miSF1GaUibCOAhaDStyb=s96-c",
    //     "email": "saqibyousuf001@gmail.com",
    //     "email_verified": true,
    //     "locale": "en"
    // }
    const onLogoutSuccess = useCallback(() => {
        setProfile(null);
        setProvider('');
        alert('logout success');
    }, []);

    const onLogout = useCallback(() => { }, []);



    const GoogleLoginComponent = () => (
        <GoogleLogin
            onSuccess={(credentialResponse) => {
                const decoded = jwtDecode(credentialResponse.credential);
                console.log(decoded);
                onLoggedIn(decoded, 'google')
            }}
            onError={() => {
                console.log('Login Failed');
            }}
        />
    );

    // const GoogleOneTapLoginComponent = () => {
    //     useGoogleOneTapLogin({
    //         onSuccess: (credentialResponse) => {
    //             console.log(credentialResponse);
    //         },
    //         onError: () => {
    //             console.log('Login Failed');
    //         },
    //     });

    //     return <div>Your One Tap Login component</div>;
    // };

    // const GoogleLoginWithAutoSelect = () => (
    //     <GoogleLogin
    //         onSuccess={(credentialResponse) => {
    //             console.log(credentialResponse);
    //         }}
    //         onError={() => {
    //             console.log('Login Failed');
    //         }}
    //         auto_select
    //     />
    // );

    // const GoogleLogoutComponent = () => {
    //     googleLogout();
    //     return <div>Logging out...</div>;
    // };

    // const CustomGoogleButton = () => {
    //     const login = useGoogleLogin({
    //         onSuccess: (tokenResponse) => console.log(tokenResponse),

    //     });

    //     return <button onClick={() => login()}>Sign in with Google 🚀</button>;
    // };

    //     const CustomGoogleButtonWithAuthCode = () => {
    //         const login = useGoogleLogin({
    //             onSuccess: (codeResponse) => console.log(codeResponse),
    //             flow: 'auth-code',
    //         });

    //         return <button onClick={() => login()}>Sign in with Google 🚀</button>;
    //     };

    //       const handleGoogleLogin = (userData) => {
    //     console.log('Google login successful', userData);
    //   };

    return (
        <>
            <div className='socialLogin'>
                <h3 className='socialLoginHeading'></h3>
                <div className='socialLoginButtons'>
                    {/* <GoogleAuthProvider /> */}
                    <GoogleOAuthProvider clientId="85022104920-hgc28k8mk2p891itc37chlrms4koag5c.apps.googleusercontent.com">
                        <GoogleLoginComponent />
                    </GoogleOAuthProvider>
                    {/* <button type="button"
                        onClick={linkedInLogin}
                        style={{
                            display: 'block',
                            border: '0px',
                            borderRadius: '3px',
                            boxShadow: 'rgba(0, 0, 0, 0.5) 0px 1px 2px',
                            color: 'rgb(255, 255, 255)',
                            cursor: 'pointer',
                            fontSize: '19px',
                            margin: '5px',
                            width: 'calc(100% - 10px)',
                            overflow: 'hidden',
                            padding: '0px 10px',
                            userSelect: 'none',
                            height: '50px',
                            background: 'rgb(26, 129, 185)'
                        }}><div style={{
                            alignItems: 'center',
                            display: 'flex',
                            height: '100%'
                        }}>
                            <div style={{ display: 'flex', justifyContent: 'center', minWidth: '26px' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 430.117 430.117">
                                    <g>
                                        <path d="M430.117,261.543V420.56h-92.188V272.193c0-37.271-13.334-62.707-46.703-62.707   c-25.473,0-40.632,17.142-47.301,33.724c-2.432,5.928-3.058,14.179-3.058,22.477V420.56h-92.219c0,0,1.242-251.285,0-277.32h92.21   v39.309c-0.187,0.294-0.43,0.611-0.606,0.896h0.606v-0.896c12.251-18.869,34.13-45.824,83.102-45.824   C384.633,136.724,430.117,176.361,430.117,261.543z M52.183,9.558C20.635,9.558,0,30.251,0,57.463   c0,26.619,20.038,47.94,50.959,47.94h0.616c32.159,0,52.159-21.317,52.159-47.94C103.128,30.251,83.734,9.558,52.183,9.558z    M5.477,420.56h92.184v-277.32H5.477V420.56z" fill="#FFFFFF" />
                                    </g>
                                </svg>
                            </div>
                            <div style={{ width: '10px' }} />
                            <div style={{ textAlign: 'left', width: '100%' }}>
                                Log in with LinkedIn</div>
                        </div>
                    </button> */}
                    {/* <LoginSocialLinkedin
                        isOnlyGetCode
                        client_id={process.env.REACT_APP_LINKEDIN_APP_ID || ''}
                        client_secret={process.env.REACT_APP_LINKEDIN_APP_SECRET || ''}
                        redirect_uri={process.env.REACT_APP_LOCAL_REDIRECT_URL || ''}
                        scope="openid profile email"
                        onLoginStart={onLoginStart}
                        response_type='code'
                        onResolve={({ provider, data, ...props }) => {
                            console.log(data, 'datadatadata')
                            setProvider(provider);
                            setProfile(data);
                        }}
                        onReject={(err) => {
                            console.log(err);
                        }}
                        className='SocialloginButton'
                    > */}
                        {/* <LinkedInLoginButton onClick={linkedInLogin}/> */}
                    {/* </LoginSocialLinkedin>

                    <LoginSocialLinkedin
                        client_id={process.env.REACT_APP_LINKEDIN_APP_ID || ''}
                        client_secret={process.env.REACT_APP_LINKEDIN_APP_SECRET || ''}
                        redirect_uri={process.env.REACT_APP_LOCAL_REDIRECT_URL || ''}
                        scope="openid profile email"
                        onLoginStart={onLoginStart}
                        onResolve={({ provider, data }) => {
                            console.log(provider, data)
                            setProvider(provider);
                            setProfile(data);
                        }}
                        onReject={(err) => {
                            console.log(err);
                        }}
                    > */}
                        {/* <LinkedInLoginButton /> */}
                    {/* </LoginSocialLinkedin> */}

                    {/* <LoginSocialGoogle
                        // isOnlyGetToken={true}
                        client_id={process.env.REACT_APP_GOOGLE_APP_CLIENT_ID || ''}
                        onLoginStart={onLoginStart}
                        // redirect_uri={process.env.REACT_APP_REDIRECT_URL || ''}
                        scope="openid profile email"
                        // dccess_type="offline"
                        iscoveryDocs="claims_supported"
                        // a
                        onResolve={({ provider, data }) => {
                            onLoggedIn(data, provider)

                            setProvider(provider);
                            setProfile(data);
                            console.log("many times", data)
                            
                        }}
                        onReject={err => {
                            console.log(err);
                            console.log("hello");
                        }}
                    >
                        <GoogleLoginButton/>
                    </LoginSocialGoogle> */}
                </div>
            </div>
        </>
    )
}

export default SocialLogin