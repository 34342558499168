import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit'
import { message } from 'antd';
import axios from 'axios'
import { act } from 'react-dom/test-utils';
import { errorHandle, getCookie } from '../../Constant/Utils';

const initialState = {
    outcomes: [],
    loader: false,
    competenciesOutcome: [],
    outcomesLoader: false,
    scheduledOutcome: false,
    userScheduledOutcome: [],
    getSingleUserOutcomeResult: []
}

export const getOutcomes = createAsyncThunk(
    "outcomesSlice/getOutcomes",
    async (data) => {
        const token = getCookie('ungradeToken');
        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/admin/outcome`,
                headers: {
                    'authorization': token
                }
            };

            const response = await axios.request(config);
            return response.data.data; // Return the data here


        } catch (error) {
            console.error(error);
        }
    });

export const getOutcomeById = createAsyncThunk(
    "outcomesSlice/getOutcomeById",
    async (data) => {
        const token = getCookie('ungradeToken');
        try {
            console.log(data)
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/admin/outcome/${data?.ids}`,
                headers: { 'authorization': token },
                data: data
            };

            const response = await axios.request(config);
            return response.data.data; // Return the data here


        } catch (error) {
            console.error(error);
        }
    });


// export const getScheduledOutcome = createAsyncThunk(
//     "outcomesSlice/getScheduledOutcome",
//     async (data) => {
//         try {
//             console.log(data)
//             let config = {
//                 method: 'get',
//                 maxBodyLength: Infinity,
//                 url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}emailToStudents/get`,
//                 headers: {}
//             };

//             const response = await axios.request(config);
//             console.log(response.data, 'response.data')
//             if (response?.data?.data.length) {
//                 return response.data.data[0]; // Return the data here
//             }


//         } catch (error) {
//             console.error(error);
//         }
//     });


export const getUserOutcome = createAsyncThunk(
    "outcomesSlice/getUserOutcome",
    async (data) => {
        const token = getCookie('ungradeToken');
        try {
            console.log(data)
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/client/outcome/${data.ids}?user_id=${data.userId}`,
                headers: {
                    'authorization': token
                }
            };

            const response = await axios.request(config);
            if (response?.data?.data.length) {
                return response.data.data; // Return the data here
            }


        } catch (error) {
            // console.log(error.response.data, 'asdsadsadasdasd')
            // console.error(error);
            message.error(error.response.data?.message)
            setTimeout(() => {
                window.location.href = '/'
            }, 2000);
        }
    });

export const getSingleUserOutcome = createAsyncThunk(
    "outcomesSlice/getSingleUserOutcome",
    async (data) => {
        const token = getCookie('ungradeToken');
        try {
            console.log(data)
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/client/outcome/course/outcome/${data.courseId}`,
                headers: {
                    'authorization': token
                }
            };

            const response = await axios.request(config);
            console.log(response.data, 'userOutcome response.data')
            if (response?.data?.data) {
                return response.data.data; // Return the data here
            }


        } catch (error) {
            console.error(error);
        }
    });



export const outcomesSlice = createSlice({
    name: 'outcomesSlice',
    initialState,
    reducers: {
        addOutcomes: (state, action) => {
            let data = JSON.stringify(action.payload.data);
            const token = getCookie('ungradeToken');
            // state.loader = true
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/admin/outcome`,
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': token

                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    message.success("Outcome Added")
                    if (action.payload.callback) {
                        action.payload.callback()
                        // state.loader = false
                    }
                })
                .catch((error) => {
                    let errorMsg = error?.response?.data?.error  || error?.response?.data?.message || 'Please Try Again16'
                    message.error(errorMsg)
                    // state.loader = false

                });
        },
        updateOutcomes: (state, action) => {
            let data = JSON.stringify(action.payload.data);
            const token = getCookie('ungradeToken');
            state.loader = true
            let config = {
                method: 'patch',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/admin/outcome/${action.payload.id}`,
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': token
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    message.success("Outcome Updated")
                    if (action.payload.callback) {
                        action.payload.callback()
                        // state.loader = false
                    }
                })
                .catch((error) => {
                    let errorMsg = error?.response?.data?.error  || error?.response?.data?.message || 'Please Try Again17'
                    message.error(errorMsg)
                    // state.loader = false

                });
        },
        deleteOutcomes: (state, action) => {
            let data = JSON.stringify(action.payload.data);
            state.loader = true
            let config = {
                method: 'delete',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}outcomes/delete/${action.payload.id}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    message.success("Outcome Deleted")
                    if (action.payload.callback) {
                        action.payload.callback()
                        // state.loader = false
                    }
                })
                .catch((error) => {
                    let errorMsg = error?.response?.data?.error  || error?.response?.data?.message || 'Please Try Again18'
                    message.error(errorMsg)
                    // state.loader = false

                });
        },
        // sendOutcomesEmail: (state, action) => {
        //     let data = JSON.stringify(action.payload.data);
        //     state.loader = true
        //     let config = {
        //         method: 'post',
        //         maxBodyLength: Infinity,
        //         url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}emailToStudents/setTime`,
        //         headers: {
        //             'Content-Type': 'application/json'
        //         },
        //         data: data
        //     };

        //     axios.request(config)
        //         .then((response) => {
        //             message.success("Outcomes Scheduled")
        //             if (action.payload.callback) {
        //                 action.payload.callback()
        //                 // state.loader = false
        //             }
        //         })
        //         .catch((error) => {
        //             let errorMsg = error?.response?.data?.error  || error?.response?.data?.message || 'Please Try Again19'
        //             message.error(errorMsg)
        //             // state.loader = false

        //         });
        // },

        // updateSendOutcomesEmail: (state, action) => {
        //     let data = JSON.stringify(action.payload.data);
        //     state.loader = true
        //     let config = {
        //         method: 'put',
        //         maxBodyLength: Infinity,
        //         url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}emailToStudents/updateTime/${action.payload.id}`,
        //         headers: {
        //             'Content-Type': 'application/json'
        //         },
        //         data: data
        //     };

        //     axios.request(config)
        //         .then((response) => {
        //             message.success("Outcomes Scheduled")
        //             if (action.payload.callback) {
        //                 action.payload.callback()
        //                 // state.loader = false
        //             }
        //         })
        //         .catch((error) => {
        //             let errorMsg = error?.response?.data?.error  || error?.response?.data?.message || 'Please Try Again20'
        //             message.error(errorMsg)
        //             // state.loader = false

        //         });
        // },

        updateUserOutcome: (state, action) => {
            let data = JSON.stringify(action.payload.data);
            state.loader = true
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/client/user-outcome`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    message.success("Outcomes Scheduled")
                    if (action.payload.callback) {
                        action.payload.callback()
                        // state.loader = false
                    }
                })
                .catch((error) => {
                    console.log(error?.response)
                    // errorHandle(error)
                    let errorMsg = error?.response?.data?.error  || error?.response?.data?.message || 'Please Try Again21'
                    message.error(errorMsg)
                    // state.loader = false

                });
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOutcomes.pending, (state, action) => {
            })
            .addCase(getOutcomes.fulfilled, (state, action) => {
                state.outcomes = action.payload;
            })
            .addCase(getOutcomes.rejected, (state, action) => {
            })

            .addCase(getOutcomeById.pending, (state, action) => {
                state.outcomesLoader = true
            })
            .addCase(getOutcomeById.fulfilled, (state, action) => {
                state.competenciesOutcome = action.payload;
                state.outcomesLoader = false
            })
            .addCase(getOutcomeById.rejected, (state, action) => {
                state.outcomesLoader = false
            })

            .addCase(getUserOutcome.pending, (state, action) => {
                // state.outcomesLoader = true
            })
            .addCase(getUserOutcome.fulfilled, (state, action) => {
                state.userScheduledOutcome = action.payload;
                // state.outcomesLoader = false
            })
            .addCase(getUserOutcome.rejected, (state, action) => {
                // state.outcomesLoader = false
            })

            .addCase(getSingleUserOutcome.pending, (state, action) => {
                // state.outcomesLoader = true
            })
            .addCase(getSingleUserOutcome.fulfilled, (state, action) => {
                state.getSingleUserOutcomeResult = action.payload;
                // state.outcomesLoader = false
            })
            .addCase(getSingleUserOutcome.rejected, (state, action) => {
                // state.outcomesLoader = false
            })



    }
})

export const { addOutcomes, updateOutcomes, deleteOutcomes, sendOutcomesEmail, updateSendOutcomesEmail, updateUserOutcome } = outcomesSlice.actions

export default outcomesSlice.reducer