import React, { useEffect, useState } from 'react'

import profile from './../../Assest/profile.jpg'
import './Navbar.scss'
import { Drawer, Dropdown } from 'antd';
import Sidebar from '../Sidebar/Sidebar';
import { useRef } from 'react';
import { getUser, logout } from '../../module/features/authentication';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
function Navbar() {
    const [open, setOpen] = useState(false);
    const [searchActive, setSearchActive] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userProfile = useSelector(state => state.completeProfileSlice.userProfile)
    const user = useSelector(state => state.Authentication.user)
    const searchInput = useRef()
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const items = [
        {
            label: 'Setting',
            key: '0',
            onClick: () => {
                navigate('/dashboard/Setting')
            }
        },
        {
            label: `Edit Profile`,
            key: '1',
            onClick: () => {
                navigate('/dashboard/Profile?tab=General')

            }
        },
        {
            type: 'divider',
        },
        {
            label: 'Logout',
            key: '3',
            onClick: () => {
                logoutFunction()
            }
        },
    ];
    const logoutFunction = () => {
        const callback = () => {
            window.location.href = "/"
        }
        dispatch(logout({ callback }))
    }

    return (
        <>
            <div className='navbar'>
                <div className=''>
                    <button className='drawerButton' type="primary" onClick={showDrawer}>
                        <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 15 15" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3H1.5ZM1 7.5C1 7.22386 1.22386 7 1.5 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H1.5C1.22386 8 1 7.77614 1 7.5ZM1 11.5C1 11.2239 1.22386 11 1.5 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H1.5C1.22386 12 1 11.7761 1 11.5Z" fill="currentColor"></path></svg>
                    </button>
                </div>
                <div className='toolNav'>
                    {/* <label className={`searchLabel ${searchActive ? 'active' : ''}`}>
                        <input className='searchInput' placeholder='Search' ref={searchInput} />
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5z"></path></svg>
                    </label>
                    <div className='navButtons' onClick={() => {
                        if (!searchActive) {
                            searchInput.current.focus()
                        }
                        setSearchActive(!searchActive)
                    }}>
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                    </div>
                    <div className='navButtons'>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M4 4h16v12H5.17L4 17.17V4m0-2c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2H4zm2 10h12v2H6v-2zm0-3h12v2H6V9zm0-3h12v2H6V6z"></path></svg>
                    </div>
                    <Dropdown
                        menu={{
                            items,
                        }}
                        trigger={['click']}
                    >
                        <div className='navButtons'>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"></path></svg>
                        </div>
                    </Dropdown> */}
                    {user ?
                        <Dropdown
                            menu={{
                                items,
                            }}
                            trigger={['click']}
                        >
                            <div className='navButtons'>
                                <img src={userProfile?.profileUrl || profile} />
                            </div>
                        </Dropdown>
                        :
                        <div className='signInLink'>
                            <NavLink to="/login">Sign In</NavLink> / <NavLink to='/register'>Sign Up</NavLink>
                        </div>
                    }

                </div>
            </div>
            <Drawer className='drawer' title={false} placement="left" onClose={onClose} open={open}>
                <button className='drawerCancel' onClick={onClose}>
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" fillRule="evenodd" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M799.855 166.312c.023.007.043.018.084.059l57.69 57.69c.041.041.052.06.059.084a.118.118 0 0 1 0 .069c-.007.023-.018.042-.059.083L569.926 512l287.703 287.703c.041.04.052.06.059.083a.118.118 0 0 1 0 .07c-.007.022-.018.042-.059.083l-57.69 57.69c-.041.041-.06.052-.084.059a.118.118 0 0 1-.069 0c-.023-.007-.042-.018-.083-.059L512 569.926 224.297 857.629c-.04.041-.06.052-.083.059a.118.118 0 0 1-.07 0c-.022-.007-.042-.018-.083-.059l-57.69-57.69c-.041-.041-.052-.06-.059-.084a.118.118 0 0 1 0-.069c.007-.023.018-.042.059-.083L454.073 512 166.371 224.297c-.041-.04-.052-.06-.059-.083a.118.118 0 0 1 0-.07c.007-.022.018-.042.059-.083l57.69-57.69c.041-.041.06-.052.084-.059a.118.118 0 0 1 .069 0c.023.007.042.018.083.059L512 454.073l287.703-287.702c.04-.041.06-.052.083-.059a.118.118 0 0 1 .07 0Z"></path></svg>
                </button>
                <Sidebar />
            </Drawer>
        </>

    )
}

export default Navbar