import { Tooltip, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../module/features/authentication'
import { getProfile, completeProfile, updateProfile } from '../../module/features/completeProfile'
import { country, ethnicity, genders, religions } from '../../Constant/Utils'
import Spinner from '../../Constant/Spinner/Spinnner';
import { Select } from 'antd'
import { searchUniversities, searchUniversitiesWithYour } from '../../module/features/university'

function General({ changeTab }) {
    const [loader, setLoader] = useState(false)
    const dispatch = useDispatch()
    const user = useSelector(state => state.Authentication.user)
    const [universityArr, setUniversityArr] = useState([]);
    const [search, setSearch] = useState('')
    const userProfile = useSelector(state => state.completeProfileSlice.userProfile)
    const universityList = useSelector(
        (state) => state.universitySlice.searchUniversitieWithYoursData
    );
    const courses = useSelector(state => state.coursesSlice.courses)
    useEffect(() => {
    }, [])
    useEffect(() => {
        const debounceId = setTimeout(() => {

            dispatch(searchUniversities({ search: search }))


        }, 1000);

        return () => {
            clearTimeout(debounceId);
        };
    }, [search]);
    const onSearch = (value) => {
        setSearch(value);
        dispatch(searchUniversitiesWithYour({ search: value }))
    };
    const [data, setData] = useState({
        "nameOfCollege": "",
        "university_id": 566,
        "location": "",
        'gender': '',
        "entryYear": 0,
        "graduationYear": 0,
        "fullname": "",
        "age": "",
        "religion": "",
        "ethnicity": "",
        "bio": "",
        "gender": ''
    })
    const [university, setUniversity] = useState(false)
    useEffect(() => {
        console.log(userProfile, 'userProfileuserProfile')
        if (userProfile) {
            let obj = {
                ...data,
                ...userProfile,
                username: userProfile.username,

                profileUrl: userProfile?.profileUrl ? userProfile?.profileUrl : ""
            }
            dispatch(searchUniversitiesWithYour({ search: search, university_id: userProfile?.university_id }))
            setUniversity(String(userProfile.university_id))
            setData(obj)
        } else {
            dispatch(searchUniversitiesWithYour({ search: search }))
        }
    }, [userProfile])

    const callback = () => {
        dispatch(getUser(false))
        changeTab('Courses')
    }
    const completeProfileFunction = () => {
        let obj = {
            ...data,
            // majorCourse: data?.majorCourse?.join(','),
            // minorCourse: data?.minorCourse?.join(','),
        }
        delete obj.email
        delete obj.updated_at
        delete obj.created_at
        delete obj.id
        delete obj.username
        delete obj.profileUrl
        delete obj.theme
        delete obj.outcome_id
        delete obj.lastEmailSendDate
        delete obj.user_id
        delete obj.createdAt
        delete obj.updatedAt
        delete obj.majorCourses
        delete obj.minorCourses
        if (userProfile?.id) {
            dispatch(updateProfile({ data: { ...obj }, callback, setLoader, id: userProfile?.id }))
        } else {
        if(obj.fullname && obj.age && obj.gender && obj.religion && obj.ethnicity && obj.university_id && obj.entryYear && obj.graduationYear && obj.location){
            dispatch(completeProfile({ data: { ...obj, tier: 1 }, callback, setLoader }))

        }else{
            message.error("Required fields can't be empty")
        }
        }
    }

    const [coursesArr, setCoursesArr] = useState([])
    useEffect(() => {
        if (courses?.length) {
            let arr = []
            courses.map((a, i) => {
                if (a.type === 'course') {
                    arr.push({
                        ...a,
                        value: a.id,
                        label: a.course,
                    })
                }
            })
            console.log(arr, 'coursesArr')
            setCoursesArr(arr)
        }
    }, [courses])

    useEffect(() => {
        if (universityList) {
            let arr = universityList.map((a, i) => {
                return {
                    value: `${a.id}`,
                    label: `${a.NAME}`,
                    ...a,
                };
            });
            setUniversityArr(arr);
        }
    }, [universityList]);

    console.log(data, 'datadata')

    const filterOption = (input, option) =>
        (option?.label ?? option?.key ?? '').toLowerCase().includes(input.toLowerCase());

    useEffect(() => {
        console.log(universityArr, data, 'asdasdasdsadas')
    }, [universityArr])
    return (
        <>
            <div className='PageHeading'>
                <h1>Profile</h1>
            </div>
            <div className='form'>
                <label>
                    <p>Full name  {userProfile?.id ? null

                        : <sup className="requiredField">*</sup>}</p>
                    <input placeholder='Your full name'
                        value={data.fullname}
                        onChange={(ev) => {
                            let obj = { ...data }
                            obj.fullname = ev.target.value
                            setData(obj)

                        }}
                    />
                </label>
                <label>
                    <p>Date Of Birth  {userProfile?.id ? null

                        : <sup className="requiredField">*</sup>}</p>
                    <input value={data.age} placeholder='DOB' type='date'
                        onChange={(ev) => {
                            let obj = { ...data }
                            obj.age = ev.target.value
                            setData(obj)
                        }}
                    />
                </label>
                <label>
                    <p>Gender  {userProfile?.id ? null
                        : <sup className="requiredField">*</sup>}</p>
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        value={data?.gender}
                        options={genders}
                        onSelect={(ev, option) => {
                            console.log(ev, 'evev')
                            let obj = { ...data }
                            obj.gender = ev
                            setData(obj)

                        }}
                    />
                </label>
                <label>
                    <p>Religion  {userProfile?.id ? null

                        : <sup className="requiredField">*</sup>}</p>
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        value={data?.religion}
                        options={religions}
                        onSelect={(ev, option) => {
                            console.log(ev, 'evev')
                            let obj = { ...data }
                            obj.religion = ev
                            setData(obj)

                        }}
                    />
                    {/* <input placeholder='-Select your religion-'
            value={data.religion}
            onChange={(ev) => {
                let obj = { ...data }
                obj.religion = ev.target.value
                setData(obj)

            }}
        /> */}
                </label>
                <label>
                    <p>Ethnicity  {userProfile?.id ? null

                        : <sup className="requiredField">*</sup>}</p>
                    <Select
                        showSearch
                        defaultValue={"Choose"}
                        placeholder="Ethnicity"
                        style={{
                            width: 120,
                        }}
                        value={data.ethnicity ? data.ethnicity : "Choose"}
                        onChange={(ev) => {
                            let obj = { ...data }
                            obj.ethnicity = ev
                            setData(obj)

                        }}
                        options={ethnicity}
                    />
                    {/* <input placeholder='-Select your ethnicity-'
            value={data.ethnicity}
            onChange={(ev) => {
                let obj = { ...data }
                obj.ethnicity = ev.target.value
                setData(obj)

            }}
        /> */}
                </label>
                <label>
                    <p>University Name  {userProfile?.id ? null

                        : <sup className="requiredField">*</sup>}</p>
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        value={university ? university : false}
                        options={universityArr}
                        filterOption={filterOption}
                        onSearch={onSearch}
                        onSelect={(ev, option) => {
                            console.log(option, 'optionoption')
                            let obj = { ...data }
                            obj.nameOfCollege = option.NAME
                            obj.university_id = option.id
                            setUniversity(option.value)
                            setData(obj)

                        }}
                    />
                </label>
                {/* <label>
        <p>Major</p>

        <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
            value={data.majorCourse}
            options={coursesArr}
            onChange={(ev) => {
                let obj = { ...data }
                obj.majorCourse = ev
                setData(obj)

            }}
        />
    </label> */}
                {/* <label>
        <p>Courses Taken</p>

        <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
            value={data.minorCourse}
            options={coursesArr}
            onChange={(ev) => {
                let obj = { ...data }
                obj.minorCourse = ev
                setData(obj)

            }}
        />
    </label> */}

                <label>
                    <p>Entry Year In University  {userProfile?.id ? null

                        : <sup className="requiredField">*</sup>}</p>
                    <input value={data.entryYear ? data.entryYear : ''} placeholder='Entry Year In University' type="number" min="1900" max="2099" step="1"
                        onChange={(ev) => {
                            let obj = { ...data }
                            obj.entryYear = Number(ev.target.value)
                            setData(obj)
                        }}
                    />
                </label>
                <label>
                    <p>Expected Graduation Year  {userProfile?.id ? null

                        : <sup className="requiredField">*</sup>}</p>
                    <input value={data.graduationYear ? data.graduationYear : ''} placeholder='Expected Graduation Year' type="number" min="1900" max="2099" step="1"
                        onChange={(ev) => {
                            let obj = { ...data }
                            obj.graduationYear = Number(ev.target.value)
                            setData(obj)
                        }}
                    />
                </label>
                <label>
                    <p>Location  {userProfile?.id ? null

                        : <sup className="requiredField">*</sup>}</p>
                    {/* <input placeholder='-Select your location-'
                        value={data.location}
                        onChange={(ev) => {
                            let obj = { ...data }
                            obj.location = ev.target.value
                            setData(obj)

                        }}
                    /> */}
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        value={data?.location}
                        options={country}
                        onSelect={(ev, option) => {
                            console.log(ev, 'evev')
                            let obj = { ...data }
                            obj.location = ev
                            setData(obj)
                        }}
                    />
                </label>
                <label className='bio'>
                    <p>Bio</p>
                    <textarea placeholder='Input Text'
                        value={data.bio}
                        onChange={(ev) => {
                            let obj = { ...data }
                            obj.bio = ev.target.value
                            setData(obj)
                        }}
                    ></textarea>
                </label>
            </div>
            {/* <div className='PageHeading'>
    <h1>Add Course</h1>
</div>
<CoursesTable /> */}
            <div className='PageHeading'>
                <h1>Account</h1>
            </div>
            <div className='form'>

                <label className='editAccountDetail'>
                    <p>Username
                        <Tooltip title="Coming Soon">
                            <svg onClick={() => { }} className='editAccountDetailIcon' stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                        </Tooltip>
                    </p>
                    <input disabled placeholder='Your username'
                        value={data.username}
                        onChange={(ev) => {
                            let obj = { ...data }
                            obj.username = ev.target.value
                            setData(obj)

                        }}
                    />
                </label>
                <label>
                    <p>Email</p>
                    <input placeholder='Example.email@gmail.com' value={userProfile?.email} disabled />
                </label>
                <label className='editAccountDetail'>
                    <p>Password
                        <Tooltip title="Coming Soon">
                            <svg onClick={() => { }} className='editAccountDetailIcon' stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                        </Tooltip>
                    </p>
                    <input type='password' placeholder='●●●●●●●●●' disabled />
                </label>
            </div>
            {/* <div className='PageHeading'>
    <h1>Preferences</h1>
</div>
<label className='InterestinputLabel'>
    <input type='checkbox' />
    <span>
        <p className='heading'>Receive monthly product updates</p>
        <p>Quis nostrud exercitaion ullamco laboris nisi ut aliquip ex ea commodo consequa </p>
    </span>
</label>
<label className='InterestinputLabel'>
    <input type='checkbox' />
    <span>
        <p className='heading'>See info about people who view my profile</p>
        <p>Quis nostrud exercitaion ullamco laboris nisi ut aliquip ex ea commodo consequa </p>
    </span>
</label> */}
            <div className='SaveInformation'>
                <button className='hoverEffect'
                    onClick={completeProfileFunction}
                >{loader ? <Spinner /> :
                    userProfile?.id ? `Update Information` : `Save Information`
                    }</button>
            </div>
        </>
    )
}

export default General