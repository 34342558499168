import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getUser } from '../../module/features/authentication'
import { useNavigate } from 'react-router-dom'

function RedirectPage() {

    return (
        <div></div>
    )
}

export default RedirectPage