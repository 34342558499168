import React, { useEffect, useState } from "react";
// import "./LandingPage.scss";
// import "./NewLandingPage.scss";
import "../Compare/CompareSchool.scss";
import "./NewProfessorRating.scss";
import "./../schoolComponents/schoolcomponents.scss";

import "../schoolComponents/schoolcomponents.scss";
import { NavLink, useNavigate } from "react-router-dom";
import LandingPageNavbar from "../Navbar/LandingPageNavbar";
import NewLandNavBar from "../Navbar/NewLandNavBar";
import slider1 from "./../../Assest/slider1.png";
import slider2 from "./../../Assest/slider2.png";
import slider3 from "./../../Assest/slider3.png";
import card1Vector from "./../../Assest/card1Vector.png";
import card2Vector from "./../../Assest/card2Vector.png";
import card3Vector from "./../../Assest/card3Vector.png";
import testimonial from "./../../Assest/testimonial.png";
import gradhatIcon from "./../../Assest/gradhatIcon.png";
import searchIcon from "./../../Assest/searchIcon.png";
import Teacher from "./../../Assest/teacher.png";
import reset from "./../../Assest/reset.png";
import { Progress, Rate, Space, Tag, message } from "antd";
import topUniversity1 from "./../../Assest/topUniversity1.png";
import topUniversity2 from "./../../Assest/topUniversity2.png";
import topUniversity3 from "./../../Assest/topUniversity3.png";
import nextArrow from "./../../Assest/nextArrow.png";
import professor1 from "./../../Assest/TopProfessors/professor1.png";
import professor2 from "./../../Assest/TopProfessors/professor2.png";
import professor3 from "./../../Assest/TopProfessors/professor3.png";
import professor4 from "./../../Assest/TopProfessors/professor4.png";
import course1 from "./../../Assest/course1.png";
import course2 from "./../../Assest/course2.png";
import course3 from "./../../Assest/course3.png";
import facebook from "./../../Assest/socialIcons/facebook.png";
import linkedin from "./../../Assest/socialIcons/twitter.png";
import twitter from "./../../Assest/socialIcons/facebook.png";
import instagram from "./../../Assest/socialIcons/instagram.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Card, Col, Row } from "antd";
import image from "./../../Assest/logo.png";
import EditRatingImg from "../../Assest/rmp-Family/rating.png";
import AnonymousRatingImg from "../../Assest/rmp-Family/anonymous.png";
import LikeRatingImg from "../../Assest/rmp-Family/like.png";
import LandingPageFooter from "../footer/LandingPageFooter";
import { Carousel, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import SearchSchool from "../SearchSchool/SearchSchool";
import {
  getProfessorOutcomes,
  getSingleAvgProfessorRating,
  getSingleProfessor,
  getSingleProfessorRating,
} from "../../module/features/professor";
import { getQuery, ratingDateFormat } from "../../Constant/Utils";
import thumbUp from "../../Assest/RateMyProfessor/thumbs-up-green.751eef02.svg";
import thumbDown from "../../Assest/RateMyProfessor/thumbs-down-red.bff6d353.svg";
import share from "../../Assest/RateMyProfessor/share.svg";
import report from "../../Assest/RateMyProfessor/report.svg";
import OutcomesGraph from "./OutcomesGraph";
const { Option } = Select;
function NewProfessorRating() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const url = new URL(window.location);
  const id = url.searchParams.get("id");
  const professorRating = useSelector(
    (state) => state.professorSlice.professorRating
  );
  const professor = useSelector((state) => state.professorSlice.professor);
  const CoursesRating = useSelector(
    (state) => state.professorSlice.CoursesRating
  );
  const outcomes = useSelector((state) => state.professorSlice.outcomes);
  // const bothAvg = useSelector(state => state.professorSlice.bothAvg)
  let overAll = 0;

  const handleButtonClick = (e) => {
    message.info("Click on left button.");
    console.log("click left button", e);
  };
  const handleMenuClick = (e) => {
    message.info("Click on menu item.");
    console.log("click", e);
  };
  const items = [
    {
      label: "All Courses ",
      key: "1",
    },
    {
      label: "SC101 (1)",
      key: "2",
    },
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  useEffect(() => {

    if (id) {
      dispatch(getSingleProfessorRating({ id }));
      dispatch(getSingleProfessor({ id }));
      // let avgApiPayload = {}
      // if(id){
      //   avgApiPayload.professor_id = id
      // }
      dispatch(getSingleAvgProfessorRating({ professor_id: id }));
      dispatch(getProfessorOutcomes({ professor_id: id }));
    }
  }, [id]);

  useEffect(() => {
    console.log(CoursesRating, "CoursesRating");
  }, [CoursesRating]);

  return (
    <React.Fragment>
      <div className="landing-page">
        {/* <NewLandNavBar /> */}
        <NewLandNavBar bg={"#f1f7ff"} />

        {/* firstSection */}
        <SearchSchool defaultProfessor={true} />

        <div className="First-Professor-Rating-Section">
          <div className="firstSection_headers">
            <p className="SchoolName">{professor?.collegesAndUniversity?.NAME}</p>
            <h1 className="ProfessorName">
              Sir {professor?.professor_name} {professor?.professor_middle_name}{" "}
              {professor?.professor_last_name}
            </h1>
            <p className="DepartmentName">
              Professor in the {professor?.department_name} at {professor?.collegesAndUniversity?.NAME}
            </p>
          </div>

          <div className="firstSection-buttons">
            <div className="ratingBtns">
              <button
                className="rateSchoolBtn"
                onClick={() => {
                  navigate(`/compare-professor${window.location.search}`);
                }}
              >
                Compare this Professor
              </button>
            </div>
          </div>
        </div>

        {/* SecondSection */}

        <div className="Second-Professor-Rating-Section">
          <div className="overall-Rating-Text">
            {/* <div className="Third-Professor-Rating-Section"> */}
            {/* <div className="ProfessorName"> */}
            {/* <h2>Professor {professor?.professor_name}'s Top Tags</h2> */}
            {/* </div> */}
            {/* </div> */}
            {outcomes ?
              <OutcomesGraph outcomes={outcomes} />
              :
              <div className="noRatingText">
                <h3>There is no tags yet</h3>
              </div>
            }
          </div>

          <div className="schoolratingsummrary-container schoolratingsummrary-border-remove">
            {/* fourth section  */}
            <div className="fourthSection">
              <h2>Courses rating</h2>
              <div className="fourth-Section-child">
                Select Course:
                <Select
                  className="selectCourseDropDown"
                  showSearch
                  placeholder="Select course"
                  optionFilterProp="children"
                  onChange={(course) => {
                    let id = getQuery("id");
                    dispatch(
                      getSingleAvgProfessorRating({
                        professor_id: id,
                        course_id: course?.toString(),
                      })
                    );
                    dispatch(
                      getProfessorOutcomes({
                        professor_id: id,
                        course_id: course,
                      })
                    );
                  }}
                  options={professor?.courses?.map((a, i) => {
                    return {
                      ...a,
                      value: a.course.id,
                      label: a.course.course,
                    };
                  })}
                />
              </div>
            </div>
            {CoursesRating ?
              <>
                <table style={{ borderSpacing: "14px" }}>
                  {CoursesRating &&
                    CoursesRating.map((a, i) => {
                      // overAll += a.PrePercentage + a.postPercentage
                      // setoverAll(overAll + a.PrePercentage + a.postPercentage)
                      return (
                        <tr className="ratingProgressBarDiv">
                          <td>
                            <p>{a?.courses?.course}</p>
                          </td>
                          <td>
                            <div className="ratingProgressBar">
                              <div
                                className="postProgress"
                                style={{
                                  width: `${a?.postaverage ? `${a?.postaverage * 100}%` : "0%"
                                    }`,
                                  zIndex: `${a?.postaverage < a?.preaverage ? '0' : '1'}`
                                }}
                              ></div>
                              <div
                                className="preProgress"
                                style={{
                                  width: `${a?.preaverage ? `${a?.preaverage * 100}%` : "0%"
                                    }`,
                                  zIndex: `${-(a?.preaverage < a?.postaverage ? '0' : '1')}`
                                }}
                              ></div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </table>
                <div className="colorIndicator">
                  <div className="inner-colorIndicator">
                    <div className="indiDiv"><div className="preIndi"></div>Pre-assesment</div>
                    <div className="indiDiv"><div className="postIndi"></div>Post-assesment</div>
                  </div>
                </div>
              </>
              :
              <div className="noRatingText">
                <h3>There is no rating yet</h3>
              </div>
            }
            {/* 
                        <div className='display-slider  Newdisplay-slider '>
                     
                            <div className='inner-display-slider   Newinner-display-slider '>
                                <div className='display-slider-title'>Diffulty</div>

                                <Progress strokeLinecap="butt" type="circle" size={86} percent={avgRating?.difficultyInPercent?.toFixed(1)} strokeWidth={10} strokeColor={"#7FF6C3"} />
                            </div>

                            <div className='inner-display-slider   Newinner-display-slider '>
                                <div className='display-slider-title'>Rating</div>
                                <Progress strokeLinecap="butt" type="circle" size={86} percent={avgRating?.ratingInPercent?.toFixed(1)} strokeWidth={10} strokeColor={"#7FF6C3"} />
                            </div>

                        </div>
                             */}
          </div>
        </div>

        {/* third section */}

        {/* 
                fifth section */}
        {/* <div className="fifthSection">
                    <div className='school-rating'>
                        <ul className='ratingList-ul'>
                            {professorRating.map((a, i) => (
                                <li className='styled-listed'>
                                    <div className='ratingStyle-rating'>
                                        <div className='ratingBody'>

                                            <div className='ratingValue'>

                                                <div className='ratingValue-RatingContainers'>
                                                    <div className='cardNumRating'>
                                                        <div className='cardNumRating-header'>Quality</div>
                                                        <div className='cardNumRating-number'>{Number(a.rating).toFixed(1)}</div>
                                                    </div>

                                                </div>
                                                <div className='ratingValue-RatingContainer'>
                                                    <div className='styleCardNumRating'>
                                                        <div className='cardNumRatingHeader'>Difficulty</div>
                                                        <div className='cardNumRatingNumber'>{Number(a.difficulty).toFixed(1)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='ratingInfo'>
                                                <div className='ratingheader'>
                                                    <div className='RatingHeader__ClassInfoWrapper'>
                                                        <div className='RatingHeader__StyledClass'>{a.class}</div>
                                                    </div>
                                                    <div className='TimeStamp__StyledTimeStamp'>{ratingDateFormat(a.created_at)}</div>
                                                </div>
                                                <div className='courseMeta'>
                                                    <div className='MetaItem__StyledMetaItem'>
                                                        For Credit:
                                                        <span>{a.forCredit ? 'Yes' : 'No'}</span>
                                                    </div>
                                                    <div className='MetaItem__StyledMetaItem'>
                                                        Attendance:
                                                        <span>{a.attendanceMandatory ? 'Mandatory' : "Not mandatory"}</span>
                                                    </div>
                                                    <div className='MetaItem__StyledMetaItem'>
                                                        Would Take Again:
                                                        <span>{a.wouldTakeAgain ? 'Yes' : 'No'}</span>
                                                    </div>
                                                    <div className='MetaItem__StyledMetaItem'>
                                                        Grade:
                                                        <span>{a.grade}</span>
                                                    </div>
                                                    <div className='MetaItem__StyledMetaItem'>
                                                        Textbook:
                                                        <span> {a.usesTextbooks ? 'Yes' : 'No'}</span>
                                                    </div>
                                                </div>
                                                <div className='comments'>
                                                    {a.comment}
                                                </div>
                                                <div className='ratingTag'>
                                                    {a?.tags && (a?.tags).split(',').map((b, j) => (
                                                        <span className='tag'>{b}</span>
                                                    ))}

                                                </div>
                                                <div className='ratingFooter'>
                                                    <div className='thumbs-btn-wrapper' id='thumb-id'>
                                                        <div className='thumb-container' id='thumb-up'>
                                                            <div className='voteThumb'>
                                                                <div className='voteThumb-helpful'>Helpful</div>
                                                                <img src={thumbUp} alt='thumb' className='VOTEThumb' />
                                                            </div>
                                                            <div className="Thumbs__HelpTotalNumber">0</div>
                                                        </div>
                                                        <div className='thumb-container' id='thumb-down'>
                                                            <div className='voteThumb'>
                                                                <div className='voteThumb-helpful'></div>
                                                                <img src={thumbDown} alt='thumb' className='VOTEThumb' />
                                                            </div>
                                                            <div className="Thumbs__HelpTotalNumber">0</div>
                                                        </div>
                                                    </div>
                                                    <div className='RatingFooter__ButtonWrapper'>
                                                        <button className='IconButton__StyledIconButton'>
                                                            <span className='empty'>
                                                                <div className='ShareRating__ShareWrapper'>
                                                                    <img src={share} alt='share' className='share' />
                                                                </div>
                                                            </span>
                                                        </button>
                                                        <a className='ReportFlag__StyledReportFlag' data-tooltip="true" data-tip="Report this rating" data-for="GLOBAL_TOOLTIP" data-testid="reportflag_test_id" aria-disabled="false" currentitem="false" href='#'>
                                                            <div className='ReportFlag__FlagWrapper'>
                                                                <img src={report} alt='report' className='report' />
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}

                        



                        </ul>
                    </div>
                </div> */}

        <LandingPageFooter />
      </div>
    </React.Fragment>
  );
}

export default NewProfessorRating;
