import React, { useEffect, useState } from "react";
// import "./LandingPage.scss";
// import "./NewLandingPage.scss";
import "../Compare/CompareSchool.scss";
// import './NewProfessorRating.scss';
import "./../schoolComponents/schoolcomponents.scss"
import "./NewUniversityRating.scss";

import '../schoolComponents/schoolcomponents.scss';
import { NavLink, useNavigate } from "react-router-dom";
import LandingPageNavbar from "../Navbar/LandingPageNavbar";
import NewLandNavBar from "../Navbar/NewLandNavBar";
import slider1 from "./../../Assest/slider1.png"
import slider2 from "./../../Assest/slider2.png"
import slider3 from "./../../Assest/slider3.png"
import card1Vector from "./../../Assest/card1Vector.png"
import card2Vector from "./../../Assest/card2Vector.png"
import card3Vector from "./../../Assest/card3Vector.png"
import testimonial from "./../../Assest/testimonial.png"
import gradhatIcon from "./../../Assest/gradhatIcon.png"
import searchIcon from "./../../Assest/searchIcon.png"
import Teacher from "./../../Assest/teacher.png"
import reset from "./../../Assest/reset.png"
import { Progress, Rate, Space, Tag } from 'antd';
import topUniversity1 from "./../../Assest/topUniversity1.png"
import topUniversity2 from "./../../Assest/topUniversity2.png"
import topUniversity3 from "./../../Assest/topUniversity3.png"
import nextArrow from "./../../Assest/nextArrow.png"
import professor1 from "./../../Assest/TopProfessors/professor1.png"
import professor2 from "./../../Assest/TopProfessors/professor2.png"
import professor3 from "./../../Assest/TopProfessors/professor3.png"
import professor4 from "./../../Assest/TopProfessors/professor4.png"
import course1 from "./../../Assest/course1.png";
import course2 from "./../../Assest/course2.png";
import course3 from "./../../Assest/course3.png";
import facebook from "./../../Assest/socialIcons/facebook.png";
import linkedin from "./../../Assest/socialIcons/twitter.png";
import twitter from "./../../Assest/socialIcons/facebook.png";
import instagram from "./../../Assest/socialIcons/instagram.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { Card, Col, Row } from 'antd';
import image from './../../Assest/logo.png'
import EditRatingImg from "../../Assest/rmp-Family/rating.png";
import AnonymousRatingImg from "../../Assest/rmp-Family/anonymous.png";
import LikeRatingImg from "../../Assest/rmp-Family/like.png";
import LandingPageFooter from "../footer/LandingPageFooter";
import { Carousel, Select } from "antd";
import { useSelector } from "react-redux";
import SearchSchool from "../SearchSchool/SearchSchool";
const { Option } = Select;
function NewUniversityRating() {
    const [professorSchool, setProfessorSchool] = useState(false);

    const [professorArr, setProfessorArr] = useState([])
    const professorList = useSelector(e => e.professorSlice.professorList)
    const universityList = useSelector(state => state.universitySlice.universityList)
    const navigate = useNavigate();
    useEffect(() => {
        if (professorSchool) {

            if (professorList) {
                let arr = professorList.map((a, i) => {
                    return {
                        value: a.id,
                        label: a.professor_name
                    }
                })
                setProfessorArr(arr)
                console.log(professorList, 'professorList')
            }
        } else if (universityList) {
            let arr = universityList.map((a, i) => {
                return {
                    value: `${a.OBJECTID}`,
                    label: `${a.NAME}`,
                    ...a
                }
            })
            setProfessorArr(arr)
            console.log(professorList, 'professorList')
            // setProfessorArr([])

        }
    }, [professorList, professorSchool, universityList])
    const onChange = (value) => {
        if (professorSchool) {
            navigate(`/professors?id=${value}`)
        } else {
            navigate(`/school?id=${value}`)

        }
        console.log(`selected ${value} `);
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    const renderOptionUniversity = (option) => {
        // Customize the rendering of each option
        return (
            <div className="universityListOpt" onClick={() => console.log(option)}>
                <div>
                    <span style={{ marginRight: '8px' }}>{option.label}</span>
                    <div>
                        <span className="location">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 32C167.67 32 96 96.51 96 176c0 128 160 304 160 304s160-176 160-304c0-79.49-71.67-144-160-144zm0 224a64 64 0 1 1 64-64 64.07 64.07 0 0 1-64 64z"></path></svg>
                            &nbsp;
                            {option.CITY ? option.CITY + ", " : ''} {option.COUNTRY ? option.COUNTRY : ''}</span>
                    </div>
                </div>
            </div>
        );
    };

    const onChangeTop = (currentSlide) => {
        console.log(currentSlide);
    };

    return (
        <React.Fragment>
            <div className="landing-page">

                {/* <NewLandNavBar /> */}
                <NewLandNavBar />

                <SearchSchool />


                {/* first section */}
                <div className="firstsection_universityRating">

                    <div className="rateHeaderTexts">
                        <p className="rateHead">Rate</p>
                        <p className="rateschool">the Premier School, </p>
                        <p className="rateLocation">Asheville, NC</p>
                    </div>

                </div>

                {/* second section */}
                <div className="secondsection_universityRating">

                    <div className="reputationDiv">
                        <div className="reputationStar">
                            <span className="repo-text">Reputation <span className="aesterix">*</span></span>

                        </div>

                        <div className="RateStars">
                            <div className="innerStars">
                                <Rate allowHalf defaultValue={5} />
                            </div>
                            <div className="RateComments">
                                <p>Awful</p>
                                <p>Awesome</p>
                            </div>
                        </div>
                    </div>
                    <div className="reputationDiv">
                        <div className="reputationStar">
                            <span className="repo-text">Reputation <span className="aesterix">*</span></span>

                        </div>

                        <div className="RateStars">
                            <div className="innerStars">
                                <Rate allowHalf defaultValue={5} />
                            </div>
                            <div className="RateComments">
                                <p>Awful</p>
                                <p>Awesome</p>
                            </div>
                        </div>
                    </div>
                    <div className="reputationDiv">
                        <div className="reputationStar">
                            <span className="repo-text">Reputation <span className="aesterix">*</span></span>

                        </div>

                        <div className="RateStars">
                            <div className="innerStars">
                                <Rate allowHalf defaultValue={5} />
                            </div>
                            <div className="RateComments">
                                <p>Awful</p>
                                <p>Awesome</p>
                            </div>
                        </div>
                    </div>
                    <div className="reputationDiv">
                        <div className="reputationStar">
                            <span className="repo-text">Reputation <span className="aesterix">*</span></span>

                        </div>

                        <div className="RateStars">
                            <div className="innerStars">
                                <Rate allowHalf defaultValue={5} />
                            </div>
                            <div className="RateComments">
                                <p>Awful</p>
                                <p>Awesome</p>
                            </div>
                        </div>
                    </div>
                    <div className="reputationDiv">
                        <div className="reputationStar">
                            <span className="repo-text">Reputation <span className="aesterix">*</span></span>

                        </div>

                        <div className="RateStars">
                            <div className="innerStars">
                                <Rate allowHalf defaultValue={5} />
                            </div>
                            <div className="RateComments">
                                <p>Awful</p>
                                <p>Awesome</p>
                            </div>
                        </div>
                    </div>
                    <div className="reputationDiv">
                        <div className="reputationStar">
                            <span className="repo-text">Reputation <span className="aesterix">*</span></span>

                        </div>

                        <div className="RateStars">
                            <div className="innerStars">
                                <Rate allowHalf defaultValue={5} />
                            </div>
                            <div className="RateComments">
                                <p>Awful</p>
                                <p>Awesome</p>
                            </div>
                        </div>
                    </div>

                    <div className="reputationDiv">
                        <div className="reputationStar">
                            <span className="repo-text">Reputation <span className="aesterix">*</span></span>

                        </div>

                        <div className="RateStars">
                            <div className="innerStars">
                                <Rate allowHalf defaultValue={5} />
                            </div>
                            <div className="RateComments">
                                <p>Awful</p>
                                <p>Awesome</p>
                            </div>
                        </div>
                    </div>

                    <div className="reputationDiv">
                        <div className="reputationStar">
                            <span className="repo-text">Reputation <span className="aesterix">*</span></span>

                        </div>

                        <div className="RateStars">
                            <div className="innerStars">
                                <Rate allowHalf defaultValue={5} />
                            </div>
                            <div className="RateComments">
                                <p>Awful</p>
                                <p>Awesome</p>
                            </div>
                        </div>
                    </div>

                    <div className="reputationDiv">
                        <div className="reputationStar">
                            <span className="repo-text">Reputation <span className="aesterix">*</span></span>

                        </div>

                        <div className="RateStars">
                            <div className="innerStars">
                                <Rate allowHalf defaultValue={5} />
                            </div>
                            <div className="RateComments">
                                <p>Awful</p>
                                <p>Awesome</p>
                            </div>
                        </div>
                    </div>

                </div>

                {/* third section */}

                <div className="thirdsection_universityRating">

                    <h1>Write a Review</h1>

                    <div className="guidelines">
                        <h3>Guidelines:</h3>
                        <p>Discuss your personal experience on this school. What’s great about it? What could use improvement?</p>
                        <ul>
                            <li>Your rating could be removed if you use profanity or derogatory terms.</li>
                            <li>Refer to the rating categories to help you better elaborate your comments.</li>
                            <li>Don’t forget to proof read! View all guidelines</li>
                        </ul>
                    </div>
                </div>

                {/* fourth section */}

                <div className="fourthsection_universityRating">
                    <textarea className="textareaInput" placeholder="What do you want to know bout this school?"></textarea>



                </div>

                <button className="submitRating">Submit Rating</button>
                <LandingPageFooter />
            </div>
        </React.Fragment>
    );
}

export default NewUniversityRating;
