import logo from './logo.svg';
import './App.scss';
import Layout from './Layout';
import 'draft-js/dist/Draft.css';
import 'swiper/css';
import 'swiper/css/pagination';

function App() {
  return (
    <div className="App" id="appDiv">
      <Layout/>
    </div>
  );
}

export default App;
