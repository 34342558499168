import { key } from 'localforage'
import React, { useEffect } from 'react'
import { useState } from 'react'

function Answers({
    addAssesmentFun, keyed, loader, question, index
}) {
    const [keyed1_5, setkeyed1_5] = useState([
        { color: '#E91728', value: 1, text: 'Very Inaccurate', textSuggest: 'Very Inaccurate' },
        { color: '#FF3547', value: 2, text: 'Moderly Inaccurate', textSuggest: 'Moderly Inaccurate' },
        { color: '#FFA921', value: 3, text: 'Neutral', textSuggest: 'Neutral' },
        { color: '#343334', value: 4, text: 'Moderly Accurate', textSuggest: 'Moderly Accurate' },
        { color: '#3D7E45', value: 5, text: 'Very Accurate', textSuggest: 'Very Accurate' },
    ])
    const [negKeyed1_5, setnegKeyed1_5] = useState([
        { color: '#E91728', value: 5, text: 'Never', textSuggest: 'Never' },
        { color: '#FF3547', value: 4, text: 'Rarely', textSuggest: 'Rarely' },
        { color: '#FFA921', value: 3, text: 'Sometimes', textSuggest: 'Sometimes' },
        { color: '#343334', value: 2, text: 'Often', textSuggest: 'Often' },
        { color: '#3D7E45', value: 1, text: 'Very Often', textSuggest: 'Very Often' },
    ])
    const [keyed1_7, setkeyed1_7] = useState([
        { color: '#E91728', value: 1, text: 1, textSuggest: 'Very Negative' },
        { color: '#FF3547', value: 2, text: 2, textSuggest: '' },
        { color: '#FFA921', value: 3, text: 3, textSuggest: '' },
        { color: '#343334', value: 4, text: 4, textSuggest: '' },
        { color: '#3D7E45', value: 5, text: 5, textSuggest: '' },
        { color: '#E91728', value: 6, text: 6, textSuggest: '' },
        { color: '#FF3547', value: 7, text: 7, textSuggest: 'Very Positive' }
    ])
    const [negkeyed1_7, setnegkeyed1_7] = useState([
        { color: '#E91728', value: 7, text: 1, textSuggest: 'Very Negative' },
        { color: '#FF3547', value: 6, text: 2, textSuggest: '' },
        { color: '#FFA921', value: 5, text: 3, textSuggest: '' },
        { color: '#343334', value: 4, text: 4, textSuggest: '' },
        { color: '#3D7E45', value: 3, text: 5, textSuggest: '' },
        { color: '#E91728', value: 2, text: 6, textSuggest: '' },
        { color: '#FF3547', value: 1, text: 7, textSuggest: 'Very Positive' }
    ])
    const openResponse = "Open Response"
    const [yesNo, setyesNo] = useState([
        { color: '#E91728', value: 1, text: 'Yes', textSuggest: 'Yes' },
        { color: '#FF3547', value: 2, text: 'No', textSuggest: 'No' },
        { color: '#FFA921', value: 3, text: 'Uncertain', textSuggest: 'Uncertain' },
    ])
    const [incDec, setincDec] = useState([
        { color: '#E91728', value: 1, text: 'Increase', textSuggest: 'Increase' },
        { color: '#FF3547', value: 2, text: 'Same', textSuggest: 'Same' },
        { color: '#FFA921', value: 3, text: 'Decrease', textSuggest: 'Decrease' },
    ])
    const [ommited1_5, setOmmited1_5] = useState([
        { color: '#E91728', value: 1, text: 'Very Inaccurate', textSuggest: 'Very Inaccurate' },
        { color: '#FF3547', value: 2, text: 'Moderly Inaccurate', textSuggest: 'Moderly Inaccurate' },
        { color: '#FFA921', value: 3, text: 'Neutral', textSuggest: 'Neutral' },
        { color: '#343334', value: 4, text: 'Moderly Accurate', textSuggest: 'Moderly Accurate' },
        { color: '#3D7E45', value: 5, text: 'Very Accurate', textSuggest: 'Very Accurate' },
    ])
    const [ommited1_7, setOmmited1_7] = useState([
        { color: '#E91728', value: 1, text: 1, textSuggest: 'Very Negative' },
        { color: '#FF3547', value: 2, text: 2, textSuggest: '' },
        { color: '#FFA921', value: 3, text: 3, textSuggest: '' },
        { color: '#343334', value: 4, text: 4, textSuggest: '' },
        { color: '#3D7E45', value: 5, text: 5, textSuggest: '' },
        { color: '#E91728', value: 6, text: 6, textSuggest: '' },
        { color: '#FF3547', value: 7, text: 7, textSuggest: 'Very Positive' }
    ])
    const [keyed0_3, setkeyed0_3] = useState([
        { color: '#E91728', value: 1, text: 1, textSuggest: 'Not at all' },
        { color: '#FF3547', value: 2, text: 2, textSuggest: 'Several days' },
        { color: '#FFA921', value: 3, text: 3, textSuggest: 'More days than not' },
        { color: '#343334', value: 4, text: 4, textSuggest: 'Nearly every day' }
    ])
    const [keyed0_4, setkeyed0_4] = useState([
        { color: '#E91728', value: 1, text: 1, textSuggest: 'Very Inaccurate' },
        { color: '#FF3547', value: 2, text: 2, textSuggest: 'Moderly Inaccurate' },
        { color: '#FFA921', value: 3, text: 3, textSuggest: 'Neutral' },
        { color: '#343334', value: 4, text: 4, textSuggest: 'Moderly Accurate' },
        { color: '#3D7E45', value: 5, text: 5, textSuggest: 'Very Accurate' },
    ])
    const [Keyed1_10, setKeyed1_10] = useState([
        { color: '#E91728', value: 1, text: 1, textSuggest: 'Very Negative' },
        { color: '#FF3547', value: 2, text: 2, textSuggest: '' },
        { color: '#FFA921', value: 3, text: 3, textSuggest: '' },
        { color: '#343334', value: 4, text: 4, textSuggest: '' },
        { color: '#3D7E45', value: 5, text: 5, textSuggest: '' },
        { color: '#E91728', value: 6, text: 6, textSuggest: '' },
        { color: '#FF3547', value: 7, text: 7, textSuggest: '' },
        { color: '#FFA921', value: 8, text: 8, textSuggest: '' },
        { color: '#343334', value: 9, text: 9, textSuggest: '' },
        { color: '#3D7E45', value: 10, text: 10, textSuggest: 'Very Positive' },
    ])


    const [currentKeyed, setCurrentKeyed] = useState([])
    const [openResponseText, setOpenResponseText] = useState('')

    useEffect(() => {

        // setCurrentKeyed(openResponse)

        if (keyed) {
            if (keyed === "1-5 +keyed") {
                setCurrentKeyed(keyed1_5)
            } else if (keyed === "1-5 -keyed") {
                setCurrentKeyed(negKeyed1_5)
            } else if (keyed === "1-7 +keyed") {
                setCurrentKeyed(keyed1_7)
            } else if (keyed === "1-7 -keyed") {
                setCurrentKeyed(negkeyed1_7)
            } else if (keyed === "Open Response") {
                setCurrentKeyed(openResponse)
            } else if (keyed === "Yes/No/Uncertain") {
                setCurrentKeyed(yesNo)
            } else if (keyed === "Increase/Same/Decrease") {
                setCurrentKeyed(incDec)
            } else if (keyed === "1-5 Omitted") {
                setCurrentKeyed(ommited1_5)
            } else if (keyed === "1-7 Omitted") {
                setCurrentKeyed(ommited1_7)
            } else if (keyed === "0-3 +keyed") {
                setCurrentKeyed(keyed0_3)
            } else if (keyed === "0-4 +keyed") {
                setCurrentKeyed(keyed0_4)
            } else if (keyed === "1-5 -keyed") {
                setCurrentKeyed(negKeyed1_5)
            } else if (keyed === "1-10 +keyed") {
                setCurrentKeyed(Keyed1_10)
            }
        }
    }, [keyed])

    return (
        <div className='answers'>
            {typeof currentKeyed === 'string' ?
                <div className='submitButtonAssesDiv'>
                    <textarea value={openResponseText} disabled={loader} className='textCurrent' placeholder='Write Your Response Here'
                        onChange={(ev) => {
                            setOpenResponseText(ev.target.value)
                        }}
                    ></textarea>
                    <button className='submitButton' onClick={(ev) => addAssesmentFun({
                        value: openResponseText
                    }, question, index)}>Submit</button>
                </div>
                :
                <>
                    <div className='keyedValues'>

                        {currentKeyed.map((a, i) =>
                            keyed === "1-10 +keyed" ||
                                keyed === "0-3 +keyed" ||
                                keyed === "0-4 +keyed" ||
                                keyed === "1-7 -keyed" ||
                                keyed === "1-7 +keyed" ||
                                keyed === "1-7 Omitted"
                                ?
                                (
                                    <button disabled={loader} onClick={() => addAssesmentFun(a, question, index)} style={{ backgroundColor: a.color }}>{a.text}</button>
                                )
                                : (
                                    <button disabled={loader} onClick={() => addAssesmentFun(a, question, index)} style={{ backgroundColor: a.color }}>{a.text}</button>
                                ))}

                    </div>

                    <div className='keyedSuggest'>
                        <p>
                            {currentKeyed.map((a, i) => (
                                <>
                                    <span>{i + 1}: {a.textSuggest}</span> {i + 1 === currentKeyed.length ? null :
                                        <span className='dividerKeyed'>
                                            |
                                        </span>

                                    }
                                </>
                            ))}
                        </p>
                    </div>

                </>
            }
        </div>
    )
}

export default Answers