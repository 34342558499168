import React, { useState } from 'react'
import loginGIF from './../../Assest/loginGIF.json'
import { Link, useNavigate } from 'react-router-dom'
import './Authentication.scss'
import { useLottie } from "lottie-react";
import logo from './../../Assest/logo.png'
import { useDispatch } from 'react-redux';
import { ForgetPasswordApi, register } from '../../module/features/authentication';
import SocialLogin from './SocialLogin';
import { message } from 'antd';

function ForgetPassword() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const [notMatched,setNotMatch] = useState(false)
  const url = new URL(window.location)
  const [data, setData] = useState({
    "password": "",
    "confirmPassword": ""
  })
  const options = {
    animationData: loginGIF,
    loop: true
  };

  const { View } = useLottie(options);

  const clearState = () => {
    setData({
      "password": "",
      "confirmPassword": ""
    })
    const url = new URL(window.location)
    const redirect = url.searchParams.get('redirect')
    if (redirect) {
      navigate(`/login?redirect=${redirect}`)
    } else {
      navigate(`/login`)
    }
  }

  const ForgetPasswordUser = () => {
    const token = url.searchParams.get('token')
    if (data.password === data.confirmPassword) {
      let obj = {
        new_password: data.password
      }
      dispatch(ForgetPasswordApi({ data: obj, token, clearState: clearState, setLoader }))
    } else {
      message.error('Password not matched')
      setNotMatch(true)
    }
  }
  return (
    <div className='authenticationPage'>
      <div className='innerAuthenticationPage'>
        <div className='gifBoxDiv'>
          <div className='gifBox'>
            {View}
          </div>
        </div>
        <div className='form'>
          <div className='formDIv'>
            <div>
              <h3>Change Your Password</h3>
            </div>
            <div className='innerForm'>

              <label className={notMatched ? 'error' : ''}>
                <p>Password</p>
                <input type='password' value={data.password} placeholder='Password'
                  onChange={(ev) => {
                    let obj = { ...data }
                    obj.password = ev.target.value
                    setData(obj)
                  }}
                // onKeyDown={(ev) => {
                //   if (ev.keyCode === 13) {
                //     ForgetPasswordUser()
                //   }
                // }}
                />
              </label>
              <label className={notMatched ? 'error' : ''}>
                <p>Confirm Password</p>
                <input type='password' value={data.confirmPassword} placeholder='Password'
                  onChange={(ev) => {
                    let obj = { ...data }
                    obj.confirmPassword = ev.target.value
                    setData(obj)
                  }}
                  onKeyDown={(ev) => {
                    if (ev.keyCode === 13) {
                      ForgetPasswordUser()
                    }
                  }}
                />
              </label>

              <div className='loginButtons'>
                <button className='hoverEffect' onClick={ForgetPasswordUser}>Submit</button>
                {/* <p>Already have an account? <Link to={"/login"}>Login Now</Link></p> */}
              </div>
            </div>
            {/* <SocialLogin type="student" /> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgetPassword