import { Spin } from 'antd'
import React from 'react'

function FullPageLoader() {
    return (
        <div className='FullPageLoader'>
            <div className='innerFullPageLoader'>
                <h3>Preparing your map</h3>
                <Spin size="large" />
            </div>
        </div>
    )
}

export default FullPageLoader