import React, { Component, useEffect, useState } from "react";
import Sidebar from "./Component/Sidebar/Sidebar";
import {
  createBrowserRouter,
  Link,
  Outlet,
  Route,
  RouterProvider,
  Routes,
  useNavigate,
} from "react-router-dom";

import Home from "./Component/Home/Home";
import Competency from "./Component/Competency/Competency";
import Navbar from "./Component/Navbar/Navbar";
import Course from "./Component/Course/Course";
import Occupational from "./Component/Occupational/Occupational";
import Profile from "./Component/Profile/Profile";
import Setting from "./Component/Setting/Setting";
import EditSetting from "./Component/Setting/EditSetting";
import Login from "./Component/Auth/Login";
import Register from "./Component/Auth/Register";
import RedirectPage from "./Component/RedirectPage/RedirectPage";
import {
  getCourse,
  getCourseById,
  getCourseStudentById,
  getMajorCourses,
  getMinorCourses,
  getTierCourses,
} from "./module/features/courses";
import { useDispatch, useSelector } from "react-redux";
import AddCompetency from "./Admin/Competencies/AddCompetency";
import {
  AllStudentAverageResult,
  checkSubmitted,
  getCompetencies,
  getCompetenciesById,
  getCompetenciesByIdTier,
  getSelectedCompetenciesById,
  getSelectedCompetenciesWithCourse,
  getUserCompentencies,
} from "./module/features/competency";
import AddAssesment from "./Admin/Assesment/AddAssesment";
import AddCourse from "./Admin/AddCourse/AddCourse";
import AdminRegister from "./Component/Auth/AdminRegister";
import { getUser } from "./module/features/authentication";
import { getProfile } from "./module/features/completeProfile";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { themeColors } from "./Constant/Utils";
import Assesment from "./Component/Assesment/Assesment";
import Tier from "./Component/Tier/Tier";
import Authentication from "./Authentication";
import AssesmentTier from "./Component/Assesment/AssesmentTier";
import User from "./Admin/Users/User";
import { getUsers } from "./module/features/users";
import { getStudentOccupation } from "./module/features/assesments";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import Professors from "./Component/rateMyProfessors/professors";
import seeAllProfessor from "./Component/ProfessorRating/success/seeAllProfessor";
import seeAllUniversity from "./Component/ProfessorRating/success/seeAllUniversity";
import ProfessorSuccessRating from "./Component/ProfessorRating/success/professorSuccessRating";
import SearchComponent from "./Component/SearchComponent/index";
import { getUniversities } from "./module/features/university";
import University from "./Component/University/University";
import AddProfessor from "./Component/Professor/professor";
import professor, { getProfessors } from "./module/features/professor";
import ProfessorRating from "./Component/ProfessorRating/ProfessorRating";
import NewLandingPage from "./Component/LandingPage/NewLandingPage";
import LandingPage from "./Component/LandingPage/LandingPage";
import SchoolComponent from "./Component/schoolComponents/index";
import AddProfessorLanding from "./Component/AddProfessorAndUniversity/AddProfessorLayout";
import AddUniversityLanding from "./Component/AddProfessorAndUniversity/AddUniversityLayout";
import UniversityRatingSuccess from "./Component/UniversityRating/success/index";
import UniversityRating from "./Component/UniversityRating/index";
import CompareSchool from "./Component/Compare/CompareSchool";
import AddOutcomes from "./Admin/AddOutcomes/AddOutcomes";
import { getOutcomes } from "./module/features/outcomesSlice";
import NewProfessorRating from "./Component/ProfessorRating/NewProfessorRating";
import NewUniversityRating from "./Component/UniversityRating/NewUniversityRating";
import AProfessorRating from "./Component/ProfessorRating/aProfessorRating";
import AddYourCourse from "./Component/AddYourCourse/AddYourCourse";
import { getYourCourses } from "./module/features/ProfessorCourse";
import AboutUs from "./Component/AboutUs/aboutus";
import ContactUs from "./Component/ContactUs/Contactus";
import CompareProfessor from "./Component/Compare/CompareProfessor";
import { getRelatableJobs } from "./module/features/occupational";
import ForgetPassword from "./Component/Auth/ForgetPassword";
import ConfirmEmail from "./Component/Auth/ConfirmEmail";
import AddDegree from "./Admin/Degree/AddDegree";
import AddConcentration from "./Admin/Concentration/AddConcentration";
const LayoutOutlet = () => {
  const dispatch = useDispatch();
  const courseCompetencies = useSelector(
    (state) => state.competencySlice.courseCompetencies
  );
  const competenciesWithCourse = useSelector(
    (state) => state.competencySlice.selectedCourseCompetenciesWithCourse
  );
  const notSubmitted = useSelector(
    (state) => state.competencySlice.notSubmitted
  );
  const user = useSelector((state) => state.Authentication.user);
  const userProfile = useSelector(
    (state) => state.completeProfileSlice.userProfile
  );
  const tier1 = useSelector((state) => state.coursesSlice.tier1);
  const tier2 = useSelector((state) => state.coursesSlice.tier2);
  const tier3 = useSelector((state) => state.coursesSlice.tier3);
  const [completeProfile, setCompleteProfile] = useState(false);
  const majorCourses = useSelector((state) => state.coursesSlice.majorCourses);
  const AllCompetencieTier = useSelector(
    (state) => state.competencySlice.AllCompetencieTier
  );

  const [lastColor, setLastColor] = useState("");
  useEffect(() => {
    console.log(userProfile, "userProfile");
    if (userProfile) {
      let data = {
        ids: userProfile?.majorCourse + "," + userProfile?.minorCourse,
      };

      // dispatch(getYourCourses({
      //   "student_id": user.id?.toString(),
      //   "course_id": data.ids
      // }))

      // let dataUser = {
      //   "ids": userProfile?.majorCourse + "," + userProfile?.minorCourse,
      //   "userId": userProfile?.id
      // }
      // dispatch(getCourseStudentById(dataUser))

      const toggleTheme = (className, color) => {
        document.getElementsByTagName("body")[0].classList.add(className);
        setLastColor(className);
        if (lastColor) {
          document.getElementsByTagName("body")[0].classList.remove(lastColor);
        }
      };
      themeColors.map((obj) => {
        if (obj.color === userProfile?.theme) {
          toggleTheme(obj.class, obj.color);
        } else {
          document.getElementsByTagName("body")[0].classList.add("blue");
        }
      });

      if (user?.type === "admin") {
        dispatch(getUsers());
      } else {
        // dispatch(getCourseById(data))
        dispatch(getMajorCourses());
        dispatch(getMinorCourses());
        // dispatch(getSelectedCompetenciesById(data))
        dispatch(getSelectedCompetenciesWithCourse());
        dispatch(checkSubmitted());
        dispatch(getUserCompentencies());
      }
    }
    let profile = userProfile;
    console.log(profile, "sadasdasdsadasd");
    if (profile) {
      if (
        profile.fullname &&
        profile.age &&
        profile.bio &&
        profile.entryYear &&
        profile.graduationYear &&
        profile.nameOfCollege &&
        profile.university_id
      ) {
        setCompleteProfile(false);
      } else {
        setCompleteProfile(true);
      }
    } else {
      setCompleteProfile(true);
    }
  }, [userProfile, user]);

  useEffect(() => {
    if (tier1?.length && userProfile) {
      console.log(tier1, "tier1tier1");
      let data = {
        ids:
          tier1[0].id +
          "," +
          userProfile?.majorCourse +
          "," +
          userProfile?.minorCourse,
      };
      // dispatch(getCompetenciesById(data))
      if (tier1?.length) {
        let data = {
          courseIds: [tier1[0].id],
        };
        dispatch(getCompetenciesByIdTier({ data, tier: "1" }));
      }
    }
  }, [tier1, userProfile]);

  useEffect(() => {
    // if (courseCompetencies && courseCompetencies.length) {
    console.log("calledasdasd");
    let arr = [];
    let resultArr = [];
    if (competenciesWithCourse) {
      console.log(competenciesWithCourse, "competenciesWithCourse");
      let minors = competenciesWithCourse?.minor
        ? competenciesWithCourse?.minor
        : [];
      let major = competenciesWithCourse?.major
        ? competenciesWithCourse?.major
        : [];
      let updatedArr = [];
      [...minors, ...major].map((a, i) => {
        a.course_competencies?.map((b) => {
          let data = {
            competencies: [{ ...b.competencies, courseId: b.course.id }],
          };
          if (!updatedArr.includes(data)) {
            updatedArr.push(data);
          }
        });
      });
      resultArr = [...updatedArr];
    }
    if (resultArr.length) {
      console.log(resultArr, "course_competencies");
      resultArr.map(({ competencies }, i) => {
        // course.competencies.map(({competencies}, j) => {
        if (!arr.includes(competencies[0].id)) arr.push(competencies[0].id);
        // })
      });
      console.log(arr, "asdasd");
      let AllCompetencieTierID = AllCompetencieTier.map((val) => val.id).join(
        ","
      );
      console.log(
        AllCompetencieTier,
        AllCompetencieTierID,
        "AllCompetencieTierID + ",
        " + "
      );
      dispatch(
        AllStudentAverageResult({
          userId: userProfile?.id,
          competencyIds: AllCompetencieTierID + "," + arr,
        })
      );
    }
    // }
  }, [competenciesWithCourse, AllCompetencieTier]);

  useEffect(() => {
    if (majorCourses && majorCourses.length) {
      dispatch(getRelatableJobs(majorCourses));
    }
  }, [majorCourses]);
  return (
    <>
      <div className="innerLayout">
        {completeProfile ? (
          <div className="completeProfileToolTip">
            <p>
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M228.9 79.9L51.8 403.1C40.6 423.3 55.5 448 78.9 448h354.3c23.3 0 38.2-24.7 27.1-44.9L283.1 79.9c-11.7-21.2-42.5-21.2-54.2 0zM273.6 214L270 336h-28l-3.6-122h35.2zM256 402.4c-10.7 0-19.1-8.1-19.1-18.4s8.4-18.4 19.1-18.4 19.1 8.1 19.1 18.4-8.4 18.4-19.1 18.4z"></path>
              </svg>
              Your profile is not complete yet!
              <Link to={"/dashboard/Profile?tab=General"}>click here</Link>
            </p>
          </div>
        ) : notSubmitted ? (
          <div className="completeProfileToolTip">
            <p>
              <span>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M228.9 79.9L51.8 403.1C40.6 423.3 55.5 448 78.9 448h354.3c23.3 0 38.2-24.7 27.1-44.9L283.1 79.9c-11.7-21.2-42.5-21.2-54.2 0zM273.6 214L270 336h-28l-3.6-122h35.2zM256 402.4c-10.7 0-19.1-8.1-19.1-18.4s8.4-18.4 19.1-18.4 19.1 8.1 19.1 18.4-8.4 18.4-19.1 18.4z"></path>
                </svg>
              </span>
              Complete pending competency pre-tests before course begins to
              avoid enrollment cancellation.
              <span>
                <Link to={"/dashboard/assesment"}>Click here to attempt</Link>
              </span>
            </p>
          </div>
        ) : null}
        <div className="layoutBody">
          <div
            className="sideBar"
            style={{ backgroundColor: userProfile?.theme }}
          >
            <Sidebar />
          </div>
          <div className="content">
            <Navbar />
            <div className="innerContent">
              <Authentication>
                <Outlet />
              </Authentication>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function LayoutComp() {
  const userProfile = useSelector(
    (state) => state.completeProfileSlice.userProfile
  );
  const user = useSelector((state) => state.Authentication.user);
  const dispatch = useDispatch();
  const navigateToLogout = () => {
    if (
      window.location.pathname.toLowerCase() != "/login" &&
      window.location.pathname.toLowerCase() != "/register" &&
      window.location.pathname.toLowerCase() != "/school" &&
      window.location.pathname.toLowerCase() != "/professionalrating" &&
      window.location.pathname.toLowerCase() != "/professors" &&
      window.location.pathname.toLowerCase() != "/professorrating" &&
      // window.location.pathname.toLowerCase() != '/ProfessorRatingSuccess' &&
      window.location.pathname.toLowerCase() != "/add-professor" &&
      window.location.pathname.toLowerCase() != "/add-university" &&
      window.location.pathname.toLowerCase() != "/universityrating" &&
      window.location.pathname.toLowerCase() != "/compare" &&
      window.location.pathname.toLowerCase() != "/compare-professor" &&
      window.location.pathname.toLowerCase() != "/seeallprofessor" &&
      window.location.pathname.toLowerCase() != "/professorsuccessrating" &&
      window.location.pathname.toLowerCase() != "/aprofessorrating" &&
      window.location.pathname.toLowerCase() != "/seealluniversity" &&
      window.location.pathname.toLowerCase() != "/aboutus" &&
      window.location.pathname.toLowerCase() != "/contactus" &&
      window.location.pathname.toLowerCase() != "/admin/register" &&
      window.location.pathname.toLowerCase() != "/forget-password" &&
      window.location.pathname.toLowerCase() != "/confirm-email" &&
      window.location.pathname.toLowerCase() != "/"
    ) {
      window.location.href = "/";
    }
  };
  useEffect(() => {
    console.log("asdas");
    dispatch(getUser(navigateToLogout));
    // dispatch(getUniversities())
    dispatch(getProfessors());
    dispatch(getCourse());
    dispatch(getOutcomes());
  }, []);
  useEffect(() => {
    if (user) {
      dispatch(getProfile({ id: user.id }));

      dispatch(getCompetencies());
      dispatch(getTierCourses(1));
      dispatch(getTierCourses(2));
      dispatch(getTierCourses(3));
      if (
        window.location.pathname === "/login" ||
        window.location.pathname === "/register"
      ) {
        if (user.type === "admin") {
          window.location.href = "/dashboard/admin/users";
        } else {
          window.location.href = "/dashboard";
        }
      }
    }
  }, [user]);

  const router = createBrowserRouter([
    {
      path: "/login",
      Component: Login,
    },
    {
      path: "/register",
      Component: Register,
    },
    {
      path: "/forget-password",
      Component: ForgetPassword,
    },
    {
      path: "/confirm-email",
      Component: ConfirmEmail,
    },
    {
      path: "/linkedin/callback",
      Component: LinkedInCallback,
    },
    {
      path: "/admin/register",
      Component: AdminRegister,
    },
    {
      path: "/seealluniversity",

      Component: seeAllUniversity,
    },
    {
      path: "/aboutus",

      Component: AboutUs,
    },
    {
      path: "/contactus",

      Component: ContactUs,
    },
    {
      path: "/",
      // Component: LandingPage
      Component: NewLandingPage,
    },
    {
      path: "/professorRating",
      Component: NewProfessorRating,
    },
    {
      path: "/school",
      Component: SchoolComponent,
    },
    {
      path: "/school/professors",
      Component: SearchComponent,
    },
    {
      path: "/compare",
      Component: CompareSchool,
    },
    {
      path: "/compare-professor",
      Component: CompareProfessor,
    },
    {
      path: "/professionalrating",
      Component: ProfessorRating,
    },
    {
      path: "/professors",
      Component: NewProfessorRating,
    },
    {
      path: "/professors-old",
      Component: professor,
    },
    {
      path: "/professors-old",
      Component: professor,
    },
    {
      path: "/seeallprofessor",
      Component: seeAllProfessor,
    },
    {
      path: "/professorsuccessrating",
      Component: ProfessorSuccessRating,
    },
    {
      path: "/aprofessorrating",
      Component: AProfessorRating,
    },
    {
      path: "/UniversityRatingSuccess",
      Component: UniversityRatingSuccess,
    },
    {
      path: "/UniversityRating",
      Component: NewUniversityRating,
    },
    {
      path: "/add-professor",
      Component: AddProfessorLanding,
    },
    {
      path: "/add-university",
      Component: AddUniversityLanding,
    },
    {
      element: <LayoutOutlet />,
      children: [
        {
          path: "/dashboard",
          Component: Home,
        },
        {
          path: "/dashboard/Competency",
          Component: Competency,
        },
        {
          path: "/dashboard/Course",
          Component: Course,
        },
        {
          path: "/dashboard/Course/Add",
          Component: AddYourCourse,
        },
        {
          path: "/dashboard/Occupational",
          Component: Occupational,
        },
        {
          path: "/dashboard/Profile",
          Component: Profile,
        },
        {
          path: "/dashboard/Setting",
          Component: Setting,
        },
        {
          path: "/dashboard/EditSetting",
          Component: EditSetting,
        },
        {
          path: "/dashboard/Admin/add-competency",
          Component: AddCompetency,
        },
        {
          path: "/dashboard/Admin/add-degree",
          Component: AddDegree,
        },
        {
          path: "/dashboard/Admin/add-concentration",
          Component: AddConcentration,
        },
        {
          path: "/dashboard/Admin/add-assesment",
          Component: AddAssesment,
        },
        {
          path: "/dashboard/Admin/add-course",
          Component: AddCourse,
        },
        {
          path: "/dashboard/assesment",
          Component: Assesment,
        },
        {
          path: "/dashboard/AssesmentTier",
          Component: AssesmentTier,
        },
        {
          path: "/dashboard/Admin/Users",
          Component: User,
        },
        {
          path: "/dashboard/Admin/add-university",
          Component: University,
        },
        {
          path: "/dashboard/Admin/add-professor",
          Component: AddProfessor,
        },
        {
          path: "/dashboard/Admin/add-outcomes",
          Component: AddOutcomes,
        },
      ],
    },
  ]);

  return (
    <div className="layout">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <RouterProvider router={router} />
      {/* <ToastContainer /> */}
    </div>
  );
}

export default LayoutComp;
