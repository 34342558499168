import React, { useEffect, useState } from "react";
import "./professorRatingNavbar.scss";
// import logo from "../../Assest/RateMyProfessor/small_rmp_logo_white.4d5ff7fa.svg";
import logo from './../../Assest/logo.png'
import search from "../../Assest/RateMyProfessor/search-white.7b02d8be.svg";
import toggle from "../../Assest/RateMyProfessor/teacherSchoolToggleBtn.svg";
import carat from "../../Assest/RateMyProfessor/caret-down.a8eae91f.svg";
import pencil from "../../Assest/RateMyProfessor/white_pencil.3e84ae13.svg";
import hamburger from '../../Assest/RateMyProfessor/hamburger-icon-light.e395d28a.svg'
import profile from './../../Assest/profile.jpg'
// import search from '../../Assest/RateMyProfessor/search-white.7b02d8be.svg'
import { Dropdown, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { logout } from "../../module/features/authentication";
const { Option } = Select;
function ProfessorRatingNavbar() {
    const user = useSelector(state => state.Authentication.user)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [professorArr, setProfessorArr] = useState([])
    const [universityArr, setUniversityArr] = useState([])
    const professorList = useSelector(e => e.professorSlice.professorList)
    const universityList = useSelector(state => state.universitySlice.universityList)
    useEffect(() => {
        if (professorList) {
            let arr = professorList.map((a, i) => {
                return {
                    value: a.professor_name,
                    label: a.professor_name
                }
            })
            setProfessorArr(arr)
            console.log(professorList, 'professorList')
        }
    }, [professorList])
    const logoutFunction = () => {
        const callback = () => {
            window.location.reload()
        }
        dispatch(logout({ callback }))
    }
    const items = [
        {
            label: 'Setting',
            key: '0',
            onClick: () => {
                navigate('/dashboard/Setting')
            }
        },
        {
            label: `Edit Profile`,
            key: '1',
            onClick: () => {
                navigate('/dashboard/Profile?tab=General')

            }
        },
        {
            type: 'divider',
        },
        {
            label: 'Logout',
            key: '3',
            onClick: () => {
                logoutFunction()
            }
        },
    ];
    const onChange = (value) => {
        // if (professorSchool) {
        //   navigate(`/professors?id=${value}`)
        // } else {
        //   navigate(`/school?id=${value}`)

        // }
        console.log(`selected ${value} `);
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    useEffect(() => {
        if (universityList) {
            let arr = universityList.map((a, i) => {
                return {
                    value: `${a.OBJECTID}`,
                    label: `${a.NAME}`,
                    ...a
                }
            })
            setUniversityArr(arr)
        }
    }, [universityList])
    const [universitySelected, setUniversitySelected] = useState(false)
    const onChangeUniversity = (value) => {
        // if (professorSchool) {
        //   navigate(`/professors?id=${value}`)
        // } else {
        //   navigate(`/school?id=${value}`)

        // }
        setUniversitySelected(JSON.parse(value))
        console.log(`selected ${value} `);
    };
    const onSearchUniversity = (value) => {
        console.log('search:', value);
    };
    const filterOptionUniversity = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    const renderOptionProfessor = (option) => {
        // Customize the rendering of each option
        return (
            <div>
                <span style={{ marginRight: '8px' }}>{option.label}</span>
                <span style={{ color: 'gray' }}>({option.value})</span>
            </div>
        );
    };
    const renderOptionUniversity = (option) => {
        // Customize the rendering of each option
        return (
            <div className="universityListOpt" onClick={() => console.log(option)}>
                <div>
                    <span style={{ marginRight: '8px' }}>{option.label}</span>
                    <div>
                        <span className="location">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 32C167.67 32 96 96.51 96 176c0 128 160 304 160 304s160-176 160-304c0-79.49-71.67-144-160-144zm0 224a64 64 0 1 1 64-64 64.07 64.07 0 0 1-64 64z"></path></svg>
                            &nbsp;
                            {option.CITY ? option.CITY + ", " : ''} {option.COUNTRY ? option.COUNTRY : ''}</span>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <>
            <header className="professor-rating-navbar">
                <div className="professor-rating-navbar-child">
                    <div className="professor-navbar-inner">
                        <img src={logo} alt="logo" className="professor-navbar-inner-img" onClick={()=>{
                            navigate('/')
                        }} />
                        <img src={hamburger} alt="hamburger" className="humbarreger" />
                        <Select
                            defaultValue="school"
                            className="navbar-inner-select"
                            style={{ width: 140, color: "white" }}
                            bordered={false}
                            options={[
                                { value: "school", label: "School" },
                                { value: "professors", label: "Professors" },
                            ]}
                        />
                        <div className="navbar-search">
                            <div className="navbar-search-child flex-start">
                                <Select
                                    showSearch
                                    placeholder="Professor Name"
                                    optionFilterProp="children"
                                    onChange={onChange}
                                    onSearch={onSearch}
                                    filterOption={filterOption}
                                // options={}
                                >
                                    {professorArr.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {renderOptionProfessor(option)}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <span className="dzEziE">at</span>
                            <div className="navbar-search-child">
                                {universitySelected ?
                                    <>
                                        <div className="universityListOpt" onClick={() => console.log(universitySelected)}>
                                            <div>

                                                <span style={{ marginRight: '8px', color: 'white' }}>{universitySelected.label}</span>
                                                <div>
                                                    <span className="location" style={{ color: 'white' }}>
                                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 32C167.67 32 96 96.51 96 176c0 128 160 304 160 304s160-176 160-304c0-79.49-71.67-144-160-144zm0 224a64 64 0 1 1 64-64 64.07 64.07 0 0 1-64 64z"></path></svg>
                                                        &nbsp;
                                                        {universitySelected.CITY ? universitySelected.CITY + ", " : ''} {universitySelected.COUNTRY ? universitySelected.COUNTRY : ''}</span>
                                                </div>
                                            </div>
                                            <svg onClick={() => setUniversitySelected(false)} className="editUni" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M17.263 2.177a1.75 1.75 0 0 1 2.474 0l2.586 2.586a1.75 1.75 0 0 1 0 2.474L19.53 10.03l-.012.013L8.69 20.378a1.753 1.753 0 0 1-.699.409l-5.523 1.68a.748.748 0 0 1-.747-.188.748.748 0 0 1-.188-.747l1.673-5.5a1.75 1.75 0 0 1 .466-.756L14.476 4.963ZM4.708 16.361a.26.26 0 0 0-.067.108l-1.264 4.154 4.177-1.271a.253.253 0 0 0 .1-.059l10.273-9.806-2.94-2.939-10.279 9.813ZM19 8.44l2.263-2.262a.25.25 0 0 0 0-.354l-2.586-2.586a.25.25 0 0 0-.354 0L16.061 5.5Z"></path></svg>
                                        </div>
                                    </>
                                    :
                                    <Select
                                        showSearch
                                        placeholder="University Name"
                                        optionFilterProp="children"
                                        onChange={onChangeUniversity}
                                        onSearch={onSearchUniversity}
                                        filterOption={filterOptionUniversity}

                                    // options={universityArr}
                                    >
                                        {universityArr.map((option) => (
                                            <Option key={option.value} value={JSON.stringify(option)}>
                                                {renderOptionUniversity(option)}
                                            </Option>
                                        ))}
                                    </Select>
                                }

                            </div>
                        </div>

                    </div>
                    <div className="web">
                        <img src={logo} alt="logo" className="web-logo" />
                    </div>
                    {user ?
                        <div className="landingNavBarTool">
                            <NavLink to={'/dashboard'} className="gotodash">Dashboard</NavLink>
                            <Dropdown
                                menu={{
                                    items,
                                }}
                                trigger={['click']}
                            >
                                <div className='navButtons'>
                                    <img src={profile} />
                                </div>
                            </Dropdown>
                        </div> :
                        <>
                            <div className="landing-page-navbar-child">
                                <button className="navbar-child-btn">Login</button>
                                <button className="navbar-child-active">Sign Up</button>
                            </div>
                            <div className="landing-page-navbar-hamburger">
                                <img src={hamburger} alt="hamburger" className="show" />
                                <img src={search} alt="search" className="showOne" />
                            </div>
                        </>
                    }
                </div>
            </header>
            {/* <div className='header-header-section'>
            <header className='header'>
                <div className='header-container'>
                    <div className='header-empty-div'></div>
                    <div className='header-logo'>
                        <div className='mobile-menu-hamburger'>
                            <button className='hamburger'></button>
                        </div>
                        <a href='#' className='logo'>
                            <img src={logo} alt='logo' className='web-logo' />
                        </a>
                        <button className='search-btn'>
                            <img src={search} alt='search' className='search' />
                        </button>
                    </div>
                    <div className='header-search'>
                        <div className='header-search-inner'>
                            <div>
                                <button className='teacherSchoolTogglebutton'>
                                    <span className='togglebtn'>
                                        <img src={toggle} alt='toggle' className='toogal' />
                                    </span>
                                    <div className='toggleBtnText'>
                                        Professors
                                    </div>
                                    <img src={carat} alt='carat' className='carat' />
                                </button>
                            </div>
                        </div>
                        <div className='header-search-inner-outer'>
                            <div className='header-search-school-selector'>
                                at
                            </div>
                            <button className='selectorInput'>
                                <div className='headerSearchInput'>
                                    Quest University
                                </div>
                                <img src={pencil} alt='edit' className='pencil' />
                            </button>
                        </div>
                        <div className='header-search-user-menu'>
                            <div className='loginSignupButton'>
                                <button className='login btn'>Login</button>
                                <button className='signup btn'>Signup</button>
                            </div>
                        </div>
                        <div className='header-search-mobile-view'>
                            <div className='mobile-view'>
                                <button className='mobile-btn'></button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div> */}
        </>
    );
}

export default ProfessorRatingNavbar;
