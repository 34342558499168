import React, { useState } from 'react'
import loginGIF from '../../Assest/loginGIF.json'
import { Link, useNavigate } from 'react-router-dom'
import './Authentication.scss'
import { useLottie } from "lottie-react";
import logo from './../../Assest/logo.png'
import { useDispatch } from 'react-redux';
import { register } from '../../module/features/authentication';
import SocialLogin from './SocialLogin';

function AdminRegister() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const [data, setData] = useState({
    "username": "",
    "password": "",
    "email": "",
    "type": "admin"
  })
  const options = {
    animationData: loginGIF,
    loop: true
  };

  const { View } = useLottie(options);

  const clearState = () => {
    setData({
      "username": "",
      "password": "",
      "email": "",
      "type": "admin"
    })
    // setLoader(false)
    navigate("/login")
  }

  const registerUser = () => {
    // setLoader(true)
    let obj = {
      ...data
    }
    dispatch(register({ data: obj, clearState: clearState, setLoader }))
  }
  return (
    <div className='authenticationPage'>
      <div className='innerAuthenticationPage'>
        <div className='gifBox'>
          {View}
        </div>
        <div className='form center'>
          <div>
            <h3>Register</h3>
          </div>
          <div className='innerForm'>
            <label>
              <p>User Name</p>
              <input value={data.username} placeholder='User Name'
                onChange={(ev) => {
                  let obj = { ...data }
                  obj.username = ev.target.value
                  setData(obj)
                }}
              />
            </label>
            <label>
              <p>Email</p>
              <input value={data.email} placeholder='Email'
                onChange={(ev) => {
                  let obj = { ...data }
                  obj.email = ev.target.value
                  setData(obj)
                }}
              />
            </label>
            <label>
              <p>Password</p>
              <input type='password' value={data.password} placeholder='Password'
                onChange={(ev) => {
                  let obj = { ...data }
                  obj.password = ev.target.value
                  setData(obj)
                }}
              />
            </label>

            <div className='loginButtons'>
              <button className='hoverEffect' onClick={registerUser}>Register</button>
              <p>Don't have an account? <Link to={'/register'}>Register Now</Link></p>
          
          
            </div>

            
          </div>
              <SocialLogin type="admin"
             
              />
        
        </div>
      </div>
    </div>
  )
}

export default AdminRegister