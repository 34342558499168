import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Column from 'antd/es/table/Column';
import React, { useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { DeleteIcon, EditIcon } from '../../Constant/Icons';
import { Popconfirm } from 'antd';


function CoursesTable({ courses, showModal, setMajorMinor, type, confirm }) {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch([], confirm, dataIndex)
                        }}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    return (
        <div className='coursesTableDiv'>
            <Table dataSource={courses}
            >
                <Column width="40%" filterDropdown={getColumnSearchProps('course').filterDropdown} filterIcon={getColumnSearchProps('course').filterIcon} onFilter={getColumnSearchProps('course').onFilter}
                    onFilterDropdownOpenChange={getColumnSearchProps('course').onFilterDropdownOpenChange} title="Course Name" className='tdDiv title cursorNormal' dataIndex="course" key="course"
                    filterSearch

                    render={getColumnSearchProps('course').render}
                />
                <Column width="40%" filterDropdown={getColumnSearchProps('professor_name').filterDropdown} filterIcon={getColumnSearchProps('professor_name').filterIcon} onFilter={getColumnSearchProps('professor_name').onFilter}
                    onFilterDropdownOpenChange={getColumnSearchProps('professor_name').onFilterDropdownOpenChange} title="Professor Name" className='tdDiv title cursorNormal' dataIndex="professor_name" key="professor_name"
                    filterSearch

                    render={getColumnSearchProps('professor_name').render}
                />
                <Column filterDropdown={getColumnSearchProps('semesterType').filterDropdown} filterIcon={getColumnSearchProps('semesterType').filterIcon} onFilter={getColumnSearchProps('semesterType').onFilter}
                    onFilterDropdownOpenChange={getColumnSearchProps('semesterType').onFilterDropdownOpenChange} title="Semester Type" className='tdDiv title cursorNormal' dataIndex="semesterType" key="semesterType"
                    filterSearch

                    render={getColumnSearchProps('semesterType').render}
                />

                <Column title="Actions"
                    align='center'
                    sorter={(a, b) => a.hidden.length - b.hidden.length}
                    render={(text, record, index) => (
                        <div className='actionButtons'>

                            {/* <Tooltip title={"Set Visibility"}>
                        <div>
                            {record.hidden ?
                                <Tag color="red" onClick={() => {
                                    updateTierAndVisiblityCourseFn({
                                        "hidden": 0,
                                    }, record.id)
                                }}>Hidden</Tag>
                                :

                                <Tag color="blue" onClick={() => {
                                    updateTierAndVisiblityCourseFn({
                                        "hidden": 1,
                                    }, record.id)
                                }}>Visible</Tag>
                            }
                        </div>
                    </Tooltip> */}
                            {/* <Tooltip title={"Coming Soon"}> */}
                            {/* <button className='editButton' onClick={(e) => {
                                // e.stopPropagation()
                                showModal(record)
                                setMajorMinor(type)

                            }}>
                                <EditIcon />
                            </button> */}
                            {/* </Tooltip> */}
                            <Popconfirm
                                title="Delete Course"
                                description="Are Your Sure You Want To Delete Course"
                                onConfirm={() => confirm(record,type)}
                                // onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                            >
                                <button className='deleteButton'>
                                    <DeleteIcon />
                                </button>
                            </Popconfirm>
                        </div>
                    )
                    }
                />
            </Table >

        </div >
    )
}

export default CoursesTable