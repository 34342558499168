import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserCourse, getCourse, getMajorCourses, getMinorCourses } from '../../module/features/courses';
import AddNewCourseModal from './AddNewCourseModal';
import CoursesTable from './CoursesTable';


function AddYourCourse() {
    const dispatch = useDispatch()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editState, setEditState] = useState(false)
    const userProfile = useSelector(state => state.completeProfileSlice.userProfile)
    const [yourCoursesArr, setYourCoursesArr] = useState([])
    const [majorMinor, setMajorMinor] = useState('')
    const [yourMajorCourses, setYourMajorCourses] = useState([])
    const [yourMinorCourses, setYourMinorCourses] = useState([])
    const yourCourses = useSelector(state => state.professorCourseSlice.yourCourses)
    const majorCourses = useSelector(state => state.coursesSlice.majorCourses)
    const minorCourses = useSelector(state => state.coursesSlice.minorCourses)
    const showModal = (data) => {
        setIsModalOpen(true);
        setEditState(data)
    };
    const handleOk = () => {
        setIsModalOpen(false);
        setEditState(false)
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setEditState(false)
    };
    const callback = () => {
        dispatch(getMajorCourses())
        dispatch(getMinorCourses())
    }



    const updateTierAndVisiblityCourseFn = (data, id) => {
        // dispatch(updateTierAndVisiblityCourse({ data: data, id: id, callback }))
    }

    useEffect(() => {
        if (yourCourses && yourCourses.length) {
            let arr = yourCourses.map((a, i) => {
                return {
                    ...a.course,
                    ...a.linkedObject,
                    ...a.professor,
                    profId: a?.professor?.id,
                    linkedObjectId: a?.linkedObject?.id
                }
            })
            setYourCoursesArr(arr)
        }
    }, [yourCourses])

    useEffect(() => {
        if (majorCourses) {
            let arr = majorCourses.map((a, i) => {
                return {
                    ...a.course,
                    ...a.professor,
                    profId: a?.professor?.id,
                    courseId: a?.course?.id,
                    id: a.id,
                    semesterType: a?.semesterType,
                    duration: a?.duration
                }
            })
            setYourMajorCourses(arr)
        }
        if (minorCourses) {
            console.log(minorCourses, 'minorCourses')
            let arr = minorCourses.map((a, i) => {
                return {
                    ...a.course,
                    ...a.professor,
                    profId: a?.professor?.id,
                    courseId: a?.course?.id,
                    id: a.id,
                    semesterType: a?.semesterType,
                    duration: a?.duration
                }
            })
            setYourMinorCourses(arr)
        }
        // console.log(majorCourses, 'majorCourses')
    }, [majorCourses, minorCourses])

    const confirmDelete = (data, type) => {
        console.log(data, type, 'asdasdasdasdas')
        dispatch(deleteUserCourse({ id: data.id, type: type, callback }))
    }

    return (
        <div className='AddYourCourse'>
            <div className='innerAddYourCourse'>
                <div className='PageHeading'>
                    <h1 onClick={() => {
                        console.log(yourCoursesArr, 'yourCoursesArr')
                    }}>Major Course</h1>
                    <button className='hoverEffect' onClick={() => {
                        showModal(false)
                        setMajorMinor('major')
                    }}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8Z"></path><path d="M192 474h672q8 0 8 8v60q0 8-8 8H160q-8 0-8-8v-60q0-8 8-8Z"></path></svg>
                        Add Course
                    </button>
                </div>
                <div className='coursesTable'>
                    <CoursesTable
                        courses={yourMajorCourses}
                        showModal={showModal}
                        setMajorMinor={setMajorMinor}
                        type={'major'}
                        confirm={confirmDelete}
                    />
                </div>
                {/* <div className='PageHeading'>
                    <h1 onClick={() => {
                        console.log(yourCoursesArr, 'yourCoursesArr')
                    }}>Minor Course</h1>
                    <button className='hoverEffect' onClick={() => {
                        showModal(false)
                        setMajorMinor('minor')
                    }}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8Z"></path><path d="M192 474h672q8 0 8 8v60q0 8-8 8H160q-8 0-8-8v-60q0-8 8-8Z"></path></svg>
                        Add Course
                    </button>
                </div>
                <div className='coursesTable'>
                    <CoursesTable
                        courses={yourMinorCourses}
                        showModal={showModal}
                        setMajorMinor={setMajorMinor}
                        type={'minor'}
                        confirm={confirmDelete}
                    />
                </div> */}

            </div>
            <AddNewCourseModal
                showModal={showModal}
                handleOk={handleOk}
                handleCancel={handleCancel}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                editState={editState}
                setEditState={setEditState}
                majorMinor={majorMinor}
            />

        </div>
    )
}

export default AddYourCourse