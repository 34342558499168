import { Modal, Select } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../Constant/Spinner/Spinnner'
import { keyedOptions } from '../../Constant/Utils'
import { addAssessments, editAssessments, getAssessments } from '../../module/features/assesments'

function AddAssesmentModal({
    isModalOpen,
    handleOk,
    handleCancel,
    editState,
    status
}) {
    const courses = useSelector(state => state.coursesSlice.courses)
    const competencies = useSelector(state => state.competencySlice.competencies)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const url = new URL(window.location)
    const assessmentId = url.searchParams.get('id')
    const [data, setData] = useState({
        "competency_id": Number(assessmentId),
        "question": "",
        "name": "",
        "key": "",
        "status": status
    })
    const [loader, setLoader] = useState(false)
    const [competenciesArr, setCompetenciesArr] = useState([])
    useEffect(() => {
        if (competencies?.length) {
            let arr = competencies.map((a, i) => {
                return {
                    ...a,
                    value: a.id,
                    label: a.name,
                }
            })
            setCompetenciesArr(arr)
        }
    }, [competencies])
    useEffect(() => {
      setData({
        "competency_id": Number(assessmentId),
        "question": "",
        "name": "",
        "key": "",
        "status": status
      })
    }, [status])
    const clearState = () => {
        setData({
            "competency_id": Number(assessmentId),
            "question": "",
            "name": "",
            "key": "",
            "status": status
        })
    }
    const callback = () => {
        clearState()
        handleOk()
        dispatch(getAssessments({ id: assessmentId }))
    }
    const addAssesmentFun = () => {
        dispatch(addAssessments({ data: data, callback, setLoader }))
    }

    const editAssesmentFun = () => {
        dispatch(editAssessments({ data: data, id: editState.id, callback, setLoader }))
    }
    useEffect(() => {
        if (editState) {
            setData({
                "competency_id": Number(assessmentId),
                "question": editState.question,
                "name": editState.name,
                "key": editState.key,
                "status": status,
            })
        } else {
            clearState()
        }
    }, [editState])

    return (
        <Modal className='addCompetencyModal' title="Add Question" open={isModalOpen} footer={false} onCancel={() => {
            handleCancel();
            clearState()
        }}>
            <div className='form'>
                <label className='addCourseInput'>
                    <p>Competency</p>
                    <Select
                        showSearch
                        style={{
                            width: 200,
                        }}
                        placeholder="Select Course"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        disabled
                        value={data.competency_id}

                        options={competenciesArr}
                        onChange={(ev) => {
                            let obj = { ...data }
                            obj.competency_id = ev
                            setData(obj)
                        }}
                    />
                </label>

                <label className='addCourseInput'>
                    <p>Question Name</p>
                    <input placeholder='Question Name'
                        value={data.name}
                        onChange={(ev) => {
                            let obj = { ...data }
                            obj.name = ev.target.value
                            setData(obj)

                        }}
                    />
                </label>
                <label className='addCourseInput'>
                    <p>Question Key</p>
                    <Select
                        showSearch
                        style={{
                            width: 200,
                        }}
                        placeholder="Select Question Key"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        value={data.key}

                        options={keyedOptions}
                        onChange={(ev) => {
                            let obj = { ...data }
                            obj.key = ev
                            setData(obj)
                        }}
                    />
                </label>
                <label className='addCourseInput questionInput'>
                    <p>Question </p>
                    <textarea rows={4} placeholder=' write your question here'
                        value={data.question}
                        onChange={(ev) => {
                            let obj = { ...data }
                            obj.question = ev.target.value
                            setData(obj)

                        }}
                    ></textarea>
                </label>

            </div>
            <div className='footer'>
                <button className='addCompetencyButton cancel' onClick={handleCancel}>Cancel</button>
                {editState ?
                    <button className='addCompetencyButton add' onClick={editAssesmentFun}>{loader ? <Spinner /> : `Edit Assessment`}</button>
                    :
                    <button className='addCompetencyButton add' onClick={addAssesmentFun}>{loader ? <Spinner /> : `Add Assessment`}</button>
                }
            </div>
        </Modal>
    )
}

export default AddAssesmentModal