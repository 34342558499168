import React from "react";
import "./addForm.scss";
import ProfessorRatingNavbar from "../Navbar/professorRatingNavbar";
import AddProfessorLanding from "./AddProfessor";
import NewLandNavBar from "../Navbar/NewLandNavBar";

export default () => {
  return (
    <div className="rating-section">
      <div className="navbar-section">
        <NewLandNavBar bg={'#f1f7ff'} />
      </div>
      <AddProfessorLanding />
    </div>
  );
};
