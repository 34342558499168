import { Modal, Select } from 'antd';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from '../../module/features/completeProfile';
import { getUser } from '../../module/features/authentication';
import Spinner from '../../Constant/Spinner/Spinnner';

function AddCourseModal({
    isModalOpen,
    handleOk,
    handleCancel

}) {
    const courses = useSelector(state => state.coursesSlice.courses)
    const userProfile = useSelector(state => state.completeProfileSlice.userProfile)
    const [coursesArr, setCoursesArr] = useState([])
    const [loader,setLoader] = useState(false)
    const dispatch = useDispatch()
    const callback = () =>{
        dispatch(getUser(false))
    }
    const [data, setData] = useState({
        majorCourse: [],
        minorCourse: []
    })
    const clearState = () => {
        setData({
            majorCourse: [],
            minorCourse: []
        })
    }
    useEffect(() => {
        if (courses?.length) {
            let arr = courses.map((a, i) => {
                return {
                    ...a,
                    value: a.id,
                    label: a.course,
                }
            })
            setCoursesArr(arr)
        }
    }, [courses])
    useEffect(() => {
        if (userProfile) {
            let obj = {
                majorCourse: userProfile?.majorCourse?.split(',').map((a) => {
                    return (
                        Number(a)
                    )
                }),
                minorCourse: userProfile?.minorCourse?.split(',').map((a) => {
                    return (
                        Number(a)
                    )
                })
            }
            setData(obj)
        }
    }, [userProfile])

    const completeProfileFunction = () => {
        let obj = {
            ...data,
            majorCourse: data?.majorCourse?.join(','),
            minorCourse: data?.minorCourse?.join(','),
        }
        delete obj.email
        delete obj.updated_at
        delete obj.created_at
        delete obj.id
        delete obj.username
        console.log(userProfile,'userProfileuserProfile')
        dispatch(updateProfile({ data: obj, callback, setLoader, id: userProfile?.profile?.id }))

    }

    return (
        <Modal className='AddCourseModal' title="Add Course" open={isModalOpen} footer={false} onCancel={() => { handleCancel() }}>
            <div className='form' style={{flexDirection:'column'}}>
                <label style={{ width: "100%" }}>
                    <p>Major</p>
                    {/* <input placeholder='-Choose your major-'
                            value={data.majorCourse}
                            onChange={(ev) => {
                                let obj = { ...data }
                                obj.majorCourse = ev.target.value
                                setData(obj)

                            }}
                        /> */}
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        value={data.majorCourse}
                        options={coursesArr}
                        onChange={(ev) => {
                            let obj = { ...data }
                            obj.majorCourse = ev
                            setData(obj)

                        }}
                    />
                </label>
                <label style={{ width: "100%" }}>
                    <p>Courses Taken</p>
                    {/* <input placeholder='-Choose the courses you have taken-'
                            value={data.minorCourse}
                            onChange={(ev) => {
                                let obj = { ...data }
                                obj.minorCourse = ev.target.value
                                setData(obj)

                            }}
                        /> */}
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        value={data.minorCourse}
                        options={coursesArr}
                        onChange={(ev) => {
                            let obj = { ...data }
                            obj.minorCourse = ev
                            setData(obj)

                        }}
                    />
                </label>
            </div>
            <button className='addCourseButton hoverEffect'
                onClick={completeProfileFunction}
            >{
                    loader ? <Spinner /> : `Add Course` 
                }</button>
        </Modal>
    )
}

export default AddCourseModal