import React, { useEffect, useState } from 'react'
import LandingPageNavbar from '../../Navbar/NewLandNavBar'
import SearchSchool from '../../SearchSchool/SearchSchool'
import './success.scss'
// import '../../schoolComponents/schoolComponents.scss'
import { Button, Dropdown, message, Space, Tooltip } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Popover, Progress } from 'antd'
import LandingPageFooter from '../../footer/LandingPageFooter'
import { useSelector } from 'react-redux';


function ProfessorSuccessRating() {

    const [professorArr, setProfessorArr] = useState([])
    const professorList = useSelector(e => e.professorSlice.professorList)


    // useEffect(() => {
    // console.log("Professor List eeeeeeeeee", professorList)

    //     if (professorList) {
    //         let arr = professorList.map((a, i) => {
    //             return {
    //                 value: a.id,
    //                 label: a.professor_name
    //             }
    //         })
    //         setProfessorArr(arr)
    //         console.log(professorList, 'professorList See aLL')
    //     }




    // }, [professorList])
    //    console.log(professorArr, 'professorArr')
    // professorList.map((professor, i) => {
    //     console.log("Professor List", professor_name);
    // })

    const handleButtonClick = (e) => {
        console.log('click left button', e);
    };
    const handleMenuClick = (e) => {
        console.log('click', e);
    };
    const items = [
        {
            label: '1st menu item',
            key: '1',
        },
        {
            label: '2nd menu item',
            key: '2',
        },
        {
            label: '3rd menu item',
            key: '3',
        },
        {
            label: '4rd menu item',
            key: '4',
        },
    ];
    const menuProps = {
        items,
        onClick: handleMenuClick,
    };
    return (
        <>
            <div className='main-component-container' style={{ overflow: "auto" }}>
                <LandingPageNavbar bg={'#f1f7ff'} />
                <div className='searchComponent'>
                    <SearchSchool />
                </div>
                <div className='compo'>
                    <h5 className='heading-professors'>{professorList?.length}  Professors <span className='heading-professors-bold'>Found</span></h5>
                </div>
                <div className='department'>
                    <h5 className='department-heading'>Department</h5>
                    <div className='dropsown'>
                        <Space wrap>
                            <Dropdown menu={menuProps} className='dropbtn'>
                                <Button>
                                    <Space>
                                        Department...
                                        <DownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                        </Space>
                    </div>
                </div>
                <div className='school-rating'>
                    <ul className='ratingList-ul'>


                        <li className='styled-listed'>
                            {professorList?.map((professor) => {

                                return (
                                    <>
                                        <div className='ratingStyle-rating'>
                                            <div className='ratingBody'>

                                                <div className='ratingValue'>
                                                    <div className='ratingValue-RatingContainers'>
                                                        <div className='cardNumRating'>
                                                            <div className='cardNumRating-header'>Overall</div>
                                                            <div className='cardNumRating-number'>50</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='ratingInfo'>
                                                    <div className='ratingheader'>
                                                        <div className='RatingHeader__ClassInfoWrapper'>
                                                            <div className='RatingHeader__StyledClass'></div>
                                                        </div>
                                                        <div className='TimeStamp__StyledTimeStamp'>Apr14,2020</div>
                                                    </div>
                                                    <div className='comments'>
                                                        Oddly functional chaos … mean nurse professor and the nursing students are rude as ever .. great
                                                        central processing and surgical tech students
                                                        <hr />

                                                        <div className='namess'>
                                                            <h4 className='bold'>{professor.professor_name}</h4>
                                                            <h4 className='normal'>English</h4>
                                                            <h4 className='normal'>Govt, premier collage...</h4>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                      
                                    </>
                                );

                            })}
                              <div className='CompareThisProfessor'>
                                            <button className='compareProfessor'>Compare This Professor</button>
                                        </div>
                        </li>


                    </ul>

                </div>
                <div className='looking'>
                    <p className='normal'>
                        Don't see the professor you're
                        looking for?
                    </p>
                    <p className='normal' style={{ textDecoration: "underline" }}>Add a Professor</p>
                </div>

                <div className='footer'>
                    <LandingPageFooter />
                </div>
            </div>
        </>
    )
}

export default ProfessorSuccessRating;