import React, { useState } from "react";
// import "./LandingPageNavbar.scss";
// import "./LandingPage.scss";
import "../LandingPage/LandingPage.scss"
import "./NewLandNavBar.scss";
import "./ColouredNavbar.scss";
import Facebooks from '../../Assest/RateMyProfessor/facebook-black.942803b4.svg';
import Instagrams from '../../Assest/RateMyProfessor/instagram-black.b1c1f7b5.svg';
import Twitters from '../../Assest/RateMyProfessor/twitter-black.9e8418fe.svg';
import { NavLink, useNavigate } from "react-router-dom";
import { Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import profile from './../../Assest/profile.jpg'
import image from './../../Assest/logo.png'
import NavBarLogo from './../../Assest/NavBarLogo.png'
import { logout } from "../../module/features/authentication";
import { Button, Drawer, Radio, Space } from 'antd';
function LandingPageNavbar({bg}) {
  const user = useSelector(state => state.Authentication.user)
  const dispatch = useDispatch()
  const logoutFunction = () => {
    const callback = () => {
      window.location.reload()
    }
    dispatch(logout({ callback }))
  }
  const items = [
    {
      label: 'Setting',
      key: '0',
      onClick: () => {
        navigate('/dashboard/Setting')
      }
    },
    {
      label: `Edit Profile`,
      key: '1',
      onClick: () => {
        navigate('/dashboard/Profile?tab=General')

      }
    },
    {
      type: 'divider',
    },
    {
      label: 'Logout',
      key: '3',
      onClick: () => {
        logoutFunction()
      }
    },
  ];
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState('left');


  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };



  return (

    <>

      <div className=" landing-page-navbar" style={{backgroundColor:bg}}>

        <div className="ungradeLogo" onClick={() => {
                navigate(`/`)
              }}>
          <img src={NavBarLogo} />
        </div>

        <div className="navbar-options NonBurger_NavBar ">
           <div className="navbar-options-inner">
           <p onClick={() => {
                navigate(`/seealluniversity`)
              }} >Universities</p>
           <p onClick={() => {
                navigate(`/seeallprofessor`)
              }}>Professors</p>
      
            {/* <p>Compentencies</p>
            <p>About Us</p>
            <p>Contact Us</p> */}

          </div>
        

        </div>

        {user ?
            <div className="landingNavBarTool">
              <NavLink to={'/dashboard'} className="gotodash">Dashboard</NavLink>
              <Dropdown
                menu={{
                  items,
                }}
                trigger={['click']}
              >
                <div className='navButtons'>
                  <img src={profile} />
                </div>
              </Dropdown>
            </div>
            :
            <div className="landing-page-navbar-child">
              <button className="navbar-child-btn login" onClick={() => {
                navigate(`/login`)
              }}>LOGIN</button>
              <button className="navbar-child-active signup" onClick={() => {
                navigate(`/register`)
              }}>SIGN UP</button>
            </div>
          } 
        <div className="Burger_NavBar">

          <Space >

            <svg onClick={showDrawer} className="burger-icon" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg"><path d="M32 96v64h448V96H32zm0 128v64h448v-64H32zm0 128v64h448v-64H32z" color="#053C6F" ></path></svg>
          </Space>
          <Drawer
           
            placement={placement}
            closable={false}
            onClose={onClose}
            open={open}
            key={placement}
          >
            <div className="ungradeLogo">
              <img src={NavBarLogo} />
            </div>
                <div className="navbar-options2 ">
          <p>Universities</p>
          <p>Professors</p>
          <p>Compentencies</p>
          <p>About Us</p>
          <p>Contact Us</p>
          {user ?
            <div className="landingNavBarTool">
              <NavLink to={'/dashboard'} className="gotodash">Dashboard</NavLink>
              <Dropdown
                menu={{
                  items,
                }}
                trigger={['click']}
              >
                <div className='navButtons'>
                  <img src={profile} />
                </div>
              </Dropdown>
            </div>
            :
            <div className="landing-page-navbar-child2">
              <button className="navbar-child-btn login2" onClick={() => {
                navigate(`/login`)
              }}>LOGIN</button>
              <button className="navbar-child-active signup2" onClick={() => {
                navigate(`/register`)
              }}>SIGN UP</button>
            </div>
          }
        </div>
          </Drawer>
        </div>


      </div>










    </>



  );
}

export default LandingPageNavbar;
