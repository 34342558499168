import React, { useEffect, useState } from 'react'
import { getanswers } from '../../module/features/assesments'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CompetencyBox from '../../Constant/Competency/CompetencyBox'
import NotAdded from '../../Constant/NotAdded'
import { Select } from 'antd'
import { professorCourseAdd } from '../../module/features/ProfessorCourse'

function CourseCompetency({ courseCompetencies, rerender, setRerender, course }) {
    const dispatch = useDispatch()
    const user = useSelector(state => state.Authentication.user)
    const AllCompetencieResult = useSelector(state => state.competencySlice.AllCompetencieResult)
    // const yourCourses = useSelector(state => state.professorCourseSlice.yourCourses)
    const [yourCourses, setYourCourses] = useState([])
    const competenciesWithCourse = useSelector(state => state.competencySlice.selectedCourseCompetenciesWithCourse)
    const professorList = useSelector(state => state.professorSlice.professorList)
    const [professorArr, setProfessorArr] = useState([])
    const [courseCompetenciesArr, setCourseCompetenciesArr] = useState([])
    const competencies = useSelector(state => state.competencySlice.competencies)
    const majorCourses = useSelector(state => state.coursesSlice.majorCourses)
    const minorCourses = useSelector(state => state.coursesSlice.minorCourses)

    useEffect(() => {
        console.log(competenciesWithCourse, 'competenciesWithCourse')
        if (competenciesWithCourse) {
            let minors = competenciesWithCourse?.minor ? competenciesWithCourse?.minor : []
            let major = competenciesWithCourse?.major ? competenciesWithCourse?.major : []
            let updatedArr = [];
            // let arr = []
            ([...minors, ...major]).map((a, i) => {
                a.course_competencies?.map((b) => {
                    let data = { competencies: { ...b.competencies, courseId: b.course.id } }
                    if (!updatedArr.includes(data)) {
                        updatedArr.push(data)
                    }
                })
                //   updatedArr.push(data)
            })
            console.log(updatedArr, 'updatedArrupdatedArr')
            setCourseCompetenciesArr(updatedArr)
        }
    }, [competenciesWithCourse])


    const navigate = useNavigate()
    useEffect(() => {
        const url = new URL(window.location)
        const tier = url.searchParams.get('tier')
        console.log(courseCompetencies, 'courseCompetenciescourseCompetencies')
        if (courseCompetencies?.length) {
            let arr = []
            courseCompetencies.map((a, i) => {
                let score = 0
                let PostscoreIndividual = 0
                AllCompetencieResult && AllCompetencieResult.map((b, j) => {
                    if (a.id === b.competencyId) {
                        if (b.scoreIndividual) {
                            score = b.scoreIndividual
                        }
                        if (b.PostscoreIndividual) {
                            PostscoreIndividual = b.PostscoreIndividual
                        }
                    }
                })
                if (score || PostscoreIndividual) {
                    arr.push({
                        ...a,
                        score: score ? score : 0,
                        PostscoreIndividual: PostscoreIndividual ? PostscoreIndividual : 0
                    })
                } else {
                    arr.push({
                        ...a
                    })
                }

            })
            console.log(arr, 'arrr')
            setCourseCompetenciesArr(arr)
        }


    }, [courseCompetencies, AllCompetencieResult, competencies])

    useEffect(() => {

        if (professorList) {
            let arr = professorList.map((a, i) => {
                return {
                    value: a.id,
                    label: a.professor_name
                }
            })
            setProfessorArr(arr)
            console.log(professorList, 'professorList')
        }
    }, [professorList])

    useEffect(() => {
        if (majorCourses &&
            minorCourses) {
            let arrMajor = majorCourses || [];
            let arrMinor = minorCourses || [];
            setYourCourses([...arrMajor, ...arrMinor])
        }
    }, [majorCourses,
        minorCourses])


    return (
        <>
            <div className='addPRofComp'>
                <h3 className='competencyHeading'>{course.course}</h3>

                {/* <Select
                    showSearch
                    style={{
                        width: 200,
                    }}
                    placeholder="Search Your Professor"
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={professorArr}
                    onSelect={(value) => {
                        console.log(user, 'useruser')
                        dispatch(professorCourseAdd({
                            data: {
                                "course_id": course.id,
                                "professor_Id": value,
                                "student_id": user.id
                            }
                        }))
                        console.log(value, 'value')
                    }}
                /> */}
            </div>
            <div className='competencyDiv'>
                {courseCompetenciesArr?.length ? courseCompetenciesArr.map(({ competencies }, i) => {
                    console.log(competencies,'competenciescompetencies')
                    let resultFound = AllCompetencieResult?.filter(obj => obj.competency_id === competencies[0]?.id)
                    let courseData = yourCourses.filter((obj) =>
                        (obj.course.id == competencies?.course?.id) || (obj.course.id == competencies[0]?.courseId)
                    )
                    console.log(courseData,'courseDatacourseDatacourseData')
                    let data = {
                        "id": competencies[0]?.id || competencies[0]?.competency_id,
                        "courseId": competencies[0]?.courseId || competencies[0]?.course_id,
                        "name": competencies[0]?.name || competencies[0]?.course_course,
                        "level": competencies[0]?.competency_level || competencies[0]?.level,
                        "average": competencies[0]?.competency_average || competencies[0]?.average,
                        "hidden": competencies[0]?.hidden || competencies[0]?.competency_hidden,
                        "tier": competencies[0]?.competency_tier || competencies[0]?.tier,
                        "duration": courseData.length ? courseData[0].duration : [],
                        "createdAt": courseData.length ? courseData[0].createdAt : [],
                        // 'score': a.score,
                        // 'PostscoreIndividual': a.PostscoreIndividual,
                    }
                    // console.log(data, 'competenciescompetencies')
                    if (resultFound?.length) {
                        data.score = resultFound[0]?.pre_score;
                        data.PostscoreIndividual = resultFound[0]?.post_score
                    }
                    return !competencies?.hidden || !competencies?.competency_hidden
                        ? (
                            <>
                                <CompetencyBox data={data}
                                    onClick={() => {
                                        let professorFound = yourCourses.filter((obj) =>
                                            obj.course.id == data.courseId
                                        )
                                        if (professorFound.length) {
                                            navigate(`/dashboard/assesment?assesmentId=${data.id}&professorId=${professorFound[0].professor.id}&university_id=${professorFound[0].professor.university_id}&course_id=${professorFound[0].course.id}`)
                                            setRerender(!rerender)
                                            dispatch(getanswers({
                                                "userId": user.id,
                                                "competencyId": data.id
                                            }))
                                        }
                                    }}
                                />
                            </>
                        ) : null
                }) :
                    <NotAdded text={"This course doesn't have any competency yet"} addCourse={false} />
                }
            </div>
        </>
    )
}

export default CourseCompetency