import React, { useEffect, useRef } from 'react'
import './Course.scss'
import { Progress, Rate } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import AddCourseModal from './AddCourseModal'
import { useNavigate } from 'react-router-dom'
import { Button, Input, Space, Table } from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import Column from 'antd/es/table/Column';
import { getCourseProgress, getCourseTracking } from '../../module/features/courses'

function Course() {
    const yourCourses = useSelector(state => state.coursesSlice.yourCourses)
    const courseTracking = useSelector(state => state.coursesSlice.courseTracking)
    const courseProgessInfo = useSelector(state => state.coursesSlice.courseProgessInfo)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [completeted, setCompleted] = useState(false)
    const [inprogress, setinprogress] = useState(false)
    const [notStarted, setnotStarted] = useState(false)
    const [coursesList, setCoursesList] = useState([])
    const [yourMajorCourses, setYourMajorCourses] = useState([])
    const [yourMinorCourses, setYourMinorCourses] = useState([])
    const majorCourses = useSelector(state => state.coursesSlice.majorCourses)
    const minorCourses = useSelector(state => state.coursesSlice.minorCourses)
    useEffect(() => {
        dispatch(getCourseTracking())
    }, [])
    useEffect(() => {
        console.log(courseProgessInfo, 'courseProgessInfo')
        renderCourses(courseProgessInfo || [])
    }, [majorCourses, minorCourses, courseProgessInfo])
    const renderCourses = (scoreArr) => {
        if (majorCourses) {
            let arr = majorCourses.map((a, i) => {
                let score = 0
                scoreArr.map((course, i) => {
                    if (a?.course?.id === course.courseId) {
                        score += course.result
                    }
                })
                return {
                    ...a.course,
                    ...a.professor,
                    profId: a?.professor?.id,
                    courseId: a?.course?.id,
                    id: a.id,
                    result: score
                }
            })
            setYourMajorCourses(arr)
        }
        if (minorCourses) {
            let arr = minorCourses.map((a, i) => {
                let score = 0
                scoreArr.map((course, i) => {
                    if (a?.course?.id === course.courseId) {
                        score += course.result
                    }
                })
                return {
                    ...a.course,
                    ...a.professor,
                    profId: a?.professor?.id,
                    courseId: a?.course?.id,
                    id: a.id,
                    result: score
                }
            })
            setYourMinorCourses(arr)
        }
    }
    useEffect(() => {
        let arr = [...yourMajorCourses, ...yourMinorCourses]
        dispatch(getCourseProgress({
            id: arr.map((a, i) => {
                return a.courseId
            })
        }))

    }, [yourMajorCourses.length, yourMinorCourses.length])
    useEffect(() => {
        console.log(yourMajorCourses, yourMinorCourses, 'asasdasdas')
    }, [yourMajorCourses, yourMinorCourses])
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        if (courseTracking) {
            console.log(courseTracking, 'courseTracking')
            setCompleted(courseTracking.completedCourse)
            setinprogress(courseTracking.inprogress)
            setnotStarted(courseTracking.notStarted)
        }
    }, [courseTracking])

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch([], confirm, dataIndex)
                        }}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    return (
        <div className='course'>
            <div className='innerCourse'>
                <div className='tracking'>
                    <div className='trackingHead'>
                        <h3>Course Tracking</h3>
                        <button onClick={() => {
                            // setIsModalOpen(true)
                            navigate(`/dashboard/Profile?tab=Courses`)
                        }} className='hoverEffect'>New Course <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8Z"></path><path d="M192 474h672q8 0 8 8v60q0 8-8 8H160q-8 0-8-8v-60q0-8 8-8Z"></path></svg></button>
                    </div>
                    <div className='trackingBox'>
                        <div className='track'>
                            <h4>In Progress</h4>
                            <span className='trackNumber'>
                                <h2>{inprogress}</h2>
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path></svg>
                            </span>

                        </div>
                        <div className='track'>
                            <h4>Completed</h4>
                            <span className='trackNumber'>
                                <h2>{completeted}</h2>
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0 0 51.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z"></path><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path></svg>
                            </span>

                        </div>
                        <div className='track'>
                            <h4>Not Started</h4>
                            <span className='trackNumber'>
                                <h2>{notStarted}</h2>
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0 0 51.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z"></path><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path></svg>
                            </span>

                        </div>
                    </div>
                </div>
                <div className='coursesRecomended'>
                    <h3>Your Major Courses</h3>
                    <div className='coursesTable'>
                        <div className='coursesTableDiv'>
                            <Table dataSource={yourMajorCourses}
                            >
                                <Column width="40%" filterDropdown={getColumnSearchProps('course').filterDropdown} filterIcon={getColumnSearchProps('course').filterIcon} onFilter={getColumnSearchProps('course').onFilter}
                                    onFilterDropdownOpenChange={getColumnSearchProps('course').onFilterDropdownOpenChange} title="Course Name" className='tdDiv title cursorNormal' dataIndex="course" key="course"
                                    filterSearch

                                    render={getColumnSearchProps('course').render}
                                />
                                <Column width="30%" filterDropdown={getColumnSearchProps('professor_name').filterDropdown} filterIcon={getColumnSearchProps('professor_name').filterIcon} onFilter={getColumnSearchProps('professor_name').onFilter}
                                    onFilterDropdownOpenChange={getColumnSearchProps('professor_name').onFilterDropdownOpenChange} title="Professor Name" className='tdDiv title cursorNormal' dataIndex="professor_name" key="professor_name"
                                    filterSearch

                                    render={getColumnSearchProps('professor_name').render}
                                />
                                <Column width="30%" title={'Competency Level'} render={(text,record) => {
                                    return (

                                        <div className='progressDiv'>
                                            <Progress className='progress' percent={record.result * 100} showInfo={false} strokeColor={"#7b77d0"} />
                                            <div className='percentCompetency'>
                                                {(record.result * 100).toFixed(2)}%
                                            </div>
                                        </div>
                                    )
                                }} />
                            </Table>

                        </div>
                    </div>
                    {/* <h3>Your Minor Courses</h3>
                    <div className='coursesTable'>
                        <div className='coursesTableDiv'>
                            <Table dataSource={yourMinorCourses}
                            >
                                <Column width="40%" filterDropdown={getColumnSearchProps('course').filterDropdown} filterIcon={getColumnSearchProps('course').filterIcon} onFilter={getColumnSearchProps('course').onFilter}
                                    onFilterDropdownOpenChange={getColumnSearchProps('course').onFilterDropdownOpenChange} title="Course Name" className='tdDiv title cursorNormal' dataIndex="course" key="course"
                                    filterSearch

                                    render={getColumnSearchProps('course').render}
                                />
                                <Column width="30%" filterDropdown={getColumnSearchProps('professor_name').filterDropdown} filterIcon={getColumnSearchProps('professor_name').filterIcon} onFilter={getColumnSearchProps('professor_name').onFilter}
                                    onFilterDropdownOpenChange={getColumnSearchProps('professor_name').onFilterDropdownOpenChange} title="Professor Name" className='tdDiv title cursorNormal' dataIndex="professor_name" key="professor_name"
                                    filterSearch

                                    render={getColumnSearchProps('professor_name').render}
                                />
                                <Column width="30%" title={'Competency Level'} render={(text,record) => {
                                    return (
                                        <div className='progressDiv'>
                                            <Progress className='progress' percent={record.result * 100} showInfo={false} strokeColor={"#7b77d0"} />
                                            <div className='percentCompetency'>
                                                {(record.result * 100).toFixed(2)}%
                                            </div>
                                        </div>
                                    )
                                }} />
                            </Table>

                        </div>
                    </div> */}
                    {/* <div className='coursesTableDiv'>

                        <table className='coursesTable hideMob'>
                            <thead>
                                <tr>
                                    <th className='title'>Course Title</th>
                                    <th className='level'>Competency Level</th>
                                </tr>
                            </thead>
                            <tbody>
                                {coursesList?.map((a, i) => (
                                    <tr>
                                        <td>
                                            <div>
                                                {a.course ? a.course[0].course : ''}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='progressDiv'>
                                                <Progress className='progress' percent={a.level * 100} showInfo={false} strokeColor={"#7b77d0"} />
                                                <div className='percentCompetency'>
                                                    {(a.level * 100).toFixed(2)}%
                                                </div>
                                            </div>
                                        </td>
                                
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div> */}

                    {/* <div className='coursesList hidePc'>
                        <div className='innerCoursesList'>
                            {coursesList?.map((a, i) => (
                                <div className='courseCard'>
                                    <div className='title'>
                                        <h2>Course Title</h2>
                                        <p>{a.course ? a.course[0].course : ''}</p>
                                    </div>
                                    <div className='progressLevel'>
                                        <Progress className='progress' percent={a.level * 100} showInfo={false} strokeColor={"#7b77d0"} />
                                        <div className='percentCompetency'>
                                            {(a.level * 100).toFixed(2)}%
                                        </div>
                                    </div>
                         
                                </div>
                            ))}
                        </div>
                    </div> */}
                    <AddCourseModal
                        isModalOpen={isModalOpen}
                        handleOk={handleOk}
                        handleCancel={handleCancel}
                    />
                </div>
            </div>
        </div>
    )
}

export default Course